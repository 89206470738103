import Login from 'components/app/views/login';
import Otp from 'components/app/views/login/otp';
import LoginOAuth from 'components/app/views/login/loginWithOAuth';
import SponsorLogin from 'components/app/views/login/sponsorLogin';
import NoAccess from 'components/app/views/noAccess';
import NoMatch from 'components/app/views/noMatch';

// import ManagerDashboard from 'components/app/views/dashboard/manager';
import Reports from 'components/app/views/reports';
import Onboarding from 'components/app/views/onboarding';
import Previewer from 'components/app/views/previewer';
// import Profiles from 'components/app/views/apps/showcase/profiles';

import ShowcaseDashboard from 'components/app/views/apps/showcase/showcaseDashboard';
import Content from 'components/app/views/apps/showcase/content';

import LeadsDashboard from 'components/app/views/apps/leads/leadsDashboard';
import LeadsReport from 'components/app/views/apps/leads/leadsDashboard/report';

import ProjectDashboard from 'components/app/views/dashboard/manager2.0'; // Manager2, should be renamed for better maintenance
import CompanyDashboard from 'components/app/views/dashboard/manager2.0/companyListing'; // Manager2, should be renamed for better maintenance

// import ProjectSettings from 'components/app/views/dashboard/manager2.0/settings';
import ProjectCreate from 'components/app/views/dashboard/manager2.0/create';
import VenueCollectionDashboard from 'components/app/views/venuecollections';
// import VirtualMenus from 'components/app/views/apps/virtual/menus';
import VirtualHome from 'components/app/views/apps/virtual/home';
// import VirtualNavigation from 'components/app/views/apps/virtual/navigation';
// import VirtualAuthentication from 'components/app/views/apps/virtual/authentication';

// import PeopleHome from 'components/app/views/apps/people/home';
// import UploadPeopleFile from 'components/app/views/apps/people/import/uploadPeopleFile';
// import MapFields from 'components/app/views/apps/people/import/mapFields';
// import MapFieldsEdit from 'components/app/views/apps/people/import/mapFieldsEdit';
// import ChooseAction from 'components/app/views/apps/people/import/chooseAction';

// import SelectDataIntegration from 'components/app/views/apps/people/dataIntegration/selectDataIntegration';
// import DataIntegration from 'components/app/views/apps/people/dataIntegration/';
// import Fields from 'components/app/views/apps/people/Fields';
// import GevmeLogin from 'components/app/views/apps/people/dataIntegration/gevmeIntegration/gevmeLogin';
import GevmeRedirect from 'components/app/views/apps/people/dataIntegration/gevmeIntegration/gevmeRedirect';
// import SelectEvents from 'components/app/views/apps/people/dataIntegration/gevmeIntegration/selectEvents';

// import SponsorshipPackages from 'components/app/views/apps/sponsorship/packages';
// import SponsorshipSponsors from 'components/app/views/apps/sponsorship/sponsors';

import SponsorCompanyProfiles from 'components/app/views/dashboard/sponsorship/sponsorCompanyProfiles';

// import SponsorEdit from 'components/app/views/apps/sponsorship/sponsors/sponsors/sponsorEdit';
// import UserImport from 'components/app/views/apps/sponsorship/sponsors/import/userImport';
// import CompanyImport from 'components/app/views/apps/sponsorship/sponsors/import/companyImport';

// import LiveHome from 'components/app/views/apps/live/';
// import LivePageHome from 'components/app/views/apps/live/pages';
// import LivePageCreate from 'components/app/views/apps/live/pages/create';
// import LivePageSettings from 'components/app/views/apps/live/pages/liveSettings';
// import LiveContent from 'components/app/views/apps/live/pages/liveContent';
// import Widgets from 'components/app/views/apps/live/pages/widgets/';
// import SiteContent from 'components/app/views/apps/live/site/siteContent/';
// import SiteSettings from 'components/app/views/apps/live/site/siteSettings/';
// import WidgetChatAdminDashboard from 'components/app/views/apps/live/pages/widgets/widgetChatAdminDashboard';
// import Templates from 'components/app/views/apps/live/pages/templates';
import Analytics from 'components/app/views/apps/live/analytics';

import VenueBuilder from '../Apps/VenueBuilder';
import ChatModeration from '../Apps/ChatModeration';
// import VideoApp from '../Apps/Video';
// import MeetingsApp from '../components/app/views/apps/meetings';

// import RecommendationEngine from '../Apps/RecommendationEngine';
// import Registration from '../Apps/Registration';

// import ManagerListing from 'components/app/views/apps/admin/managerListing';
// import CompanyListing from "components/app/views/apps/admin/companyListing";
// import ProjectListing from "components/app/views/apps/admin/projectListing";
// import CreateManager from "components/app/views/apps/admin/createManager";
// import CreateCompany from "components/app/views/apps/admin/createCompany";
// import CreateProject from "components/app/views/apps/admin/createProject";
// import CreateMyXPLoginLink from "components/app/views/apps/admin/createMyXPLoginLink";

// Switch 2020
// import SwitchCreate from 'components/app/views/dashboard/switch2020/create';
// import Sponsor from 'components/app/views/dashboard/switch2020/sponsor';
// import SwitchOnboarding from 'components/app/views/dashboard/switch2020/sponsor/onboarding';
import Agenda from 'components/app/views/apps/live/pages/agenda/';
// import Polls from 'components/app/views/apps/live/pages/polls/';

// Super Admin
import SuperAdminDashboard from 'components/app/views/dashboard/admin';
import SuperProjectDashboard from 'components/app/views/dashboard/admin/projectsDashboard';
import ContentSecurityPolicy from 'components/app/views/dashboard/admin/contentSecurityPolicy';

// Sponsorship
import SponsorshipDashboard from 'components/app/views/dashboard/sponsorship';
import SponsorDashboardEdit from 'components/app/views/dashboard/sponsorship/sponsorDashboardEdit';
import SponsorProjectDashboard from 'components/app/views/dashboard/sponsorship/projectsDashboard';

//Announcements
import Announcements from 'components/app/views/apps/notification/announcements';
import CreateAnnouncement from 'components/app/views/apps/notification/announcements/createAnnouncement';

//Polls
import NotificationPolls from 'components/app/views/apps/notification/polls';
import CreatePoll from 'components/app/views/apps/notification/polls/createPoll';

//Product
import Products from 'components/app/views/apps/products/products';
import CreateProduct from 'components/app/views/apps/products/products/createProduct';

//Sponsor Import
// import ImportSponsor from 'components/app/views/import-sponsor';
// import LiveManager from 'components/app/views/dashboard/switch2020/live-manager/LiveManager';

//Channels
import Channels from 'components/app/views/apps/channels';
import ChannelEdit from 'components/app/views/apps/channels/channelEdit';
// import QnA from 'components/app/views/apps/channels/qna';

//Tags
// import Tags from 'components/app/views/apps/tags';

//Apps
// import Apps from 'components/app/views/apps/apps';

import sponsorHome from 'components/app/views/apps/sponsorHome';

//badges
// import Badges from 'components/app/views/apps/badges';
// import CreateBadge from 'components/app/views/apps/badges/createBadge';

// New component 02/09/2022
import SignUp from 'ComponentsV2/SignUp';
import ProjectDashboardV2 from 'ComponentsV2/ProjectDashboard/_LazyLoaderWrapper';
import UpgradeScreen from 'ComponentsV2/ProjectDashboard/Components/UpgradeScreen';
// import ExperienceSelection from 'Apps/ExperienceSelection';

import Venue from 'ComponentsV2/ProjectDashboard/Components/Virtual/virtual/home';

// New component 05/01/2023
import { TwoFA, TwoFASetup } from 'ComponentsV2/SignIn';
import SpeakerImport from 'ComponentsV2/ProjectDashboard/Components/Content/speakers/speakerImport';
import SpeakerImportErrors from 'ComponentsV2/ProjectDashboard/Components/Content/speakers/speakerImportErrors';

const mainRoutes = [
    {
        path: '/login',
        component: Login,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/signup',
        component: SignUp,
        isPrivate: false,
        isExact: false,
    },
    {
        path: '/sponsor/login',
        component: SponsorLogin,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/otp',
        component: Otp,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/oauth/login',
        component: LoginOAuth,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/2fa-setup',
        component: TwoFASetup,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/2fa',
        component: TwoFA,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/onboarding',
        component: Onboarding,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/reports',
        component: Reports,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/previewer',
        component: Previewer,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project',
        component: ProjectDashboard,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/',
        component: CompanyDashboard,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/azuread_callback',
        component: Otp,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/sponsor-home',
        component: sponsorHome,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/company-profile',
        component: SponsorDashboardEdit,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/leads/users',
        component: LeadsDashboard,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/leads/reports',
        component: LeadsReport,
        isPrivate: true,
        isExact: true,
    },
    // {
    //     path: '/company/:companyId/project/:projectId/live-manager',
    //     component: LiveManager,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/project/onboarding',
    //     component: SwitchOnboarding,
    //     isPrivate: true,
    //     isExact: true,
    // },
    {
        path: '/project/sponsors',
        component: SponsorshipDashboard,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/sponsor',
        component: SponsorProjectDashboard,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/project/sponsors/:sponsorId',
        component: SponsorDashboardEdit,
        isPrivate: true,
        isExact: true,
    },
];

const projectRoutes = [
    {
        path: '/company/:companyId/project/create',
        component: ProjectCreate,
        isPrivate: true,
        isExact: true,
    },
    // {
    //     path: '/company/:companyId/project/:projectId',
    //     component: ProjectSettings,
    //     isPrivate: true,
    //     isExact: true,
    // },
    {
        path: '/v2/company/:companyId/project/:projectId',
        component: ProjectDashboardV2,
        name: 'ProjectDashboardV2',
        isPrivate: true,
        isExact: false,
    },
    {
        path: '/v2/company/:companyId/upgrade',
        component: UpgradeScreen,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId',
        component: ProjectDashboardV2,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/dashboard',
        component: ProjectDashboardV2,
        name: 'ProjectDashboardV2',
        isPrivate: true,
        isExact: false,
    },
    {
        path: '/company/:companyId/upgrade',
        component: UpgradeScreen,
        isPrivate: true,
        isExact: true,
    },
];

const appsInstallationRoutes = [
    {
        path: '/github/installation',
        component: VirtualHome,
        isPrivate: true,
        isExact: true,
    },
];

const venuesRoutes = [
    {
        path: '/company/:companyId/venue-collections/:projectId',
        component: VenueCollectionDashboard,
        isPrivate: true,
        isExact: true,
    },
];

const virtualRoute = [
    // {
    //     path: '/company/:companyId/project/:projectId/virtual',
    //     component: VirtualHome,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/virtual/navigation',
    //     component: VirtualNavigation,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/virtual/authentication',
    //     component: VirtualAuthentication,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/virtual/menus',
    //     component: VirtualMenus,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/virtual/menus/:menuId',
    //     component: VirtualMenus,
    //     isPrivate: true,
    //     isExact: true,
    // },
];

const venueBuilderRoutes = [
    {
        path: '/company/:companyId/project/:projectId/venue-builder/:livepageId',
        component: VenueBuilder,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/venue-builder/:livepageId/settings',
        component: VenueBuilder,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/venue-builder/',
        component: VenueBuilder,
        isPrivate: true,
        isExact: true,
    },
];

const websiteBuilderRoutes = [
    {
        path: '/company/:companyId/project/:projectId/website-builder/:livepageId',
        component: VenueBuilder,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/website-builder/:livepageId/settings',
        component: VenueBuilder,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/website-builder/',
        component: VenueBuilder,
        isPrivate: true,
        isExact: true,
    },
];

const showcaseAppRoutes = [
    {
        path: '/p/:personId/showcase/dashboard',
        component: ShowcaseDashboard,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/p/:personId/showcase/c/:showcaseId',
        component: Content,
        isPrivate: true,
        isExact: true,
    },
];

const liveAppRoutes = [
    // {
    //     path: '/company/:companyId/project/:projectId/live',
    //     component: LiveHome,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/live/pages',
    //     component: LivePageHome,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/live/chatdb',
    //     component: WidgetChatAdminDashboard,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/live/templates',
    //     component: Templates,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/live/pages/create',
    //     component: LivePageCreate,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/live/pages/:showcaseId/settings',
    //     component: LivePageSettings,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/live/pages/:showcaseId/content',
    //     component: LiveContent,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/live/pages/:showcaseId/widgets',
    //     component: Widgets,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/live/site/settings',
    //     component: SiteSettings,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/live/site/content',
    //     component: SiteContent,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/live/pages/:showcaseId/agenda',
    //     component: Agenda,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/live/pages/:showcaseId/polls',
    //     component: Polls,
    //     isPrivate: true,
    //     isExact: true,
    // },
];

const miscellaneous = [
    // Start of new menu routing, needed for uploading venue via github
    {
        path: '/company/:companyId/project/:projectId/virtual/venue',
        component: Venue,
        isPrivate: true,
        isExact: true,
    },
];

const analyticsAppRoutes = [
    {
        path: '/company/:companyId/project/:projectId/analytics',
        component: Analytics,
        isPrivate: true,
        isExact: true,
    },
];

const peopleAppRoutes = [
    // {
    //     path: '/company/:companyId/project/:projectId/people',
    //     component: PeopleHome,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/people/upload-file',
    //     component: UploadPeopleFile,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/people/map-fields',
    //     component: MapFields,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/people/map-fields/:importId',
    //     component: MapFieldsEdit,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/people/choose-action',
    //     component: ChooseAction,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/people/data-integration',
    //     component: DataIntegration,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/people/fields',
    //     component: Fields,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/people/select-data-integration',
    //     component: SelectDataIntegration,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/people/gevme-integration/gevme-login',
    //     component: GevmeLogin,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/people/gevme-integration/select-events',
    //     component: SelectEvents,
    //     isPrivate: true,
    //     isExact: true,
    // },
    {
        path: '/people/gevme-integration/gevme-redirect',
        component: GevmeRedirect,
        isPrivate: true,
        isExact: true,
    },
];

const agendaAppRoutes = [
    {
        path: '/company/:companyId/project/:projectId/agenda/speaker-import/:errorId',
        component: SpeakerImportErrors,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/agenda/speaker-import',
        component: SpeakerImport,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/agenda',
        component: Agenda,
        isPrivate: true,
        isExact: true,
    },
];

const commonAppRoutes = [
    // {
    //     path: '/company/:companyId/project/:projectId/registration',
    //     component: Registration,
    //     isPrivate: true,
    //     isExact: true,
    // },
    {
        path: '/company/:companyId/project/:projectId/chat-moderation',
        component: ChatModeration,
        isPrivate: true,
        isExact: true,
    },
    // {
    //     path: '/company/:companyId/project/:projectId/recommendation',
    //     component: RecommendationEngine,
    //     isPrivate: true,
    //     isExact: true,
    // },
];

const videoAppRoutes = [
    // {
    //     path: '/company/:companyId/project/:projectId/videos',
    //     component: VideoApp,
    //     isPrivate: true,
    //     isExact: true,
    // },
];
const meetingAppRoutes = [
    // {
    //     path: '/company/:companyId/project/:projectId/meetings',
    //     component: MeetingsApp,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/meetings/:pageType',
    //     component: MeetingsApp,
    //     isPrivate: true,
    //     isExact: true,
    // },
];

/**
 * @todo need to revisit `superadmin` routes / pages
 */
const superAdminRoutes = [
    {
        path: '/admin/dashboard/',
        component: SuperAdminDashboard,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/admin/dashboard/:companyId',
        component: SuperProjectDashboard,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/admin/dashboard/:companyId/:projectId',
        component: ContentSecurityPolicy,
        isPrivate: true,
        isExact: true,
    },
];

const importSponsorRoutes = [
    // {
    //     path: '/import-sponsor/:projectId',
    //     component: ImportSponsor,
    //     isPrivate: true,
    //     isExact: true,
    // },
];

const sponsorship = [
    {
        path: '/sponsor/companyProfiles',
        component: SponsorCompanyProfiles,
        isPrivate: true,
        isExact: true,
    },
    // {
    //     path: '/company/:companyId/project/:projectId/sponsorship/packages',
    //     component: SponsorshipPackages,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/sponsorship/sponsors',
    //     component: SponsorshipSponsors,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/sponsorship/sponsors/create',
    //     component: SponsorEdit,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/sponsorship/sponsors/user-import',
    //     component: UserImport,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/sponsorship/sponsors/:sponsorId',
    //     component: SponsorEdit,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/sponsorship/sponsors/:sponsorId/user-import',
    //     component: UserImport,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/sponsorship/company-import',
    //     component: CompanyImport,
    //     isPrivate: true,
    //     isExact: true,
    // },
];

const notification = [
    {
        path: '/company/:companyId/project/:projectId/notification/announcements',
        component: Announcements,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/notification/create-announcement',
        component: CreateAnnouncement,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/notification/edit-announcement/:notificationId',
        component: CreateAnnouncement,
        isPrivate: true,
        isExact: true,
    },

    {
        path: '/company/:companyId/project/:projectId/notification/polls',
        component: NotificationPolls,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/notification/create-poll',
        component: CreatePoll,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/notification/edit-poll/:notificationId',
        component: CreatePoll,
        isPrivate: true,
        isExact: true,
    },
];

const channels = [
    {
        path: '/company/:companyId/project/:projectId/channels/',
        component: Channels,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/channels/create',
        component: ChannelEdit,
        isPrivate: true,
        isExact: true,
    },
    // {
    //     path: '/company/:companyId/project/:projectId/channels/qna',
    //     component: QnA,
    //     isPrivate: true,
    //     isExact: true,
    // },
    {
        path: '/company/:companyId/project/:projectId/channels/:channelId',
        component: ChannelEdit,
        isPrivate: true,
        isExact: true,
    },
];

const tagsAppRoutes = [
    // {
    //     path: '/company/:companyId/project/:projectId/tags/',
    //     component: Tags,
    //     isPrivate: true,
    //     isExact: true,
    // },
];

const appsAppRoutes = [
    // {
    //     path: '/company/:companyId/project/:projectId/apps/',
    //     component: Apps,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/experiences/company/:companyId/project/:projectId',
    //     component: ExperienceSelection,
    //     isPrivate: true,
    //     isExact: true,
    // },
];

const products = [
    {
        path: '/company/:companyId/project/:projectId/products/',
        component: Products,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/products/create-product',
        component: CreateProduct,
        isPrivate: true,
        isExact: true,
    },
    {
        path: '/company/:companyId/project/:projectId/products/edit-product/:productId',
        component: CreateProduct,
        isPrivate: true,
        isExact: true,
    },
];

const badges = [
    // {
    //     path: '/company/:companyId/project/:projectId/badges/',
    //     component: Badges,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/badges/create-badge',
    //     component: CreateBadge,
    //     isPrivate: true,
    //     isExact: true,
    // },
    // {
    //     path: '/company/:companyId/project/:projectId/badges/edit-badge/:badgeId',
    //     component: CreateBadge,
    //     isPrivate: true,
    //     isExact: true,
    // },
];

const fallbackRoutes = [
    {
        path: '/no-access',
        component: NoAccess,
        isPrivate: false,
        isExact: true,
    },
    {
        component: NoMatch,
    },
];

export const routes = [].concat.apply(
    [],
    [
        miscellaneous,
        mainRoutes,
        appsInstallationRoutes,
        // virtualRoute,
        projectRoutes,
        analyticsAppRoutes,
        venuesRoutes,
        showcaseAppRoutes,
        // liveAppRoutes,
        peopleAppRoutes,
        superAdminRoutes,
        // importSponsorRoutes,
        venueBuilderRoutes,
        websiteBuilderRoutes,
        sponsorship,
        channels,
        notification,
        products,
        agendaAppRoutes,
        commonAppRoutes,
        // videoAppRoutes,
        // meetingAppRoutes,
        // tagsAppRoutes,
        // appsAppRoutes,
        // badges,
        fallbackRoutes,
    ]
);
