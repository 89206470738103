import React from 'react';

import './sidebarContainer.scss';

const SidebarContainer = (props) => {
    // right: boolean => position
    // zindex: number => z-index
    const {
        right,
        zindex: z,
        children
    } = props;

    return (
        <section
            id="sidebarContainer"
            className={`${right ? 'pRight' : 'pLeft'}`}
            style={{
                zIndex: z ? `${z}` : '50',
            }}
        >
            {children}
        </section>
    );
}

export default SidebarContainer;
