import * as ls from 'local-storage';
import { isEmpty } from 'lodash';
import { store } from 'redux/store';

let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

export async function UpdateCompany(companyId, inputs) {
    setAuthBearer();
    const user = store?.getState()?.user
    let path = null,
        method = 'POST';

    if (authBearer && user) {
        let formData = new FormData();
        for (const property in inputs) {
            formData.append(property, inputs[property]);
        }
        if (window.SPONSOR_AUTH) {
            path = `${process.env.REACT_APP_MYXP_API_V2}/sponsor/company-profile`;
            method = 'PUT';
        } else
            path = `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/company-profiles/${companyId}`;

        const response = await fetch(path, {
            method,
            headers: {
                Authorization: authBearer,
            },
            body: formData,
        });

        return await response.json();
    }
}
