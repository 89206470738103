import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import './inviteUsers.scss';

function InviteUsers(props) {
    const { setModalVisibility, setModalView, leadsQuota, totalLeadsQuota } =
        props;

    /**
     * renders text for inviting users
     */
    const renderText = () => {
        if (leadsQuota < totalLeadsQuota) {
            return (
                <>
                    You can invite up to{' '}
                    <strong>{totalLeadsQuota - leadsQuota} user(s)</strong>
                </>
            );
        } else {
            return "You don't have invites left.";
        }
    };

    return (
        <div className="invite-users">
            <button
                className="btn"
                onClick={() => {
                    setModalVisibility(true);
                    setModalView('inviteUsers');
                }}
                disabled={leadsQuota >= totalLeadsQuota ? 'disabled' : ''}
            >
                Invite Your Users
            </button>
            <p>{renderText()}</p>
        </div>
    );
}

InviteUsers.propTypes = {
    setModalVisibility: PropTypes.func.isRequired,
    setModalView: PropTypes.func.isRequired,
    leadsQuota: PropTypes.number.isRequired,
    totalLeadsQuota: PropTypes.number.isRequired,
};

export default withRouter(InviteUsers);
