import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as ls from 'local-storage';

import {
    generateSponsorOtp,
    sponsorTempAuthenticate,
} from 'apis/rest/Authentication';
import loginValidationCriteria from 'components/app/utils/validators/loginValidator';
import useAlert from 'components/app/utils/hooks/useAlert';
import useFormValidator from 'components/app/utils/hooks/useFormValidator';
import Alert from 'components/app/common/alert';
import { maskEmail } from 'components/app/utils/functions/common';
import { getUser } from 'apis/rest/User';
import OTPInput from 'otp-input-react';

import * as act from 'redux/actions/common/userActions';

import '../login.scss';
import '../otp/otp.scss';

import styles from '../index.module.scss';
import NotificationPopup from 'ComponentsV2/CommonComponents/NotificationPopup';

const logoSource = '/assets/gevme-new-logo.png';
const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';

function SponsorLogin() {
    const [email, setEmail] = useState('');
    const [sendBySms, setSendBySms] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const history = useHistory();

    const [otp, setOtp] = useState('');
    const [inputDisabled, setInputDisabled] = useState(false);

    const [showOtpView, setOtpView] = useState(false);

    const { alert, showAlert } = useAlert();

    const { validateInput, renderFieldError, hasErrors } = useFormValidator({
        input: { email },
        validationCriteria: loginValidationCriteria,
    });

    const dispatch = useDispatch();

    const handleGetUser = async () => {
        const user = await getUser();
        const project = user.user.projects[0];

        dispatch(act.setUser(user));

        // ls('user', user.user);
        ls('project', project);
    };

    const login = async (data, validate = true) => {
        setIsBusy(true);
        let _hasErrors = false;

        if (validate) {
            const formErrors = validateInput();

            _hasErrors = hasErrors(formErrors);
        }

        if (!_hasErrors) {
            const response = await generateSponsorOtp(data);
            const responseJson = await response.json();

            if (responseJson.status) {
                setOtpView(true);
            }

            if (!responseJson.status) showAlert(responseJson.message, 'error');
            setIsBusy(false);
        }
    };
    const submitEmail = async (e) => {
        e.preventDefault();
        await login({ identifier: email, sms: sendBySms });
    };

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const handleOtpChange = (e) => {
        try {
            setOtp(e);
        } catch (error) {
            console.error(error);
        }
    };

    const handleOtpUpdate = async () => {
        try {
            setInputDisabled(true);
            const response = await sponsorTempAuthenticate({
                code: otp,
                identifier: email,
            });
            if (response.status) {
                ls.set('sponsorTempAuth', response.token);
                ls.set('sponsorIdentifier', email);
                history.push('/sponsor/companyProfiles');
            } else {
                setInputDisabled(false);
                showAlert(response?.message || DEFAULT_ERROR_MESSAGE, 'error');
            }
        } catch (error) {
            setInputDisabled(false);
            showAlert(error.message || DEFAULT_ERROR_MESSAGE, 'error');
        }
    };

    useEffect(() => {
        if (otp.length === 6) {
            handleOtpUpdate();
        }
    }, [otp]);

    useEffect(() => {
        if (ls.get('sponsorTempAuth')) {
            history.replace('/sponsor/companyProfiles');
        } else if (ls.get('auth')) {
            history.replace('/');
        }
    }, []);

    const inputStyles = {
        fontSize: '2.2em',
        fontWeight: 'bold',
        width: '45px',
        height: '60px',
    };

    return (
        <>
            <Alert alert={alert}></Alert>
            {!showOtpView ? (
                <div className={`${styles.login} login`}>
                    <div className={styles.wrapper}>
                        <NotificationPopup />
                        <div className={styles.innerWrapper}>
                            <form className="login__box" onSubmit={submitEmail}>
                                <img
                                    src={logoSource}
                                    alt="logo"
                                    className="login__logo"
                                />

                                <h1>Log in</h1>

                                <p>Type your email address below to proceed.</p>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="email"
                                        value={email}
                                        className="form-control"
                                        onChange={handleInputChange}
                                        placeholder="Enter your email id"
                                        autoComplete="off"
                                    />
                                    {renderFieldError('email')}
                                </div>

                                <div className="checkbox-set">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={sendBySms}
                                            onChange={() => {
                                                setSendBySms(!sendBySms);
                                            }}
                                            id="sendBySms"
                                        />{' '}
                                        <span>Send OTP by SMS</span>
                                    </label>
                                </div>

                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={isBusy ? true : false}
                                >
                                    {isBusy ? (
                                        <span>
                                            <img
                                                src="/assets/icon_spinner.gif"
                                                alt=""
                                            />
                                            Please wait...
                                        </span>
                                    ) : (
                                        'Next'
                                    )}
                                </button>
                            </form>

                            <div className="mt-4">
                                <p className="foot-note">
                                    By logging in you agree to the{' '}
                                    <a
                                        href="https://www.gevme.com/en/terms-of-use/"
                                        target="_blank"
                                        className=""
                                    >
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href="https://www.gevme.com/en/privacy-policy/"
                                        target="_blank"
                                    >
                                        Privacy Policy
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={`${styles.login} otp`}>
                    <div className={styles.wrapper}>
                        <div className={styles.innerWrapper}>
                            <div className={`${styles.otpBox} otp__box`}>
                                <img
                                    src={'/assets/gevme-new-logo.png'}
                                    alt=""
                                />

                                <h1>
                                    Check your {sendBySms ? 'phone' : 'email'}{' '}
                                    for your OTP
                                </h1>

                                <p>
                                    To confirm your email address, we've sent an
                                    One-Time Password to{' '}
                                    {sendBySms
                                        ? 'your phone number which is associated to'
                                        : ''}{' '}
                                    {email || ''}
                                    .
                                    <br />
                                    Check your {sendBySms
                                        ? 'phone'
                                        : 'email'}{' '}
                                    and key it below to proceed.
                                </p>

                                <OTPInput
                                    inputStyles={inputStyles}
                                    value={otp}
                                    onChange={handleOtpChange}
                                    autoFocus
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={inputDisabled}
                                    className={'otp-box'}
                                />

                                <p>
                                    An OTP has been sent to your{' '}
                                    {sendBySms
                                        ? 'phone number'
                                        : 'email address'}{' '}
                                    if you are registered.
                                </p>
                                <div className="mt-4">
                                    <p className="foot-note">
                                        By logging in you agree to the{' '}
                                        <a
                                            href="https://www.gevme.com/en/terms-of-use/"
                                            target="_blank"
                                            className=""
                                        >
                                            Terms of Use
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href="https://www.gevme.com/en/privacy-policy/"
                                            target="_blank"
                                        >
                                            Privacy Policy
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default SponsorLogin;
