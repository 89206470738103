import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import * as ls from 'local-storage';

import checkAccess from './checkAccess';

import * as act from 'redux/actions/common/userActions';

// import * as actUI from 'redux/actions/live/uiActionsLive';

import NoAccess from 'components/app/views/noAccess';

function PrivateRoute({ component: Component, requiredRole, ...rest }) {
    const dispatch = useDispatch();
    const isInStarterPlan = rest?.isInStarterPlan;

    return (
        <Route
            {...rest}
            render={(props) => {
                const auth = ls.get('auth');
                const sponsorAuth = ls.get('sponsorAuth');
                const sponsorTempAuth = ls.get('sponsorTempAuth');
                const user = rest?.user;
                let projectId = rest?.computedMatch?.params?.projectId,
                    companyId = rest?.computedMatch?.params?.companyId;

                const checkSponsorTime = sponsorAuth
                    ? moment().isBefore(
                          sponsorAuth[`${companyId}-${projectId}`]?.expire_in
                      )
                    : null;

                // venue feature
                if (
                    sponsorTempAuth &&
                    window.location.pathname.includes(
                        '/sponsor/companyProfiles'
                    )
                )
                    return (
                        <Component
                            {...props}
                            isInStarterPlan={isInStarterPlan}
                            user={user}
                        />
                    );

                if (sponsorAuth) {
                    projectId = rest?.computedMatch?.params?.projectId;
                    companyId = rest?.computedMatch?.params?.companyId;
                    if (window.location.pathname === '/') {
                        return (
                            <Redirect
                                to={{
                                    pathname: '/sponsor/companyProfiles',
                                }}
                            />
                        );
                    }
                    if (window.location.pathname.includes('previewer')) {
                        const pageParams = new URL(document.location)
                            .searchParams;
                        companyId = pageParams.get('companyId');
                        projectId = pageParams.get('projectId');
                    }
                }

                if (sponsorAuth && companyId && projectId && checkSponsorTime) {
                    window.SPONSOR_AUTH =
                        sponsorAuth[`${companyId}-${projectId}`];
                    Object.freeze(window.SPONSOR_AUTH);
                }

                if (
                    (auth && moment().isBefore(auth?.expire_in)) ||
                    (sponsorAuth && companyId && projectId && checkSponsorTime)
                ) {
                    const role = user.role;
                    const checkAccessControl = checkAccess(role, rest);

                    // Switch Event
                    // Lets dipatch our menu's here
                    // if(user?.roleId === 4) {
                    //     dispatch(actUI.enableSwitchEvent())
                    // } else {
                    //     // show normal menu
                    //     dispatch(actUI.disabledSwitchEvent())
                    // }

                    if (checkAccessControl) {
                        dispatch(
                            act.setRestrictAccess(
                                checkAccessControl.restrictAccess
                            )
                        );

                        if (checkAccessControl.isUserAuthorised) {
                            return (
                                <Component
                                    {...props}
                                    isInStarterPlan={isInStarterPlan}
                                    user={user}
                                />
                            );
                        } else {
                            return <NoAccess />;
                        }
                    } else {
                        dispatch(act.setRestrictAccess(null));

                        return (
                            <Component
                                {...props}
                                isInStarterPlan={isInStarterPlan}
                                user={user}
                            />
                        );
                    }
                } else {
                    ls.clear();
                    if (window.location.pathname.includes('sponsor'))
                        return (
                            <Redirect
                                to={{
                                    pathname: '/sponsor/login',
                                    state: {
                                        callbackUrl: `${window.location.pathname}${window.location.search}`,
                                    },
                                }}
                            />
                        );
                    else
                        return (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: {
                                        callbackUrl: `${window.location.pathname}${window.location.search}`,
                                    },
                                }}
                            />
                        );
                }
            }}
        />
    );
}

export default PrivateRoute;
