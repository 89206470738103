import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IFrameResizer from 'iframe-resizer-react';
import { withRouter, useParams } from 'react-router-dom';
import PageSpinner from 'components/app/common/pageSpinner';
import * as ls from 'local-storage';

import DateTimeRangePicker from 'components/app/common/dateTimeRangePicker';

import { Select, Tooltip } from 'antd';
import moment from 'moment';
import TimezoneSelector from 'components/app/common/timezoneSelector';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import '../index.scss';
import { getMetabaseToken } from 'ComponentsV2/ProjectDashboard/Components/analytics/overview/api';
import { store } from 'redux/store';

const { Option } = Select;

const autoRefreshOptions = [
    { value: '1', label: '1 minute' },
    { value: '2', label: '2 minutes' },
    { value: '5', label: '5 minutes' },
    { value: '10', label: '10 minutes' },
    { value: '15', label: '15 minutes' },
    { value: '30', label: '30 minutes' },
    { value: '60', label: '60 minutes' },
];

const BoothOverview = () => {
    const { projectId } = useParams();
    const siteId = useSelector(
        (state) => state?.venuesettings?.analyticsSiteId
    );
    const { startDate, endDate, activeMenu } = useSelector(
        (state) => state?.venueAnalytics
    );
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );
    const dispatch = useDispatch();

    const [metabaseToken, setMetabaseToken] = useState(null);
    const [autoRefresh, setAutoRefresh] = useState(1);

    async function getMetaToken() {
        try {
            const exhibitorid = store?.getState()?.user.exhibitorCompanyProfileId;
            const json = await getMetabaseToken(
                projectId,
                siteId,
                startDate,
                endDate,
                timezone,
                process.env.REACT_APP_BOOTH_OVERVIEW_METABASE_DASHBOARD_ID,
                exhibitorid
            );
            setMetabaseToken(json.token);
        } catch (error) {
            console.error(error);
        }
    }

    const updateAutoRefreshStartEndDate = () => {
        const currentDate = moment().format('YYYY-MM-DDTHH:mm:ss');
        if (moment(currentDate).isAfter(endDate)) {
            let start = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
            let end = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
            dispatch({
                type: 'SET_DATE',
                payload: { startDate: start, endDate: end },
            });
        }
    };

    useEffect(() => {
        if (projectId && siteId) getMetaToken();
    }, [
        projectId,
        siteId,
        startDate,
        endDate,
        timezone,
        autoRefresh,
        activeMenu,
    ]);

    return (
        <div className="interactionsAnalysisContainer">
            <div style={{ position: 'relative', marginBottom: 10 }}>
                <div className="visitLiveBox controls">
                    <DateTimeRangePicker showTime showRelativePicker showLive />
                </div>
                <TimezoneSelector top={0} />
                {activeMenu === 'live' && (
                    <Tooltip title="Set the refresh frequency of the dashboard">
                        <div className="autoRefresh d-flex align-items-center">
                            {autoRefresh && autoRefresh !== 'off' && (
                                <CountdownCircleTimer
                                    key={autoRefresh}
                                    style={{ marginLeft: 10 }}
                                    strokeLinecap="butt"
                                    size={25}
                                    isPlaying
                                    duration={autoRefresh * 60}
                                    colors={[
                                        '#004777',
                                        '#F7B801',
                                        '#A30000',
                                        '#A30000',
                                    ]}
                                    colorsTime={[10, 6, 3, 0]}
                                    onComplete={() => {
                                        updateAutoRefreshStartEndDate();
                                        return {
                                            shouldRepeat: true,
                                            delay: 0,
                                        };
                                    }}
                                ></CountdownCircleTimer>
                            )}
                            <Select
                                placeholder="Auto-Refresh"
                                defaultValue="1 minute"
                                style={{ width: '150px', marginLeft: '5px' }}
                                onChange={(value) => setAutoRefresh(value)}
                            >
                                {autoRefreshOptions.map((option) => (
                                    <Option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </Tooltip>
                )}
            </div>

            {metabaseToken && (
                <div className="visitLiveBox">
                    <IFrameResizer
                        src={
                            'https://bi.gevme.com' +
                            '/embed/dashboard/' +
                            metabaseToken +
                            '#bordered=false&titled=false' +
                            `&refresh=${autoRefresh * 60}`
                        }
                        style={{
                            width: '1px',
                            minWidth: '100%',
                            border: 'none',
                        }}
                    />
                </div>
            )}
            {!metabaseToken && (
                <PageSpinner
                    type="Oval"
                    color="#ACBDC9"
                    height={45}
                    width={45}
                    msg="Fetching data..."
                />
            )}
        </div>
    );
};

export default withRouter(BoothOverview);
