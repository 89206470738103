import * as ls from 'local-storage';
import { store } from 'redux/store';
// import { apiInterceptor } from 'utils/apiInterceptor'; 

export async function ImportSpeaker(inputs, projectId, sponsorId = '') {

    const sponsorAuth = ls.get('sponsorAuth') ?
         typeof ls.get('sponsorAuth') === 'string' ? JSON.parse(ls.get('sponsorAuth')) : ls.get('sponsorAuth') 
         : null
    const auth = sponsorId && sponsorAuth ? sponsorAuth[sponsorId] :  ls.get('auth');
    
    const user = store?.getState()?.user;

    // Will change this

    if (user) {
        const authBearer = `Bearer ${auth?.access_token}`;

        let formData = new FormData();

        for (const property in inputs) {
            formData.append(property, inputs[property]);
        }
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers/import?projectId=${projectId}`,
            {
                method: 'POST',
                headers: {
                    Authorization: authBearer,
                },
                body: formData,
            }
        );

        return await response.json();
    }
}

export async function downloadSampleSpeakers(projectId, sponsorId) {
    const sponsorAuth = ls.get('sponsorAuth') ?
         typeof ls.get('sponsorAuth') === 'string' ? JSON.parse(ls.get('sponsorAuth')) : ls.get('sponsorAuth') 
         : null
    const auth = sponsorId && sponsorAuth ? sponsorAuth[sponsorId] :  ls.get('auth');
    const authBearer = `Bearer ${auth?.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers/demo-csv?projectId=${projectId}`,
        {
            headers: {
                Authorization: authBearer,
            },
        }
    );

    return await response.text();
}
