import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ls from 'local-storage';
import OTPInput from 'otp-input-react';
import TagManager from 'react-gtm-module';
import { useForm } from 'react-hook-form';

import { validateTwoFA } from 'apis/rest/TwoFAAuth';

import { generateValidation } from 'utils/inputValidation';
import TwoFAWrapper from './TwoFAWrapper';
import { TwoFAAuthenticatorIcon, InfoIcon } from './icons';

import './TwoFA.scss';

const inputStyles = {
    fontSize: '2.2em',
    fontWeight: 'bold',
    height: '60px',
    width: '50px',
    marginRight: '10px',
};

function TwoFA({
    email,
    identifier,
    isLoading,
    setIsLoading,
    handleGoToLogin,
    handleGoToDashboard,
    checkUserRole,
    getLoadingScreen,
}) {
    const twoFAToken = ls.get('2FAToken');
    const { register, errors, handleSubmit, setValue } = useForm();
    const user = useSelector((state) => state.user);

    const [isTwoFAMethod, setIsTwoFAMethod] = useState(true);
    const [verificationCode, setVerificationCode] = useState('');
    const [trustDevice, setTrustDevice] = useState(false);
    const [showSelectMethod, setShowSelectMethod] = useState(false);
    const [isVerifyLoading, setIsVerifyLoading] = useState(false);
    const [verifyError, setVerifyError] = useState('');
    const error = errors?.verificationCode?.message || verifyError;
    const isInvalidValidOtp =
        verifyError ||
        isVerifyLoading ||
        (isTwoFAMethod
            ? verificationCode.length !== 6
            : verificationCode.length < 3);

    function selectMethod(twoFAMethod = true) {
        setIsTwoFAMethod(twoFAMethod);
        setShowSelectMethod(false);
    }

    async function handleVerify() {
        try {
            setIsVerifyLoading(true);
            setVerifyError(false);
            const resp = await validateTwoFA(
                {
                    code: verificationCode,
                    identifier,
                    trustedDevice: trustDevice,
                },
                isTwoFAMethod
            );
            if (!resp?.status) throw resp;

            ls.remove('2FAToken');
            ls('auth', resp.token);

            if (resp?.firstTimeVerification) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'emailVerified',
                        email,
                    },
                });
            }

            handleGoToDashboard();
        } catch (error) {
            console.error(error);
            setVerifyError(error?.message || 'Something went wrong');
        } finally {
            setIsVerifyLoading(false);
        }
    }

    useEffect(() => {
        if (twoFAToken) {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        if (user?.email) {
            checkUserRole();
        } else {
            handleGoToLogin();
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        setVerificationCode('');
        setTrustDevice(false);
    }, [isTwoFAMethod]);

    useEffect(() => {
        setVerifyError('');
    }, [verificationCode]);

    const getSelectMethodScreen = () => (
        <>
            <p className="tw-p-0 tw-m-0 tw-text-2xl tw-font-bold tw-text-center">
                Try another way to sign in
            </p>
            <div
                className="tw-mt-8 tw-w-96 tw-h-32 tw-flex tw-flex-row tw-gap-4 tw-px-4 tw-mx-4 tw-justify-around tw-items-center tw-border-2 tw-border-solid tw-border-gray-200 tw-rounded-lg tw-cursor-pointer hover:tw-border-blue-500"
                onClick={() => selectMethod(true)}
            >
                <div className="tw-w-1/5">
                    <TwoFAAuthenticatorIcon />
                </div>
                <div className="tw-w-3/5 tw-pr-4">
                    <p className="tw-p-0 tw-m-0 tw-mr-auto tw-text-xs tw-font-light">
                        <span className="tw-whitespace-nowrap">
                            Retrieve a verification code from your
                        </span>{' '}
                        authenticator app.
                    </p>
                </div>
            </div>
            <div
                className="tw-mt-3 tw-w-96 tw-h-32 tw-flex tw-flex-row tw-gap-4 tw-px-4 tw-mx-4 tw-justify-around tw-items-center tw-border-2 tw-border-solid tw-border-gray-200 tw-rounded-lg tw-cursor-pointer hover:tw-border-blue-500"
                onClick={() => selectMethod(false)}
            >
                <div className="tw-w-1/5">
                    <img src="/assets/BackupCodesIcon.png" alt="" />
                </div>
                <div className="tw-w-3/5 tw-pr-4">
                    <p className="tw-p-0 tw-m-0 tw-mr-auto tw-text-xs tw-font-light">
                        <span className="tw-whitespace-nowrap">
                            Use a backup code that you printed or
                        </span>{' '}
                        saved
                    </p>
                </div>
            </div>
            <a
                className="tw-p-0 tw-m-0 tw-mt-10 tw-mb-6 tw-text-sm tw-text-blue-500 tw-cursor-pointer hover:tw-underline hover:tw-underline-offset-4"
                href={`mailto:support@gevme.com?subject=Need help on Two Step Verification || Omni-Channel&body=Hi, I am facing issue with the 2FA login. Please help${
                    email ? `, my email is "${email}"` : ''
                }.`}
            >
                Need help?
            </a>
        </>
    );

    const getTwoFALoginScreen = () => (
        <>
            <p className="tw-text-2xl tw-font-bold tw-text-center tw-font-montserrat">
                2-Step verification is required for this account
            </p>
            <div className="tw-mt-8">
                {isTwoFAMethod ? (
                    <TwoFAAuthenticatorIcon />
                ) : (
                    <img src="/assets/BackupCodesIcon.png" alt="" />
                )}
            </div>
            <p className="tw-p-0 tw-m-0 tw-mt-3 tw-text-lg tw-font-bold tw-font-montserrat">
                {isTwoFAMethod
                    ? 'Enter verification code'
                    : 'Enter backup code'}
            </p>
            <p className="tw-p-0 tw-m-0 tw-mt-0.5 tw-text-xs tw-font-light">
                {isTwoFAMethod
                    ? 'Retrieve a verification code from your authenticator app.'
                    : 'Enter one of the codes that you printed or saved.'}
            </p>
            {isTwoFAMethod ? (
                <OTPInput
                    inputStyles={inputStyles}
                    className="tw-mt-2 tw-justify-center otp-box"
                    value={verificationCode}
                    onChange={setVerificationCode}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={isVerifyLoading}
                />
            ) : (
                <input
                    type="text"
                    className="tw-w-4/5 tw-p-2 tw-my-2 tw-border tw-border-solid tw-border-blue-300 tw-shadow tw-rounded tw-text-3xl tw-font-bold placeholder:tw-text-xs placeholder:tw-text-gray-400"
                    value={verificationCode}
                    onChange={(e) => {
                        setVerificationCode(e.target.value);
                        setValue('verificationCode', e.target.value, {
                            shouldValidate: true,
                        });
                    }}
                    ref={register(
                        'verificationCode',
                        generateValidation({
                            name: 'name',
                            inputLabel: 'verificationCode',
                            isRequired: true,
                            minLength: 3,
                            maxLength: 10,
                        })
                    )}
                    autoFocus
                    disabled={isVerifyLoading}
                />
            )}
            <button
                type="button"
                className={`${
                    isInvalidValidOtp
                        ? 'tw-border-black/50 tw-bg-black/50 hover:tw-border-gray-900/50 hover:tw-bg-gray-900/50'
                        : 'tw-border-black tw-bg-black hover:tw-border-gray-900 hover:tw-bg-gray-900'
                } tw-w-4/5 tw-rounded tw-border tw-border-solid tw-py-3.5 tw-text-base tw-font-semibold tw-text-white tw-text-center`}
                disabled={isInvalidValidOtp}
                onClick={handleVerify}
            >
                Verify
            </button>
            {error ? (
                <p className="tw-p-0 tw-m-0 tw-text-sm tw-text-rose-600 tw-font-semibold tw-text-left tw-mt-2">
                    {error}
                </p>
            ) : null}
            <div className="tw-mt-3 tw-w-4/5 tw-flex tw-flex-row tw-gap-1 tw-justify-start tw-items-center">
                <input
                    className="tw-ml-2"
                    type="checkbox"
                    name="trust-otp"
                    value={trustDevice ? 'on' : 'off'}
                    checked={trustDevice}
                    onChange={(e) => setTrustDevice(e.target.checked)}
                />
                <p
                    className="tw-p-0 tw-m-0 tw-ml-1 tw-text-xs tw-font-light tw-cursor-pointer"
                    onClick={(e) => {
                        e.preventDefault();
                        setTrustDevice((prev) => !prev);
                    }}
                >
                    Trust this browser for 30 days
                </p>
                <div>
                    <InfoIcon />
                </div>
            </div>
            <p
                className="tw-p-0 tw-m-0 tw-mt-4 tw-text-sm tw-text-blue-500 tw-cursor-pointer hover:tw-underline hover:tw-underline-offset-4"
                onClick={handleGoToLogin}
            >
                Cancel
            </p>
            <p className="tw-p-0 tw-m-0 tw-mt-6 tw-text-sm">
                Having trouble signing in?{' '}
                <span
                    className="tw-text-blue-500 tw-cursor-pointer hover:tw-underline hover:tw-underline-offset-4"
                    onClick={() => setShowSelectMethod(true)}
                >
                    Try another way
                </span>
            </p>
        </>
    );

    function getStepScreen() {
        if (showSelectMethod) {
            return getSelectMethodScreen();
        }

        return isLoading ? getLoadingScreen() : getTwoFALoginScreen();
    }

    return getStepScreen();
}

export default (props) => <TwoFAWrapper {...props} Component={TwoFA} />;
