import * as ls from 'local-storage';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

export async function DeleteChannel(projectId, channelId) {
    setAuthBearer();

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/channels/${channelId}?projectId=${projectId}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}
