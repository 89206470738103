import * as ls from 'local-storage';
import { store } from 'redux/store';

export async function UpdateContentSecurity(projectId, inputs) {
    const auth = ls.get('auth');
    const user = store?.getState()?.user

    if (auth && user) {
        const authBearer = `Bearer ${auth.access_token}`;
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/content-security/${projectId}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authBearer
                },
                body: JSON.stringify(inputs)

            }
        );
        return await response.json();
    }
}
