import { useState, useEffect } from 'react';

import produce from 'immer';

/**
 * @note this is a legacy `useAlert`, use `useAlertBar` instead for showing success / error alerts
 */
const useAlert = () => {
    const [alert, setAlert] = useState({
        visibility: false,
        message: 'Some alert',
        type: 'success',
    });

    useEffect(() => {
        const interval = setTimeout(() => {
            const nextState = produce(alert, (draftState) => {
                draftState.visibility = false;
            });

            setAlert(nextState);
        }, 10000);
        return () => clearInterval(interval);
    }, [alert]);

    /**
     * shows what type of message to render and triggers alert visibility
     * @param {string} message message to be shown
     * @param {string} alertType type of alert (success or error)
     */
    const showAlert = (message, alertType) => {
        let displayMessage = '';

        if (alertType === 'error') {
            displayMessage = `${message}.`;
        } else {
            displayMessage = message;
        }

        let nextState = produce(alert, (draftState) => {
            draftState.visibility = true;
            draftState.message = displayMessage;
            draftState.type = alertType;
        });

        setAlert(nextState);
    };

    return {
        alert,
        showAlert,
    };
};

export default useAlert;
