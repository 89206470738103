export const isBusinessEmail = async (email) => {
    const API_KEY = process.env.REACT_APP_ABSTRACT_API_KEY;
    const BASE_URL = process.env.REACT_APP_ABSTRACT_API_BASE_URL;

    const response = await fetch(
        `${BASE_URL}/?api_key=${API_KEY}&email=${email}`,
        {
            method: 'GET',
        }
    );

    const {
        deliverability,
        is_valid_format,
        is_free_email,
        is_disposable_email,
        is_mx_found,
    } = await response.json();

    // Is a valid business email if it is deliverable, valid format, not free, not disposable and mx record found
    if (
        deliverability === 'DELIVERABLE' &&
        is_valid_format.value &&
        !is_disposable_email.value &&
        is_mx_found.value
    ) {
        return { status: true, group: is_free_email.value ? 1 : 2 };
    }

    return false;
};

export const getEnrichmentData = async (domain) => {
    const API_KEY = process.env.REACT_APP_ABSTRACT_ENRICHMENT_API_KEY;
    const BASE_URL = process.env.REACT_APP_ABSTRACT_ENRICHMENT_API_BASE_URL;

    const response = await fetch(
        `${BASE_URL}/?api_key=${API_KEY}&domain=${domain}`
    );

    return await response.json();
};
