import getAuthBearer from '../../utils/getAuthBearer';
/**
 * invites users for the leads app
 * @param {object} data data required invite users to leads app
 */
export async function inviteUsers(data, projectId) {
    const authBearer = getAuthBearer();
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/sponsor/leads?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}
