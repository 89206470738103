import produce from 'immer';
// import moment from 'moment';

export const initialState = {
    venues: {},
    venuestore: {},
    venuedetails: {},
    venuecollections: {},
    filters: {},
    labels: [],
    hasVenue: true
}

function venueReducers(state = initialState, action) {
    switch (action.type) {
        case 'SET_VENUES':
            return {
                ...state,
                venues: action.payload
            }
        case 'SET_VENUE_COLLECTIONS':
            return {
                ...state,
                venuecollections: action.payload
            }
        case 'DELETE_VENUE_COLLECTION':
            return produce(state, (draftState) => {
                const deleteVenue = draftState.venues.venues.findIndex(
                    (venue) => venue.id === action.payload
                );
                draftState.venues.venues.splice(deleteVenue, 1);
            });
        case 'SET_VENUE_STORE':
            return {
                ...state,
                venuestore: action.payload
            }
        case 'SET_VENUE_STORE_DETAILS':
            return {
                ...state,
                venuedetails: action.payload
            }
        case 'COMPANY_HAS_VENUE':
            return {
                ...state,
                hasVenue: action.payload
            }
        default:
            return state;
    }
}

export default venueReducers;