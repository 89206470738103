import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, useParams } from 'react-router-dom';

import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import { getLeadsCaptured, exportLeadsCaptured } from 'apis/rest/LeadsUsers';
import { Table, Button } from 'antd';
import './index.scss';
import { capitalize } from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
import IframeResizer from 'iframe-resizer-react';
import Building from 'ComponentsV2/ProjectDashboard/Components/OnSite/BadgeDashboard/icons/Building';
import { getLeadsMetabaseToken } from './Api';

const deploymentIds = {
    'a4982ee0-e10f-11ee-8852-a9cc4bf35433': '65f8ffaac58dc3185abc2408',
    '8ad72a60-e10f-11ee-8852-a9cc4bf35433': '65f9001cc58dc3185abc243d',
    'cf1f2d30-e10f-11ee-93b2-0dffc313e247': '65f9002dc58dc3185abc245a',
    '0ba93930-e110-11ee-93b2-0dffc313e247': '65f90043c58dc3185abc2477',
    'baea2450-e10f-11ee-8852-a9cc4bf35433': '65f90058c58dc3185abc2493',
    'f3c5a740-e10f-11ee-8852-a9cc4bf35433': '65f9006778424a00275d5b9b',
};
const DASHBOARD_URL = 'https://bi.gevme.com/embed/dashboard/';
const DASHBOARD_DEFAULT_PARAMS = '?bordered=false&titled=false';
const WHITE_LISTED_PROJECTS = ['65efd326878bbe168e5d12e2'];
const DASHBOARD_ID = 389;

function Report() {
    const { projectId, companyId } = useParams();
    const [leadsData, setLeadsData] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [isExporting, setIsExporting] = useState(false);
    const [frameLoading, setFrameLoading] = useState(true);
    const [frameUrl, setFrameUrl] = useState('');
    const limit = 10;
    const IswhiteListed = WHITE_LISTED_PROJECTS.includes(projectId)

    const getLeadsMetabase = async () => {
        try {
            setFrameLoading(true);
            const res = await getLeadsMetabaseToken(
                projectId,
                deploymentIds[companyId],
                DASHBOARD_ID
            );
            if (res?.status) {
                let url = DASHBOARD_URL + res?.token + DASHBOARD_DEFAULT_PARAMS;

                setFrameUrl(url);
            } else throw res;
        } catch (error) {
            console.error(error?.message);
        } finally {
            setFrameLoading(false);
            setLoading(false);
        }
    };

    const getLeads = useCallback(
        async (page = 1) => {
            try {
                setLoading(true);
                const { status, leads, pagination } = await getLeadsCaptured({
                    projectId,
                    page,
                    limit,
                });
                if (status && leads?.length > 0) {
                    const columns = Object.keys(leads[0]).map((ele) => ({
                        title: (
                            <span className="tableHead">{capitalize(ele)}</span>
                        ),
                        dataIndex: ele,
                        key: ele,
                    }));
                    const dataSrc = leads.map((ele, index) => ({
                        ...ele,
                        key: index,
                    }));
                    setLeadsData({ columns, dataSrc, pagination });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
        [projectId]
    );
    const exportLeadsData = async () => {
        try {
            setIsExporting(true);
            const response = await exportLeadsCaptured({ projectId });
            FileSaver.saveAs(response, `Captured-Leads-${moment().unix()}.csv`);
        } catch (error) {
            console.error(error);
        } finally {
            setIsExporting(false);
        }
    };

    useEffect(() => {
        if (projectId) {
            if (IswhiteListed) getLeadsMetabase();
            else getLeads();
        }
    }, [projectId]);

    return (
        <Layout allowFixed={false} background="#F7F9FA">
            <SidebarContainer>
                <MainMenu priActive="sponsorHome" all />
            </SidebarContainer>
            <div
                className={`reportContainer ${
                    IswhiteListed && 'tw-h-[calc(100vh-204px)]'
                }`}
            >
                <div className="rprtheader">
                    <h1 className="mb-3">Leads Captured</h1>
                    {!IswhiteListed && (
                        <Button
                            onClick={exportLeadsData}
                            loading={isExporting}
                            className="exportBtn"
                        >
                            Export Leads
                        </Button>
                    )}
                </div>
                {IswhiteListed ? (
                    <>
                        {frameLoading && (
                            <span className="tw-flex tw-justify-center tw-h-full tw-items-center tw-gap-2">
                                <Building /> Crafting reports...
                            </span>
                        )}
                        <IframeResizer
                            className="tw-w-full tw-min-h-full tw-border-none"
                            src={frameUrl}
                            onLoad={() => setFrameLoading(false)}
                        />
                    </>
                ) : (
                    <Table
                        loading={isLoading}
                        dataSource={leadsData?.dataSrc || []}
                        columns={leadsData?.columns || []}
                        pagination={{
                            hideOnSinglePage: true,
                            position: ['bottomCenter'],
                            total: leadsData?.pagination?.total,
                            pageSize: limit,
                            current: Number(
                                leadsData?.pagination?.currentPage || 1
                            ),
                            onChange: (current) => {
                                getLeads(current);
                            },
                        }}
                    />
                )}
            </div>
        </Layout>
    );
}

export default withRouter(Report);
