import * as ls from 'local-storage';
let authBearer = null,
    headers = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
        headers = {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        };
    } catch (error) {
        console.error(error);
    }
};

/**
 * Get bookmarks
 */
export async function getBookmarks(projectId, type, limit = 10, page = 1) {
    setAuthBearer();
    let endpoint = `${process.env.REACT_APP_MYXP_API}/manager/livestream/analytics/bookmarks?type=${type}&projectId=${projectId}&limit=${limit}&page=${page}`;

    if (authBearer) {
        const response = await fetch(endpoint, {
            method: 'GET',
            headers,
        });

        return await response.json();
    }
}
export async function exportBookmarks(projectId, type) {
    setAuthBearer();
    let endpoint = `${process.env.REACT_APP_MYXP_API}/manager/livestream/analytics/bookmarks/export?type=${type}&projectId=${projectId}`;

    if (authBearer) {
        const response = await fetch(endpoint, {
            method: 'GET',
            headers,
        });

        return await response.blob();
    }
}
