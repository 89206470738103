import React from 'react';
import { Modal } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import styles from './index.module.scss';

const PopUp = ({ setModalVisibility, modalVisibilty, children, callback }) => {
    const history = useHistory();
    const { companyId } = useParams();
    const pathname = `/v2/company/${companyId}/upgrade`;

    return (
        <div className={styles.PopUp}>
            <Modal
                centered
                visible={modalVisibilty}
                okText={null}
                cancelText={null}
                width={426}
                style={{ maxWidth: 426 }}
                footer={null}
                className={styles.RequestModal}
                onCancel={() => {
                    if (callback) callback();
                    setModalVisibility(false);
                }}
            >
                <h2>Upgrade Your Plan</h2>
                {children}
                <div className="action-buttons">
                    <button
                        className="cancel"
                        onClick={() => {
                            if (callback) callback();
                            setModalVisibility(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="submit"
                        onClick={() => history.push(pathname)}
                    >
                        Upgrade
                    </button>
                </div>
            </Modal>
            <style jsx="true">{`
                .ant-modal-content {
                    padding: 60px 32px 40px 32px;
                    background: #ffffff;
                    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.15);
                    border-radius: 4px;
                }
                .ant-modal-content h2 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    text-align: center;
                    letter-spacing: -0.04em;
                    color: #000000;
                    margin-bottom: 16px;
                }
                .ant-modal-content p,
                .ant-modal-content button {
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    text-align: center;
                    letter-spacing: -0.01em;
                    color: #000000;
                    margin-bottom: 16px;
                }
                .ant-modal-content .user-details {
                    background: #f0f0f0;
                    border-radius: 8px;
                    padding: 16px;
                    text-align: left;
                    margin-bottom: 30px;
                }
                .ant-modal-content .user-details p {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 16px;
                }
                .ant-modal-body {
                    padding: 0;
                }
                .ant-modal-content .user-details p:last-child {
                    margin-bottom: 0;
                }
                .ant-modal-content .user-details .label {
                    font-size: 11px;
                    font-weight: bold;
                }
                .ant-modal-content .user-details .value {
                    font-size: 15px;
                }
                .ant-modal-content .action-buttons {
                    display: flex;
                    align-items: center;
                }
                .ant-modal-content .action-buttons button {
                    font-size: 13px;
                    padding: 7px;
                    font-weight: bold;
                    border-radius: 4px;
                    border: none;
                    width: calc(100% - 8px);
                }
                .ant-modal-content .action-buttons .cancel {
                    color: #000;
                    background: #e2e9f3;
                    margin-right: 8px;
                }
                .ant-modal-content .action-buttons .submit {
                    background: #008060;
                    color: #fff;
                }
            `}</style>
        </div>
    );
};

export default PopUp;
