import produce from 'immer';
import { set } from 'lodash';

import { stringToBoolean } from 'components/app/utils/functions/common';

const useInputChange = (input, setInput) => {
    /**
     *
     * @param {object} e event object
     * @param {boolean} isCheckedReversed whether the checked value should be reversed
     * @todo shouldn't use negative boolean variable names e.g. `titleHidden`
     * (means it's on shown when false) to prevent using `isCheckedReversed`
     */
    const handleInputChange = (e, isCheckedReversed = false) => {
        /**
         * need this extra attribute for values that are expected to be a number / boolean
         * because number / booleans are coerced into string in checkboxes / radio buttons
         */
        const doesValueNeedParsing = stringToBoolean(
            e.target.getAttribute('data-doesvalueneedparsing')
        );

        /**
         * need this extra attribute if need logic which converts
         * true / false boolean values into 1 / 0 instead
         * now, only used in `checkbox` type
         */
        const isUsingNumberAsBoolean = stringToBoolean(
            e.target.getAttribute('data-isusingnumberasboolean')
        );

        let value = doesValueNeedParsing
            ? JSON.parse(e.target.value)
            : e.target.value;
        let name = e.target.name;
        let type = e.target.type;

        let checked = isCheckedReversed ? !e.target.checked : e.target.checked;

        let nextState;

        switch (type) {
            case 'radio':
                nextState = produce(input, (draftState) => {
                    set(draftState, name, value); // set: updates an object with property structure passed in as "name"
                });
                break;
            case 'checkbox':
                nextState = produce(input, (draftState) => {
                    if (isUsingNumberAsBoolean) {
                        checked = checked ? 1 : 0;
                    }
                    set(draftState, name, checked);
                });
                break;
            case 'text':
            case 'textarea':
            case 'color':
                nextState = produce(input, (draftState) => {
                    set(draftState, name, value);
                });
                break;
            default:
                nextState = produce(input, (draftState) => {
                    set(draftState, name, value);
                });
                break; 
        }
        setInput(nextState);
    };

    return {
        handleInputChange,
    };
};

export default useInputChange;
