import * as ls from 'local-storage';
import { store } from 'redux/store';
// import { apiInterceptor } from 'utils/apiInterceptor';

export async function getSpeakerImportLogById(projectId, id, sponsorId) {
    const sponsorAuth = ls.get('sponsorAuth') ?
         typeof ls.get('sponsorAuth') === 'string' ? JSON.parse(ls.get('sponsorAuth')) : ls.get('sponsorAuth') 
         : null
    const auth = sponsorId && sponsorAuth ? sponsorAuth[sponsorId] :  ls.get('auth');
    const user = store?.getState()?.user;

    // Will change this

    if (user) {
        const authBearer = `Bearer ${auth?.access_token}`;

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers/import-logs/${id}/${projectId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    }
}
