import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import parse from 'html-react-parser';

import './prompt.scss';

/**
 * Will display a prompt depending on the case
 * @param show {boolean} will set if modal should be shown or not
 * @param message {string} the message
 * @param buttons {array} index 0 will be the left button, index 1 the right
 * @param callback {function} the action that will be fired upon proceeding
 * @param onHide {function} the action that will be fired upon close
 * @param description {string} message on what will happen if they proceed
 * @param type {string} is it warning, question, information
 * @returns {*}
 * @constructor
 */
const Prompt = ({
    show,
    message,
    buttons,
    callback,
    onHide,
    description = '',
    descriptionBy = '',
    icon = 'question',
    hideCancel = false,
    hideActionBtn = false,
    showClose = true,
    alwaysMinimize = false,
}) => {
    const [showStat, setShowStat] = useState(show);
    const headerImages = {
        question: '/assets/icon_question.svg',
        warning: '/assets/icon_warning.svg',
        info: '/assets/icon_alert_info.svg',
        processing: '/assets/icon_processing.svg',
        error: '/assets/icon_error.svg',
    };
    const [isMinimized, setIsMinimized] = useState(false);
    const maxNameLength = 15;

    useEffect(() => {
        setShowStat(show);
        if (!show) {
            setIsMinimized(false);
        }
    }, [show]);

    const getDescriptionBy = () =>
        descriptionBy
            ? descriptionBy?.length > maxNameLength
                ? descriptionBy.slice(0, maxNameLength - 1) + ' ...'
                : parse(descriptionBy)
            : null;

    if (alwaysMinimize || (!showClose && isMinimized)) {
        return (
            <div
                className={`myxp-prompt-minimize tw-w-fit tw-fixed tw-bottom-0 tw-left-56 tw-z-[42] tw-ml-2 tw-my-4 tw-bg-white tw-rounded-lg tw-shadow-md tw-shadow-black/20 ${
                    alwaysMinimize ? '' : 'tw-cursor-pointer'
                }`}
                onClick={alwaysMinimize ? null : () => setIsMinimized(false)}
            >
                <div className="myxp-prompt-glow tw-rounded-lg tw-p-0.5">
                    <div className="tw-px-4 tw-py-2 tw-bg-white tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2.5 tw-rounded-lg">
                        <div className="">
                            {icon === 'none' ? (
                                ''
                            ) : icon === 'lock' ? (
                                <div className="lockBg tw-flex tw-items-center tw-justify-center tw-w-16 tw-h-16 tw-rounded-full tw-shadow-lg tw-opacity-70">
                                    <i className="fa fa-lock fa-3x tw-text-green-800" />
                                </div>
                            ) : (
                                <img
                                    src={headerImages[icon]}
                                    alt=""
                                    width="25"
                                    height="25"
                                />
                            )}
                        </div>
                        <div className="tw-mr-1 tw-w-full tw-flex tw-flex-col tw-justify-start tw-items-center">
                            <div className="tw-w-full tw-font-montserrat tw-text-sm tw-font-semibold">
                                {message}
                            </div>
                            <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center tw-font-openSans">
                                <p className="tw-m-0 tw-p-0 tw-text-xssm">
                                    {parse(description)}
                                </p>
                                {descriptionBy ? (
                                    <p className="tw-m-0 tw-p-0 tw-ml-3 tw-text-xs">
                                        — Initiated by{' '}
                                        <span className="tw-p-0 tw-m-0 tw-font-semibold">
                                            {getDescriptionBy()}
                                        </span>
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        {alwaysMinimize ? null : (
                            <div
                                className="tw-px-1.5 tw-py-0.5 tw-border tw-border-solid tw-border-slate-700 tw-rounded-md"
                                onClick={() => setIsMinimized(false)}
                            >
                                <span className="tw-p-0 tw-m-0 tw-text-gray-500">
                                    <i className="fa fa-expand" />
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Modal
            dialogClassName="myxp-prompt"
            show={showStat}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={showClose ? onHide : () => setIsMinimized(true)}
            style={{ zIndex: 2500 }}
        >
            <Modal.Header>
                {showClose ? (
                    <div className="closeButton" onClick={onHide} />
                ) : (
                    <div
                        className="tw-absolute tw-top-3 tw-right-3 tw-gray-600 tw-px-1.5 tw-py-0.5 tw-border tw-border-solid tw-border-slate-700 tw-rounded-md tw-cursor-pointer"
                        onClick={() => setIsMinimized(true)}
                    >
                        <span className="tw-p-0 tw-m-0 tw-text-gray-500">
                            <i className="fa fa-compress" aria-hidden="true" />
                        </span>
                    </div>
                )}
                {icon === 'none' ? (
                    ''
                ) : icon === 'lock' ? (
                    <div className="lockBg tw-flex tw-items-center tw-justify-center tw-w-20 tw-h-20 tw-rounded-full tw-shadow-lg tw-opacity-70">
                        <i className="fa fa-lock fa-3x tw-text-green-800" />
                    </div>
                ) : (
                    <Modal.Title>
                        <img
                            src={headerImages[icon]}
                            alt=""
                            width="50"
                            height="50"
                        />
                    </Modal.Title>
                )}
            </Modal.Header>
            <Modal.Body>
                <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center">
                    <div className="prompt-message tw-font-montserrat">
                        {parse(message)}
                    </div>
                    {description && (
                        <div className="prompt-description tw-text-center">
                            {parse(description)}
                        </div>
                    )}
                    {descriptionBy && (
                        <div className="prompt-description tw-text-center">
                            — Initiated by{' '}
                            <span className="tw-p-0 tw-m-0 tw-font-semibold">
                                {getDescriptionBy()}
                            </span>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {!hideCancel && (
                    <span className="myxp btn btn-cancel" onClick={onHide}>
                        {buttons[0]}
                    </span>
                )}
                {!hideActionBtn && (
                    <Button
                        variant={`${icon === 'lock' ? 'success' : 'primary'}`}
                        className={`myxp ${
                            icon === 'lock'
                                ? 'tw-bg-green-700 tw-pb-7 tw-font-montserrat'
                                : 'btn-primary'
                        } tw-font-semibold`}
                        onClick={callback}
                    >
                        {icon === 'lock' ? (
                            <i className="fa fa-lock tw-text-white tw-pr-2" />
                        ) : null}
                        {buttons[1]}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default Prompt;
