import { textFilter } from "react-bootstrap-table2-filter";

export const fixedColumns = [
  {
    dataField: "source",
    text: "Source",
    sort: true,
    filter: textFilter()
  },
  {
    dataField: "rating",
    text: "Rating",
    sort: true,
    filter: textFilter()
  },
  {
    dataField: "note",
    text: "Notes",
    sort: true,
    filter: textFilter()
  },
  {
    dataField: "capturedBy",
    text: "Captured by",
    sort: true,
    filter: textFilter()
  },
  {
    dataField: "createdAt",
    text: "Captured on",
    sort: true
  }
];
