import * as ls from 'local-storage';

export const checkIfAuthorize = () => {
    try {
        const auth = ls('auth');
        const expiration = auth?.expire_in;
        return (
            auth &&
            auth?.access_token &&
            expiration &&
            new Date().getTime() < new Date(expiration).getTime()
        );
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getAuthToken = () => ls('auth');

const parseJwt = (token) => {
    try {
        return JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
    } catch (e) {
        return null;
    }
};

export const checkIfAnalyticsExpired = (token) => {
    try {
        const decodedJwt = parseJwt(token?.accessToken);

        if (decodedJwt?.exp * 1000 < Date.now()) {
            return true;
        }

        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};
