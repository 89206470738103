import * as ls from 'local-storage';
import { store } from 'redux/store';
let authBearer = null;

// multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};
export async function GetTopicTagGroups(
    projectId,
    search = '',
    page = 1,
    groupSearch = ''
) {
    setAuthBearer();
    const user = store?.getState()?.user
    if (authBearer && user) {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/topicTagGroups?projectId=${projectId}&search=${search}&page=${page}&groupSearch=${groupSearch}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    }
}
