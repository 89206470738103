import React, { useState, useRef } from 'react';

const Search = ({
    handleSearch,
    searchValue,
    placeholderText = '',
    searchRef,
    disabled,
}) => {
    const projectSearchRef = useRef('');

    const [searchIsFocused, setSearchIsFocused] = useState(false);
    const [projectSearchWidth, setProjectSearchWidth] = useState('132px');

    const handleInputSearchEvent = (width, status) => {
        setProjectSearchWidth(width);
        setSearchIsFocused(status);
    };

    return (
        <div className="project-search">
            <input
                value={searchValue}
                placeholder={placeholderText}
                ref={searchRef ? searchRef : projectSearchRef}
                type="text"
                className="form-control m-0"
                style={{ width: projectSearchWidth }}
                onChange={(e) => handleSearch(e.target.value)}
                onFocus={() => handleInputSearchEvent('210px', true)}
                onBlur={() => handleInputSearchEvent('132px', false)}
                disabled={disabled}
            />
            <i
                className={
                    'fa ' +
                    (searchValue === '' && !searchIsFocused
                        ? 'fa-search'
                        : 'fa-close')
                }
                onClick={() => {
                    searchValue === '' && !searchIsFocused
                        ? handleInputSearchEvent('132px', false)
                        : handleSearch('');
                }}
            >
                {' '}
            </i>
        </div>
    );
};

export default Search;
