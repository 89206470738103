import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
const { Option } = Select;

const TimezoneSelector = ({
    selectStyle = { width: 150, height: '100%' },
    selectClassnames = '',
    wrapperClass = 'timezoneSelect',
}) => {
    const dispatch = useDispatch();
    const { activeTimezone } = useSelector((state) => state?.settings);
    const renderOptions = () => {
        const timezones = moment.tz.names();
        let mappedValues = {};
        let regions = [];

        timezones.map((timezone) => {
            if (
                timezone.includes('/') &&
                !timezone.toLowerCase().includes('us') &&
                !timezone.toLowerCase().includes('etc')
            ) {
                const splitTimezone = timezone.split('/');
                const region = splitTimezone[0];
                if (!mappedValues[region]) {
                    mappedValues[region] = [];
                    regions.push(region);
                }
                mappedValues[region].push(timezone);
            }
        });
        return regions.map((region) => {
            const options = mappedValues[region].map((timezone) => {
                return <Option key={timezone}>{timezone}</Option>;
            });
            return options;
        });
    };

    return (
        <div className={wrapperClass}>
            <Select
                className={selectClassnames}
                style={selectStyle}
                showSearch
                allowClear={false}
                value={activeTimezone}
                onChange={(val) =>
                    dispatch({
                        type: 'SET_ACTIVE_TIMEZONE',
                        payload: val,
                    })
                }
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                    optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                }
                optionFilterProp="children"
            >
                {renderOptions()}
            </Select>
            <style>{`
            .Onsite_Analytics .ant-select-selector
            {
                height:100% !important;
            }
            `}</style>
        </div>
    );
};

export default TimezoneSelector;
