import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { capitalize, isArray, isEmpty, isObject, isString } from 'lodash';
import { uploadImage } from 'Apps/VenueBuilder/Components/Layout/AppSideBar/actions';
import { UploadImage } from 'apis/rest/UploadImage';
import 'pintura/pintura.css';
import {
    // editor
    openEditor,
    locale_en_gb,
    createDefaultImageReader,
    createDefaultImageWriter,

    // plugins
    setPlugins,
    plugin_crop,
    plugin_crop_locale_en_gb,
    // plugin_crop_defaults,
    plugin_finetune,
    plugin_finetune_locale_en_gb,
    plugin_finetune_defaults,
    plugin_filter,
    plugin_filter_locale_en_gb,
    plugin_filter_defaults,
    plugin_annotate,
    plugin_annotate_locale_en_gb,
    markup_editor_defaults,
    markup_editor_locale_en_gb,
} from 'pintura';

import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import Select from 'antd/lib/select';

import './imageUpload.scss';
import AudioModal from 'Apps/VenueBuilder/Components/Layout/AppSideBar/Components/Tags/Components/Widget/AudioFeatures/AudioModal';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);
function ImageUpload(props) {
    const {
        maxFiles,
        maxSize,
        sortEnable,
        multiple,
        disabled,
        accept,
        previewImages,
        prepareFilesUpload,
        prepareFilesUploadFileType,
        handleFileDelete,
        autoUpload,
        disableStyle,
        autoUploadData,
        thumbsHeight,
        classicButtons,
        showRecommendedSize,

        withoutRedux,
        projectId,
        resetDefaultImage,

        imageCropAspectRatio,
        imageCropMinSize,
        imageCropMaxSize,
        imageCropRecommendedSize,

        uploadText,
        uploadIcon = null,
        sizeOptions = [],
        className = '',
        recommendedSizeCss = '',
        enableDrop = false,
    } = props;

    const dispatch = useDispatch();
    // get blockid state
    const reduxProjectId = useSelector(
        (state) => state?.header?.selection?.projectId
    );
    const showcaseId = useSelector(
        (state) => state?.header?.selection?.livepageId
    );

    const sizeOptionRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [dimension, setDimension] = useState(imageCropRecommendedSize);
    const [aspectRatio, setAspectRation] = useState(imageCropAspectRatio);
    const [openSizeSlection, setOpenSizeSelection] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        []
    );

    useEffect(() => {
        loadPreviewImages(previewImages);
    }, [previewImages]);

    const editImage = (image, done) => {
        const imageFile = image.pintura ? image.pintura.file : image;
        const imageState = image.pintura ? image.pintura.data : {};

        const editor = openEditor({
            src: imageFile,
            imageState,
            imageReader: createDefaultImageReader(),
            imageWriter: createDefaultImageWriter(),
            ...plugin_filter_defaults,
            // ...plugin_crop_defaults,
            ...plugin_finetune_defaults,
            ...markup_editor_defaults,
            ...(aspectRatio && {
                imageCropAspectRatio: aspectRatio,
            }),
            ...(dimension.width &&
                dimension.height && {
                    imageCrop: {
                        x: 0,
                        y: 0,
                        width: dimension.width,
                        height: dimension.height,
                    },
                }),
            ...(!dimension.width &&
                !dimension.height && {
                    ...(imageCropMaxSize.width &&
                        imageCropMaxSize.height && {
                            imageCrop: {
                                x: 0,
                                y: 0,
                                width: imageCropMaxSize.width,
                                height: imageCropMaxSize.height,
                            },
                        }),
                }),
            ...(imageCropMinSize.width &&
                imageCropMinSize.height && {
                    imageCropMinSize: {
                        width: imageCropMinSize.width,
                        height: imageCropMinSize.height,
                    },
                }),
            ...(imageCropMaxSize.width &&
                imageCropMaxSize.height && {
                    imageCropMaxSize: {
                        width: imageCropMaxSize.width,
                        height: imageCropMaxSize.height,
                    },
                }),
            locale: {
                ...locale_en_gb,
                ...plugin_crop_locale_en_gb,
                ...plugin_finetune_locale_en_gb,
                ...plugin_filter_locale_en_gb,
                ...plugin_annotate_locale_en_gb,
                ...markup_editor_locale_en_gb,
            },
        });

        editor.on('close', () => {
            // nothing
        });

        // Click error text close
        editor.on('loadabort', () => {
            editor.close();
        });

        editor.on('process', ({ dest, imageState }) => {
            Object.assign(dest, {
                pintura: { file: imageFile, data: imageState },
            });
            done(dest);
        });
    };

    const editImagePromise = (image) => {
        return new Promise((resolve) => {
            editImage(image, resolve);
        });
    };

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept,
        maxFiles,
        maxSize,
        multiple,
        disabled,
        onDrop: async (acceptedFiles) => handleFiles(acceptedFiles),
        onDropRejected: (files) => {
            if (files.length) {
                const fileWithErrors = files.filter((file) => file?.errors);

                const showPopup = fileWithErrors.some((file) =>
                    file?.errors?.find(
                        (error) => error?.code === 'too-many-files'
                    )
                );

                showPopup &&
                    openNotification('info', {
                        message: `Maximum of ${maxFiles} files allowed`,
                    });
            }
        },
    });

    async function imageUrlToBlob(imageUrl) {
        try {
            const response = await fetch(imageUrl, {
                credentials: 'include',
            });
            const blob = await response.blob();
            return blob.type === 'image/gif'
                ? new File(
                      [blob],
                      imageUrl.split('/').pop().split('?')[0].split('#')[0],
                      { type: 'image/gif' }
                  )
                : blob;
        } catch (error) {
            console.error('Error converting image URL to Blob:', error);
            return null;
        }
    }

    const handleFiles = async (acceptedFiles) => {
        let updatedFiles = [...files];
        acceptedFiles =
            (acceptedFiles?.length || 0) + (files?.length || 0) <= maxFiles
                ? acceptedFiles
                : acceptedFiles?.slice(0, maxFiles - (files?.length || 0));
        let hasGifFile = false;
        for (let file of acceptedFiles) {
            if (file.type === 'image/gif') {
                hasGifFile = true;
                const updatedFile = file;
                Object.assign(updatedFile, {
                    preview: URL.createObjectURL(updatedFile),
                });
                updatedFiles.push(!enableDrop ? file : updatedFile);
            } else {
                const updatedFile = await editImagePromise(file);
                Object.assign(updatedFile, {
                    preview: URL.createObjectURL(updatedFile),
                });
                updatedFiles.push(updatedFile);
            }
        }
        setFiles(updatedFiles);
        handleReturnFiles(updatedFiles, hasGifFile);
    };

    const handleReturnFiles = async (returnedFiles, hasGifFile = false) => {
        let updatedFiles = [];
        const updatedReturnedFiles = returnedFiles?.filter(
            (_each) => !!_each?.pintura || !!_each?.path || !!_each?.type
        );

        if (isEmpty(updatedReturnedFiles)) {
            prepareFilesUpload([]);
            return;
        }

        if (autoUpload) {
            if (withoutRedux) {
                try {
                    let image = await UploadImage({
                        projectId,
                        files: updatedReturnedFiles,
                        ...autoUploadData,
                    });
                    updatedFiles.push(image);
                    prepareFilesUpload(updatedFiles, updatedReturnedFiles);
                } catch (error) {
                    console.error('ERROR UploadImage', error);
                }
            }
            if (!withoutRedux) {
                try {
                    let images = await dispatch(
                        uploadImage({
                            projectId: reduxProjectId,
                            showcaseId,
                            files: updatedReturnedFiles,
                            ...autoUploadData,
                        })
                    );
                    if (!isEmpty(images)) {
                        if (isArray(images)) {
                            for (let image of images) {
                                updatedFiles.push(image);
                            }
                        } else {
                            updatedFiles.push(images);
                        }
                    }
                } catch (error) {
                    console.error('ERROR redux UploadImage', error);
                }
            }
        }
        if (!autoUpload) {
            for (let file of updatedReturnedFiles) {
                if (hasGifFile) {
                    prepareFilesUpload(updatedReturnedFiles);
                    return;
                }
                // Need File instead of image blob url in createAgenda sessions.
                const image = file.pintura
                    ? file
                    : prepareFilesUploadFileType === 'url'
                    ? file.preview
                    : file;
                updatedFiles.push(image);
            }
        }

        prepareFilesUpload(updatedFiles);
    };

    const loadPreviewImages = (prevImages) => {
        if (!isArray(prevImages)) return null;
        let updatedFiles = [];
        for (let file of prevImages) {
            if (!isEmpty(file) && file !== 'null') {
                let name = isObject(file) ? file?.name : file.split('/').pop();
                let preview = isObject(file)
                    ? file?.url || file?.preview
                    : file;
                let url = isObject(file) ? file?.url : file;

                const updatedFile = {
                    ...(isObject(file) && {
                        id: file?.id,
                    }),
                    name,
                    preview,
                    url,
                };
                updatedFiles.push(updatedFile);
            }
        }
        setFiles(updatedFiles);
    };

    const fileRejectionItems = fileRejections.map(({ file, errors }) => {
        return errors.map((e) => e.message);
    });

    const SortableItem = sortableElement(({ file, index }) => {
        return renderThumbItem(file, index);
    });

    const SortableContainer = sortableContainer(({ children }) => {
        return <div>{children}</div>;
    });

    const handleOnSortEnd = ({ oldIndex, newIndex }) => {
        setFiles(arrayMove(files, oldIndex, newIndex));
    };

    const handleRemoveImage = (index) => {
        const updatedFiles = [...files];
        let selectedFile = updatedFiles[index];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
        handleReturnFiles(updatedFiles);
        handleFileDelete(selectedFile, index);
    };

    const handleEditImage = (file, index) => {
        editImage(file, (output) => {
            const updatedFiles = [...files];

            // replace original image with new image
            updatedFiles[index] = output;

            // revoke preview URL for old image
            if (file?.preview) URL.revokeObjectURL(file?.preview);

            // set new preview URL
            Object.assign(output, {
                preview: URL.createObjectURL(output),
            });

            // update view
            setFiles(updatedFiles);
            handleReturnFiles(updatedFiles);
        });
    };

    const handleOnFocus = () => setOpenSizeSelection(true);
    const handleOnBlur = () => setOpenSizeSelection(false);

    const getAspectRatio = (width, height) =>
        height == 0 ? width : getAspectRatio(height, width % height);

    const handleSizeChange = (val) => {
        if (val) {
            const [width, height] = val.split(',');
            const ratio = getAspectRatio(width, height);
            const calculatedAspectRatio = width / ratio / (height / ratio);

            setDimension({ width, height });
            setAspectRation(calculatedAspectRatio);
            setOpenSizeSelection(false);
            if (sizeOptionRef?.current) sizeOptionRef.current.blur();
        }
    };

    const handleResetDefaultImage = () => {
        if (isString(resetDefaultImage)) {
            prepareFilesUpload([resetDefaultImage]);
            setFiles([resetDefaultImage]);
            loadPreviewImages([resetDefaultImage]);
        }
    };

    const handleOutSideClick = (e) => {
        const selectSizer = document.querySelector('.size-option-selector');
        if (!e.composedPath().includes(selectSizer))
            setOpenSizeSelection(false);
    };

    useEffect(() => {
        if (sizeOptions?.length)
            document.addEventListener('mousedown', handleOutSideClick);
        return () =>
            document.removeEventListener('mousedown', handleOutSideClick);
    }, [sizeOptions]);

    const renderButtons = (file, index) => {
        if (!classicButtons) {
            return (
                <>
                    {file.pintura && (
                        <button
                            className="image-upload-thumb-btn"
                            onClick={() => handleEditImage(file, index)}
                        >
                            Edit
                        </button>
                    )}
                    <button
                        className="image-upload-thumb-btn image-upload-thumb-btn-remove"
                        onClick={() => handleRemoveImage(index)}
                    >
                        x
                    </button>
                </>
            );
        }
        if (classicButtons) {
            return (
                <div className="image-upload-classic-buttons">
                    {file.pintura && (
                        <a
                            className="btn-edit font-weight-bold"
                            onClick={() => handleEditImage(file, index)}
                        >
                            Edit
                        </a>
                    )}
                    <a
                        id="delete-image-btn"
                        className="ml-2 btn-edit font-weight-bold"
                        onClick={() => handleRemoveImage(index)}
                    >
                        Delete
                    </a>
                </div>
            );
        }
    };
    const renderThumbItem = (file, index, error = []) => {
        return (
            <>
                <div
                    className="image-upload-thumbs"
                    style={{
                        width: `${multiple ? '100px' : '100%'}`,
                        height: `${
                            disableStyle
                                ? thumbsHeight
                                    ? `${thumbsHeight * 2}px`
                                    : '300px'
                                : '100px'
                        }`,
                    }}
                    key={file.name + index}
                >
                    <div className="image-upload-thumb-inner">
                        <img
                            src={file.preview}
                            className="img-fluid mx-auto h-100"
                            alt={file?.name}
                        />
                    </div>
                    {renderButtons(file, index)}
                    {!isEmpty(error) && (
                        <span className="image-upload-thumb-error">
                            {error}
                        </span>
                    )}
                </div>
            </>
        );
    };

    const renderUploadButton = () => {
        if (!multiple) if (!isEmpty(files)) return null;
        if (files.length >= maxFiles) return null;
        return (
            <div
                {...(enableDrop &&
                    getRootProps({
                        className: 'dropzone image-upload-dropzone',
                    }))}
                {...(!enableDrop && { onClick: () => setModalVisible(true) })}
            >
                {enableDrop && <input {...getInputProps()} />}
                {uploadIcon || (
                    <span
                        className={`image-upload-button ${
                            disabled
                                ? 'tw-opacity-50 tw-cursor-not-allowed'
                                : ''
                        }`}
                    >
                        {uploadText
                            ? uploadText
                            : isEmpty(files)
                            ? multiple
                                ? 'Select images'
                                : 'Select image'
                            : 'Add image'}{' '}
                    </span>
                )}
            </div>
        );
    };

    const handleMediaImage = async (files) => {
        const blobPromises = isArray(files)
            ? files.map((data) => imageUrlToBlob(data?.url))
            : [imageUrlToBlob(files?.url)];

        try {
            const processedBlobs = await Promise.all(blobPromises);
            handleFiles(processedBlobs);
        } catch (error) {
            console.error('Error resolving blobs :', error);
        }
    };

    return (
        <div className={`imageUploadComponent ${className}`}>
            <section
                className={` ${!disableStyle ? 'image-upload-container' : ''}`}
            >
                {disabled && !disableStyle && (
                    <div className="image-upload-disabled"></div>
                )}
                <div>
                    {!sortEnable && (
                        <>
                            {isArray(files) &&
                                files.map((file, index) =>
                                    renderThumbItem(
                                        file,
                                        index,
                                        fileRejectionItems[index]
                                    )
                                )}
                        </>
                    )}
                    {sortEnable && (
                        <SortableContainer
                            onSortEnd={handleOnSortEnd}
                            axis={'x'}
                        >
                            {isArray(files) &&
                                files.map((file, index) => (
                                    <SortableItem
                                        key={`item-${index}`}
                                        index={index}
                                        file={file}
                                    />
                                ))}
                        </SortableContainer>
                    )}
                </div>
                {renderUploadButton()}
                {!isEmpty(resetDefaultImage) && (
                    <span
                        onClick={() => handleResetDefaultImage()}
                        className="image-upload-thumb-btn image-upload-btn-reset"
                    >
                        Reset Default Image
                    </span>
                )}
            </section>

            {dimension && !isEmpty(dimension) ? (
                <div className="bottom-part-wrapper">
                    {showRecommendedSize &&
                        imageCropRecommendedSize?.width &&
                        imageCropRecommendedSize?.height && (
                            <div className="imageUpload-recommended-size tw-mt-2 tw-mb-1">
                                <p
                                    className={`tw-p-0 tw-m-0 tw-font-semibold tw-text-xs tw-text-center ${recommendedSizeCss}`}
                                >
                                    Recommended size:{' '}
                                    {imageCropRecommendedSize?.width}
                                    px x {imageCropRecommendedSize?.height}px
                                </p>
                            </div>
                        )}
                    {sizeOptions?.length !== 0 && (
                        <>
                            <small className="tw-mb-2">
                                or Select among the allowed dimensions below
                            </small>
                            <Select
                                ref={sizeOptionRef}
                                defaultValue={`${imageCropRecommendedSize?.width},${imageCropRecommendedSize?.height}`}
                                style={{ width: '100%' }}
                                className="size-option-selector"
                                onChange={handleSizeChange}
                                onFocus={handleOnFocus}
                                open={openSizeSlection}
                                getPopupContainer={(e) => e.parentElement}
                                options={sizeOptions?.map((size) => ({
                                    label: capitalize(size?.key),
                                    options: size?.sizes?.map((sizeVal) => ({
                                        label: `${sizeVal?.width} x ${sizeVal?.height} (px)`,
                                        value: `${sizeVal?.width},${sizeVal?.height}`,
                                    })),
                                }))}
                                placeholder={
                                    'Select among the allowed dimensions'
                                }
                            />
                        </>
                    )}
                </div>
            ) : null}

            <AudioModal
                modalType={'image'}
                multiple={multiple}
                isVisible={modalVisible}
                setModal={setModalVisible}
                mediaAsset={files}
                setSelectedMediaAsset={(mediaAsset) => {
                    handleMediaImage(mediaAsset);
                }}
                setError={(e) => console.error(e)}
            />

            <style jsx="true">{`
                .pintura-editor {
                    --color-background: 255, 255, 255;
                    --color-foreground: 0, 0, 0;
                }
                @media (prefers-color-scheme: dark) {
                    .pintura-editor {
                        --color-background: 0, 0, 0;
                        --color-foreground: 255, 255, 255;
                    }
                }
                .image-upload-disabled {
                    background-color: #cccccc52;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 30;
                }
                .image-upload-btn-reset {
                    color: #fff !important;
                    padding: 1px 10px;
                    font-size: 10px !important;
                    left: auto;
                    right: auto !important;
                    bottom: 3px !important;
                }
                .image-upload-button {
                    font-family: Roboto;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 15px;
                    letter-spacing: -0.02em;
                    text-align: left;
                    background: #e2e9f3;
                    border-radius: 4px;
                    padding: 10px 15px;
                    cursor: pointer;
                    margin: 15px 0;
                }
                .image-upload-container {
                    border: 1px solid #dadeeb;
                    border-radius: 4px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    min-height: 170px;
                }
                .image-upload-dropzone {
                    display: inherit;
                }
                .image-upload-thumbs {
                    position: relative;
                    display: inline-flex;
                    border-radius: 3px;
                    border: 1px solid #eaeaea;
                    margin-bottom: 7px;
                    margin-left: 3px;
                    margin-right: 3px;
                    width: 100px;
                    height: 100px;
                    padding: 5px;
                    box-sizing: border-box;
                    justify-content: center;
                }
                .image-upload-thumb-error {
                    position: absolute;
                    z-index: 10;
                    color: #c10000;
                    background: #ffffff8a;
                    width: 100%;
                    height: inherit;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 11px;
                }
                .image-upload-thumb-inner {
                    display: flex;
                    min-width: 0;
                    overflow: hidden;
                }

                .image-upload-thumb-btn {
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    background: rgba(0, 0, 0, 0.8);
                    color: rgb(255, 255, 255);
                    border: 0px;
                    border-radius: 0.325em;
                    cursor: pointer;
                    z-index: 20;
                }
                .image-upload-thumb-btn-remove {
                    top: 10px;
                    bottom: auto;
                    border-radius: 30px;
                    line-height: 18px;
                }
                .image-upload-classic-buttons {
                    position: absolute;
                    bottom: -23px;
                    left: 0px;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500 !important;
                    font-size: 13px;
                }
            `}</style>
        </div>
    );
}

ImageUpload.propTypes = {
    multiple: PropTypes.bool,
    maxFiles: PropTypes.number,
    maxSize: PropTypes.number,
    sortEnable: PropTypes.bool,
    disabled: PropTypes.bool,
    accept: PropTypes.string,
    previewImages: PropTypes.array,
    prepareFilesUpload: PropTypes.func,
    prepareFilesUploadFileType: PropTypes.string,
    handleFileDelete: PropTypes.func,
    autoUpload: PropTypes.bool,
    autoUploadData: PropTypes.object,
    disableStyle: PropTypes.bool,
    thumbsHeight: PropTypes.number,
    classicButtons: PropTypes.bool,
    withoutRedux: PropTypes.bool,
    projectId: PropTypes.string,
    resetDefaultImage: PropTypes.string,
    imageCropAspectRatio: PropTypes.number,
    imageCropRecommendedSize: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    imageCropMinSize: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    imageCropMaxSize: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
    }),
};

ImageUpload.defaultProps = {
    maxFiles: 10,
    maxSize: 52428800, //50MB
    sortEnable: false, //Sort order
    multiple: false,
    disabled: false,
    accept: 'image/*',
    previewImages: [],
    prepareFilesUpload: () => {},
    prepareFilesUploadFileType: 'url',
    handleFileDelete: () => {},
    autoUpload: false,
    autoUploadData: {},
    disableStyle: false,
    thumbsHeight: null,
    classicButtons: false,
    showRecommendedSize: true,
    withoutRedux: false,
    projectId: null,
    resetDefaultImage: null,
    imageCropAspectRatio: null,
    imageCropRecommendedSize: { width: null, height: null },
    imageCropMinSize: { width: null, height: null },
    imageCropMaxSize: { width: null, height: null },
};

export default ImageUpload;
