import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { Input, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { setUser } from 'redux/actions/common/userActions';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';
import produce from 'immer';

import { inviteUsers } from 'apis/rest/InviteUsers';
import { getUser } from 'apis/rest/User';

import Modal from 'components/app/common/modal';
import { generateValidation } from 'utils/inputValidation';
import { getEllipseTextWithTooltip } from 'utils/getEliipseTextWithTooltip';

const InputCustom = ({ error, ...props }) => (
    <div className="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-justify-start tw-items-start">
        <Input
            {...props}
            className="tw-rounded-md tw-border tw-border-gray-300 tw-py-2 tw-px-3 tw-m-0 tw-text-gray-700  tw-text-sm focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-500 focus:tw-border-blue-500"
        />
        {error ? (
            <p className="tw-w-full tw-p-0 tw-m-0 tw-text-xs tw-text-rose-700">
                {getEllipseTextWithTooltip(error, 30, 'bottom')}
            </p>
        ) : null}
    </div>
);

function InviteUsersPanel(props) {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const {
        setModalVisibility,
        showAlert,
        handleGetLeadsUsers,
        leadsQuota,
        totalLeadsQuota,
    } = props;
    const { register, errors, handleSubmit, setValue } = useForm();

    const handleGetUser = async () => {
        try {
            const { status, user, message } = await getUser();
            if (!status) {
                throw new Error(message);
            }
            dispatch(setUser(user));
        } catch (err) {
            console.error(err);
            showAlert(err?.message || 'Something went wrong', 'error');
        }
    };

    const [invitedUsers, setInvitedUsers] = useState([]);
    const [sendingInvites, setSendingInvites] = useState(false);
    const remainingInvites = totalLeadsQuota - leadsQuota - invitedUsers.length;

    function updateUserHandler(payload, index) {
        const updatedInvitedUsers = produce(invitedUsers, (draftState) => {
            const currentInput = draftState[index];
            currentInput[payload.name] = payload.value;
        });
        setInvitedUsers(updatedInvitedUsers);
        setValue(`${payload.name}-${index}`, payload.value, {
            shouldValidate: true,
        });
    }

    function addUserHandler() {
        if (remainingInvites <= 0) return;
        setInvitedUsers((prev) =>
            prev.concat({
                invitationType: 'email',
                firstname: '',
                lastname: '',
                email: '',
                phoneNo: '',
            })
        );
    }

    async function sendInvitesHandler() {
        try {
            setSendingInvites(true);
            const response = await inviteUsers(invitedUsers, projectId);
            if (!response.status) {
                throw new Error(response?.message);
            }
            handleGetLeadsUsers();
            await handleGetUser();
            setModalVisibility(false);
            showAlert('Invite(s) sent!', 'success');
        } catch (err) {
            console.error(err);
            showAlert(err?.message || 'Something went wrong', 'error');
        } finally {
            setSendingInvites(false);
        }
    }

    useEffect(() => {
        if (remainingInvites > 0 && invitedUsers.length === 0) {
            addUserHandler();
        }
    }, [remainingInvites, invitedUsers]);

    const columns = [
        {
            key: 'slNo',
            title: (
                <p className="tw-m-0 tw-p-0 tw-text-xs tw-text-gray-500 tw-font-semibold">
                    #
                </p>
            ),
            dataIndex: 'slNo',
            render: (slNo, record, index) => (
                <p className="tw-m-0 tw-p-0 tw-text-xs tw-text-gray-500 tw-font-semibold">
                    {index + 1}
                </p>
            ),
            width: 50,
        },
        {
            key: 'firstname',
            title: (
                <p className="tw-m-0 tw-p-0 tw-text-xs tw-text-gray-500 tw-font-semibold">
                    First Name
                </p>
            ),
            dataIndex: 'firstname',
            render: (firstname, record, index) => (
                <InputCustom
                    name="firstname"
                    placeholder="First Name"
                    value={firstname}
                    onChange={(e) =>
                        updateUserHandler(
                            { name: e.target.name, value: e.target.value },
                            index
                        )
                    }
                    ref={register(
                        `firstname-${index}`,
                        generateValidation({
                            name: 'name',
                            inputLabel: 'First Name',
                            isRequired: true,
                        })
                    )}
                    disabled={sendingInvites}
                    error={errors?.[`firstname-${index}`]?.message}
                />
            ),
            width: 150,
        },
        {
            key: 'lastname',
            title: (
                <p className="tw-m-0 tw-p-0 tw-text-xs tw-text-gray-500 tw-font-semibold">
                    Last Name
                </p>
            ),
            dataIndex: 'lastname',
            render: (lastname, record, index) => (
                <InputCustom
                    name="lastname"
                    placeholder="Last Name"
                    value={lastname}
                    onChange={(e) =>
                        updateUserHandler(
                            { name: e.target.name, value: e.target.value },
                            index
                        )
                    }
                    ref={register(
                        `lastname-${index}`,
                        generateValidation({
                            name: 'name',
                            inputLabel: 'Last Name',
                            isRequired: true,
                        })
                    )}
                    disabled={sendingInvites}
                    error={errors?.[`lastname-${index}`]?.message}
                />
            ),
            width: 150,
        },
        {
            key: 'email',
            title: (
                <p className="tw-m-0 tw-p-0 tw-text-xs tw-text-gray-500 tw-font-semibold">
                    Email
                </p>
            ),
            dataIndex: 'email',
            render: (email, record, index) => (
                <InputCustom
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) =>
                        updateUserHandler(
                            { name: e.target.name, value: e.target.value },
                            index
                        )
                    }
                    ref={register(
                        `email-${index}`,
                        generateValidation({
                            name: 'email',
                            inputLabel: 'Email',
                            isRequired: true,
                        })
                    )}
                    disabled={sendingInvites}
                    error={errors?.[`email-${index}`]?.message}
                />
            ),
            width: 150,
        },
        {
            key: 'phoneNo',
            title: (
                <p className="tw-m-0 tw-p-0 tw-text-xs tw-text-gray-500 tw-font-semibold">
                    Phone Number
                </p>
            ),
            dataIndex: 'phoneNo',
            render: (phoneNo, record, index) => (
                <PhoneInput
                    country={'sg'}
                    enableSearch={true}
                    inputStyle={{ width: '100%' }}
                    name="phoneNo"
                    countryCodeEditable={true}
                    value={phoneNo}
                    onChange={(phone) =>
                        updateUserHandler(
                            { name: 'phoneNo', value: phone },
                            index
                        )
                    }
                    disabled={sendingInvites}
                />
            ),
            width: 150,
        },
    ];

    return (
        <Modal
            visible={true}
            onClose={() => setModalVisibility(false)}
            showCloseIcon
            preventCloseOnOutsideClick
            style={{ maxWidth: '850px', maxHeight: '80vh' }}
        >
            <div className="tw-w-full tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center">
                <p className="tw-m-0 tw-p-0 tw-text-center tw-text-2xl tw-font-bold tw-text-gray-800">
                    Invite Users
                </p>
                <p className="tw-m-0 tw-p-0 tw-text-center tw-text-sm tw-text-gray-600">
                    You can invite up to{' '}
                    <strong>{totalLeadsQuota - leadsQuota} user(s)</strong>.
                </p>
            </div>
            <style jsx="true">
                {`
                    .ant-table-thead > tr > th:first-child {
                        width: 64px;
                    }
                    .ant-table-measure-row {
                        display: none;
                    }
                    .ant-table-cell {
                        vertical-align: baseline !important;
                    }
                `}
            </style>
            <Table
                columns={columns}
                dataSource={invitedUsers}
                pagination={false}
                scroll={{ y: 300, x: true }}
                className="tw-mt-4"
                footer={() => (
                    <div className="tw-w-full tw-flex tw-flex-row tw-gap-2 tw-justify-between tw-items-center">
                        {remainingInvites > 0 ? (
                            <button
                                className="tw-w-fit tw-border-0 tw-bg-blue-500 tw-text-white tw-text-sm tw-active:bg-blue-600 tw-font-bold tw-rounded tw-px-4 tw-py-2 tw-shadow hover:tw-shadow-lg"
                                onClick={addUserHandler}
                            >
                                Add User
                            </button>
                        ) : (
                            <p className="tw-m-0 tw-p-0 tw-w-fit tw-text-center tw-text-sm tw-text-gray-600">
                                You don't have invites left.
                            </p>
                        )}
                        <button
                            className="tw-w-fit tw-border-0 tw-bg-blue-500 tw-text-white tw-text-sm tw-active:bg-blue-600 tw-font-bold tw-rounded tw-px-4 tw-py-2 tw-shadow hover:tw-shadow-lg"
                            onClick={sendInvitesHandler}
                            disabled={
                                Object.keys(errors).length || sendingInvites
                            }
                        >
                            Send invite(s)
                        </button>
                    </div>
                )}
            />
        </Modal>
    );
}

InviteUsersPanel.propTypes = {
    setModalVisibility: PropTypes.func.isRequired,
    showAlert: PropTypes.func.isRequired,
    handleGetLeadsUsers: PropTypes.func.isRequired,
    leadsQuota: PropTypes.number.isRequired,
    totalLeadsQuota: PropTypes.number.isRequired,
};

export default withRouter(InviteUsersPanel);
