import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, Space, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';

import './createdTable.scss';

const CreatedTable = ({
    data,
    onRefreshData,
    pagination,
    loading,
    search,
    onClickDelete,
    onClickPreview,
}) => {
    const { projectId, companyId } = useParams();
    const history = useHistory();
    const [searchText, setSearchText] = useState('');

    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    //Search part
    let searchInput;
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder={`Search`}
                    value={selectedKeys[0]}
                    defaultValue={searchText}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        let searchTextSelect = selectedKeys[0] ? selectedKeys[0] : searchText;
        if (!/\s/.test(searchTextSelect)) {
            confirm();
            setSearchText(searchTextSelect);
            let trimSearchText = searchTextSelect?.trim();
            search(trimSearchText);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        search('');
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a?.name.localeCompare(b?.name),
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Sponsor',
            dataIndex: 'exhibitorCompanyProfile',
            sorter: (a, b) => a?.name.localeCompare(b?.name),
            render: (exhibitorCompanyProfile, item) => {
                if (isEmpty(exhibitorCompanyProfile)) return null;
                return (
                    <span className="custom-cut-sponsor-name">
                        {exhibitorCompanyProfile?.name}
                    </span>
                );
            },
        },
        {
            title: 'Tags',
            dataIndex: 'topicTags',
            sorter: (a, b) => a?.topicTags?.length - b?.topicTags?.length,
            render: (topicTags, item) => {
                if (isEmpty(topicTags)) return null;
                return topicTags.map((tag, i) => {
                    return (
                        <span
                            key={'tags-key-' + i}
                            className="badge badge-xpmanager custom-cut-badge"
                        >
                            {tag?.name}
                        </span>
                    );
                });
            },
        },
        {
            title: 'Last edited on',
            dataIndex: 'updatedAt',
            sorter: (a, b) =>
                moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
            render: (updatedAt, item) => {
                if (!updatedAt) return null;
                return (
                    <span>
                        {moment(updatedAt)
                            .tz(timezone)
                            .format('DD MMMM YYYY [at] HH:mm')}
                    </span>
                );
            },
        },
        {
            render: (item) => {
                return (
                    <div className="d-flex justify-content-end">
                        <button
                            className="myxp btn btn-sm btn-link"
                            onClick={() => onClickPreview(item)}
                        >
                            Preview
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link"
                            onClick={() =>
                                history.push({
                                    pathname: `/company/${companyId}/project/${projectId}/products/edit-product/${item.id}`,
                                })
                            }
                        >
                            Edit
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link"
                            onClick={() => onClickDelete(item)}
                        >
                            Delete
                        </button>
                    </div>
                );
            },
        },
    ];

    const globalLoadingComponent = {
        spinning: loading,
        indicator: <GlobalLoading />,
    };

    const renderPagination = (current, type, originalElement) => {
        return (
            <a
                onClick={() => {
                    if (originalElement?.type === 'button') {
                        if (data?.pagination?.currentPage === 1) {
                            return null;
                        }
                    }
                    pagination(current);
                }}
            >
                {originalElement}
            </a>
        );
    };

    return (
        <>
            {!loading && (
                <Table
                    className="sponsors-company-table"
                    rowKey="id"
                    columns={columns}
                    dataSource={data?.products}
                    loading={globalLoadingComponent}
                    pagination={{
                        itemRender: renderPagination,
                        pageSize: 10,
                        total: data?.pagination?.total,
                        current: data?.pagination?.currentPage,
                    }}
                />
            )}
        </>
    );
};

CreatedTable.propTypes = {
    data: PropTypes.object,
    onRefreshData: PropTypes.func,
    pagination: PropTypes.func,
    search: PropTypes.func,
    loading: PropTypes.bool,
    onClickPreview: PropTypes.func,
    onClickDelete: PropTypes.func,
};

CreatedTable.defaultProps = {
    data: null,
    onRefreshData: () => {},
    pagination: () => {},
    search: () => {},
    loading: false,
    onClickPreview: () => {},
    onClickDelete: () => {},
};

export default CreatedTable;
