import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { LockIcon } from './icons';
import './lockedOverlay.scss';

export default function LockedOverlay({
    isInStarterPlan,
    children,
    showOverlay = true,
    customclass
}) {
    const { companyId } = useParams();
    const history = useHistory();
    const [isUnveil, setIsUnveil] = useState(false);

    function onMouseDown(e) {
        e?.stopPropagation && e.stopPropagation();
        if (isInStarterPlan) {
            setIsUnveil(true);
        }
    }

    function onMouseUp(e) {
        e?.stopPropagation && e.stopPropagation();
        if (isInStarterPlan) {
            setIsUnveil(false);
        }
    }

    const getComponent = (className = '') => (
        <div
            className={customclass? customclass:`${className} tw-w-full tw-flex tw-flex-row`}
            onMouseUp={onMouseUp}
        >
            {children}
        </div>
    );

    useEffect(() => {
        window.addEventListener('mouseup', onMouseUp);
        return () => {
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, []);

    if (!isInStarterPlan || (isInStarterPlan && !showOverlay)) {
        return getComponent('');
    }
    return (
        <>
            <div
                className={`overlay ${
                    isUnveil ? 'moving overlay-fast' : 'still'
                } tw-h-full tw-absolute tw-z-30 tw-rounded-md tw-border tw-border-solid tw-border-slate-200`}
            />
            <div
                className={`upgrade ${
                    isUnveil ? 'moving' : 'still'
                } tw-absolute tw-flex tw-flex-col tw-w-full tw-h-full tw-items-center tw-justify-center tw-z-30`}
            >
                <div className="animate-bounce-upgrade tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-fit tw-bg-white/[.45] tw-rounded-lg tw-px-9 tw-py-4">
                    <p className="tw-font-montserrat tw-text-sm tw-text-slate-800 tw-font-semibold tw-p-0 tw-m-0">
                        Your current plan does not give you access to this
                        feature.
                    </p>
                    <div
                        className="tw-font-montserrat tw-group tw-w-auto tw-px-4 tw-py-2 tw-text-sm tw-text-white tw-font-semibold tw-m-1 tw-mt-2 tw-bg-green-700 tw-rounded tw-cursor-pointer hover:tw-bg-green-600"
                        onClick={() =>
                            history.push(`/v2/company/${companyId}/upgrade`)
                        }
                    >
                        <LockIcon />
                        &nbsp;Upgrade
                    </div>
                </div>
            </div>
            <div
                className="eye tw-absolute tw-top-4 tw-right-4 tw-z-30 tw-overflow-hidden tw-cursor-pointer"
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            >
                <Tooltip
                    title={
                        isUnveil ? 'Release to hide' : 'Click & Hold to reveal'
                    }
                    placement="top"
                >
                    <div
                        className="shine tw-w-8 tw-h-8 tw-cursor-pointer tw-rounded-full tw-overflow-hidden tw-border tw-border-solid tw-border-gray-300"
                        onMouseDown={onMouseDown}
                        onMouseUp={onMouseUp}
                    >
                        <img
                            src={
                                isUnveil
                                    ? './assets/eye-close.png'
                                    : './assets/eye-open.png'
                            }
                            className="deselect tw-w-full tw-h-full tw-m-0 tw-p-0 tw-cursor-pointer"
                            alt="eye open"
                        />
                    </div>
                </Tooltip>
            </div>
            {getComponent(isUnveil ? 'blur-none' : 'blur-xs')}
        </>
    );
}

LockedOverlay.getParentClass = ({
    showBorder = true,
    showShadow = true,
    isInStarterPlan = false,
    isScrollable = false,
} = {}) => {
    let parentClassName = 'tw-relative tw-rounded-md';
    parentClassName += isScrollable
        ? ' tw-overflow-scroll'
        : ' tw-overflow-hidden';
    if (showBorder) {
        parentClassName += ` tw-border tw-border-solid ${
            isInStarterPlan ? 'tw-border-green-700' : 'tw-border-gray-200'
        }`;
    }
    if (showShadow && isInStarterPlan) {
        parentClassName += ' tw-shadow-sm tw-shadow-green-700';
    }

    return parentClassName;
};

LockedOverlay.LockIcon = LockIcon;
