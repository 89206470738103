import createDOMPurify from 'dompurify';

const defaultTags = {
    html: [
        'a',
        'abbr',
        'acronym',
        'address',
        'applet',
        'area',
        'article',
        'aside',
        'audio',
        'b',
        'base',
        'basefont',
        'bdi',
        'bdo',
        'bgsound',
        'big',
        'blink',
        'blockquote',
        'body',
        'br',
        'button',
        'canvas',
        'caption',
        'center',
        'cite',
        'code',
        'col',
        'colgroup',
        'content',
        'data',
        'datalist',
        'dd',
        'decorator',
        'del',
        'details',
        'dfn',
        'dir',
        'div',
        'dl',
        'dt',
        'element',
        'em',
        'embed',
        'fieldset',
        'figcaption',
        'figure',
        'font',
        'footer',
        'form',
        'frame',
        'frameset',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'head',
        'header',
        'hgroup',
        'hr',
        'html',
        'i',
        'iframe',
        'img',
        'input',
        'ins',
        'isindex',
        'kbd',
        'keygen',
        'label',
        'legend',
        'li',
        'link',
        'listing',
        'main',
        'map',
        'mark',
        'marquee',
        'menu',
        'menuitem',
        'meta',
        'meter',
        'nav',
        'nobr',
        'noframes',
        'noscript',
        'object',
        'ol',
        'optgroup',
        'option',
        'output',
        'p',
        'param',
        'plaintext',
        'pre',
        'progress',
        'q',
        'rp',
        'rt',
        'ruby',
        's',
        'samp',
        'script',
        'section',
        'select',
        'shadow',
        'small',
        'source',
        'spacer',
        'span',
        'strike',
        'strong',
        'style',
        'sub',
        'summary',
        'sup',
        'table',
        'tbody',
        'td',
        'template',
        'textarea',
        'tfoot',
        'th',
        'thead',
        'time',
        'title',
        'tr',
        'track',
        'tt',
        'u',
        'ul',
        'var',
        'video',
        'wbr',
        'xmp',
    ],
    richtext: [
        'html',
        'head',
        'body',
        'div',
        'style',
        'br',
        'hr',
        'iframe',
        'p',
        'span',
        'strong',
        'ol',
        'ul',
        'li',
        'u',
        'em',
        'b',
        's',
        'sub',
        'sup',
        'blockquote',
        'textarea',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'pre',
        'a',
        'table',
        'tr',
        'th',
        'td',
        'tbody',
        'tfoot',
        'thead',
        'caption',
    ],
    heading: [
        'html',
        'head',
        'body',
        'style',
        'br',
        'hr',
        'iframe',
        'p',
        'span',
        'strong',
        'ol',
        'ul',
        'li',
        'em',
        'b',
        'u',
    ],
    description: ['br', 'span', 'p', 'div', 'iframe' ],
    bio: [
        'br',
        'hr',
        'p',
        'span',
        'strong',
        'ol',
        'ul',
        'li',
        'u',
        'em',
        'b',
        's',
        'sub',
        'sup',
        'blockquote',
        'textarea',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'div',
        'pre',
        'a',
    ],
};

const defaultSanitizeOptions = {
    ALLOWED_TAGS: ['html', 'head', 'body', 'style', 'br', 'hr', 'iframe'],
    ALLOWED_ATTR: [
        'style',
        'class',
        'type',
        'id',
        'name',
        'title',
        'href',
        'src',
        'target',
        'rel',
        'allow',
        'allowfullscreen',
        'referrerpolicy',
        'sandbox',
        'frameborder',
        'scrolling',
        'loading',
        'async',
        'defer',
        'media',
        'settings',
        'preview',
        'fullpagepreview',
        'target',
        'width',
        'height',
        'alt',
        'buffered',
        'charset',
        'checked',
        'color',
        'controls',
        'crossorigin',
        'data-*',
        'defer',
        'dir',
        'disabled',
        'download',
        'draggable',
        'enctype',
        'enterkeyhint',
        'for',
        'form',
        'formenctype',
        'hidden',
        'href',
        'integrity',
        'intrinsicsize',
        'inputmode',
        'ismap',
        'itemprop',
        'label',
        'lang',
        'loading',
        'list',
        'loop',
        'media',
        'method',
        'min',
        'multiple',
        'muted',
        'name',
        'novalidate',
        'open',
        'pattern',
        'ping',
        'placeholder',
        'playsinline',
        'poster',
        'preload',
        'readonly',
        'referrerpolicy',
        'rel',
        'required',
        'reversed',
        'role',
        'rows',
        'cols',
        'sandbox',
        'selected',
        'shape',
        'size',
        'slot',
        'span',
        'spellcheck',
        'srclang',
        'srcset',
        'start',
        'step',
        'summary',
        'tabindex',
        'target',
        'title',
        'translate',
        'type',
        'usemap',
        'value',
        'wrap',
        'rowspan',
        'colspan',
        'coords',
    ],
    KEEP_CONTENT: true,
    FORCE_BODY: true,
    ALLOW_UNKNOWN_PROTOCOLS: false,
};

const sanitizeContent = ({ str = '', sanitizeOptions = {}, type = '' }) => {
    if (typeof str !== 'string') {
        return str;
    }
    
    const DOMPurify = createDOMPurify(window);
    let sanitizeOptionsUpdate = { ...sanitizeOptions };

    if (type && type.length !== 0) {
        sanitizeOptionsUpdate = {
            ...defaultSanitizeOptions,
            ...sanitizeOptionsUpdate,
            ALLOWED_TAGS: defaultTags[type],
        };
    } else {
        sanitizeOptionsUpdate = {
            ...defaultSanitizeOptions,
            ...sanitizeOptionsUpdate,
        };
    }

    return DOMPurify.sanitize(
        decodeEntitiesForSanitization(str),
        sanitizeOptionsUpdate
    );
};

export const decodeEntitiesForSanitization = (encodedString) => {
    const translateRe = /&(nbsp|amp|quot|lt|gt);/g;
    const translate = {
        nbsp: ' ',
        amp: '&',
        quot: '"',
        lt: '<',
        gt: '>',
    };
    if (typeof encodedString === 'string') {
        return encodedString
            .replace(translateRe, (match, entity) => translate[entity])
            .replace(/&#(\d+);/gi, (match, numStr) => {
                const num = parseInt(numStr, 10);
                return String.fromCharCode(num);
            });
    }
    return encodedString;
};

export default sanitizeContent;
