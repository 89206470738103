import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import * as ls from 'local-storage';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

export async function UploadImage(data) {
    try {
        setAuthBearer();

        let formData = new FormData();

        formData.append('projectId', data.projectId);
        for (let i = 0; i < data.files.length; i++) {
            formData.append(`files`, data.files[i]);
        }

    let url = `${process.env.REACT_APP_MYXP_API}/manager/livestream/upload`;
    if (data?.miniExperience) {
        url += '?miniExperience=1';
    }
    const response = await fetch(url, {
        method: 'POST',
        headers: { Authorization: authBearer },
        body: formData,
    });

        const res = await response.json();

        if (res.success) {
            return data?.files?.length > 1 ? res.files : res.files[0];
        } else throw res;
    } catch (error) {
        openNotification('error', {
            message: error?.message || `Image upload failed: ${error}`,
        });
    }
}
