import produce from 'immer';

export const initialState = {
    labels: [],
    labelFilters: [],
};

function badgeReducers(state = initialState, action) {
    switch (action.type) {
        case 'SET_BADGE_LABELS':
            return {
                ...state,
                labels: action.payload,
            };
        case 'ADD_BADGE_LABEL':
            return produce(state, (draftState) => {
                draftState.labels = [...draftState.labels, action.payload];
            });
        case 'UPDATE_BADGE_LABEL':
            return produce(state, (draftState) => {
                const updateLabel = draftState.labels.findIndex(
                    (label) => label.id === action.payload.id
                );
                draftState.labels[updateLabel] = action.payload;
                draftState.labelFilters = draftState.labelFilters.map(
                    (label) => {
                        if (label.id === action.payload.id) {
                            return action.payload;
                        }
                        return label;
                    }
                );
            });
        case 'DELETE_BADGE_LABEL':
            return produce(state, (draftState) => {
                const deleteLabel = draftState.labels.findIndex(
                    (label) => label.id === action.payload
                );
                draftState.labels.splice(deleteLabel, 1);
                draftState.labelFilters = draftState.labelFilters.filter(
                    (label) => label.id !== action.payload
                );
            });
        case 'SET_BADGE_FILTER':
            return produce(state, (draftState) => {
                const filter = draftState.labelFilters.findIndex(
                    (label) => label.id === action.payload.id
                );
                if (filter === -1) {
                    draftState.labelFilters = [
                        ...draftState.labelFilters,
                        action.payload,
                    ];
                } else {
                    draftState.labelFilters.splice(filter, 1);
                }
            });
        case 'RESET_BADGE_FILTER':
            return {
                ...state,
                labelFilters: [],
            };

        default:
            return state;
    }
}

export default badgeReducers;
