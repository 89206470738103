import produce from 'immer';
import momentTimezone from 'moment-timezone';
import * as ls from 'local-storage'

export const initialState = {
    showcaseSlug: '',
    timezone: ls.get('timezone')|| '',
    venueId: '',
    loginOption: {},
    enableCaptcha: null,
    settingId: null,
    analyticsSiteId: null,
    customDomain: null,
    experienceAssociated: false,
    experience: '',
    totalExperiencesCount: 0,
    activeTimezone: ls.get('timezone') || momentTimezone.tz.guess(),
    triggers: [
        {
            "id": "8abc401a-ed6f-11eb-9a03-0242ac130003",
            "code": "LIVEPAGE_LOAD",
            "type": "client",
            "description": "When livepage loads",
            "actionTypes": []
        },
        {
            "id": "8abc4272-ed6f-11eb-9a03-0242ac130003",
            "code": "LIVEPAGE_SPECIFIC_TIME",
            "type": "client",
            "description": "Specific amount of time after livepage loads",
            "actionTypes": []
        }
    ],
};

function userReducers(state = initialState, action) {
    switch (action.type) {
        case 'SET_SHOWCASE_SLUG':
            return produce(state, (draft) => {
                draft.showcaseSlug = action.payload;
            });
        case 'SET_CUSTOM_DOMAIN':
            return {
                ...state,
                customDomain: action.payload,
            };
        case 'SET_TRIGGERS':
            return {
                ...state,
                triggers: action.payload,
            };
        case 'SET_ACTIVE_TIMEZONE':
            return {
                ...state,
                activeTimezone: action.payload,
            };
        case 'SET_PROJECT_TIMEZONE':
            return {
                ...state,
                timezone:action.payload
            };
        case 'SET_PROJECT_VENUE_DETAILS':
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export default userReducers;
