import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import * as ls from 'local-storage';

import './previewer.scss';
import { getIframePolicies } from 'apis/rest/Utilities';

const qs = require('qs');

function Previewer(props) {
    const queryString = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
    });
    const auth = window.SPONSOR_AUTH ? window.SPONSOR_AUTH : ls.get('auth');

    const { showcaseId, templateId, projectId, url } = queryString;

    let source = '';
    if (templateId)
        source =
            url ||
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/showcases/${showcaseId}/preview?templateId=${templateId}&projectId=${projectId}&token=${auth.access_token}&version=2`;
    else source = queryString.source;

    if (url) {
        source = url;
    }

    const previewIframe = useRef(null);

    const [viewport, setViewport] = useState('desktop');

    /**
     * renders viewport size depending on `viewport` type
     */
    const renderViewportSize = () => {
        switch (viewport) {
            case 'mobile':
                return '450px';
            case 'tablet':
                return '768px';
            default:
                return '100%';
        }
    };

    /**
     * reloads the iframe content
     * @todo seems a little hacky, see if there's a better way
     */
    const reloadIframe = () => {
        /**
         * reference for below logic: https://stackoverflow.com/questions/86428/what-s-the-best-way-to-reload-refresh-an-iframe
         */
        previewIframe.current.src = previewIframe.current.src;
    };

    /**
     * changes the `viewport` size type
     * @param {string} selectedViewport selected viewport
     */
    const selectViewport = (selectedViewport) => {
        setViewport(selectedViewport);
    };

    const iframePolicies = getIframePolicies();

    return (
        <div className="previewer">
            <div className="previewer__header">
                <div className="previewer__selector">
                    <span
                        className={`previewer__viewport ${
                            viewport === 'desktop' &&
                            'previewer__viewport--selected'
                        }`}
                        onClick={() => {
                            selectViewport('desktop');
                        }}
                    >
                        <img
                            src={`/assets/icon_desktop${
                                viewport === 'desktop' ? '' : '_inactive'
                            }.svg`}
                            alt=""
                        />
                    </span>
                    <span
                        className={`previewer__viewport ${
                            viewport === 'tablet' &&
                            'previewer__viewport--selected'
                        }`}
                        onClick={() => {
                            selectViewport('tablet');
                        }}
                    >
                        <img
                            src={`/assets/icon_tablet${
                                viewport === 'tablet' ? '' : '_inactive'
                            }.svg`}
                            alt=""
                        />
                    </span>
                    <span
                        className={`previewer__viewport ${
                            viewport === 'mobile' &&
                            'previewer__viewport--selected'
                        }`}
                        onClick={() => {
                            selectViewport('mobile');
                        }}
                    >
                        <img
                            src={`/assets/icon_mobile${
                                viewport === 'mobile' ? '' : '_inactive'
                            }.svg`}
                            alt=""
                        />
                    </span>
                </div>
                <p onClick={reloadIframe} className="previewer__refresh">
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="redo"
                        class="svg-inline--fa fa-redo fa-w-16 "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            fill="currentColor"
                            d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"
                        ></path>
                    </svg>
                    Refresh Preview
                </p>
            </div>

            <iframe
                src={source}
                sandbox={iframePolicies.sandbox}
                allow={iframePolicies.allow}
                allowVr={iframePolicies.allowVr}
                allowFullScreen={iframePolicies.allowFullScreen}
                seamless={iframePolicies.seamless}
                loading={iframePolicies.loading}
                frameBorder={iframePolicies.frameBorder}
                style={{ width: renderViewportSize() }}
                name="previewer"
                title="previewer"
                ref={previewIframe}
            ></iframe>
        </div>
    );
}

export default withRouter(Previewer);
