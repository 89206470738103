import * as ls from 'local-storage';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};
/**
 * Update the isPublished livepage settings
 * @param  {string} livepageId
 * @param  {string} status  'publish'|'unpublish'
 * @category Venue Builder
 */
const updateLivePageStatus = async (livepageId,projectId, status = 'publish') => {
    setAuthBearer();
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/showcases/${livepageId}/${status}?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );

    return await response.json();
};
export default updateLivePageStatus;
