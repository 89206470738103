import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/react-hooks';

import produce from 'immer';
import { omit, isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';

import useAlert from 'components/app/utils/hooks/useAlert';

import SHOWCASE from 'apis/graphql/Showcase';
import PERSON from 'apis/graphql/Person';
import DELETE_SHOWCASEBLOCK from 'apis/graphql/DeleteShowcaseblock';

import { Modal } from 'react-bootstrap';

import Layout from 'components/app/common/layout';
import Alert from 'components/app/common/alert';
import GeneralPreloader from 'components/app/common/preloader/generalPreloader';

import BlockCreator from 'components/app/components/showcase/blockCreator';
import BlockEditor from 'components/app/components/showcase/blockEditor';
import ShowcaseEditor from 'components/app/components/showcase/showcaseEditor';
import PersonEditor from 'components/app/components/showcase/personEditor';
import QrCodeDisplay from 'components/app/components/showcase/qrCodeDisplay';

import ContentTogglePanel from './contentTogglePanel';

import './content.scss';
function Content(props) {
    const {
        match: { params },
    } = props;
    const showcaseId = params.showcaseId;
    const personId = params.personId;
    const [isModalVisible, setModalVisibility] = useState(false);
    const [updatePayload, setUpdatePayload] = useState({});
    const [modalView, setModalView] = useState('showcaseInfo');
    const [iframeLoading, setIframeLoading] = useState(false);

    const { alert, showAlert } = useAlert();

    const contentShowcaseIframe = useRef(null);

    const { loading: loadingShowcase, data: dataShowcase } = useQuery(
        SHOWCASE,
        {
            variables: { id: showcaseId },
            notifyOnNetworkStatusChange: true,
        }
    );

    const { data: dataPerson } = useQuery(PERSON, {
        variables: { id: personId },
        notifyOnNetworkStatusChange: true,
    });

    const [deleteShowcaseblock] = useMutation(DELETE_SHOWCASEBLOCK, {
        onCompleted: (data) => {
            showAlert('Content block deleted!', 'success');
        },
        onError: (err) => {
            showAlert(err.message, 'error');
        },
    });

    /**
     * renders icon depending on the content type
     * @param {string} type type of block content
     */
    const renderTypeIcon = (type) => {
        switch (type) {
            case 'Image':
                return <img src="/assets/icon_image.svg" alt="" />;
            case 'Pdf':
                return <img src="/assets/icon_pdf.svg" alt="" />;
            case 'Video':
                return <img src="/assets/icon_video.svg" alt="" />;
            case 'Post':
                return <img src="/assets/icon_rich_text.svg" alt="" />;
            default:
                break;
        }
    };

    /**
     * reloads the iframe content
     * @todo seems a little hacky, see if there's a better way
     */
    const reloadIframe = () => {
        setIframeLoading(true);
        contentShowcaseIframe.current.src = contentShowcaseIframe.current.src;

        setTimeout(() => {
            /**
             * reference for below logic: https://stackoverflow.com/questions/86428/what-s-the-best-way-to-reload-refresh-an-iframe
             */
            contentShowcaseIframe.current.src =
                contentShowcaseIframe.current.src;
            setIframeLoading(false);
        }, 400);
    };

    /**
     * determines what type of form / update to show
     * there are 4 types currently: new block, update block, update content owner info and qr code display
     */
    const determineModalView = () => {
        const contentEditorProps = {
            setModalVisibility,
            reloadIframe,
            showAlert,
        };
        switch (modalView) {

            case 'content':
                if (isEmpty(updatePayload)) {
                    return (
                        <BlockCreator {...contentEditorProps}></BlockCreator>
                    );
                } else {
                    return (
                        <BlockEditor
                            updatePayload={updatePayload}
                            {...contentEditorProps}
                        ></BlockEditor>
                    );
                }

            case 'showcaseInfo':
                return (
                    <ShowcaseEditor
                        updatePayload={updatePayload}
                        {...contentEditorProps}
                    ></ShowcaseEditor>
                );

            case 'userInfo':
                return (
                    <PersonEditor
                        updatePayload={updatePayload}
                        {...contentEditorProps}
                    ></PersonEditor>
                );

            case 'qrCodeDisplay':
                return (
                    <QrCodeDisplay
                        showcaseId={showcaseId}
                        setModalVisibility={setModalVisibility}
                    ></QrCodeDisplay>
                );
            default:
                break;
        }
    };

    /**
     * opens form for adding a new block
     */
    const addBlock = () => {
        setUpdatePayload({});
        setModalView('content');
        setModalVisibility(true);
    };

    /**
     * opens form for updating an existing block
     * @param {string} showcaseblockId id of showcase block to be updated
     * @param {string} type type of block content to be updated
     */
    const updateBlock = (showcaseblockId, type) => {
        setUpdatePayload({});

        let nextState = produce(updatePayload, (draftState) => {
            draftState.showcaseblockId = showcaseblockId;
            draftState.type = type;
        });

        setUpdatePayload(nextState);
        setModalView('content');
        setModalVisibility(true);
    };

    /**
     * deletes a block content
     * @param {object} e event object
     * @param {string} showcaseblockId id of block / content to be deleted
     */
    const deleteBlock = (e, showcaseblockId) => {
        e.stopPropagation();

        let isDeleteConfirmed = window.confirm(
            'Are you sure you want to delete this block?'
        );

        if (isDeleteConfirmed) {
            deleteShowcaseblock({
                variables: {
                    input: {
                        where: { id: showcaseblockId },
                    },
                },
                refetchQueries: [
                    { query: SHOWCASE, variables: { id: showcaseId } },
                ],
            });

            reloadIframe();
        }
    };

    /**
     * opens form for updating showcase general information
     * @param {string} type type of content to be updated
     */
    const updateShowcaseInfo = (type) => {
        setUpdatePayload({});

        let showcaseInfo = omit(dataShowcase.showcase, [
            'showcaseblocks',
            '__typename',
        ]);

        let nextState = produce(updatePayload, (draftState) => {
            draftState.showcase = showcaseInfo;
            draftState.type = type;
        });

        setUpdatePayload(nextState);
        setModalView('showcaseInfo');
        setModalVisibility(true);
    };

    /**
     * opens form for updating person information
     * @param {string} type type of content to be updated
     */
    const updateUserInfo = (type) => {
        setUpdatePayload({});

        let userInfo = omit(dataPerson.person, ['__typename']);

        let nextState = produce(updatePayload, (draftState) => {
            draftState.person = userInfo;
            draftState.type = type;
        });

        setUpdatePayload(nextState);
        setModalView('userInfo');
        setModalVisibility(true);
    };

    /**
     * generates QR code
     */
    const displayQrCode = () => {
        setModalVisibility(true);
        setModalView('qrCodeDisplay');
    };

    /**
     * renders the modal size required for a particular modal view
     */
    const renderModalSize = () => {
        if (
            modalView === 'showcaseInfo' ||
            modalView === 'userInfo' ||
            modalView === 'qrCodeDisplay'
        ) {
            return 'small';
        } else {
            return 'large';
        }
    };

    /**
     * if showcase id doesn't exist, go to 404 error
     */
    if (!loadingShowcase && !dataShowcase) {
        props.history.push('/404');
    }

    return (
        <Layout
            pagePayload={{
                displayQrCode,
                title: dataShowcase && dataShowcase.showcase.title,
            }}
        >
            <Alert alert={alert}></Alert>
            <Helmet>
                <title>Showcase Manager - GEVME XP</title>
            </Helmet>
            <div className="content">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="content__editor">
                            <h1>Content</h1>

                            <p>
                                Customise the content of your Digital Folio
                                showcase by editing the widgets below. You can
                                edit the content of a widget or turn off its
                                visibility so that it does not appear on your
                                showcase.
                            </p>

                            {dataShowcase && dataPerson && (
                                <ContentTogglePanel
                                    updateShowcaseInfo={updateShowcaseInfo}
                                    updateUserInfo={updateUserInfo}
                                    data={dataShowcase}
                                    showAlert={showAlert}
                                    reloadIframe={reloadIframe}
                                ></ContentTogglePanel>
                            )}

                            {loadingShowcase && (
                                <GeneralPreloader color="#dee0e6"></GeneralPreloader>
                            )}

                            <h3>Blocks</h3>

                            <p>
                                Blocks allow you to add various types of content
                                to your showcase.
                            </p>

                            {dataShowcase && (
                                <div className="content__blocks">
                                    <div
                                        className="content__block content__block--add-block"
                                        onClick={addBlock}
                                    >
                                        + Add a Block
                                    </div>
                                    {dataShowcase.showcase.showcaseblocks.map(
                                        (showcaseblock) => {
                                            return (
                                                <div
                                                    className="content__block"
                                                    onClick={() =>
                                                        updateBlock(
                                                            showcaseblock.id,
                                                            showcaseblock.type
                                                        )
                                                    }
                                                    key={showcaseblock.id}
                                                >
                                                    <div className="content__block-wrap">
                                                        <div className="content__block-name">
                                                            <span className="content__block-type">
                                                                {renderTypeIcon(
                                                                    showcaseblock.type
                                                                )}
                                                            </span>
                                                            {
                                                                showcaseblock.title
                                                            }
                                                        </div>

                                                        <div className="content__block-action">
                                                            <img
                                                                src="/assets/icon_edit.png"
                                                                alt=""
                                                            />
                                                            <img
                                                                src="/assets/icon_trash.svg"
                                                                alt=""
                                                                onClick={(e) =>
                                                                    deleteBlock(
                                                                        e,
                                                                        showcaseblock.id
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            )}

                            {loadingShowcase && (
                                <GeneralPreloader color="#dee0e6"></GeneralPreloader>
                            )}
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="content__preview">
                            <div className="content__preview-iframe-wrap">
                                {iframeLoading && (
                                    <div className="content__preview-iframe-loading">
                                        <div>
                                            <img
                                                src="/assets/icon_rolling_spinner.gif"
                                                alt=""
                                            />
                                            <p>Refreshing your showcase</p>
                                        </div>
                                    </div>
                                )}
                                <iframe
                                    src={`${process.env.REACT_APP_SHOWCASE_CONTENT_URL}/content/${showcaseId}`}
                                    className="content__preview-iframe"
                                    title="Content Preview"
                                    ref={contentShowcaseIframe}
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    show={isModalVisible}
                    centered={true}
                    onHide={setModalVisibility}
                    dialogClassName={`custom-modal--${renderModalSize()}`}
                >
                    <Modal.Body>{determineModalView()}</Modal.Body>
                </Modal>
            </div>
        </Layout>
    );
}

export default withRouter(Content);
