import getAuthBearer from '../../../utils/getAuthBearer';
/**
 * Update the isPublished livepage settings
 * @param  {string} livepageId
 * @param  {string} status  'publish'|'unpublish'
 * @category Venue Builder
 */
const setWidgetPageStatus = async ({
    livepageId,
    status = 'publish',
    projectId,
}) => {
    const authBearer = getAuthBearer();
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/widgets/${livepageId}/${status}?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );

    return await response.json();
};
export default setWidgetPageStatus;
