import produce from "immer";

export const initialState = {
  isLoading: false
};

function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_LOADING_STATUS":
      return produce(state, draft => {
        draft.isLoading = action.payload;
      });
    default:
      return state;
  }
}

export default loadingReducer;
