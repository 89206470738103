import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip, Input, Select, Divider } from 'antd';
import copy from 'copy-to-clipboard';
import CopyIcon from 'Apps/VenueBuilder/Icons/CopyIcon';
import { InfoCircleOutlined } from '@ant-design/icons';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import Loader from 'components/app/components/venue/Loader';
import styles from '../index.module.css';
import { GetChannel } from 'apis/rest/channels/GetChannel';
import * as ls from 'local-storage';

const GevmeLive = ({
    setChannelId,
    value,
    error,
    channels,
    setPlayBackUrl,
    getLivePageChannels,
}) => {
    const isSponsor = !!ls.get('sponsorAuth');
    const dropdownContainer = useRef(null);
    const [visible, setVisible] = useState(false);

    const { projectId, companyId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [selectedStream, setSelectedStream] = useState(null);
    const [defaultSelectedChannel, setDefaultSelectedChannel] = useState('');

    const [searchVal, setSearchVal] = useState([]);

    const handleOutsideClick = (e) => {
        if (e.composedPath().includes(dropdownContainer.current)) return;
        setVisible(false);
    };

    // on click outside of container
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        setSearchVal(channels?.length > 0 ? channels : []);
    }, [channels]);

    const onChange = (value) => {
        if (!value) {
            setDefaultSelectedChannel('');
            setSelectedStream(null);
            setPlayBackUrl('');
            setChannelId('');
            return getLivePageChannels(projectId);
        } else {
            setDefaultSelectedChannel(value);
            return getLivePageChannels(projectId, value);
        }
        // const searchVal = channels?.findIndex((item) => item.id === val);
        // if (findIndex !== -1) {
        //     setSelectedStream(channels[findIndex]);
        //     setPlayBackUrl(channels[findIndex]?.playbackUrl);
        //     setChannelId(val);
        // }
    };

    const onFocus = () => {};

    const onBlur = () => {
        return getLivePageChannels(projectId);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 8) {
        }
    };

    const handleCopy = (type, value) => {
        if (value) {
            copy(value);
            openNotification('success', {
                message: `${type} Copied to clipboard!`,
            });
        }
    };

    const getChannel = async (id) => {
        const response = await GetChannel(projectId, id);
        setIsLoading(true);
        if (response.status) {
            setSelectedStream(response?.channel);
            setPlayBackUrl(response?.channel?.playbackUrl);
            setDefaultSelectedChannel(response?.channel?.name);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (value && !isSearching) {
            getChannel(value);
        }
    }, [value, isSearching]);

    return (
        <>
            {isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '10px 0',
                    }}
                >
                    <Loader />
                </div>
            ) : (
                <>
                    <div className={styles.Row} style={{ marginTop: 0 }}>
                        <div className={styles.InputContainerHeading}>
                            <h3>
                                Choose a channel to broadcast
                                <span className="required">*</span>
                            </h3>
                        </div>
                        <div
                            ref={dropdownContainer}
                            id="gevmelive-stream-container"
                            className={`gevmelive-stream ${
                                styles.InputContainerHeading
                            } ${error ? 'error' : ''}`}
                            style={{ marginBottom: 16, position: 'relative' }}
                        >
                            <input
                                onChange={(e) => onChange(e.target.value)}
                                onBlur={onBlur}
                                disabled={isLoading}
                                // onFocus={onFocus}
                                placeholder={''}
                                // className={link?.length > 0 || isRequired ? isSubmitted ? 'error' : '' : ''}
                                value={defaultSelectedChannel}
                                onKeyDown={onKeyDown}
                                onClick={() => setVisible(!visible)}
                                style={{ width: '100%' }}
                            />

                            {visible && (
                                <div
                                    className={`${styles.LivePagesItems} livepage-items`}
                                >
                                    <div className={styles.ChannelsContainer}>
                                        {searchVal?.length > 0 ? (
                                            searchVal?.map((data, index) => (
                                                <span
                                                    onMouseDown={() => {
                                                        setDefaultSelectedChannel(
                                                            data.name
                                                        );
                                                        setSelectedStream(data);
                                                        setPlayBackUrl(
                                                            data.playbackUrl
                                                        );
                                                        setChannelId(data.id);
                                                        setVisible(false);
                                                    }}
                                                    className="livepage-item-select"
                                                    key={index}
                                                >
                                                    {data.name}
                                                </span>
                                            ))
                                        ) : (
                                            <span>Not found</span>
                                        )}
                                    </div>
                                    {error && (<span className="error">
                                                Required
                                            </span>)}
                                    {!isSponsor && (
                                        <>
                                            <Divider
                                                style={{ margin: '4px 0' }}
                                            />
                                            <span
                                                onClick={() =>
                                                    window.open(
                                                        `/company/${companyId}/project/${projectId}/dashboard/livestreaming/create`,
                                                        '_blank'
                                                    )
                                                }
                                                className="select-description"
                                            >
                                                Create Channel
                                            </span>
                                        </>
                                    )}
                                </div>
                            )}
                            {/* <Select
                            // size={'middle'}
                            showSearch
                            style={{
                                width: 268,
                            }}
                            // getPopupContainer={(trigger) => trigger.parentNode}
                            defaultValue={defaultSelectedChannel}
                            // notFoundContent={
                            //     fetchingPages
                            //         ? 'Fetching...'
                            //         : channels?.length === 0
                            //             ? 'Not found'
                            //             : 'Not found'
                            // }
                            placeholder="Select a page"
                            optionFilterProp="children"
                            onChange={onChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            onPopupScroll={onPopupScroll}
                            dropdownRender={(menu) => (
                                <div>
                                    {menu}
                                    {!isSponsor && <>
                                        <Divider style={{ margin: '4px 0' }} />
                                        <span
                                            onClick={() =>
                                                window.open(
                                                    `/company/${companyId}/project/${projectId}/channels`,
                                                    '_blank'
                                                )
                                            }
                                            className="select-description"
                                        >
                                            Create Channel
                                        </span>
                                    </>}
                                </div>
                            )}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0 ||
                                option.props.value
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {channels?.map(({ id, name, description }) => (
                                <Option value={id} key={id}>
                                    {name}
                                </Option>
                            ))}
                        </Select> */}
                        </div>
                        {error && (
                            <span className={styles.ErrorTxt}>Required</span>
                        )}
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.InputContainerHeading}>
                            <h3>
                                RTMP URL
                                <Tooltip
                                    placement="right"
                                    title="GEVME Live RTMP URL"
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </h3>
                        </div>
                        <div className={styles.InputContainer}>
                            <Input
                                name="rtmpurl"
                                value={`${
                                    selectedStream
                                        ? `${selectedStream?.ingestEndpoint}`
                                        : ''
                                }`}
                                disabled={true}
                            />
                            <span
                                onClick={() =>
                                    handleCopy(
                                        'RTMP URL',
                                        selectedStream
                                            ? `${selectedStream?.ingestEndpoint}`
                                            : ''
                                    )
                                }
                            >
                                <CopyIcon />
                            </span>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.InputContainerHeading}>
                            <h3>
                                Stream Key
                                <Tooltip
                                    placement="right"
                                    title="GEVME Live Stream Key"
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </h3>
                        </div>
                        <div className={styles.InputContainer}>
                            <Input
                                name="streamkey"
                                value={
                                    selectedStream
                                        ? selectedStream?.streamKey
                                        : ''
                                }
                                disabled={true}
                            />
                            <span
                                onClick={() =>
                                    handleCopy(
                                        'Stream Key',
                                        selectedStream
                                            ? selectedStream?.streamKey
                                            : ''
                                    )
                                }
                            >
                                <CopyIcon />
                            </span>
                        </div>
                    </div>
                    {/* <div className={styles.Row}>
                    <div className={styles.InputContainerHeading}>
                        <h3>
                            Playback URL
                            <Tooltip placement="right" title="GEVME Live Playback URL">
                                <InfoCircleOutlined />
                            </Tooltip>
                        </h3>
                    </div>
                    <div className={styles.InputContainer}>
                        <Input
                            name='playbackUrl'
                            value={selectedStream ? selectedStream?.playbackUrl : ''}
                            disabled={true}
                        />
                        <span onClick={() => handleCopy('Playback URL',selectedStream ? selectedStream?.playbackUrl : '')}>
                            <CopyIcon />
                        </span>
                    </div>
                </div> */}
                    <style jsx={true}>
                        {`
                            h3 .required {
                                color: #dc3545 !important;
                            }
                            .select-description {
                                display: block;
                                padding: 16px;
                                cursor: pointer;
                                background: #0b1c2c;
                                border-radius: 4px;
                                padding: 10px 20px;
                                color: #fff !important;
                                text-align: center;
                                width: 75% !important;
                                margin: 5px auto;
                            }
                            .gevmelive-stream .ant-select-dropdown {
                                padding: 0px;
                            }
                            .gevmelive-stream.error .ant-select-selector {
                                border: 1px solid red;
                            }
                             {
                                /* .select-description span:first-child {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 17px;
                        color: #000000;
                    }
                    .select-description span {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 16px;
                        letter-spacing: -0.02em;
                        color: #7A7A7A;
                    } */
                            }
                            .ant-select-item-option-content,
                            .select-description {
                                font-family: 'Open Sans';
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 17px;
                                color: #000000;
                            }
                            .ant-select-selection-item .select-description {
                                display: block;
                            }
                             {
                                /* .ant-select-selection-item .select-description span:nth-child(2) {
                        display: none;
                    } */
                            }
                            #gevmelive-stream-container
                                .ant-dropdown-placement-topCenter {
                                position: fixed;
                            }
                        `}
                    </style>
                </>
            )}
        </>
    );
};

export default GevmeLive;
