import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import SettingsHeadingVisibility from 'Apps/VenueBuilder/Components/Common/SettingsHeadingVisibility';
import RichTextContent from 'Apps/VenueBuilder/Components/Common/RichTextContent';
import SettingsRadio from 'Apps/VenueBuilder/Components/Common/SettingsRadio';
import SettingsPixels from 'Apps/VenueBuilder/Components/Common/SettingsPixels';
import SettingsCheckbox from 'Apps/VenueBuilder/Components/Common/SettingsCheckbox';
import SettingsMargin from 'Apps/VenueBuilder/Components/Common/SettingsMargin';
import SettingsCoverImage from 'Apps/VenueBuilder/Components/Common/SettingsCoverImage';
import BlockViewConfiguration from '../BlockViewConfiguration';
import EmojiReactionSettings from '../EmojiReactionSettings';
import { getUserRole } from 'Apps/VenueBuilder/helpers/common';

import styles from './index.module.css';
const properties = ['backgroundCoverImage', 'backgroundCoverImageVisibility'];

/**
 * Common Block Settings Component
 *
 * @param {string} blockName block name
 * @param {object} config settings config
 * @param {object} values settings values
 * @param {children} block block contents
 * @param {func} setValue set settings value
 * @param {object} errors set settings value
 * @param {func} control  value
 */
const CommonBlockSettings = ({
    blockName,
    config,
    values,
    children,
    setValue,
    getValues,
    errors,
    control,
    linkedSettings,
    setLinkedSettings,
}) => {
    const { type } = values;

    const { register } = useFormContext();

    const displayModeRedux = useSelector((state) => state?.header.display);

    const [blockSettingsLoading, setBlockSettingsLoading] = useState(true);

    const preventBlockSettingsState = useSelector(
        (state) => state?.sidebar?.item?.preventBlockSettings
    );

    const [
        enableBackgroundCoverImageDesktop,
        setEnableBackgroundCoverImageDesktop,
    ] = useState(
        typeof values?.backgroundCoverImageVisibility === 'boolean'
            ? values?.backgroundCoverImageVisibility
            : false
    );
    const [
        enableBackgroundCoverImageTablet,
        setEnableBackgroundCoverImageTablet,
    ] = useState(
        typeof values?.tabletBreakpoints?.backgroundCoverImageVisibility ===
            'boolean'
            ? values?.tabletBreakpoints?.backgroundCoverImageVisibility
            : enableBackgroundCoverImageDesktop
    );
    const [
        enableBackgroundCoverImageMobile,
        setEnableBackgroundCoverImageMobile,
    ] = useState(
        typeof values?.mobileBreakpoints?.backgroundCoverImageVisibility ===
            'boolean'
            ? values?.mobileBreakpoints?.backgroundCoverImageVisibility
            : enableBackgroundCoverImageDesktop
    );

    const defaultBGIMG =
        'https://files-myxp.gevme.com/default_background/Light_BG_1.png';
    const [backgroundCoverImageDesktop, setBackgroundCoverImageDesktop] =
        useState(
            values?.backgroundCoverImage
                ? values?.backgroundCoverImage
                : defaultBGIMG
        );

    const [backgroundCoverImageTablet, setBackgroundCoverImageTablet] =
        useState(
            values?.tabletBreakpoints?.backgroundCoverImage
                ? values?.tabletBreakpoints?.backgroundCoverImage
                : backgroundCoverImageDesktop
        );

    const [backgroundCoverImageMobile, setBackgroundCoverImageMobile] =
        useState(
            values?.mobileBreakpoints?.backgroundCoverImage
                ? values?.mobileBreakpoints?.backgroundCoverImage
                : backgroundCoverImageDesktop
        );

    // Desktop, tablet and mobile for block heading
    const blockHeadingData = [
        {
            name: 'heading',
            value: values?.heading
                ? values?.heading
                : `<p style="line-height: 27.6px; text-align: left;"><strong><span style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(0, 0, 0);">${blockName}</span></strong></p>`,
            headingVisibilityName: 'headingVisibility',
            headingVisibility:
                typeof values?.headingVisibility === 'boolean'
                    ? values?.headingVisibility
                    : true,
            required: true,
            device: 'desktop',
        },
        {
            name: 'tabletBreakpoints.heading',
            value: values?.tabletBreakpoints?.heading
                ? values?.tabletBreakpoints?.heading
                : !values?.id
                ? `<p style="line-height: 27.6px; text-align: left;"><strong><span style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(0, 0, 0);">${blockName}</span></strong></p>`
                : values?.heading,
            headingVisibilityName: 'tabletBreakpoints.headingVisibility',
            headingVisibility: values?.tabletBreakpoints
                ? values?.tabletBreakpoints?.headingVisibility
                : typeof values?.headingVisibility === 'boolean'
                ? values?.headingVisibility
                : true,
            required: true,
            device: 'tablet',
        },
        {
            name: 'mobileBreakpoints.heading',
            value: values?.mobileBreakpoints?.heading
                ? values?.mobileBreakpoints?.heading
                : !values?.id
                ? `<p style="line-height: 27.6px; text-align: left;"><strong><span style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(0, 0, 0);">${blockName}</span></strong></p>`
                : values?.heading,
            headingVisibilityName: 'mobileBreakpoints.headingVisibility',
            headingVisibility: values?.mobileBreakpoints
                ? values?.mobileBreakpoints?.headingVisibility
                : typeof values?.headingVisibility === 'boolean'
                ? values?.headingVisibility
                : true,
            required: true,
            device: 'mobile',
        },
    ];

    // set value for backgroundCoverImageVisibility
    // useEffect(() => {
    //     // setValue('backgroundCoverImageVisibility',enableBackgroundCoverImageDesktop);
    //     // setValue('tabletBreakpoints.backgroundCoverImageVisibility',enableBackgroundCoverImageTablet);
    //     // setValue('mobileBreakpoints.backgroundCoverImageVisibility',enableBackgroundCoverImageMobile);
    // },[enableBackgroundCoverImageDesktop,enableBackgroundCoverImageTablet,enableBackgroundCoverImageMobile])

    useEffect(() => {
        properties.forEach((property) => {
            if (!linkedSettings?.[property]) return;
            const currentModePropertyKey = displayModeRedux.desktop
                ? property
                : displayModeRedux.tablet
                ? `tabletBreakpoints.${property}`
                : `mobileBreakpoints.${property}`;
            const currentModePropertyValue = getValues(currentModePropertyKey);
            switch (property) {
                case 'backgroundCoverImage':
                    setBackgroundCoverImageDesktop(currentModePropertyValue);
                    setBackgroundCoverImageTablet(currentModePropertyValue);
                    setBackgroundCoverImageMobile(currentModePropertyValue);
                    break;
                case 'backgroundCoverImageVisibility':
                    setEnableBackgroundCoverImageDesktop(
                        currentModePropertyValue
                    );
                    setEnableBackgroundCoverImageTablet(
                        currentModePropertyValue
                    );
                    setEnableBackgroundCoverImageMobile(
                        currentModePropertyValue
                    );
                    break;
                default:
                    break;
            }
        });
    }, [
        linkedSettings,
        ...properties.map((property) => getValues(property)),
        ...properties.map((property) =>
            getValues(`tabletBreakpoints.${property}`)
        ),
        ...properties.map((property) =>
            getValues(`mobileBreakpoints.${property}`)
        ),
    ]);

    const blockConverImageData = [
        {
            name: 'backgroundCoverImage',
            value: backgroundCoverImageDesktop,
            // values?.backgroundCoverImage ? values?.backgroundCoverImage : defaultBGIMG,
            setCoverImage: (val) => {
                if (linkedSettings?.backgroundCoverImage) {
                    setBackgroundCoverImageDesktop(val);
                    setBackgroundCoverImageTablet(val);
                    setBackgroundCoverImageMobile(val);
                } else {
                    setBackgroundCoverImageDesktop(val);
                }
            },
            backgroundCoverImageDimension:
                values?.backgroundCoverImageDimension,
            enableName: 'backgroundCoverImageVisibility',
            enableCoverImage: enableBackgroundCoverImageDesktop,
            setEnableBGCoverImage: (val) => {
                if (linkedSettings?.backgroundCoverImage) {
                    setEnableBackgroundCoverImageDesktop(val);
                    setEnableBackgroundCoverImageTablet(val);
                    setEnableBackgroundCoverImageMobile(val);
                } else {
                    setEnableBackgroundCoverImageDesktop(val);
                }
            },
            device: 'desktop',
        },
        {
            name: 'tabletBreakpoints.backgroundCoverImage',
            value: backgroundCoverImageTablet,
            //  getValues('tabletBreakpoints.backgroundCoverImage') ? getValues('tabletBreakpoints.backgroundCoverImage') :
            //         values?.tabletBreakpoints?.backgroundCoverImage ? values?.tabletBreakpoints?.backgroundCoverImage
            //         : defaultBGIMG,
            setCoverImage: (val) => {
                if (linkedSettings?.backgroundCoverImage) {
                    setBackgroundCoverImageDesktop(val);
                    setBackgroundCoverImageTablet(val);
                    setBackgroundCoverImageMobile(val);
                } else {
                    setBackgroundCoverImageTablet(val);
                }
            },
            backgroundCoverImageDimension:
                values?.tabletBreakpoints?.backgroundCoverImageDimension,
            enableName: 'tabletBreakpoints.backgroundCoverImageVisibility',
            enableCoverImage: enableBackgroundCoverImageTablet,
            setEnableBGCoverImage: (val) => {
                if (linkedSettings?.backgroundCoverImage) {
                    setEnableBackgroundCoverImageDesktop(val);
                    setEnableBackgroundCoverImageTablet(val);
                    setEnableBackgroundCoverImageMobile(val);
                } else {
                    setEnableBackgroundCoverImageTablet(val);
                }
            },
            device: 'tablet',
        },
        {
            name: 'mobileBreakpoints.backgroundCoverImage',
            value: backgroundCoverImageMobile,
            // values?.mobileBreakpoints?.backgroundCoverImage ? values?.mobileBreakpoints?.backgroundCoverImage :
            //         getValues('mobileBreakpoints.backgroundCoverImage') ? getValues('mobileBreakpoints.backgroundCoverImage')
            //         : defaultBGIMG,
            setCoverImage: (val) => {
                if (linkedSettings?.backgroundCoverImage) {
                    setBackgroundCoverImageDesktop(val);
                    setBackgroundCoverImageTablet(val);
                    setBackgroundCoverImageMobile(val);
                } else {
                    setBackgroundCoverImageMobile(val);
                }
            },
            backgroundCoverImageDimension:
                values?.mobileBreakpoints?.backgroundCoverImageDimension,
            enableName: 'mobileBreakpoints.backgroundCoverImageVisibility',
            enableCoverImage: enableBackgroundCoverImageMobile,
            setEnableBGCoverImage: (val) => {
                if (linkedSettings?.backgroundCoverImage) {
                    setEnableBackgroundCoverImageDesktop(val);
                    setEnableBackgroundCoverImageTablet(val);
                    setEnableBackgroundCoverImageMobile(val);
                } else {
                    setEnableBackgroundCoverImageMobile(val);
                }
            },
            device: 'mobile',
        },
    ];

    // Desktop, tablet and mobile for block width
    const blockWidthData = [
        {
            name: 'blockWidth',
            value: values?.blockWidth || '100%',
            required: config?.blockWidth?.required,
            device: 'desktop',
            customChoices: null,
            choices: [
                {
                    name: 'Full-width',
                    value: 'full',
                    disabled:
                        config?.blockWidth?.disabledChoices?.includes('full'),
                },
                {
                    name: 'Half-width',
                    value: 'half',
                    disabled:
                        config?.blockWidth?.disabledChoices?.includes('half'),
                },
                {
                    name: 'Specific width',
                    value: 'custom',
                    disabled:
                        config?.blockWidth?.disabledChoices?.includes('custom'),
                },
                {
                    name: 'Fit to content',
                    value: 'fitcontent',
                    disabled:
                        config?.blockWidth?.disabledChoices?.includes(
                            'fitcontent'
                        ),
                },
            ],
        },
        {
            name: 'tabletBreakpoints.blockWidth',
            value: values?.tabletBreakpoints?.blockWidth
                ? values?.tabletBreakpoints?.blockWidth
                : values?.blockWidth || '100%',
            required: config?.blockWidth?.required,
            device: 'tablet',
            customChoices: null,
            choices: [
                {
                    name: 'Full-width',
                    value: 'full',
                    disabled:
                        config?.blockWidth?.disabledChoices?.includes('full'),
                },
                {
                    name: 'Half-width',
                    value: 'half',
                    disabled:
                        config?.blockWidth?.disabledChoices?.includes('half'),
                },
                {
                    name: 'Specific width',
                    value: 'custom',
                    disabled:
                        config?.blockWidth?.disabledChoices?.includes('custom'),
                },
                {
                    name: 'Fit to content',
                    value: 'fitcontent',
                    disabled:
                        config?.blockWidth?.disabledChoices?.includes(
                            'fitcontent'
                        ),
                },
            ],
        },
        {
            name: 'mobileBreakpoints.blockWidth',
            value:
                config?.blockWidth?.customMobileOptions &&
                config?.blockWidth?.customMobileOptions?.length &&
                type === 'People'
                    ? '100%'
                    : values?.mobileBreakpoints?.blockWidth
                    ? values?.mobileBreakpoints?.blockWidth
                    : values?.blockWidth || '100%',
            required: config?.blockWidth?.required,
            device: 'mobile',
            customChoices:
                config?.blockWidth?.customMobileOptions &&
                config?.blockWidth?.customMobileOptions?.length
                    ? config.blockWidth.customMobileOptions
                    : null,
            choices: [
                {
                    name: 'Full-width',
                    value: 'full',
                    disabled:
                        config?.blockWidth?.disabledChoices?.includes('full'),
                },
                {
                    name: 'Half-width',
                    value: 'half',
                    disabled:
                        config?.blockWidth?.disabledChoices?.includes('half'),
                },
                {
                    name: 'Specific width',
                    value: 'custom',
                    disabled:
                        config?.blockWidth?.disabledChoices?.includes('custom'),
                },
                {
                    name: 'Fit to content',
                    value: 'fitcontent',
                    disabled:
                        config?.blockWidth?.disabledChoices?.includes(
                            'fitcontent'
                        ),
                },
            ],
        },
    ];

    // Desktop, tablet and mobile for bounding box
    const blockBoundingBoxData = [
        {
            name: 'boundingBox',
            value: values?.boundingBox ? values?.boundingBox : false,
            required: config?.boundingBox?.required,
            device: 'desktop',
        },
        {
            name: 'tabletBreakpoints.boundingBox',
            value:
                typeof values?.tabletBreakpoints?.boundingBox === 'boolean'
                    ? values?.tabletBreakpoints?.boundingBox
                    : values?.boundingBox || false,
            required: config?.boundingBox?.required,
            device: 'tablet',
        },
        {
            name: 'mobileBreakpoints.boundingBox',
            value:
                typeof values?.mobileBreakpoints?.boundingBox === 'boolean'
                    ? values?.mobileBreakpoints?.boundingBox
                    : values?.boundingBox || false,
            required: config?.boundingBox?.required,
            device: 'mobile',
        },
    ];
    
    const [desktopFitToContent, setDesktopFitToContent] = useState(
        typeof values?.fitHeightToContent === 'boolean'?
        values?.fitHeightToContent
            ? true
            : ['0px', 'fit-content'].includes(values?.blockHeight)
            ? true
            : false
            :true
    );

    const [tabletFitToContent, setTabletFitToContent] = useState(
        // typeof values?.tabletBreakpoints?.fitHeightToContent === 'boolean' ? values?.tabletBreakpoints?.fitHeightToContent
        //         : typeof values?.fitHeightToContent === 'boolean' ? values?.fitHeightToContent
        //         : typeof config?.blockHeight?.defValue === 'string' ? true : values?.fitHeightToContent
        typeof values?.tabletBreakpoints?.fitHeightToContent === 'boolean'
            ? // ? values?.tabletBreakpoints?.blockHeight : values?.fitHeightToContent
              ['0px', 'fit-content'].includes(
                  values?.tabletBreakpoints?.blockHeight
              )
                ? true
                : !['0px', 'fit-content'].includes(
                      values?.tabletBreakpoints?.blockHeight
                  )
                ? false
                : values?.tabletBreakpoints?.fitHeightToContent
            : typeof config?.blockHeight?.defValue === 'string'
            ? false
            : true
    );

    const [mobileFitToContent, setMobileFitToContent] = useState(
        // typeof values?.mobileBreakpoints?.fitHeightToContent === 'boolean' ? values?.mobileBreakpoints?.fitHeightToContent
        //     : typeof values?.fitHeightToContent === 'boolean' ? values?.fitHeightToContent
        //     : typeof config?.blockHeight?.defValue === 'string' ? true : values?.fitHeightToContent
        typeof values?.mobileBreakpoints?.fitHeightToContent === 'boolean'
            ? // ? values?.mobileBreakpoints?.fitHeightToContent : values?.fitHeightToContent
              ['0px', 'fit-content'].includes(
                  values?.mobileBreakpoints?.blockHeight
              )
                ? true
                : !['0px', 'fit-content'].includes(
                      values?.mobileBreakpoints?.blockHeight
                  )
                ? false
                : values?.mobileBreakpoints?.fitHeightToContent
            : typeof config?.blockHeight?.defValue === 'string'
            ? false
            : values?.fitHeightToContent
    );

    const fitToContent =
        desktopFitToContent && tabletFitToContent && mobileFitToContent;

    // Desktop, tablet and mobile for block height
    const blockHeightData = [
        {
            name: 'blockHeight',
            value: !!values?.blockHeight
                ? values?.blockHeight
                : !!config?.blockHeight?.defValue
                ? config?.blockHeight?.defValue
                : 'fit-content',
            fitContent: linkedSettings?.fitHeightToContent
                ? fitToContent
                : desktopFitToContent,
            // typeof values?.fitHeightToContent === 'boolean' ? values?.fitHeightToContent
            //     : config?.blockHeight?.defValue ? false : desktopFitToContent,
            required: config?.blockHeight?.required,
            device: 'desktop',
        },
        {
            name: 'tabletBreakpoints.blockHeight',
            value: !!values?.tabletBreakpoints?.blockHeight
                ? values?.tabletBreakpoints?.blockHeight
                : !!values?.blockHeight
                ? values?.blockHeight
                : !!config?.blockHeight?.defValue
                ? config?.blockHeight?.defValue
                : 'fit-content',
            fitContent: linkedSettings?.fitHeightToContent
                ? fitToContent
                : tabletFitToContent,
            // typeof values?.tabletBreakpoints?.fitHeightToContent === 'boolean' ? values?.tabletBreakpoints?.fitHeightToContent
            //     : config?.blockHeight?.defValue ? false : tabletFitToContent,
            // typeof tabletFitToContent === 'undefined' ? false : tabletFitToContent,
            required: config?.blockHeight?.required,
            device: 'tablet',
        },
        {
            name: 'mobileBreakpoints.blockHeight',
            value: !!values?.mobileBreakpoints?.blockHeight
                ? values?.mobileBreakpoints?.blockHeight
                : !!values?.blockHeight
                ? values?.blockHeight
                : !!config?.blockHeight?.defValue
                ? config?.blockHeight?.defValue
                : 'fit-content',
            fitContent: linkedSettings?.fitHeightToContent
                ? fitToContent
                : mobileFitToContent,
            // typeof values?.mobileBreakpoints?.fitHeightToContent === 'boolean' ? values?.mobileBreakpoints?.fitHeightToContent
            //     : config?.blockHeight?.defValue ? false : mobileFitToContent,
            // typeof mobileFitToContent === 'undefined' ? false : mobileFitToContent,
            required: config?.blockHeight?.required,
            device: 'mobile',
        },
    ];

    // Desktop, tablet and mobile for fitHeightToContent
    const blockFitHeightToContentData = [
        {
            name: 'fitHeightToContent',
            value: values?.blockHeight
                ? desktopFitToContent
                : config?.blockHeight?.defValue
                ? false
                : desktopFitToContent,
            heightValue: values?.blockHeight
                ? values?.blockHeight
                : config?.blockHeight?.defValue
                ? config?.blockHeight?.defValue
                : 'fit-content',
            required: config?.fitHeightToContent?.required,
            device: 'desktop',
        },
        {
            name: 'tabletBreakpoints.fitHeightToContent',
            // value: values?.tabletBreakpoints?.blockHeight ? tabletFitToContent : config?.blockHeight?.defValue ? false : tabletFitToContent,
            value: values?.tabletBreakpoints
                ? tabletFitToContent
                : values?.fitHeightToContent
                ? values?.fitHeightToContent
                : config?.blockHeight?.defValue
                ? false
                : !values?.id
                ? true
                : tabletFitToContent,
            heightValue: values?.tabletBreakpoints?.blockHeight
                ? values?.tabletBreakpoints?.blockHeight
                : values?.blockHeight
                ? values?.blockHeight
                : config?.blockHeight?.defValue
                ? config?.blockHeight?.defValue
                : 'fit-content',
            required: config?.fitHeightToContent?.required,
            device: 'tablet',
        },
        {
            name: 'mobileBreakpoints.fitHeightToContent',
            // value: values?.mobileBreakpoints?.blockHeight ? mobileFitToContent : config?.blockHeight?.defValue ? false : mobileFitToContent,
            value:
                values?.mobileBreakpoints ||
                !config?.blockWidth?.customMobileOptions
                    ? mobileFitToContent
                    : values?.fitHeightToContent
                    ? values?.fitHeightToContent
                    : config?.blockHeight?.defValue
                    ? false
                    : !values?.id
                    ? true
                    : mobileFitToContent,
            heightValue: values?.mobileBreakpoints?.blockHeight
                ? values?.mobileBreakpoints?.blockHeight
                : values?.blockHeight
                ? values?.blockHeight
                : config?.blockHeight?.defValue
                ? config?.blockHeight?.defValue
                : 'fit-content',
            required: config?.fitHeightToContent?.required,
            device: 'mobile',
        },
    ];

    // Desktop, tablet and mobile for contentMargin
    const [marginLinkDesktop, setMarginLinkDesktop] = useState(
        typeof values?.marginLink === 'boolean' ? values?.marginLink : false
    );
    const [marginLinkTablet, setMarginLinkTablet] = useState(
        typeof values?.tabletBreakpoints?.marginLink === 'boolean'
            ? values?.tabletBreakpoints?.marginLink
            : false
    );
    const [marginLinkMobile, setMarginLinkMobile] = useState(
        typeof values?.mobileBreakpoints?.marginLink === 'boolean'
            ? values?.mobileBreakpoints?.marginLink
            : false
    );
    const blockContentMarginData = [
        {
            name: 'contentMargin',
            value: values?.contentMargin,
            linkname: 'marginLink',
            link: marginLinkDesktop,
            setLink: setMarginLinkDesktop,
            required: config?.contentMargin?.required,
            device: 'desktop',
        },
        {
            name: 'tabletBreakpoints.contentMargin',
            value:
                values?.tabletBreakpoints?.contentMargin ||
                values?.contentMargin,
            linkname: 'tabletBreakpoints.marginLink',
            link: marginLinkTablet || marginLinkDesktop,
            setLink: setMarginLinkTablet,
            required: config?.contentMargin?.required,
            device: 'tablet',
        },
        {
            name: 'mobileBreakpoints.contentMargin',
            value:
                values?.mobileBreakpoints?.contentMargin ||
                values?.contentMargin,
            linkname: 'mobileBreakpoints.marginLink',
            link: marginLinkMobile || marginLinkDesktop,
            setLink: setMarginLinkMobile,
            required: config?.contentMargin?.required,
            device: 'mobile',
        },
    ];

    // Desktop, tablet and mobile for contentMargin
    const [paddingLinkDesktop, setPaddingLinkDesktop] = useState(
        typeof values?.paddingLink === 'boolean' ? values?.paddingLink : false
    );
    const [paddingLinkTablet, setPaddingLinkTablet] = useState(
        typeof values?.tabletBreakpoints?.paddingLink === 'boolean'
            ? values?.tabletBreakpoints?.paddingLink
            : false
    );
    const [paddingLinkMobile, setPaddingLinkMobile] = useState(
        typeof values?.mobileBreakpoints?.paddingLink === 'boolean'
            ? values?.mobileBreakpoints?.paddingLink
            : false
    );
    const blockContentPaddingData = [
        {
            name: 'contentPadding',
            value: values?.contentPadding,
            linkname: 'paddingLink',
            link: paddingLinkDesktop,
            setLink: setPaddingLinkDesktop,
            required: config?.contentPadding?.required,
            device: 'desktop',
        },
        {
            name: 'tabletBreakpoints.contentPadding',
            value:
                values?.tabletBreakpoints?.contentPadding ||
                values?.contentPadding,
            linkname: 'tabletBreakpoints.paddingLink',
            link: paddingLinkTablet || paddingLinkDesktop,
            setLink: setPaddingLinkTablet,
            required: config?.contentPadding?.required,
            device: 'tablet',
        },
        {
            name: 'mobileBreakpoints.contentPadding',
            value:
                values?.mobileBreakpoints?.contentPadding ||
                values?.contentPadding,
            linkname: 'mobileBreakpoints.paddingLink',
            link: paddingLinkMobile || paddingLinkDesktop,
            setLink: setPaddingLinkMobile,
            required: config?.contentPadding?.required,
            device: 'mobile',
        },
    ];

    useEffect(() => {
        if (
           !values?.fitHeightToContent && !['0px', 'fit-content'].includes(values?.blockHeight) &&
            config?.blockHeight?.defValue
        ) {
            setDesktopFitToContent(false);
        }
    }, [config?.blockHeight?.defValue]);

    useEffect(() => {
        setBlockSettingsLoading(false);
        if (
            !blockSettingsLoading &&
            config?.blockHeight?.defValue &&
            !values.id
        ) {
            setValue('blockHeight', config?.blockHeight?.defValue);
            setValue(
                'tabletBreakpoints.blockHeight',
                config?.blockHeight?.defValue
            );
            setValue(
                'mobileBreakpoints.blockHeight',
                config?.blockHeight?.defValue
            );
        }
    }, [blockSettingsLoading]);

    useEffect(() => {
        if (
            (!getValues('tabletBreakpoints.headingVisibility') ||
                !values?.tabletBreakpoints?.headingVisibility) &&
            values?.headingVisibility
        ) {
            setValue(
                'tabletBreakpoints.headingVisibility',
                typeof values?.tabletBreakpoints?.headingVisibility ===
                    'boolean'
                    ? values?.tabletBreakpoints?.headingVisibility
                    : typeof values?.headingVisibility === 'boolean'
                    ? values?.headingVisibility
                    : true
            );
        }

        if (
            (!getValues('mobileBreakpoints.headingVisibility') ||
                !values?.mobileBreakpoints?.headingVisibility) &&
            values?.headingVisibility
        ) {
            setValue(
                'mobileBreakpoints.headingVisibility',
                typeof values?.mobileBreakpoints?.headingVisibility ===
                    'boolean'
                    ? values?.mobileBreakpoints?.headingVisibility
                    : typeof values?.headingVisibility === 'boolean'
                    ? values?.headingVisibility
                    : true
            );
        }

        if (
            (!getValues('tabletBreakpoints.blockHeight') ||
                !values?.tabletBreakpoints?.blockHeight) &&
            values?.blockHeight
        ) {
            setValue('tabletBreakpoints.blockHeight', values?.blockHeight);
        }
        if (
            (!getValues('mobileBreakpoints.blockHeight') ||
                !values?.mobileBreakpoints?.blockHeight) &&
            values?.blockHeight
        ) {
            setValue('mobileBreakpoints.blockHeight', values?.blockHeight);
        }

        if (
            !getValues('tabletBreakpoints.boundingBox') ||
            !values?.tabletBreakpoints?.boundingBox
        ) {
            setValue(
                'tabletBreakpoints.boundingBox',
                typeof values?.tabletBreakpoints?.boundingBox === 'boolean'
                    ? values?.tabletBreakpoints?.boundingBox
                    : typeof values?.boundingBox === 'boolean'
                    ? values?.boundingBox
                    : false
            );
        }
        if (
            !getValues('mobileBreakpoints.boundingBox') ||
            !values?.mobileBreakpoints?.boundingBox
        ) {
            setValue(
                'mobileBreakpoints.boundingBox',
                typeof values?.mobileBreakpoints?.boundingBox === 'boolean'
                    ? values?.mobileBreakpoints?.boundingBox
                    : typeof values?.boundingBox === 'boolean'
                    ? values?.boundingBox
                    : false
            );
        }

        if (
            !getValues('tabletBreakpoints.marginLink') ||
            !values?.tabletBreakpoints?.marginLink
        ) {
            setValue('tabletBreakpoints.marginLink', values?.marginLink);
        }
        if (
            !getValues('mobileBreakpoints.marginLink') ||
            !values?.mobileBreakpoints?.marginLink
        ) {
            setValue('mobileBreakpoints.marginLink', values?.marginLink);
        }

        if (
            !getValues('tabletBreakpoints.contentMargin') ||
            !values?.tabletBreakpoints?.contentMargin
        ) {
            setValue('tabletBreakpoints.contentMargin', values?.contentMargin);
        }
        if (
            !getValues('mobileBreakpoints.contentMargin') ||
            !values?.mobileBreakpoints?.contentMargin
        ) {
            setValue('mobileBreakpoints.contentMargin', values?.contentMargin);
        }

        if (
            !getValues('tabletBreakpoints.paddingLink') ||
            !values?.tabletBreakpoints?.paddingLink
        ) {
            setValue('tabletBreakpoints.paddingLink', values?.paddingLink);
        }
        if (
            !getValues('mobileBreakpoints.paddingLink') ||
            !values?.mobileBreakpoints?.paddingLink
        ) {
            setValue('mobileBreakpoints.paddingLink', values?.paddingLink);
        }

        if (
            !getValues('tabletBreakpoints.contentPadding') ||
            !values?.tabletBreakpoints?.contentPadding
        ) {
            setValue(
                'tabletBreakpoints.contentPadding',
                values?.contentPadding
            );
        }
        if (
            !getValues('mobileBreakpoints.contentPadding') ||
            !values?.mobileBreakpoints?.contentPadding
        ) {
            setValue(
                'mobileBreakpoints.contentPadding',
                values?.contentPadding
            );
        }
        setTabletFitToContent(
            typeof values?.tabletBreakpoints?.fitHeightToContent === 'boolean'
                ? // ? values?.tabletBreakpoints?.blockHeight : values?.fitHeightToContent
                  ['0px', 'fit-content'].includes(
                      values?.tabletBreakpoints?.blockHeight
                  )
                    ? true
                    : !['0px', 'fit-content'].includes(
                          values?.tabletBreakpoints?.blockHeight
                      )
                    ? false
                    : values?.tabletBreakpoints?.fitHeightToContent
                : typeof config?.blockHeight?.defValue === 'string'
                ? false
                : true
        );
        setMobileFitToContent(
            typeof values?.mobileBreakpoints?.fitHeightToContent === 'boolean'
                ? // ? values?.mobileBreakpoints?.fitHeightToContent : values?.fitHeightToContent
                  ['0px', 'fit-content'].includes(
                      values?.mobileBreakpoints?.blockHeight
                  )
                    ? true
                    : !['0px', 'fit-content'].includes(
                          values?.mobileBreakpoints?.blockHeight
                      )
                    ? false
                    : values?.mobileBreakpoints?.fitHeightToContent
                : typeof config?.blockHeight?.defValue === 'string'
                ? false
                : true
        );
    }, [values]);

    const role = getUserRole();
    const preventBlockSettings = preventBlockSettingsState
        ? preventBlockSettingsState[role]
        : false;

    const displayStyle = (displayMode) => {
        return displayMode
            ? {
                  display:
                      (displayMode === 'desktop' && displayModeRedux.desktop) ||
                      (displayMode === 'tablet' && displayModeRedux.tablet) ||
                      (displayMode === 'mobile' && displayModeRedux.mobile)
                          ? 'flex'
                          : 'none',
              }
            : { display: 'flex' };
    };

    return (
        <div
            className={`${styles.CommonBlockSettingsContainer} common-block-settings`}
        >
            {config?.heading?.enabled && (
                <>
                    {blockHeadingData.map((data, index) => {
                        return (
                            <div
                                key={`HeadingText_${index}_${data.device}`}
                                className={`RichText_HeadingText ${
                                    styles.HeadingContainer
                                }
                                ${
                                    errors?.heading?.type === 'required' &&
                                    data.device === 'desktop'
                                        ? 'desktop'
                                        : ''
                                }
                                ${
                                    errors?.tabletBreakpoints?.heading?.type ===
                                        'required' && data.device === 'tablet'
                                        ? 'tablet'
                                        : ''
                                }
                                ${
                                    errors?.mobileBreakpoints?.heading?.type ===
                                        'required' && data.device === 'mobile'
                                        ? 'mobile'
                                        : ''
                                }
                            `}
                                style={{ ...displayStyle(data.device) }}
                            >
                                <Controller
                                    as={
                                        <RichTextContent
                                            headingTitle="Heading"
                                            tooltipText={`${blockName} ${data.device} Content Block Heading`}
                                            setContent={(name, value) => {
                                                setValue(name, value);
                                            }}
                                            isRequired={data.required}
                                            // error={errors?.heading}
                                            type="heading"
                                        />
                                    }
                                    name={data.name}
                                    control={control}
                                    defaultValue={data.value}
                                    rules={{ required: data.required }}
                                    ref={register(data?.name)}
                                />

                                <div className={styles.LeftHeading}>
                                    <BlockViewConfiguration
                                        property="heading"
                                        relatedProperties={[
                                            'headingVisibility',
                                        ]}
                                        setValue={setValue}
                                        getValues={getValues}
                                        linkedSettings={linkedSettings}
                                        setLinkedSettings={setLinkedSettings}
                                    />

                                    <Controller
                                        as={
                                            <SettingsHeadingVisibility
                                                setValue={setValue}
                                                visibleName={
                                                    data.headingVisibilityName
                                                }
                                            />
                                        }
                                        name={data.headingVisibilityName}
                                        control={control}
                                        defaultValue={data.headingVisibility}
                                        ref={register(
                                            data.headingVisibilityName
                                        )}
                                    />
                                </div>
                            </div>
                        );
                    })}
                    {errors?.heading?.type === 'required' && (
                        <p className={styles.errorText}>
                            Desktop heading is required.
                        </p>
                    )}
                    {errors?.tabletBreakpoints?.heading?.type ===
                        'required' && (
                        <p className={styles.errorText}>
                            Tablet heading is required.
                        </p>
                    )}
                    {errors?.mobileBreakpoints?.heading?.type ===
                        'required' && (
                        <p className={styles.errorText}>
                            Mobile heading is required.
                        </p>
                    )}
                </>
            )}

            {/* Parent content blocks settings */}
            {{ ...children }}

            {/* Cover image */}

            {config?.coverImage?.enabled && (
                <>
                    {blockConverImageData.map((data, index) => {
                        return (
                            <React.Fragment
                                key={`EnaleCoverImageItem_${index}_${data.device}`}
                            >
                                {/* Hide backgroundCoverImageVisibility for it to catch in form */}
                                <div style={{ display: 'none' }}>
                                    <Controller
                                        key={`EnaleCoverImageHiddenItem_${index}_${data.device}`}
                                        as={<input />}
                                        name={data.enableName}
                                        control={control}
                                        defaultValue={data.enableCoverImage}
                                        ref={register(data.enableName)}
                                    />
                                </div>

                                <div
                                    className={styles.BGImageContainer}
                                    style={{ ...displayStyle(data.device) }}
                                >
                                    <div className={styles.BGImageHeading}>
                                        <BlockViewConfiguration
                                            property="backgroundCoverImage"
                                            relatedProperties={[
                                                'backgroundCoverImageVisibility',
                                            ]}
                                            setValue={setValue}
                                            getValues={getValues}
                                            linkedSettings={linkedSettings}
                                            setLinkedSettings={
                                                setLinkedSettings
                                            }
                                        />
                                    </div>

                                    <Controller
                                        key={`EnaleCoverImage_${index}_${data.device}`}
                                        as={
                                            <SettingsCoverImage
                                                enableBackgroundCoverImage={
                                                    data.enableCoverImage
                                                }
                                                setEnableCoverImage={(val) => {
                                                    data.setEnableBGCoverImage(
                                                        val
                                                    );
                                                    setValue(
                                                        data.enableName,
                                                        val
                                                    );
                                                    if (
                                                        data?.enableName ===
                                                        'backgroundCoverImageVisibility'
                                                    ) {
                                                        if (
                                                            typeof values
                                                                ?.tabletBreakpoints
                                                                ?.backgroundCoverImageVisibility ===
                                                                'undefined' &&
                                                            linkedSettings?.backgroundCoverImageVisibility
                                                        ) {
                                                            setValue(
                                                                'tabletBreakpoints.backgroundCoverImageVisibility',
                                                                val
                                                            );
                                                            setEnableBackgroundCoverImageTablet(
                                                                val
                                                            );
                                                        }

                                                        if (
                                                            typeof values
                                                                ?.mobileBreakpoints
                                                                ?.backgroundCoverImageVisibility ===
                                                                'undefined' &&
                                                            linkedSettings?.backgroundCoverImageVisibility
                                                        ) {
                                                            setValue(
                                                                'mobileBreakpoints.backgroundCoverImageVisibility',
                                                                val
                                                            );
                                                            setEnableBackgroundCoverImageMobile(
                                                                val
                                                            );
                                                        }
                                                    }
                                                }}
                                                backgroundCoverImage={
                                                    data.value
                                                }
                                                setCoverImage={(val) => {
                                                    data.setCoverImage(val);
                                                    setValue(data.name, val);
                                                    if (
                                                        data?.name ===
                                                        'backgroundCoverImage'
                                                    ) {
                                                        if (
                                                            !values
                                                                ?.tabletBreakpoints
                                                                ?.backgroundCoverImage
                                                        ) {
                                                            setValue(
                                                                'tabletBreakpoints.backgroundCoverImage',
                                                                val
                                                            );
                                                            setBackgroundCoverImageTablet(
                                                                val
                                                            );
                                                        }

                                                        if (
                                                            !values
                                                                ?.mobileBreakpoints
                                                                ?.backgroundCoverImage
                                                        ) {
                                                            setValue(
                                                                'mobileBreakpoints.backgroundCoverImage',
                                                                val
                                                            );
                                                            setBackgroundCoverImageMobile(
                                                                val
                                                            );
                                                        }
                                                    }
                                                }}
                                                setBGCoverImage={(e) => {
                                                    data.setCoverImage(e);
                                                    setValue(data.name, e);
                                                    if (
                                                        data?.name ===
                                                        'backgroundCoverImage'
                                                    ) {
                                                        if (
                                                            !values
                                                                ?.tabletBreakpoints
                                                                ?.backgroundCoverImage
                                                        ) {
                                                            setValue(
                                                                'tabletBreakpoints.backgroundCoverImage',
                                                                e
                                                            );
                                                            setBackgroundCoverImageTablet(
                                                                e
                                                            );
                                                        }

                                                        if (
                                                            !values
                                                                ?.mobileBreakpoints
                                                                ?.backgroundCoverImage
                                                        ) {
                                                            setValue(
                                                                'mobileBreakpoints.backgroundCoverImage',
                                                                e
                                                            );
                                                            setBackgroundCoverImageMobile(
                                                                e
                                                            );
                                                        }
                                                    }
                                                }}
                                                backgroundCoverImageDimension={
                                                    data?.backgroundCoverImageDimension ||
                                                    values?.backgroundCoverImageDimension
                                                }
                                            />
                                        }
                                        name={data.name}
                                        control={control}
                                        defaultValue={data.value}
                                        rules={{
                                            required: data.enableCoverImage
                                                ? true
                                                : false,
                                        }}
                                        ref={register(data.name)}
                                    />
                                    {errors?.backgroundCoverImage?.type ===
                                        'required' && (
                                        <p className={styles.errorText}>
                                            Desktop cover image is required
                                        </p>
                                    )}
                                    {errors?.tabletBreakpoints
                                        ?.backgroundCoverImage?.type ===
                                        'required' && (
                                        <p className={styles.errorText}>
                                            Tablet cover image is required
                                        </p>
                                    )}
                                    {errors?.mobileBreakpoints
                                        ?.backgroundCoverImage?.type ===
                                        'required' && (
                                        <p className={styles.errorText}>
                                            Mobile cover image is required
                                        </p>
                                    )}
                                </div>
                            </React.Fragment>
                        );
                    })}
                </>
            )}

            {config?.emojiReactionSettings?.enabled ? (
                <>
                    <div style={{ display: 'none' }}>
                        <Controller
                            as={<input />}
                            name="enabledReaction"
                            control={control}
                            defaultValue={
                                values?.enabledReaction ||
                                config?.emojiReactionSettings
                                    ?.defaultEnabledReaction
                            }
                            ref={register('enabledReaction')}
                        />
                    </div>
                    <EmojiReactionSettings
                        config={config}
                        values={values}
                        setValue={setValue}
                        register={register}
                        control={control}
                    />
                </>
            ) : null}

            {/* Common block settings */}
            {!preventBlockSettings && (
                <>
                    <h2>Block Settings</h2>

                    {/* Block width with desktop, tablet and mobile settings  */}
                    {config?.blockWidth?.enabled && (
                        <>
                            {blockWidthData.map((data, index) => {
                                return (
                                    <Controller
                                        as={
                                            <SettingsRadio
                                                property="blockWidth"
                                                headingTitle="Width"
                                                setValue={setValue}
                                                getValues={getValues}
                                                choices={
                                                    data.customChoices ||
                                                    data.choices
                                                }
                                                displayMode={data.device}
                                                linkedSettings={linkedSettings}
                                                setLinkedSettings={
                                                    setLinkedSettings
                                                }
                                            />
                                        }
                                        key={`BlockWidthSettings_${index}_${data.device}_${data.value}`}
                                        name={data.name}
                                        control={control}
                                        defaultValue={data.value}
                                        value={data.value}
                                        ref={register(data.name, {
                                            required: data.required,
                                        })}
                                    />
                                );
                            })}
                        </>
                    )}

                    {/* Content block boundingbox */}
                    {config?.boundingBox?.enabled && (
                        <>
                            {blockBoundingBoxData?.map((data, index) => {
                                return (
                                    <Controller
                                        key={`BoundingBoxSettings_${index}_${data.device}_${data.value}`}
                                        as={
                                            <SettingsRadio
                                                property="boundingBox"
                                                headingTitle="Bounding Box"
                                                setValue={setValue}
                                                getValues={getValues}
                                                choices={[
                                                    {
                                                        name: 'Show',
                                                        value: true,
                                                    },
                                                    {
                                                        name: 'Hide',
                                                        value: false,
                                                    },
                                                ]}
                                                displayMode={data.device}
                                                linkedSettings={linkedSettings}
                                                setLinkedSettings={
                                                    setLinkedSettings
                                                }
                                            />
                                        }
                                        name={data.name}
                                        control={control}
                                        defaultValue={
                                            typeof data.value === 'boolean'
                                                ? data.value
                                                : false
                                        }
                                        ref={register(data.name, {
                                            required:
                                                config?.boundingBox?.required,
                                        })}
                                    />
                                );
                            })}
                        </>
                    )}

                    {/* Block height with desktop, tablet and mobile settings  */}
                    {config?.blockHeight?.enabled && (
                        <>
                            {blockHeightData?.map((data, index) => {
                                return (
                                    <>
                                        <Controller
                                            as={
                                                <SettingsPixels
                                                    property="blockHeight"
                                                    relatedProperties={[
                                                        config
                                                            ?.fitHeightToContent
                                                            ?.enabled
                                                            ? 'fitHeightToContent'
                                                            : undefined,
                                                    ].filter(Boolean)}
                                                    headingTitle="Height"
                                                    tooltipText={`${blockName} block height`}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                    // fitToContent={
                                                    //     data.value ? false : config?.blockHeight?.defValue
                                                    //         ? false : fitToContent
                                                    // }
                                                    fitToContent={
                                                        typeof data.fitContent ===
                                                        'undefined'
                                                            ? true
                                                            : data.fitContent
                                                    }
                                                    // fitToContent={['0px','fit-content'].includes(data.value)}
                                                    desktopFitToContent={
                                                        desktopFitToContent
                                                    }
                                                    tabletFitToContent={
                                                        tabletFitToContent
                                                    }
                                                    mobileFitToContent={
                                                        mobileFitToContent
                                                    }
                                                    displayMode={data.device}
                                                    linkedSettings={
                                                        linkedSettings
                                                    }
                                                    setLinkedSettings={
                                                        setLinkedSettings
                                                    }
                                                />
                                            }
                                            key={`BlockHeightSettings_${index}_${data.device}_${data.value}`}
                                            name={data.name}
                                            control={control}
                                            defaultValue={data.value}
                                            ref={register(data.name)}
                                        />
                                    </>
                                );
                            })}
                        </>
                    )}

                    {config?.fitHeightToContent?.enabled && (
                        <>
                            {blockFitHeightToContentData?.map((data, index) => {
                                return (
                                    <>
                                        {/* <label key={`FitHeightToContentCheckbox_${index}_${data.device}_${data.name}_${data.value}`}>
                                            <input
                                                type="checkbox"
                                                checked={getValues(data.name) || data.value}
                                                onChange={(e) => {
                                                    if(displayModeRedux.desktop) {
                                                        setDesktopFitToContent(e.target.checked);
                                                    }
                                                    if(displayModeRedux.tablet) {
                                                        setTabletFitToContent(e.target.checked);
                                                    }
                                                    if(displayModeRedux.mobile) {
                                                        setMobileFitToContent(e.target.checked);
                                                    }
                                                    setFitToContent(e.target.checked);
                                                    setValue(data.name,e.target.checked);

                                                }}
                                                ref={register(data.name)}
                                                />
                                            <span>Fit to content</span>
                                        </label> */}

                                        <Controller
                                            as={
                                                <SettingsCheckbox
                                                    key={`FitHeightToContentCheckbox_${index}_${data.device}_${data.name}_${data.value}`}
                                                    setValue={(name, value) => {
                                                        if (
                                                            displayModeRedux.desktop
                                                        ) {
                                                            setDesktopFitToContent(
                                                                value
                                                            );
                                                        }
                                                        if (
                                                            displayModeRedux.tablet
                                                        ) {
                                                            setTabletFitToContent(
                                                                value
                                                            );
                                                        }
                                                        if (
                                                            displayModeRedux.mobile
                                                        ) {
                                                            setMobileFitToContent(
                                                                value
                                                            );
                                                        }
                                                        setValue(name, value);
                                                    }}
                                                    displayMode={data.device}
                                                />
                                            }
                                            key={`FitHeightToContentSettings_${index}_${data.device}_${data.name}_${data.value}`}
                                            name={data.name}
                                            control={control}
                                            defaultValue={data.value}
                                            ref={register(data.name)}
                                        />
                                    </>
                                );
                            })}
                        </>
                    )}

                    {config?.spaceBetweenImages?.enabled && (
                        <Controller
                            as={
                                <SettingsPixels
                                    headingTitle="Space between images"
                                    tooltipText="A space between images"
                                    setValue={(name, value) => {
                                        setValue('spaceBetweenImages', value);
                                    }}
                                />
                            }
                            name="spaceBetweenImages"
                            control={control}
                            defaultValue={
                                typeof values?.spaceBetweenImages !==
                                'undefined'
                                    ? values?.spaceBetweenImages
                                    : '10px'
                            }
                            ref={register('spaceBetweenImages')}
                        />
                    )}

                    {/* Content Margin with desktop, tablet and mobile settings */}
                    {config?.contentMargin?.enabled && (
                        <>
                            {blockContentMarginData?.map((data, index) => {
                                return (
                                    <>
                                        <div style={{ display: 'none' }}>
                                            <Controller
                                                key={`MarginLinkUnlinkHiddenItem_${index}_${data.device}_${data.value}`}
                                                as={<input />}
                                                name={data.linkname}
                                                control={control}
                                                defaultValue={data.link}
                                                ref={register(data.linkname)}
                                            />
                                        </div>
                                        <Controller
                                            as={
                                                <SettingsMargin
                                                    property="contentMargin"
                                                    headingTitle="Margin"
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                    link={data.link}
                                                    setLink={(e) => {
                                                        setValue(
                                                            data.linkname,
                                                            e
                                                        );
                                                        data.setLink(e);
                                                    }}
                                                    displayMode={data.device}
                                                    linkedSettings={
                                                        linkedSettings
                                                    }
                                                    setLinkedSettings={
                                                        setLinkedSettings
                                                    }
                                                />
                                            }
                                            key={`ContentMarginSettings_${index}_${data.device}_${data.value}`}
                                            name={data.name}
                                            control={control}
                                            defaultValue={
                                                data.value || [
                                                    '0',
                                                    '0',
                                                    '0',
                                                    '0',
                                                    'px',
                                                ]
                                            }
                                            ref={register(data.name)}
                                        />
                                    </>
                                );
                            })}
                        </>
                    )}

                    {/* Content Padding with desktop, tablet and mobile settings  */}
                    {config?.contentPadding && (
                        <>
                            {blockContentPaddingData?.map((data, index) => {
                                return (
                                    <>
                                        <div style={{ display: 'none' }}>
                                            <Controller
                                                key={`PaddingLinkUnlinkHiddenItem_${index}_${data.device}_${data.value}`}
                                                as={<input />}
                                                name={data.linkname}
                                                control={control}
                                                defaultValue={data.link}
                                                ref={register(data.linkname)}
                                            />
                                        </div>
                                        <Controller
                                            as={
                                                <SettingsMargin
                                                    property="contentPadding"
                                                    headingTitle="Padding"
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                    link={data.link}
                                                    setLink={(e) => {
                                                        setValue(
                                                            data.linkname,
                                                            e
                                                        );
                                                        data.setLink(e);
                                                    }}
                                                    displayMode={data.device}
                                                    linkedSettings={
                                                        linkedSettings
                                                    }
                                                    setLinkedSettings={
                                                        setLinkedSettings
                                                    }
                                                />
                                            }
                                            key={`ContentPaddingSettings_${index}_${data.device}_${data.value}`}
                                            name={data.name}
                                            control={control}
                                            defaultValue={
                                                data.value || [
                                                    '0',
                                                    '0',
                                                    '0',
                                                    '0',
                                                    'px',
                                                ]
                                            }
                                            ref={register(data.name)}
                                        />
                                    </>
                                );
                            })}
                        </>
                    )}
                </>
            )}

            <style jsx="true">{`
                .common-block-settings .headingtext {
                    width: 100%;
                    padding: 10px 0px 16px 16px;
                }
                .common-block-settings .heading {
                    padding: 16px 0px 5px 16px !important;
                }
                .RichText_HeadingText.desktop .fr-box {
                    border: 1px solid #dc3545;
                }
                .RichText_HeadingText.tablet .fr-box {
                    border: 1px solid #dc3545;
                }
                .RichText_HeadingText.mobile .fr-box {
                    border: 1px solid #dc3545;
                }
            `}</style>
        </div>
    );
};

export default CommonBlockSettings;
