import React, { useState, useEffect } from 'react';
import { withRouter, Redirect, useParams } from 'react-router-dom';

import { Modal } from 'react-bootstrap';

import useAlert from 'components/app/utils/hooks/useAlert';

import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import Alert from 'components/app/common/alert';

import InviteUsersPanel from 'components/app/components/leads/inviteUsersPanel';

import { getLeadsUsers } from 'apis/rest/LeadsUsers';

import UserAccountStatus from './userAccountStatus';
import InviteUsers from './inviteUsers';
import UserList from './userList';

import './leadsDashboard.scss';

function LeadsDashboard(props) {
    const { projectId, companyId } = useParams();

    const [leadsData, setLeadsData] = useState({ quota: 0, total: 0 });

    const [isModalVisible, setModalVisibility] = useState(false);
    const [users, setUsers] = useState([]);
    const [isBusy, setIsBusy] = useState(false);
    const [modalView, setModalView] = useState('inviteUsers');

    const { alert, showAlert } = useAlert();

    useEffect(() => {
        handleGetLeadsUsers();
    }, []);

    /**
     * retrieve users listing every 10mins.
     * to get the latest `login` status
     */
    useEffect(() => {
        const tenMinutes = 600000;

        const timer = setInterval(() => {
            handleGetLeadsUsers();
        }, tenMinutes);
        return () => clearTimeout(timer);
    }, []);

    /**
     * gets the list of leads users
     */
    const handleGetLeadsUsers = async (type = 'all') => {
        try {
            setIsBusy(true);
            const response = await getLeadsUsers({ projectId, companyId });
            if (response.status && response?.users) {
                setUsers(response.users);
                if (type === 'all')
                    setLeadsData({
                        total: response.leadsLimit,
                        quota: response.currentUsage,
                    });
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsBusy(false);
        }
    };

    const determineModalView = () => {
        switch (modalView) {
            case 'inviteUsers':
                return (
                    <InviteUsersPanel
                        setModalVisibility={setModalVisibility}
                        handleGetLeadsUsers={handleGetLeadsUsers}
                        showAlert={showAlert}
                        leadsQuota={leadsData.quota}
                        totalLeadsQuota={leadsData.total}
                    ></InviteUsersPanel>
                );

            default:
                break;
        }
    };

    /**
     * renders the modal size required for a particular modal view
     */
    const renderModalSize = () => {
        if (modalView === 'inviteUsers') {
            return 'large';
        } else {
            return 'small';
        }
    };

    return (
        <Layout allowFixed={false} background="#F7F9FA">
            <SidebarContainer>
                <MainMenu priActive="sponsorHome" all />
            </SidebarContainer>
            <Alert alert={alert}></Alert>
            <div className="leads-dashboard">
                <h1 className="mb-3">Manage Users</h1>

                <div className="row mb-4">
                    <div className="col-12 col-sm-7 col-md-9">
                        <UserAccountStatus
                            leadsQuota={leadsData.quota}
                            setModalVisibility={setModalVisibility}
                            setModalView={setModalView}
                            totalLeadsQuota={leadsData.total}
                        ></UserAccountStatus>
                    </div>

                    <div className="col-12 col-sm-5 col-md-3">
                        <InviteUsers
                            setModalVisibility={setModalVisibility}
                            setModalView={setModalView}
                            leadsQuota={leadsData.quota}
                            totalLeadsQuota={leadsData.total}
                        ></InviteUsers>
                    </div>
                </div>

                <UserList
                    showAlert={showAlert}
                    users={users}
                    handleGetLeadsUsers={handleGetLeadsUsers}
                    isBusy={isBusy}
                    projectId={projectId}
                    disableActivate={leadsData.quota >= leadsData.total}
                ></UserList>
            </div>

            <Modal
                show={isModalVisible}
                centered={false}
                onHide={setModalVisibility}
                dialogClassName={`custom-modal--${renderModalSize()}`}
            >
                <Modal.Body>{determineModalView()}</Modal.Body>
            </Modal>
        </Layout>
    );
}

export default withRouter(LeadsDashboard);
