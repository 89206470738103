import * as ls from "local-storage";
import axios from "axios";

/**
 * handles file import for CSV
 * for file import in "Crowdflow"
 * uses 'axios' due to its `onUploadProgress` property
 * @param {array} files
 * @param {string} projectId
 * @param {func} setProgress
 */
export const importFile = async (files, projectId, setProgress) => {
  const auth = ls.get("auth");

  let url = `${process.env.REACT_APP_CROWDFLOW_API_URL}/v1/projects/${projectId}/import`;

  const importFiles = async file => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    formData.append("overrideOnDuplicate", "true");

    const response = axios
      .request({
        method: "post",
        url,
        headers: {
          Authorization: "Bearer " + auth.access_token
        },
        data: formData,
        onUploadProgress: progress => {
          setProgress((progress.loaded / progress.total) * 100);
        }
      })
      .then(data => {
        setProgress(0);
        return data.data;
      })
      .catch(err => {
        setProgress(0);
        return err.message;
      });

    return {
      file: file.name,
      response: await response
    };
  };

  const importResponseArray = await Promise.all(files.map(importFiles));

  return importResponseArray;
};
