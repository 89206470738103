import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { signup } from '../Api';
import TagManager from 'react-gtm-module';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import channels from '../config/channels/channels.json';
import defaultChannels from '../config/channels/defaultChannels.json';

import { LoadingOutlined } from '@ant-design/icons';

import styles from './index.module.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { generateValidation } from 'utils/inputValidation';
import { useEffect } from 'react';

// Components

const LandingPage = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();
    const location = useLocation();
    const params = location?.search
        ? new URLSearchParams(`${location?.search}`)
        : null;

    const { executeRecaptcha } = useGoogleReCaptcha();

    const googleConfig = {
        url: 'https://accounts.google.com/o/oauth2/auth/authorize?',
        redirect_uri: `/signup`,
        response_type: 'code',
        scope: 'email profile openid',
        grant_type: 'authorization_code',
        redirectBaseURI:
            window.location.origin.includes('dev') ||
                window.location.origin.includes('localhost')
                ? window.location.origin
                : `${window.location.origin}`.replace('www.', ''),
    };

    const linkedInConfig = {
        url: 'https://www.linkedin.com/oauth/v2/authorization?',
        redirect_uri: '/signup',
        scope: 'r_liteprofile r_emailaddress',
        state: 'linkedin',
        response_type: 'code',
        redirectBaseURI:
            window.location.origin.includes('dev') ||
                window.location.origin.includes('localhost')
                ? window.location.origin
                : `${window.location.origin}`.replace('www.', ''),
        client_id: process.env.REACT_APP_LINKEDIN_KEY,
    };

    const handleChannelOnClick = (e, channel) => {
        e.preventDefault();
        if (channel && channel.name) {
            switch (channel.name.toLowerCase()) {
                case 'google':
                    window.location.href = `${googleConfig.url}redirect_uri=${googleConfig.redirectBaseURI}${googleConfig.redirect_uri}&response_type=${googleConfig.response_type}&scope=${googleConfig.scope}&client_id=${process.env.REACT_APP_XPMGR_GOOGLE_CLIENT_ID}&state=${channel.name}`;
                    break;
                case 'linkedin':
                    window.location.href = `${linkedInConfig.url}response_type=${linkedInConfig.response_type}&client_id=${linkedInConfig.client_id}&redirect_uri=${linkedInConfig.redirectBaseURI}${linkedInConfig.redirect_uri}&scope=${linkedInConfig.scope}&state=${channel.name}`;
                    break;
            }
        }
    };

    const handleOnSubmit = async (data, channel = '') => {
        try {
            setFormSubmitting(true);
            const payload = data;

            const reCaptchaAction = 'SIGN_UP';
            const res = await signup(
                payload,
                channel,
                await executeRecaptcha(reCaptchaAction),
                reCaptchaAction
            );

            if (res && res.status) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'signedUpUserEmail',
                        email: data?.email || res?.id,
                    },
                });
                return history.push('/signup/steps', {
                    id: res?.user?.id || res.id,
                    email: data?.email,
                    data: res?.data,
                });
            }
            throw res;
        } catch (error) {
            console.error(error);
            setError(error?.message);
            history.replace('/signup');
        } finally {
            setFormSubmitting(false);
        }
    };

    useEffect(() => {
        const getUserInfo = async () => {
            const state = params.get('state');
            const email = params.get('email')
                ? location.search.split('=')[1]?.split('&otp')[0]
                : '';

            if (state === 'google' || state === 'linkedin') {
                const code = params.get('code');

                if (code) {
                    const payload = {
                        code,
                        redirectUri:
                            state === 'google'
                                ? googleConfig.redirectBaseURI +
                                googleConfig.redirect_uri
                                : linkedInConfig.redirectBaseURI +
                                linkedInConfig.redirect_uri,
                    };

                    handleOnSubmit(payload, state);
                }
            }

            // if (email) {
            //     const otp = params.get('otp');
            //     return history.push('/signup/steps', { otp, email });
            // }
        };
        if (params && !formSubmitting && executeRecaptcha) getUserInfo();
    }, [executeRecaptcha]);

    return (
        <div className={styles.LandingPage}>
            <div className={styles.Top}>
                <span className='tw-text-var-gray-900 tw-text-center tw-font-ModernEra tw-text-[44px] tw-font-bold tw-leading-normal tw-tracking-[-1.76px]'>Omnichannel</span>
                <p className={styles.SubTitle}>Get started for free. No credit card required</p>
                <ul className={styles.ChannelList}>
                    {channels.map(
                        (channel) =>
                            !channel.disabled && (
                                <li
                                    className={styles.ChannelItem}
                                    key={channel.name}
                                >
                                    <a
                                        className={styles.Button}
                                        onClick={(e) =>
                                            handleChannelOnClick(e, channel)
                                        }
                                        href="#"
                                        title={channel.name}
                                    >
                                        {channel.logo && (
                                            <img
                                                className={styles.Logo}
                                                src={channel.logo}
                                                title={channel.label}
                                                alt={channel.name}
                                            />
                                        )}
                                        {channel.label}
                                    </a>
                                </li>
                            )
                    )}
                </ul>
                <span className={styles.Divider}>Or</span>
                <ul className={styles.ChannelList}>
                    {defaultChannels.map((channel) => (
                        <li className={styles.ChannelItem} key={channel.name}>
                            <form
                                onSubmit={handleSubmit((data) =>
                                    handleOnSubmit(data)
                                )}
                            >
                                <input
                                    className={`${styles.Input} ${channel.name === 'email' && ' tw-border-[#D0D2DD]'}`}
                                    id={channel.name}
                                    name={channel.name}
                                    placeholder={channel.placeholder}
                                    ref={register(
                                        `${channel.name}`,
                                        generateValidation({
                                            name: channel.name,
                                            inputLabel: channel.name,
                                            isRequired: {
                                                value: true,
                                                message: 'Enter your email',
                                            },
                                        })
                                    )}
                                    onChange={(e) =>
                                        setValue(
                                            `${channel.name}`,
                                            e.target.value,
                                            { shouldValidate: true }
                                        )
                                    }
                                    disabled={formSubmitting ? true : false}
                                />
                                {errors[channel.name] && (
                                    <span className={styles.Error}>
                                        {errors[channel.name].message}
                                    </span>
                                )}

                                <button
                                    className={styles.Button}
                                    type="submit"
                                    disabled={formSubmitting ? true : false}
                                >
                                    {formSubmitting ? (
                                        <>
                                            <LoadingOutlined /> Submitting...
                                        </>
                                    ) : (
                                        channel.label
                                    )}
                                </button>
                            </form>
                            {error && (
                                <span className={styles.Error}>{error}</span>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.Bottom}>
                <p className={styles.FootNote + ' tw-m-0 '}>
                    By creating an account, you agree to our{' '}
                    <a
                        href="https://www.gevme.com/en/terms-of-use/"
                        target="_blank"
                    >
                        Terms of Service
                    </a>{' '}
                    and have read and understood the{' '}
                    <a
                        href="https://www.gevme.com/en/privacy-policy/"
                        target="_blank"
                    >
                        Privacy Policy
                    </a>
                </p>
            </div>

        </div>
    );
};

export default React.memo(LandingPage);
