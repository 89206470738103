import React from 'react';
import { Link, useHistory, useParams, withRouter } from 'react-router-dom';
import { Col, Row } from 'antd';
import * as ls from 'local-storage';

import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import FrontArrow from './FrontArrow';

import styles from './index.module.scss';
import { useSelector } from 'react-redux';

const SetupData = {
    name: 'Setup',
    description:
        'Get your company profile, content and booth ready to receive your visitors.',
    sublinks: [
        {
            name: 'Company Profile',
            description:
                'Setup your company profile, logo, tagline and description.',
            link: `company-profile`,
        },
        // {
        //     name: 'Team',
        //     description: 'Setup and manage your team.',
        //     link: '',
        // },
        {
            name: 'Booth',
            description:
                'This is your storefront! Get your booth ready to receive your visitors.',
            link: 'venue-builder',
        },
        {
            name: 'Products',
            description:
                'Showcase your products through images, videos, brochures, etc.',
            link: 'products',
        },
        {
            name: 'Agenda',
            description:
                'Ready for the show? Build your agenda sessions and speakers here.',
            link: 'agenda',
        },
    ],
};

const EngagementData = {
    name: 'Engage',
    description: 'Setup your company profile and get your booth ready.',
    sublinks: [
        {
            name: 'Run Your Live Stream',
            // description: 'Mange your team’s meetings.',
            link: 'channels',
        },
        {
            name: 'Launch Polls',
            // description: 'Browse the attendee directory, search and filter.',
            link: 'notification/polls',
        },
        {
            name: 'Send Announcements',
            // description: 'Browse the attendee directory, search and filter.',
            link: 'notification/announcements',
        },
    ],
};
const LeadsData = {
    name: 'Leads Generation',
    description: 'Capture and qualify leads at the event.',
    sublinks: [
        {
            name: 'App users',
            description:
                'Give users access to capture leads from the GEVME mobile app (iOS, Android)',
            link: 'leads/users',
        },
        {
            name: 'Leads',
            description:
                'View & export complete list captured by all users under your company.',
            link: 'leads/reports',
        },
    ],
};
const AnalyticsData = {
    name: 'Analytics',
    description: 'View and export your data.',
    sublinks: [
        {
            name: 'Booth Visits',
            description: '',
            link: 'analytics',
        },
        {
            name: 'Content Interactions',
            description: '',
            link: 'analytics',
        },
        {
            name: 'Media Views',
            description: '',
            link: 'analytics',
        },
        {
            name: 'Chat Logs',
            description: '',
            link: 'analytics',
        },
    ],
};
const SponsorHomePage = ({ match }) => {
    const { projectId, companyId } = useParams();
    const history = useHistory();
    const user = useSelector(state => state.user)

    const { sponsorMenuData } = useSelector((state) => state?.sponsorMenu);

    let allowedSubLinks = [];
    sponsorMenuData.map((ele) => {
        if (ele?.link) allowedSubLinks.push(ele.link);
        if (ele?.sublinks && ele.sublinks.length > 0) {
            ele.sublinks.map((slinkData) => {
                allowedSubLinks.push(slinkData.link);
            });
        }
    });
    const Card = ({ data, style = {} }) => {
        const subLinkUI = [];
        data.sublinks.map((sublink, index) => {
            if (allowedSubLinks.includes(sublink.link)) {
                subLinkUI.push(
                    <div
                        className={styles.sponsorHomeCardLinkContainer}
                        key={index}
                    >
                        <Link
                            className={styles.sponsorHomeCardLink}
                            style={{ display: 'flex', alignItems: 'center' }}
                            to={`/company/${companyId}/project/${projectId}/${sublink.link}?sponsor=1`}
                        >
                            <FrontArrow />
                            <p>{sublink.name}</p>
                        </Link>
                        {sublink.description && (
                            <span
                                className={
                                    styles.sponsorHomeCardLinkDescription
                                }
                            >
                                {sublink.description}
                            </span>
                        )}
                    </div>
                );
            }
        });
        if (subLinkUI.length === 0) return null;

        return (
            <div className={styles.sponsorHomeCard} style={style}>
                <h2 clasname={styles.sponsorHomeCardTitle}>{data.name}</h2>
                {data.description && (
                    <span className={styles.sponsorHomeCardDescription}>
                        {data.description}
                    </span>
                )}
                {subLinkUI}
            </div>
        );
    };

    const SetupCard = Card({ data: SetupData });

    const EngagementCard = Card({ data: EngagementData });

    const LeadsCard = Card({ data: LeadsData, style: { marginTop: '10px' } });

    const AnalyticsCard = Card({
        data: AnalyticsData,
        style: { marginTop: '10px' },
    });

    const BoothCard = allowedSubLinks.includes('venue-builder') ? (
        <div
            className={`${styles.sponsorHomeCard} ${styles.sponsorHomeCustomCard}`}
            style={{ background: 'white', padding: '24px', marginTop: '10px' }}
        >
            <h2 className={styles.sponsorHomeCardTitle}>Access the Event</h2>
            <span style={{ display: 'block' }}>
                Connect with your booth audience and network with them.
            </span>
            <span
                style={{
                    display: 'block',
                    fontWeight: 600,
                    marginTop: '24px',
                }}
            >
                • Discover people
            </span>
            <span
                style={{
                    display: 'block',
                    fontWeight: 600,
                    marginTop: '16px',
                }}
            >
                • Get personalised recommendations
            </span>
            <button
                onClick={() => {
                    history.push(
                        `/company/${companyId}/project/${projectId}/venue-builder`
                    );
                }}
            >
                <p>Access your booth</p>
                <FrontArrow style={{ color: 'black' }} />
            </button>
        </div>
    ) : null;

    const totalCards =
        3 -
        (SetupCard ? 0 : 1) -
        (EngagementCard ? 0 : 1) -
        (AnalyticsCard || BoothCard ? 0 : 1);

    return (
        <Layout allowFixed={false} background="#F7F9FA">
            <SidebarContainer>
                <MainMenu priActive="sponsorHome" all />
            </SidebarContainer>

            <div className={styles.sponsorHome}>
                <h1 className={styles.sponsorHomeTitle}>{`Welcome, ${
                    user?.firstname || 'User'
                }!`}</h1>
                <h1 className={styles.sponsorHomeTitle}>
                    Here are your action items
                </h1>
                <Row className={styles.sponsorHomeCardContainer} gutter={24}>
                    {SetupCard && (
                        <Col
                            span={
                                totalCards === 1
                                    ? 16
                                    : totalCards === 2
                                    ? 12
                                    : 8
                            }
                        >
                            {SetupCard}
                        </Col>
                    )}
                    {(EngagementCard || LeadsCard) && (
                        <Col
                            span={
                                totalCards === 1
                                    ? 16
                                    : totalCards === 2
                                    ? 12
                                    : 8
                            }
                        >
                            {EngagementCard}
                            {LeadsCard}
                        </Col>
                    )}
                    {/* {LeadsCard && (
                        <Col
                            span={
                                totalCards === 1
                                    ? 16
                                    : totalCards === 2
                                    ? 12
                                    : 8
                            }
                        >
                            {LeadsCard}
                        </Col>
                    )} */}
                    {(AnalyticsCard || BoothCard) && (
                        <Col
                            span={
                                totalCards === 1
                                    ? 16
                                    : totalCards === 2
                                    ? 12
                                    : 8
                            }
                        >
                            {BoothCard}
                            {AnalyticsCard}
                        </Col>
                    )}
                </Row>
            </div>
        </Layout>
    );
};
export default withRouter(SponsorHomePage);
