import React, { useEffect, useState } from 'react';
import { Link, useHistory, withRouter, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as act from 'redux/actions/live/uiActionsLive';
import * as actUser from 'redux/actions/common/userActions';

import * as ls from 'local-storage';
import Layout from '../../.././../common/layout';
import { getUser } from 'apis/rest/User';

import '../dashboard.scss';
import './index.scss';
import { isEmpty } from 'validate.js';
import { handleLogout } from 'ComponentsV2/ProjectDashboard/utils/handleLogout';
import Prompt from 'components/app/common/prompt';

function CompanyListing() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const user = useSelector((state) => state.user);
    const companyList =
        useSelector((state) => state?.user?.companies) || user?.companies;
    const company = user?.company || companyList[0];
    const [showNoAccessConfirm, setShowNoAccessConfirm] = useState(false);

    useEffect(() => {
        const updateUserData = async () => {
            if (user && !isEmpty(user)) {
                dispatch(actUser.setUser({ user }));
            } else {
                const userData = await getUser();
                dispatch(actUser.setUser(userData)); //update user data on company listing page
            }
        };

        updateUserData();
        dispatch(act.updateAllowFX(false));
        // Let Layout component know that menus are not visible
        dispatch(act.updateMainMenuVisibility(false));
        dispatch(act.updateSubMenuVisibility(false));
        // Reset defaults
        dispatch(act.updateHeaderTitle(''));
        dispatch(act.updateActiveLivePageName(''));

        if (params.get('err')) setShowNoAccessConfirm(true);
        else history.push(`/company/${company?.id}/project/`, company);
    }, []);
    return (
        <Layout isCompListing={true}>
            {companyList && companyList.length > 0 && (
                <div className="container" style={{ width: '950px' }}>
                    <div className="dashboard dashboard__xpmgr project-list">
                        <div className="dashboard__left">
                            <Link to="/">
                                <h1 className="active">Companies</h1>
                            </Link>
                        </div>
                    </div>

                    <Prompt
                        icon="warning"
                        show={showNoAccessConfirm}
                        message={`You don't have access to this organization.`}
                        buttons={['OK', 'Proceed to Logout']}
                        onHide={() => {
                            setShowNoAccessConfirm(false);
                            if (company) {
                                history.push(
                                    `/company/${company?.id}/project/`,
                                    company
                                );
                            } else handleLogout();
                        }}
                        callback={() => handleLogout()}
                    />

                    <div className="mt-5">
                        <table className="table table-borderless project-list-table">
                            <tbody>
                                {companyList.map((company, i) => {
                                    return (
                                        <Link
                                            onClick={() => {
                                                dispatch(
                                                    actUser.updateUser({
                                                        user: {
                                                            ...user,
                                                            company,
                                                        },
                                                    })
                                                );
                                            }}
                                            className="companyListItem"
                                            to={`/company/${company.id}/project/`}
                                        >
                                            <tr
                                                key={'company-row' + company.id}
                                            >
                                                <td
                                                    key={
                                                        'company-' + company.id
                                                    }
                                                >
                                                    <span className="company-title">
                                                        {company.name}
                                                    </span>
                                                </td>
                                            </tr>
                                        </Link>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </Layout>
    );
}

export default withRouter(CompanyListing);
