import * as ls from 'local-storage';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

const path = `${process.env.REACT_APP_MYXP_API}/manager/livestream/analytics/matomo`;

const VisitorsAnalytics = {
    getVisitorSummary: async (projectId, showcaseId, startDate, endDate) => {
        setAuthBearer();
        showcaseId = showcaseId === 'all' ? '' : showcaseId;
        const response = await fetch(
            `${path}/visitors/summary?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    },
    getVisitorsList: async (
        projectId,
        showcaseId,
        startDate,
        endDate,
        download,
        page = 1
    ) => {
        setAuthBearer();

        showcaseId = showcaseId === 'all' ? '' : showcaseId;
        let endPoint = download
            ? `${path}/live/users?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&download=${download}`
            : `${path}/live/users?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&page=${page}`;
        const response = await fetch(endPoint, {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        });
        if (download) return await response.blob();
        else return await response.json();
    },
    getLinkClicks: async (
        projectId,
        showcaseId,
        startDate,
        endDate,
        download,
        page = 1
    ) => {
        setAuthBearer();

        showcaseId = showcaseId === 'all' ? '' : showcaseId;
        let endPoint = download
            ? `${path}/events/links?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&download=${download}`
            : `${path}/events/links?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&page=${page}`;
        const response = await fetch(endPoint, {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        });
        if (download) return await response.blob();
        else return await response.json();
    },
    getDownloadClicks: async (
        projectId,
        showcaseId,
        startDate,
        endDate,
        download,
        page = 1
    ) => {
        setAuthBearer();

        showcaseId = showcaseId === 'all' ? '' : showcaseId;
        let endPoint = download
            ? `${path}/events/downloads?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&download=${download}`
            : `${path}/events/downloads?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&page=${page}`;
        const response = await fetch(endPoint, {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        });
        if (download) return await response.blob();
        else return await response.json();
    },
    getElementClicks: async (
        projectId,
        showcaseId,
        startDate,
        endDate,
        download,
        page = 1
    ) => {
        setAuthBearer();

        showcaseId = showcaseId === 'all' ? '' : showcaseId;
        let endPoint = download
            ? `${path}/events/clicks?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&download=${download}`
            : `${path}/events/clicks?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&page=${page}`;
        const response = await fetch(endPoint, {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        });
        if (download) return await response.blob();
        else return await response.json();
    },
    getClickDetail: async (
        category,
        projectId,
        showcaseId,
        startDate,
        endDate,
        eventAction,
        eventName,
        download,
        page = 1
    ) => {
        setAuthBearer();

        showcaseId = showcaseId === 'all' ? '' : showcaseId;
        let endPoint = download
            ? `${path}/events/${category}/users?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&eventAction=${eventAction}&eventName=${eventName}&download=${download}`
            : `${path}/events/${category}/users?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&eventAction=${eventAction}&eventName=${eventName}&page=${page}`;
        const response = await fetch(endPoint, {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        });
        if (download) return await response.blob();
        else return await response.json();
    },
    getContentTracking: async (
        projectId,
        showcaseId,
        startDate,
        endDate,
        download,
        page = 1
    ) => {
        setAuthBearer();

        showcaseId = showcaseId === 'all' ? '' : showcaseId;
        let endPoint = download
            ? `${path}/content/summary?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&download=${download}`
            : `${path}/content/summary?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&page=${page}`;
        const response = await fetch(endPoint, {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        });
        if (download) return await response.blob();
        else return await response.json();
    },
    getContentUsers: async (
        projectId,
        showcaseId,
        startDate,
        endDate,
        contentPiece,
        contentName,
        download,
        page = 1
    ) => {
        setAuthBearer();

        showcaseId = showcaseId === 'all' ? '' : showcaseId;
        let endPoint = download
            ? `${path}/content/summary/users?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&contentPiece=${contentPiece}&contentName=${contentName}&download=${download}`
            : `${path}/content/summary/users?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&contentPiece=${contentPiece}&contentName=${contentName}&page=${page}`;
        const response = await fetch(endPoint, {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        });
        if (download) return await response.blob();
        else return await response.json();
    },
    getMediaImpressions: async (
        projectId,
        showcaseId,
        startDate,
        endDate,
        download,
        page = 1
    ) => {
        setAuthBearer();

        showcaseId = showcaseId === 'all' ? '' : showcaseId;
        let endPoint = download
            ? `${path}/media/summary?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&download=${download}`
            : `${path}/media/summary?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&page=${page}`;
        const response = await fetch(endPoint, {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        });
        if (download) return await response.blob();
        else return await response.json();
    },
    getMediaUsers: async (
        projectId,
        showcaseId,
        startDate,
        endDate,
        mediaUrl,
        download,
        page = 1
    ) => {
        setAuthBearer();

        showcaseId = showcaseId === 'all' ? '' : showcaseId;
        let endPoint = download
            ? `${path}/media/summary/users?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&mediaUrl=${mediaUrl}&download=${download}`
            : `${path}/media/summary/users?projectId=${projectId}&showcaseId=${showcaseId}&startDate=${startDate}&endDate=${endDate}&mediaUrl=${mediaUrl}&page=${page}`;
        const response = await fetch(endPoint, {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        });
        if (download) return await response.blob();
        else return await response.json();
    },
};
export default VisitorsAnalytics;
