import React, { useState } from 'react';
import ls from 'local-storage';
import { Tooltip } from 'antd';

import { getFullname, getNameInitials } from '../../../utils/functions/common';

import { TwoFASecurityModal } from 'ComponentsV2/SignIn/2FA';
import Prompt from '../../prompt';
import {
    UserProfileEditIcon,
    LogoutIcon,
    OfficeIcon,
    SecurityIcon,
} from './icons';
import './UserProfile.scss';

export default function UserProfile({
    user,
    setShowUserProfileEdit,
    handleLogout,
}) {
    const [showUserProfileSecurity, setShowUserProfileSecurity] =
        useState(false);
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const isSponsorDashboard = !!ls.get('sponsorAuth');
    const fullName = getFullname(user);
    const maxNameLength = 30;

    return (
        <>
            <div className="tw-font-openSans tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-72 tw-h-auto tw-pt-6 tw-pb-1 tw-bg-white tw-rounded-md tw-cursor-default">
                <div className="tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-16 tw-h-16 tw-rounded-full tw-bg-slate-200 tw-cursor-default">
                    <span className="tw-m-0 tw-p-0 tw-text-2xl tw-font-semibold tw-cursor-default">
                        {getNameInitials(user)}
                    </span>
                </div>
                <div className="tooltip-fullname tw-m-0 tw-p-0 tw-text-sm tw-mt-5 tw-font-semibold tw-text-black tw-break-words tw-text-center">
                    {fullName?.length > maxNameLength ? (
                        <Tooltip
                            placement="top"
                            title={fullName}
                            zIndex={9999}
                            getPopupContainer={(triggerNode) => triggerNode}
                        >
                            <span className="tw-p-0 tw-m-0 tw-break-words">
                                {fullName.slice(0, maxNameLength - 1) + ' ...'}
                            </span>
                        </Tooltip>
                    ) : (
                        fullName
                    )}
                </div>
                <div className="tw-m-0 tw-p-0 tw-text-xs tw-mt-3 tw-font-extralight">
                    {user?.email}
                </div>
                <div className="tw-m-0 tw-p-0 tw-flex tw-flex-row tw-text-sm tw-mt-3 tw-font-medium">
                    <span className="tw-m-0 tw-p-0 tw-cursor-default">
                        <OfficeIcon />
                    </span>
                    <span className="tw-m-0 tw-p-0 tw-ml-1 tw-cursor-default">
                        {user?.company?.name}
                    </span>
                </div>
                {!isSponsorDashboard && (
                    <div
                        className="tw-m-0 tw-p-0 tw-w-full hover:tw-bg-gray-100 tw-border-solid tw-border-0 tw-border-t tw-border-slate-200 tw-mt-6 tw-flex tw-flex-row tw-items-center tw-py-2 tw-cursor-pointer"
                        onClick={() => setShowUserProfileEdit(true)}
                    >
                        <span className="tw-m-0 tw-p-0 tw-pl-4 tw-pr-2">
                            <UserProfileEditIcon />
                        </span>
                        <span className="tw-m-0 tw-p-0 tw-text-sm tw-font-light">
                            Edit your profile
                        </span>
                    </div>
                )}

                {user?.enable2FA ? (
                    <div
                        className="tw-m-0 tw-p-0 tw-w-full hover:tw-bg-gray-100 tw-mt-0 tw-flex tw-flex-row tw-items-center tw-py-2 tw-cursor-pointer"
                        onClick={() => setShowUserProfileSecurity(true)}
                    >
                        <span className="tw-m-0 tw-p-0 tw-pl-4 tw-pr-2">
                            <SecurityIcon />
                        </span>
                        <span className="tw-m-0 tw-p-0 tw-text-sm tw-font-light">
                            Security
                        </span>
                    </div>
                ) : null}

                <div
                    className="tw-m-0 tw-p-0 tw-w-full hover:tw-bg-gray-100 tw-mt-0 tw-flex tw-flex-row tw-items-center tw-py-2 tw-cursor-pointer"
                    onClick={() => setShowLogoutConfirm(true)}
                >
                    <span className="tw-m-0 tw-p-0 tw-pl-4 tw-pr-2">
                        <LogoutIcon />
                    </span>
                    <span className="tw-m-0 tw-p-0 tw-text-sm tw-font-light">
                        Logout
                    </span>
                </div>
            </div>
            <Prompt
                show={showLogoutConfirm}
                message={`Are you sure you want to Logout?`}
                buttons={['Cancel', 'Proceed to Logout']}
                onHide={() => setShowLogoutConfirm(false)}
                callback={() => handleLogout()}
            />
            {showUserProfileSecurity ? (
                <TwoFASecurityModal
                    visible={showUserProfileSecurity}
                    setVisible={setShowUserProfileSecurity}
                />
            ) : null}
        </>
    );
}
