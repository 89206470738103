import * as ls from 'local-storage';

/**
 * updates user profile
 * @param {object} data data required update profile
 */
export async function updateProfile(data) { 
    const auth = ls.get('auth');
    const authBearer = `Bearer ${auth.access_token}`;

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/users/profile`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}
