import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import { MinimizeIcon, MaximizeIcon } from './icons';
import { Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default function CommonBanner({
    isInStarterPlan,
    className,
    title,
    titleClassName,
    subtitle,
    subtitleShort,
    subtitleClassName,
    childrenButtons,
    showUpgradeButton,
    upgradeText,
    upgradeAction,
    icon,
    showMiniMaxIcon,
    showOnlyInStarterPlan,
    showTooltip
}) {
    const { companyId } = useParams();
    const history = useHistory();
    const [isMinimized, setIsMinimized] = useState(false);
    if (!upgradeAction) {
        upgradeAction = () => history.push(`/v2/company/${companyId}/upgrade`);
    }
    subtitleShort = subtitleShort || subtitle;

    if (showOnlyInStarterPlan && !isInStarterPlan) {
        return null;
    }

    const getUpgradeButton = () =>
        showUpgradeButton && isInStarterPlan ? (
            <div
                className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-text-xs tw-tracking-wide tw-font-light tw-rounded-md tw-bg-green-700 tw-py-2 tw-px-3 tw-text-white tw-border tw-border-solid tw-border-green-700 tw-cursor-pointer hover:tw-bg-white hover:tw-text-green-700 tw-shadow tw-shadow-green-700"
                onClick={upgradeAction}
            >
                <span className="tw-font-semibold">{upgradeText}</span>
            </div>
        ) : null;

    const getMaximizedView = () => (
        <>
            <div className="tw-flex tw-flex-col tw-py-7 tw-pl-7">
                <span
                    className={`tw-text-black tw-flex tw-items-center tw-text-2xl tw-font-bold tw-tracking-tight tw-font-montserrat ${titleClassName}`}
                >
                    {title}
                    {
                        showTooltip && (
                            <Tooltip
                                placement="rightBottom"
                                title="Once an experience is selected, you cannot make changes. Please ensure that you have finalised your selection of the experience."
                            >
                                <ExclamationCircleOutlined className="tw-cursor-pointer tw-ml-1 tw-text-lg tw-relative tw-top-[-1px]" />
                            </Tooltip>
                        )
                    }
                </span>
                <span
                    className={`tw-text-black tw-text-xs tw-font-light tw-tracking-tight tw-pt-2 ${subtitleClassName}`}
                >
                    {subtitle}

                </span>
                <div className="tw-flex tw-flex-row tw-pt-4">
                    {childrenButtons}
                    {getUpgradeButton()}
                </div>
            </div>
            {icon ? (
                <div className="tw-flex tw-flex-col tw-pt-3 tw-pb-1">
                    {icon}
                </div>
            ) : null}
        </>
    );

    const getMinimizedView = () => (
        <>
            <div className="tw-flex tw-flex-col tw-py-7 tw-pl-7">
                <span
                    className={`tw-text-black tw-flex tw-items-center tw-text-xl tw-font-bold tw-tracking-tight tw-font-montserrat ${titleClassName}`}
                >
                    {title}
                    {
                        showTooltip && (
                            <Tooltip
                                placement="rightBottom"
                                title="Once an experience is selected, you cannot make changes. Please ensure that you have finalised your selection of the experience."
                            >
                                <ExclamationCircleOutlined className="tw-cursor-pointer tw-ml-1 tw-text-lg tw-relative  tw-top-[-1px]" />
                            </Tooltip>
                        )
                    }
                </span>
                <span
                    className={`tw-text-black tw-text-xs tw-font-light tw-tracking-tight tw-pt-2  ${subtitleClassName}`}
                >
                    {subtitleShort}

                </span>
            </div>
            <div className="tw-flex tw-flex-col tw-pt-3 tw-pb-3 tw-pr-7 tw-mt-auto">
                <div className="tw-flex tw-flex-row tw-py-4 tw-pr-7">
                    {childrenButtons}
                    {getUpgradeButton()}
                </div>
            </div>
        </>
    );

    return (
        <div
            className={`tw-relative tw-w-full tw-flex tw-flex-row tw-bg-blue-100 tw-drop-shadow-lg tw-justify-between tw-border-blue-100 tw-border-solid tw-rounded-lg ${className}`}
        >
            {isMinimized ? getMinimizedView() : getMaximizedView()}
            {showMiniMaxIcon ? (
                <span
                    className="tw-absolute tw-top-2.5 tw-right-2.5 tw-cursor-pointer"
                    onClick={() => setIsMinimized((prev) => !prev)}
                >
                    {isMinimized ? <MaximizeIcon /> : <MinimizeIcon />}
                </span>
            ) : null}
        </div>
    );
}

CommonBanner.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleClassName: PropTypes.string,
    subtitle: PropTypes.string,
    subtitleShort: PropTypes.string,
    subtitleClassName: PropTypes.string,
    childrenButtons: PropTypes.node,
    showUpgradeButton: PropTypes.bool,
    upgradeText: PropTypes.string,
    upgradeAction: PropTypes.func,
    icon: PropTypes.node,
    isInStarterPlan: PropTypes.bool,
    showMiniMaxIcon: PropTypes.bool,
    showOnlyInStarterPlan: PropTypes.bool,
};

CommonBanner.defaultProps = {
    className: '',
    title: '',
    titleClassName: '',
    subtitle: '',
    subtitleShort: '',
    subtitleClassName: '',
    childrenButtons: null,
    showUpgradeButton: true,
    upgradeText: 'Upgrade to pro for unlimited space',
    upgradeAction: null,
    icon: null,
    isInStarterPlan: false,
    showMiniMaxIcon: true,
    showOnlyInStarterPlan: true,
};
