import React, {
    useState,
    useEffect,
    useLayoutEffect,
    useCallback,
} from 'react';
import { Link, withRouter, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, TimePicker, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useMediaQuery } from 'react-responsive';
import { isEmpty, debounce } from 'lodash';

import {
    getRandomString,
    renameFile,
} from '../../../../utils/functions/common';
import useAlertBar from '../../../../utils/hooks/useAlertBar';

import { createProject } from '../../../../../../apis/rest/livestream/CreateProject';

import Layout from '../../../../common/layout';
import ImageUpload from 'components/app/common/imageUpload/imageUpload';

import Gallery from './gallery';

// Redux actions
import * as act from 'redux/actions/live/uiActionsLive';
import * as ls from 'local-storage';

import './create.scss';
import Tooltip from 'rc-tooltip/es';

const { Option } = Select;

function Create() {
    const IMAGE_CROP_ASPECT_RATIO = 13 / 7;
    const IMAGE_CROP_ASPECT_RATIO_DESKTOP = 16 / 9;
    const history = useHistory();
    const { companyId } = useParams();
    const deviceHeightSmall = useMediaQuery({ query: '(max-height: 800px)' });
    const user = useSelector((state) => state.user);
    const isDashboardVersionV2 = user?.dashboardVersion === 'v2';

    const [isBusy, setIsBusy] = useState(false);
    const [isBackgroundTemplate, setIsBackgroundTemplate] = useState(true);
    const [selectedGalleryImage, setSelectedGalleryImage] = useState(0);
    const [name, setName] = useState('');
    const [debouncedName, setDebouncedName] = useState('');
    const [logo, setLogo] = useState('');
    const [background, setBackground] = useState('');
    const [creatable, setCreatable] = useState(false);
    const [uploadError, setUploadError] = useState({});

    const [currentTimezone, setCurrentTimezone] = useState(moment?.tz?.guess());
    const [startDate, setStartDate] = useState(
        moment().tz(currentTimezone).add(1, 'days').set({ hour: 9, minute: 0 })
    );
    const [endDate, setEndDate] = useState(
        moment().tz(currentTimezone).add(1, 'days').set({ hour: 18, minute: 0 })
    );

    const { showAlertBar } = useAlertBar();

    // populate galleryImages to feed display
    // const galleryImages = [
    //     {
    //         id: 0,
    //         url: 'https://files-myxp.gevme.com/default_background/BG-light-1.png',
    //         // 'https://files-myxp.gevme.com/default_background/Light_BG_1.png',
    //     },
    //     {
    //         id: 1,
    //         url: 'https://files-myxp.gevme.com/default_background/Light_BG_2.png',
    //     },
    //     {
    //         id: 2,
    //         url: 'https://files-myxp.gevme.com/default_background/Light_BG_3.png',
    //     },
    //     {
    //         id: 3,
    //         url: 'https://files-myxp.gevme.com/default_background/Light_BG_4.png',
    //     },
    //     {
    //         id: 4,
    //         url: 'https://files-myxp.gevme.com/default_background/Dark_BG_1.png',
    //     },
    //     {
    //         id: 5,
    //         url: 'https://files-myxp.gevme.com/default_background/Dark_BG_2.png',
    //     },
    //     {
    //         id: 6,
    //         url: 'https://files-myxp.gevme.com/default_background/Dark_BG_3.png',
    //     },
    //     {
    //         id: 7,
    //         url: 'https://files-myxp.gevme.com/default_background/Dark_BG_4.png',
    //     },
    // ];

    const dispatch = useDispatch();

    useEffect(() => {
        // Reset allowFX to false, to disable transition effects
        dispatch(act.updateAllowFX(false));
        // Let Layout component know that menus are not visible
        dispatch(act.updateMainMenuVisibility(false));
        dispatch(act.updateSubMenuVisibility(false));
    }, []);

    useLayoutEffect(() => {
        if (!isEmpty(uploadError)) {
            showAlertBar(uploadError.msg, 'error');
        }
    }, [uploadError]);

    const handleBackgroundOption = (status) => {
        setIsBackgroundTemplate(status);
        setBackground('');
        setSelectedGalleryImage(0);
    };

    /**
     *
     */
    const setImageOptionClass = () => {
        return !isBackgroundTemplate ? 'active-filter' : '';
    };

    /**
     *
     */
    const setTemplateOptionClass = () => {
        return isBackgroundTemplate ? 'active-filter' : '';
    };

    /**
     *
     * @param {*} image
     */
    const handleSelectTemplate = (image) => {
        setBackground(
            image.id >= 0 && image.id <= 7
                ? { key: 'backgroundTemplate', value: image.id }
                : ''
        );
        setSelectedGalleryImage(image.id);
    };

    /**
     *
     * @param {*} event
     */
    const handleNameChange = (event) => {
        let { value } = event.target;
        setName(value);
        debounceName(value);
    };

    const debounceName = useCallback(
        debounce((text) => setDebouncedName(text), 700),
        []
    );

    useEffect(() => handleEnableCreate(), [debouncedName, logo, background]);

    /**
     *
     */
    const handleEnableCreate = () => {
        let hasName = debouncedName !== '',
            hasLogo = logo !== '',
            hasBackground = background !== '';

        setCreatable(hasName);
    };

    /**
     *
     */
    const handleCreateProject = () => {
        if (!creatable || isBusy) return false;

        setIsBusy(true);
        let formData = new FormData();

        formData.append('environment', process.env.REACT_APP_ENVIRONMENT);
        formData.append('name', name);
        formData.append('timezone', currentTimezone);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        formData.append('companyId', companyId);

        if (logo !== '') formData.append('logo', logo, renameFile(logo).name);

        if (background !== '') {
            if (background.hasOwnProperty('name'))
                formData.append(
                    background.key,
                    background.value,
                    background.name
                );
            else formData.append(background.key, background.value);
        }

        createProject(formData)
            .then(async (response) => {
                if (!response.status) {
                    const errMsg = response?.message || 'Something went wrong.';
                    showAlertBar(errMsg, 'error');
                    return false;
                }

                showAlertBar('Successfully created project.', 'success');
                // Dispatch them
                await dispatch(act.updateHeaderTitle(response.project.name));
                await dispatch(act.updateProjectId(response.project.projectId));
                history.push(
                    isDashboardVersionV2
                        ? `/v2/company/${companyId}/project/${response.project.projectId}/virtual/venue`
                        : `/company/${companyId}/project/${response.project.projectId}`
                );
            })
            .catch((err) => showAlertBar('Failed to create project.', 'error'))
            .finally(() => setIsBusy(false));
    };

    const renderCustomTooltip = (description) => {
        return (
            <Tooltip
                placement="right"
                trigger="hover"
                key="project-name-question"
                overlay={<div className="project-tooltip">{description}</div>}
            >
                <ExclamationCircleOutlined />
            </Tooltip>
        );
    };

    const renderOptions = () => {
        const timezones = moment.tz.names();
        let mappedValues = {};
        let regions = [];

        timezones.map((timezone) => {
            if (
                timezone.includes('/') &&
                !timezone.toLowerCase().includes('us') &&
                !timezone.toLowerCase().includes('etc')
            ) {
                const splitTimezone = timezone.split('/');
                const region = splitTimezone[0];
                if (!mappedValues[region]) {
                    mappedValues[region] = [];
                    regions.push(region);
                }
                mappedValues[region].push(timezone);
            }
        });
        return regions.map((region) => {
            const options = mappedValues[region].map((timezone) => {
                return (
                    <Option key={timezone}>
                        ({moment.tz(timezone).format('Z')}) {timezone}
                    </Option>
                );
            });
            return options;
        });
    };

    return (
        <Layout isProjListing={true} header={'Create Project'}>
            <div className="container d-flex justify-content-center">
                <div className="d-flex flex-column project project__create mb-5">
                    <div className="text-center my-3 mb-4">
                        <h1>Create project</h1>
                    </div>
                    <div className={`create-body-inputs`}>
                        <div>
                            <h3>Project Name</h3>
                            <input
                                type="text"
                                className="form-control mt-3 mb-5"
                                value={name}
                                onChange={handleNameChange}
                            />
                        </div>
                        <h3>Project Logo</h3>
                        <div className="my-4">
                            <h4>Logo</h4>
                            <ImageUpload
                                prepareFilesUpload={(files) => {
                                    setLogo(files[0]);
                                }}
                                imageCropRecommendedSize={{
                                    width: 260,
                                    height: 140,
                                }}
                                imageCropAspectRatio={IMAGE_CROP_ASPECT_RATIO}
                                showRecommendedSize={false}
                                enableDrop={true}
                            />
                            <span className="text-muted">
                                Recommended size: within 260 x 140 px.
                            </span>
                        </div>
                    </div>
                    <div className="mt-4 mb-5 row">
                        <div className="col-12">
                            <label className="mb-1">
                                Project time zone{' '}
                                {renderCustomTooltip(
                                    'Based on this project time zone event will happen.'
                                )}
                            </label>
                            <Select
                                showSearch
                                className="w-100"
                                allowClear={false}
                                value={currentTimezone}
                                onChange={setCurrentTimezone}
                                optionFilterProp="children"
                            >
                                <>
                                    <Option key={moment?.tz?.guess()}>
                                        <div className="border-bottom pb-1">
                                            (
                                            {moment
                                                .tz(moment?.tz?.guess())
                                                .format('Z')}
                                            ) {moment?.tz?.guess()}
                                        </div>
                                    </Option>
                                    {renderOptions()}
                                </>
                            </Select>
                        </div>
                        <div className="col-6 mt-2">
                            <label className="mb-1">Start date</label>
                            <DatePicker
                                className="w-100"
                                disabledDate={(current) =>
                                    current <
                                    moment().tz(currentTimezone).startOf('day')
                                }
                                allowClear={false}
                                value={startDate}
                                onChange={setStartDate}
                                showToday={false}
                                format="D MMM YYYY"
                            />
                        </div>
                        <div className="col-6 mt-2">
                            <label className="mb-1">Start time</label>
                            <TimePicker
                                allowClear={false}
                                className="w-100"
                                value={startDate}
                                onChange={setStartDate}
                                disabledDate={(current) =>
                                    current <
                                    moment()
                                        .tz(currentTimezone)
                                        .add(1, 'minutes')
                                }
                                format="hh:mm a"
                                showNow={false}
                            />
                        </div>
                        <div className="col-6 mt-2">
                            <label className="mb-1">End date</label>
                            <DatePicker
                                className="w-100"
                                disabledDate={(current) =>
                                    current <
                                    moment(startDate)
                                        .tz(currentTimezone)
                                        .startOf('day')
                                }
                                allowClear={false}
                                value={endDate}
                                onChange={setEndDate}
                                showToday={false}
                                format="D MMM YYYY"
                            />
                        </div>
                        <div className="col-6 mt-2">
                            <label className="mb-1">End time</label>
                            <TimePicker
                                allowClear={false}
                                className="w-100"
                                value={endDate}
                                onChange={setEndDate}
                                disabledDate={(current) =>
                                    current <
                                    moment(startDate)
                                        .tz(currentTimezone)
                                        .add(9, 'minutes')
                                }
                                format="hh:mm a"
                                showNow={false}
                            />
                        </div>
                    </div>

                    {/* actions */}
                    <div
                        className={`create-footer fixed-bottom d-flex justify-content-center p-3 shadow-lg`}
                    >
                        <div
                            style={{ width: '400px' }}
                            className="container  d-flex justify-content-between"
                        >
                            <Link to={`/`} className="myxp btn btn-cancel">
                                <span>Cancel</span>
                            </Link>
                            <button
                                className={
                                    `myxp btn btn-primary ` +
                                    (!creatable || isBusy
                                        ? 'disabled pointer-not-allowed'
                                        : '')
                                }
                                disabled={
                                    !creatable || isBusy ? 'disabled' : ''
                                }
                                onClick={handleCreateProject}
                            >
                                {isBusy && (
                                    <i className="fa fa-spin fa-spinner"> </i>
                                )}
                                {!isBusy && 'Create Project'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default withRouter(Create);
