import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Button, Form, Input, Table, Switch } from 'antd';

import Empty from './common/Empty';
import {
    createLocation,
    deleteLocation,
    getLocations,
    updateLocation,
} from 'apis/rest/agenda/locations';
import PageSpinner from 'components/app/common/pageSpinner';

import styles from './index.module.scss';

import FormContainer from './common/FormContainer';
import SearchIcon from './common/SearchIcon';
import Prompt from '../../../../../../common/prompt';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

const Locations = () => {
    const projectId = useSelector((state) => state?.header?.projectId);
    const [loading, setLoading] = useState(true);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showEmpty, setShowEmpty] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [sortOrder, setSortOrder] = useState('ascend');
    const [pagination, setPagination] = useState({});
    const [locations, setLocations] = useState([]);

    const [form] = Form.useForm();
    const [formLoading, setFormLoading] = useState(false);
    const [formAction, setFormAction] = useState('create');
    const [formShouldContinue, setFormShouldContinue] = useState(false);
    const [formAPIFunction, setFormAPIFunction] = useState();

    async function openCreateLocationForm() {
        setFormAction('create');
        setFormAPIFunction(() => (name, showForSponsor) => {
            const inputs = {
                projectId,
                name,
                showForSponsor,
            };
            return createLocation(inputs);
        });
        form.setFieldsValue({ name: '', showForSponsor: true });
        setShowDrawer(true);
    }

    async function openUpdateLocationForm(location) {
        setFormAction('update');
        setFormAPIFunction(() => (name, showForSponsor) => {
            const inputs = {
                name,
                showForSponsor,
            };
            return updateLocation(projectId, location.id, inputs);
        });
        form.setFieldsValue({
            name: location?.name,
            showForSponsor: location?.showForSponsor,
        });

        setShowDrawer(true);
    }

    async function onFinish(values) {
        setFormLoading(true);
        try {
            const res = await formAPIFunction(
                values.name,
                values.showForSponsor
            );
            if (!res.status) {
                throw new Error(res.message);
            }

            openNotification('success', {
                message:
                    formAction === 'create'
                        ? 'You have successfully created the location'
                        : 'You have successfully updated the location',
            });

            if (!formShouldContinue) {
                setShowDrawer(false);
            }

            form.setFieldsValue({ name: '', showForSponsor: true });
        } catch (error) {
            console.error(error);
            openNotification('error', {
                message: error?.message || 'Something went wrong!',
            });
        } finally {
            setFormLoading(false);
            debouncedFetchData(pagination.current, searchFilter, sortOrder);
        }
    }

    const [deletingLocationId, setDeletingLocationId] = useState(null);
    const onDeleteLocationHandler = async () => {
        try {
            const res = await deleteLocation(projectId, deletingLocationId);
            if (!res.status) throw res;

            openNotification('success', {
                message: 'You have successfully deleted the location',
            });
        } catch (error) {
            console.error(error);
            openNotification('error', {
                message: error?.message || 'Something went wrong!',
            });
        } finally {
            debouncedFetchData(1, searchFilter, sortOrder);
            setDeletingLocationId(null);
        }
    };

    async function fetchData(currentPage, searchFilter, sortOrder) {
        const data = await getLocations(
            projectId,
            currentPage,
            searchFilter,
            sortOrder === 'ascend' ? 'asc' : 'desc'
        );
        setLocations(data.physicalVenues);
        setPagination({
            current: data.pagination.currentPage,
            total: data.pagination.total,
            pageSize: data.pagination.limit,
        });
        setShowEmpty(false);
    }

    const debouncedFetchData = useCallback(debounce(fetchData, 300), []);

    useEffect(() => {
        async function fetchInitialData() {
            const data = await getLocations(projectId, 1, '', 'asc');
            setLocations(data.physicalVenues);
            setPagination({
                current: data.pagination.currentPage,
                total: data.pagination.total,
                pageSize: data.pagination.limit,
            });

            if (!data.pagination.total) {
                setShowEmpty(true);
            }

            setLoading(false);
        }

        fetchInitialData();
    }, []);

    return loading ? (
        <PageSpinner type="Oval" color="#ACBDC9" height={45} width={45} />
    ) : (
        <div className={styles.meetingsLocationContainer}>
            {showEmpty ? (
                <Empty
                    heading="No Locations"
                    subHead1="For meetings with onsite physical locations, you can specify the name of the location where the meeting is supposed to happen."
                    contentMaxWidth="400px"
                    // Sponsors don't have access to create a Location (ref. https://wearegsi.atlassian.net/browse/MYXP-4401)
                    // buttonText="Create Location"
                    // onButtonClick={async () => {
                    //     await openCreateLocationForm();
                    // }}
                    showButton={false}
                />
            ) : (
                <>
                    <div className={styles.meetingsLocationControls}>
                        <Input
                            allowClear
                            placeholder="Search"
                            prefix={<SearchIcon height={16} width={16} />}
                            onChange={(event) => {
                                setSearchFilter(event.target.value);
                                setPagination({ current: 1 });
                                debouncedFetchData(
                                    pagination.current,
                                    event.target.value,
                                    sortOrder
                                );
                            }}
                            onPressEnter={(event) => {
                                setSearchFilter(event.target.value);
                                setPagination({ current: 1 });
                                debouncedFetchData(
                                    pagination.current,
                                    event.target.value,
                                    sortOrder
                                );
                            }}
                            style={{ marginRight: '8px' }}
                        />
                        {/* Sponsors don't have access to create a Location (ref. https://wearegsi.atlassian.net/browse/MYXP-4401) */}
                        {/* <Button
                            className={styles.primButton}
                            type="primary"
                            onClick={async () => {
                                await openCreateLocationForm();
                            }}
                        >
                            Create Location
                        </Button> */}
                    </div>
                    <div>
                        {pagination.total ? (
                            <Table
                                className="agenda-location-table"
                                columns={[
                                    {
                                        title: 'Location Name',
                                        dataIndex: 'name',
                                        defaultSortOrder: sortOrder,
                                        sorter: true,
                                        // to achieve ascend/descend sort only https://github.com/ant-design/ant-design/issues/16747#issuecomment-612047300
                                        sortDirections: [
                                            'ascend',
                                            'descend',
                                            'ascend',
                                        ],
                                        render: (text, record, index) => {
                                            return (
                                                <div
                                                    className={
                                                        styles.meetingsLocationTableRow
                                                    }
                                                >
                                                    <p>{text}</p>
                                                    {/* Sponsors can't edit/delete current locations (ref. https://wearegsi.atlassian.net/browse/MYXP-4401) */}
                                                    {/* <Button
                                                        type="text"
                                                        onClick={() =>
                                                            setDeletingLocationId(
                                                                record?.id
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </Button>
                                                    <Button
                                                        type="text"
                                                        onClick={async () => {
                                                            await openUpdateLocationForm(
                                                                record
                                                            );
                                                        }}
                                                    >
                                                        Edit
                                                    </Button> */}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                dataSource={locations}
                                pagination={pagination}
                                onChange={(pagination, filters, sorter) => {
                                    setSortOrder(sorter.order);
                                    debouncedFetchData(
                                        pagination.current,
                                        searchFilter,
                                        sorter.order
                                    );
                                }}
                            />
                        ) : (
                            <Empty
                                heading={
                                    searchFilter
                                        ? `No locations found for "${searchFilter}"`
                                        : `No locations found`
                                }
                                contentMaxWidth="400px"
                                showButton={false}
                            />
                        )}
                    </div>
                </>
            )}
            {/* Sponsors don't have access to create a Location (ref. https://wearegsi.atlassian.net/browse/MYXP-4401) */}
            {/* <FormContainer
                title={
                    formAction === 'create'
                        ? 'Create Location'
                        : 'Update Location'
                }
                visible={showDrawer}
                setVisible={setShowDrawer}
                onSave={() => {
                    setFormShouldContinue(false);
                    form.submit();
                }}
                onSaveAndCreate={
                    formAction === 'create'
                        ? () => {
                              setFormShouldContinue(true);
                              form.submit();
                          }
                        : null
                }
                onCancel={() => setShowDrawer(false)}
            >
                {formLoading ? (
                    <PageSpinner
                        type="Oval"
                        color="#ACBDC9"
                        height={45}
                        width={45}
                    />
                ) : (
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Show for sponsor"
                            name="showForSponsor"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Form>
                )}
            </FormContainer>
            <Prompt
                show={!!deletingLocationId}
                message="Are you sure you want to delete this location?"
                buttons={['Cancel', 'Proceed to delete']}
                onHide={() => setDeletingLocationId(null)}
                callback={onDeleteLocationHandler}
            /> */}
            <style>{`
                .ant-table thead tr th {
                    padding: 0px !important;

                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 143.87%;
                    /* or 20px */

                    letter-spacing: -0.02em;
                }

                .ant-table tbody tr td {
                    padding: 16px !important;
                }

                input.ant-input {
                    margin-bottom: 0;
                }
                    .agenda-location-table .ant-pagination{
                        margin-right: 30px!important;
                    }
              
            `}</style>
        </div>
    );
};

export default Locations;
