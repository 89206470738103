import React, { useEffect } from 'react';
import './formSlider.scss';

import PropTypes from 'prop-types';

FormSlider.propTypes = {
    width: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    zIndex: PropTypes.number.isRequired,
    showBlanket: PropTypes.bool,
    toggleSideBar: PropTypes.func.isRequired,
};

function FormSlider({
    children,
    width,
    isOpen,
    toggleSideBar,
    zIndex,
    showBlanket,
}) {
    useEffect(() => {
        try {
            if (isOpen)
                document.getElementsByClassName(
                    'intercom-lightweight-app-launcher intercom-launcher'
                )[0].style.display = 'none';
            else
                document.getElementsByClassName(
                    'intercom-lightweight-app-launcher intercom-launcher'
                )[0].style.display = 'block';
        } catch (error) {}

        toggleSideBar(isOpen);
    }, [isOpen]);

    return (
        <>
            {isOpen && showBlanket ? (
                <div
                    onClick={() => toggleSideBar(false)}
                    className="blanket"
                    style={{ zIndex: zIndex }}
                ></div>
            ) : null}
            <div
                style={
                    isOpen
                        ? {
                              right: '0px',
                              width: `${width}`,
                              zIndex: zIndex + 20,
                          }
                        : {
                              right: `-${width}`,
                              width: `${width}`,
                              zIndex: zIndex + 20,
                          }
                }
                className="sideBar"
            >
                <img
                    src="/assets/icon_close.svg"
                    className="closeIcon"
                    alt="Close Modal"
                    onClick={() => {
                        toggleSideBar(false);
                    }}
                />
                {children}
            </div>
        </>
    );
}
export default FormSlider;
