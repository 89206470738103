import * as React from 'react';

const SearchIcon = (props) => {
    return (
        <svg
            width={props.width || 24}
            height={props.height || 10}
            // width={33}
            // height={33}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.648 25.293c5.8 0 10.5-4.701 10.5-10.5s-4.7-10.5-10.5-10.5c-5.799 0-10.5 4.701-10.5 10.5s4.701 10.5 10.5 10.5zM22.073 22.218l6.075 6.075"
                stroke="#7A829D"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SearchIcon;