import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';

let authBearer = null;

const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

export const getLeadsMetabaseToken = async (
    projectId,
    deploymentId,
    dashboardId
) => {
    try {
        setAuthBearer();

        const response = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/analytics/getLeadScanningMetabaseToken?` +
                `${projectId ? `projectId=${projectId}` : ''}` +
                `&base=${deploymentId}` +
                `&dashboard=${dashboardId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
