import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';

import { ApolloProvider } from '@apollo/react-hooks';

import { store, persistor } from './redux/store';

import App from './components/app';

import ErrorBoundary from './components/app/errorBoundary/errorBoundary';

import * as serviceWorker from './serviceWorker';

import TagManager from 'react-gtm-module';

import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import { crowdflow, strapi } from 'Apps/VenueBuilder/helpers/dynamicUri';

const isDevEnvironment =
    process.env.REACT_APP_ENVIRONMENT === 'dev' ||
    process.env.REACT_APP_ENVIRONMENT === 'staging';

if (process.env.REACT_APP_GTM_ID) {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        events: {
            projectCreated: 'Project Created',
            venueSelected: 'Venue Selected',
            livepagePublished: 'Livepage Published',
            firstLivepagePublished: 'First Livepage Published',
            participantAdded: 'Participant Added',
            upgradeStarter: 'Upgrade Starter',
            upgradePro: 'Upgrade Pro',
            cancelAccount: 'Cancel Account',
            signedUpUserEmail: 'Signed Up User Email',
            signupCompleted: 'Signup Completed',
            emailVerified: 'Email Verified',
            experienceOptionSelected: 'Experience Option Selected',
            dashboardSelected: 'Dashboard Selected',
            selectedExperienceDetails: 'Selected Experience Details',
            NewsletterOpted: 'Newsletter Opted',
            NewsletterNotOpted: 'Newsletter Not Opted',
            GoalSelection: 'Goal Selection',
            PlgRegistrationRequest: 'Plg Registration Request',
            ChecklistStatus: 'Checklist Status',
        },
    };

    TagManager.initialize(tagManagerArgs);
}

/**
 * handle multiple clients here
 * source: https://www.loudnoises.us/next-js-two-apollo-clients-two-graphql-data-sources-the-easy-way/
 * uses "strapi" by default, if need to use "crowdflow" client, must specify in `useQuery` context property
 */
let client = new ApolloClient({
    link: ApolloLink.split(
        (operation) => operation.getContext().clientName === 'crowdflow',
        crowdflow,
        strapi
    ),
    cache: new InMemoryCache(),
    connectToDevTools: true,
});

ReactDOM.render(
    <>
        <ApolloProvider client={client}>
            <ErrorBoundary>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <App isDevEnvironment={isDevEnvironment} />
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
        </ApolloProvider>
    </>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
