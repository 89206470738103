import React, { useState, useCallback } from 'react';
import ImageUpload from 'components/app/common/imageUpload/imageUpload';

import SwitchComponent from 'Apps/VenueBuilder/Components/Common/SwitchComponent';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.css';

const SettingsCoverImage = ({
    hasSwitch = true,
    hasReset = true,
    enableBackgroundCoverImage,
    setEnableCoverImage,
    backgroundCoverImage,
    setCoverImage,
    setBGCoverImage,
    tooltipText = 'When turned on, the cover image will appear on the Livepage instead of the content of your block. Upon clicking the cover image, the content of your block will then appear in a popup.',
    headingTitle,
    isRequired,
    isSubmitted,
    backgroundCoverImageDimension,
}) => {
    const [img, setImg] = useState(null);
    const coverImageDimention = {
        width: backgroundCoverImageDimension?.width || null,
        height: backgroundCoverImageDimension?.height || null,
    };

    const onRemoveClick = (remove) => {
        if (remove) {
            setBGCoverImage('');
            setCoverImage('');
            setImg('');
        }
    };

    const renderUploader = useCallback(() => {
        return (
            <div className={`${styles.CTAContainer} coverimage`}>
                <ImageUpload
                    previewImages={
                        backgroundCoverImage ? [backgroundCoverImage] : [img]
                    }
                    prepareFilesUpload={(files) => {
                        setCoverImage(files[0]);
                        setImg(files[0]);
                        if (setBGCoverImage) setBGCoverImage(files[0]);
                    }}
                    handleFileDelete={(file) => {
                        onRemoveClick(true);
                    }}
                    autoUpload={true}
                    resetDefaultImage={
                        'https://files-myxp.gevme.com/default_background/Light_BG_1.png'
                    }
                    imageCropRecommendedSize={coverImageDimention}
                />
            </div>
        );
    }, [backgroundCoverImage, setBGCoverImage, img]);

    return (
        <>
            <div
                className={`${styles.BorderedSwitch} ${
                    hasSwitch ? 'bordered' : ''
                } switch-cover`}
            >
                {hasSwitch ? (
                    <SwitchComponent
                        label="Cover image"
                        tooltipText={tooltipText}
                        switchStatus={enableBackgroundCoverImage}
                        onChange={(e) => setEnableCoverImage(e)}
                        padding={true}
                    >
                        {enableBackgroundCoverImage && renderUploader()}
                    </SwitchComponent>
                ) : (
                    <div>
                        {headingTitle && tooltipText && (
                            <div className={styles.Heading}>
                                <h3>
                                    {headingTitle}{' '}
                                    {isRequired && (
                                        <span className="required">*</span>
                                    )}
                                    {tooltipText && (
                                        <Tooltip
                                            overlayStyle={{
                                                maxWidth: '260px',
                                            }}
                                            placement="right"
                                            title={tooltipText}
                                        >
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    )}
                                </h3>
                            </div>
                        )}
                        {renderUploader()}
                    </div>
                )}
            </div>
            <style jsx={true}>
                {' '}
                {`
                    .switch-cover.bordered {
                        border: 1px solid #dadeeb;
                    }
                    .switch-cover span.required {
                        color: #dc3545 !important;
                    }
                    .switch-cover.bordered .coverimage {
                        border-top: 1px solid #e3e8f7;
                        padding: 10px 0px;
                    }
                    .switch-cover .uploader {
                        margin: 0 !important;
                    }
                    .switch-cover .switch-wrapper {
                        background: #ffffff !important;
                    }
                    .coverimage .change-file-txt {
                        padding: 0;
                        color: #125dff !important;
                    }
                    .coverimage .remove-file-txt {
                        padding-top: 0;
                        padding-left: 8px;
                        padding-right: 8px;
                        color: #125dff !important;
                    }
                    .coverimage .uploader.error {
                        border: 1px solid red;
                        border-radius: 4px;
                    }
                    .switch-cover .switch-wrapper span {
                        color: #000;
                    }
                    .switch-cover .disabled .ant-switch {
                        background: #d4d9e5;
                    }
                `}
            </style>
        </>
    );
};

export default SettingsCoverImage;
