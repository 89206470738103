import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs, notification } from 'antd';
import { useSelector } from 'react-redux';

import Layout from '../../../../common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import moment from 'moment';

import ScheduledTable from './tables/scheduledTable';
import SentTable from './tables/sentTable/sentTable';
import DraftTable from './tables/draftTable/draftTable';
import ScheduleModal from './createAnnouncement/scheduleModal';
import PromptWithInput from 'components/app/common/prompt/promptWithInput';
import Prompt from 'components/app/common/prompt';

import { GetNotifications } from 'apis/rest/notifications/GetNotifications';
import { CancelNotification } from 'apis/rest/notifications/CancelNotification';
import { DeleteNotification } from 'apis/rest/notifications/DeleteNotification';
import { DuplicateNotification } from 'apis/rest/notifications/DuplicateNotification';
import TimezoneSelector from 'components/app/common/timezoneSelector';
import './announcements.scss';

const { TabPane } = Tabs;
const LOAD_MORE = 'load-more';
const REFRESH_DATA = 'refresh-data';

function Announcements() {
    const history = useHistory();
    const { projectId, companyId } = useParams();
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState(null);
    const [notificationTableLastKey, setNotificationTableLastKey] =
        useState('');
    const [notificationTableSearchText, setNotificationTableSearchText] =
        useState('');

    const [notificationSendNow, setNotificationSendNow] = useState(true);
    const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
    const [selectedNotificationData, setSelectedNotificationData] =
        useState(null);
    const [currentTab, setCurrentTab] = useState('queued');

    const [showDuplicatePrompt, setShowDuplicatePrompt] = useState(false);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [showCancelPrompt, setShowCancelPrompt] = useState(false);
    const [duplicateTitle, setDuplicateTitle] = useState('');
    const [duplicateId, setDuplicateId] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [cancelId, setCancelId] = useState('');
    const [scheduleDate, setScheduleDate] = useState(
        moment().tz(timezone).add(10, 'minutes')
    );

    useEffect(() => {
        setScheduleDate(moment().tz(timezone).add(10, 'minutes'));
    }, [timezone]);

    useEffect(() => {
        handleGetNotifications();
    }, [notificationTableSearchText, currentTab]);

    const handleGetNotifications = async (type) => {
        try {
            setIsLoading(true);
            const isTypeRefreshData = type === REFRESH_DATA;
            if (isTypeRefreshData) {
                setNotificationTableLastKey('');
            }
            const response = await GetNotifications(
                projectId,
                currentTab,
                '',
                isTypeRefreshData ? '' : notificationTableLastKey,
                notificationTableSearchText,
                timezone.toLowerCase()
            );
            if (response.status) {
                setNotificationTableLastKey(response?.lastEvaluatedKey);
                setNotifications((prev) => ({
                    ...prev,
                    [currentTab]: {
                        ...(prev?.[currentTab] || {}),
                        ...response,
                        notifications: [
                            ...(type === LOAD_MORE
                                ? prev?.[currentTab]?.notifications || []
                                : []),
                            ...(response?.notifications || []),
                        ],
                    },
                }));
            } else {
                openNotification(
                    'error',
                    'An error',
                    'An error was encountered please try again'
                );
            }
        } catch (error) {
            console.error('ERROR handleGetNotifications', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onClickCancelNotification = async (notificationId) => {
        try {
            const response = await CancelNotification(
                projectId,
                notificationId
            );
            if (response.status) {
                handleGetNotifications();
                openNotification(
                    'success',
                    'Successful!',
                    'Scheduled sending has been cancelled.'
                );
            } else {
                openNotification(
                    'error',
                    'An error',
                    'An error was encountered please try again'
                );
            }
        } catch (error) {
            console.error('ERROR onClickCancelNotification', error);
            openNotification(
                'error',
                'An error',
                'An error was encountered please try again'
            );
        } finally {
            setShowCancelPrompt(false);
        }
    };

    const onClickDeleteNotification = async (notificationId) => {
        try {
            const response = await DeleteNotification(
                projectId,
                notificationId
            );
            if (response.status) {
                handleGetNotifications();
                openNotification(
                    'success',
                    'Successful!',
                    'Notification Deleted!'
                );
            } else {
                openNotification('error', 'An error', response.message);
            }
        } catch (error) {
            console.error('ERROR onClickDeleteNotification', error);
            openNotification(
                'error',
                'An error',
                'An error was encountered please try again'
            );
        } finally {
            setShowDeletePrompt(false);
        }
    };

    const handlePreDuplicate = (notification) => {
        setNotificationTableLastKey('');
        setDuplicateTitle(`${notification.title} Copy`);
        setDuplicateId(notification?.notificationId);
        setShowDuplicatePrompt(true);
    };

    const handlePreDelete = (notificationId) => {
        setNotificationTableLastKey('');
        setDeleteId(notificationId);
        setShowDeletePrompt(true);
    };

    const handlePreCancel = (notificationId) => {
        setNotificationTableLastKey('');
        setCancelId(notificationId);
        setShowCancelPrompt(true);
    };

    const onClickDuplicateNotification = async (notificationId, newTitle) => {
        try {
            const response = await DuplicateNotification(
                newTitle,
                projectId,
                notificationId
            );
            if (response.status) {
                handleGetNotifications();
                openNotification(
                    'success',
                    'Successful!',
                    'Notification duplicated!'
                );
            }
        } catch (error) {
            console.error('ERROR onClickDuplicateNotification', error);
            openNotification(
                'error',
                'An error',
                'An error was encountered please try again'
            );
        } finally {
            setShowDuplicatePrompt(false);
        }
    };

    // Close prompt for delete and duplicate
    const handleClosePrompt = (type) => {
        if (type === 'delete') {
            setShowDeletePrompt(false);
        }
        if (type === 'duplicate') {
            setShowDuplicatePrompt(false);
        }
        if (type === 'cancel') {
            setShowCancelPrompt(false);
        }
    };

    const openNotification = (
        type = 'success',
        alertTitle = '',
        alertMessage = ''
    ) => {
        notification[type]({
            message: alertTitle,
            description: alertMessage,
            placement: 'bottomLeft',
        });
    };

    return (
        <Layout disableSubMenuWidth={true}>
            <div className="container">
                <PromptWithInput
                    show={showDuplicatePrompt}
                    message={`Are you sure you want to duplicate this Notification?`}
                    description="Name your duplicated Notification"
                    dataID={duplicateId}
                    inputField={'title'}
                    inputValue={duplicateTitle}
                    buttons={['Cancel', 'Proceed to Duplicate']}
                    onHide={() => handleClosePrompt('duplicate')}
                    callback={onClickDuplicateNotification}
                />
                <Prompt
                    show={showDeletePrompt}
                    message={`Are you sure you want to delete this notification?`}
                    buttons={['Cancel', 'Proceed to Delete']}
                    onHide={() => handleClosePrompt('delete')}
                    callback={() => onClickDeleteNotification(deleteId)}
                />
                <Prompt
                    show={showCancelPrompt}
                    message={`Are you sure you want to cancel the schedule of this announcement?`}
                    buttons={['Cancel', 'Proceed to Cancel']}
                    onHide={() => handleClosePrompt('cancel')}
                    callback={() => onClickCancelNotification(cancelId)}
                />
                <div className="row">
                    <div className="col-6">
                        <h1 className="my-3">Announcements</h1>
                    </div>
                    <div className="col-6">
                        <button
                            className="myxp btn btn-primary ml-auto my-3"
                            onClick={() =>
                                history.push(
                                    `/company/${companyId}/project/${projectId}/notification/create-announcement`
                                )
                            }
                        >
                            Create announcement
                        </button>
                    </div>

                    <div className="col-12">
                        <TimezoneSelector
                            selectStyle={{ width: 150, zIndex: 10, right: 20 }}
                        />

                        <Tabs
                            className="bg-white py-3 border border-radius sponsors-tabs"
                            defaultActiveKey={currentTab}
                            activeKey={currentTab}
                            onChange={(tabKey) => {
                                if (tabKey === currentTab) return;
                                setNotificationTableLastKey('');
                                setNotifications((prev) => ({
                                    ...prev,
                                    [currentTab]: {},
                                }));
                                setCurrentTab(tabKey);
                            }}
                        >
                            <TabPane tab={'Scheduled'} key="queued">
                                <ScheduledTable
                                    data={notifications?.queued}
                                    loading={isLoading}
                                    search={(searchText) => {
                                        setNotificationTableLastKey('');
                                        setNotificationTableSearchText(
                                            searchText
                                        );
                                    }}
                                    onClickCancelNotification={handlePreCancel}
                                    onClickDeleteNotification={handlePreDelete}
                                />
                            </TabPane>
                            <TabPane tab={'Sent'} key="published">
                                <SentTable
                                    data={notifications?.published}
                                    loading={isLoading}
                                    search={(searchText) => {
                                        setNotificationTableLastKey('');
                                        setNotificationTableSearchText(
                                            searchText
                                        );
                                    }}
                                    onClickDuplicateNotification={
                                        handlePreDuplicate
                                    }
                                    onClickDeleteNotification={handlePreDelete}
                                />
                            </TabPane>
                            <TabPane tab={'Drafts'} key="created">
                                <DraftTable
                                    data={notifications?.created}
                                    loading={isLoading}
                                    search={(searchText) => {
                                        setNotificationTableLastKey('');
                                        setNotificationTableSearchText(
                                            searchText
                                        );
                                    }}
                                    onClickDuplicateNotification={
                                        handlePreDuplicate
                                    }
                                    onClickDeleteNotification={handlePreDelete}
                                    sendNotification={(type, item) => {
                                        setIsScheduleModalVisible(true);
                                        setNotificationSendNow(
                                            type === 'sendNow' ? true : false
                                        );
                                        setSelectedNotificationData(item);
                                    }}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                {notificationTableLastKey !== '' ? (
                    <div className="row my-2">
                        <div className="col-12">
                            <button
                                className="myxp btn btn-primary m-auto"
                                onClick={() =>
                                    handleGetNotifications(LOAD_MORE)
                                }
                            >
                                Load more
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
            <ScheduleModal
                {...{
                    scheduleDate,
                    setScheduleDate,
                    sendMode: notificationSendNow ? 'sendNow' : 'schedule',
                }}
                notificationSendNow={notificationSendNow}
                visible={isScheduleModalVisible}
                setVisible={setIsScheduleModalVisible}
                notificatonData={selectedNotificationData}
                onRefreshData={() => handleGetNotifications(REFRESH_DATA)}
                notificationEditMode={true}
                sendDirect={true}
            />
            <SidebarContainer>
                <MainMenu priActive="pushengagement" all />
            </SidebarContainer>
        </Layout>
    );
}

Announcements.propTypes = {};

export default Announcements;
