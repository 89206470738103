import * as ls from 'local-storage';

export const getExperencies = async () => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/experiences/selection`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
};

export const chooseExperience = async (projectId, data) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;

    if (authBearer) {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/projects/${projectId}/associate-sync`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
                body: JSON.stringify(data),
            }
        );

        return await response.json();
    }
};

export const submitSuggestion = async (payload) => {
    const auth = ls.get('auth');

    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/experiences/suggestion`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(payload),
        }
    );
    return await response.json();
};
