import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import BlockViewConfiguration from '../BlockViewConfiguration';
import MarginCopyIcon from 'Apps/VenueBuilder/Icons/MarginCopyIcon';
import MarginLinkedIcon from 'Apps/VenueBuilder/Icons/MarginLinkedIcon';
import styles from './index.module.css';

const SettingsMargin = ({
    property,
    headingTitle,
    value,
    setValue,
    getValues,
    name,
    error,
    noPadding,
    displayMode,
    link,
    setLink,
    linkedSettings,
    setLinkedSettings,
}) => {
    const displayModeRedux = useSelector((state) => state?.header.display);
    const [toggleCopySettings, setToggleCopySettings] = useState(link);

    if (!value || typeof value === 'string') {
        value = ['0', '0', '0', '0', 'px'];
    }
    // const [lastIndex,setLastIndex] = useState([]);
    const [lastValue, setLastValue] = useState('0');

    const [topValue, setTopValue] = useState(
        typeof value !== 'string' ? value[0] : '0'
    );
    const [rightValue, setRightValue] = useState(
        typeof value !== 'string' ? value[1] : '0'
    );
    const [bottomValue, setBottomValue] = useState(
        typeof value !== 'string' ? value[2] : '0'
    );
    const [leftValue, setLeftValue] = useState(
        typeof value !== 'string' ? value[3] : '0'
    );

    // const origValue = value;


    const onChange = (e) => {
        const data = e.target;
        const newMargin = [...value];

        setLastValue(data.value);
        if (data.name === '0') setTopValue(data.value);
        if (data.name === '1') setRightValue(data.value);
        if (data.name === '2') setBottomValue(data.value);
        if (data.name === '3') setLeftValue(data.value);

        newMargin[data.name] = data.value;
        if (newMargin[4] === '%' && data.value > 100) {
            // setValue(name,newMargin);
        } else {
            setValue(name, newMargin);
        }
    };

    const onChangeSelection = (e) => {
        const marginPixel = [...value];
        marginPixel[4] = e;
        setValue(name, marginPixel);
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={() => onChangeSelection('px')}>px</Menu.Item>
            <Menu.Item onClick={() => onChangeSelection('rem')}>rem</Menu.Item>
            <Menu.Item onClick={() => onChangeSelection('em')}>em</Menu.Item>
            <Menu.Item onClick={() => onChangeSelection('%')}>%</Menu.Item>
            <Menu.Item onClick={() => onChangeSelection('')}>No unit</Menu.Item>
        </Menu>
    );

    const copyValue = (val, copy) => {
        setToggleCopySettings(!copy);

        // if true copied the first margin value to all
        if (!copy) {
            const newValue = value?.map((margin, index) => {
                if (index !== 4) {
                    return (margin = lastValue);
                } else {
                    return (margin = value[4]);
                }
            });
            const saveValue = [...newValue];
            setValue(name, saveValue);
            setLink(true);
        } else {
            const unLinkValue = [...value];
            unLinkValue[0] = topValue;
            unLinkValue[1] = rightValue;
            unLinkValue[2] = bottomValue;
            unLinkValue[3] = leftValue;

            setValue(name, unLinkValue);
            setLink(false);
        }
    };

    const labels = {
        0: 'Top',
        1: 'Right',
        2: 'Bottom',
        3: 'Left',
    };

    const displayStyle = displayMode
        ? {
              display:
                  (displayMode === 'desktop' && displayModeRedux.desktop) ||
                  (displayMode === 'tablet' && displayModeRedux.tablet) ||
                  (displayMode === 'mobile' && displayModeRedux.mobile)
                      ? 'block'
                      : 'none',
          }
        : { display: 'block' };

    return (
        <div
            style={
                noPadding
                    ? { padding: '0px', ...displayStyle }
                    : { ...displayStyle }
            }
            className={`${styles.SettingsHeightContainer} container settings-margin`}
        >
            {headingTitle && (
                <div className={styles.Heading}>
                    <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center">
                        <h3>{headingTitle}</h3>
                        {displayMode && (
                            <BlockViewConfiguration
                                property={property}
                                setValue={setValue}
                                getValues={getValues}
                                linkedSettings={linkedSettings}
                                setLinkedSettings={setLinkedSettings}
                            >
                                <span
                                    onClick={() =>
                                        copyValue(value, toggleCopySettings)
                                    }
                                >
                                    {toggleCopySettings ? (
                                        <MarginLinkedIcon />
                                    ) : (
                                        <MarginCopyIcon />
                                    )}
                                </span>
                            </BlockViewConfiguration>
                        )}
                    </div>
                </div>
            )}
            <div className={`input-pixels ${error ? styles.InputError : ''}`}>
                {value?.length > 0 &&
                    value?.map((item, index) => {
                        if (value?.length > 4 && value?.length === index + 1) {
                            return (
                                <Dropdown
                                    trigger={['click']}
                                    className={styles.PixelDropdown}
                                    overlay={menu}
                                    placement="bottomRight"
                                >
                                    <Button>
                                        {item}
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                            );
                        } else {
                            return (
                                <div className="input-margin">
                                    <Input
                                        key={`MarginSettings_${index}`}
                                        type={!value[4] ? 'text' : 'number'}
                                        value={item}
                                        name={index}
                                        onChange={onChange}
                                    />
                                    <span className={styles.MarginLabel}>
                                        {labels[index]}
                                    </span>
                                </div>
                            );
                        }
                    })}

                {value?.length === 4 && (
                    <Dropdown
                        trigger={['click']}
                        className={styles.PixelDropdown}
                        overlay={menu}
                        placement="bottomRight"
                    >
                        <Button>
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                )}
            </div>
            {error && <p className={styles.ErrorMessage}>{error.type}</p>}
            <style jsx="true">{`
                .ant-input-affix-wrapper > input.ant-input {
                    margin-bottom: 0px;
                }
                .ant-input-group .ant-input {
                    padding: 8px;
                }
                .ant-checkbox + span {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 17px;
                    letter-spacing: -0.02em;
                    color: #000000;
                }
                .ant-input-affix-wrapper {
                    border-radius: 4px;
                }
                /* Chrome, Safari, Edge, Opera */
                .input-pixels input::-webkit-outer-spin-button,
                .input-pixels input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                .input-pixels input[type='number'] {
                    -moz-appearance: textfield;
                }
                .input-pixels input[type='text'] {
                    margin-bottom: 0px;
                }
                .input-pixels .select-after {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 17px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.02em;
                    color: #000000;
                }
                .settings-margin .input-pixels {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .settings-margin .input-pixels input {
                    width: 45px;
                }
                .settings-margin .input-pixels button .anticon {
                    margin-left: 5px;
                }
                .settings-margin .input-margin {
                    display: flex;
                    flex-direction: column;
                }
            `}</style>
        </div>
    );
};

export default SettingsMargin;
