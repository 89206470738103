import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import produce from 'immer';
import { isEmpty } from 'lodash';
import moment from 'moment';
import FileSaver from 'file-saver';
import { isBrowser, isMobile } from 'react-device-detect';

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { textRemover } from 'components/app/utils/functions/common';
import { fixedColumns } from 'components/app/utils/dataList/fixedReportsColumns';

import { getSettings } from 'apis/rest/GetSettings';
import { getReports } from 'apis/rest/GetReports';
import { exportReport } from 'apis/rest/ExportReport';

import Layout from 'components/app/common/layout';
import GeneralPreloader from 'components/app/common/preloader/generalPreloader';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './reports.scss';

function Reports(props) {
    const { project } = props;

    const [data, setData] = useState({});
    const [settings, setSettings] = useState({});
    const [columns, setColumns] = useState(fixedColumns);
    const [isBusy, setIsBusy] = useState(true);
    const [filters, setFilters] = useState({});
    const [sortField, setSortField] = useState('firstname');
    const [sortOrder, setSortOrder] = useState('ASC');

    useEffect(() => {
        handleGetReports();
    }, [project]);

    useEffect(() => {
        handleGetSettings();
    }, []);

    useEffect(() => {
        /**
         * extracts profile columns from settings and appends them to the fixed columns
         */
        const appendProfileColumns = () => {
            let columnsArr = [];
            for (let key in settings) {
                const column = {
                    dataField: `profile.${key}`,
                    text: settings[key],
                    sort: true,
                    filter: textFilter(),
                };

                columnsArr.push(column);
            }

            setColumns((columns) => columnsArr.concat(columns));
        };

        appendProfileColumns();
    }, [settings]);

    /**
     * modifies filter format coming from bootstrap table to be used as param to the `GetReports` endpoint
     * @param {object} filters object containing the filters params
     */
    const modifyFilterFormat = (filters) => {
        let finalFilterFormat = {};

        for (let key in filters) {
            let filterKey = textRemover(key, 'profile.');

            finalFilterFormat[filterKey] = {
                value: filters[key].filterVal,
            };
        }

        return finalFilterFormat;
    };

    /**
     * triggered when there's any change to the bootstrap table
     * @param {string} type type of input (not in use for now)
     * @param {object} changes changes applied to the bootstrap table
     */
    const handleTableChange = (
        type,
        { page, sizePerPage, filters, sortField, sortOrder }
    ) => {
        const finalFilterFormat = modifyFilterFormat(filters);

        /**
         * need to assign to state because export functionality need to
         * know these values also and it won't go through this function
         */
        setFilters(finalFilterFormat);
        setSortField(sortField);
        setSortOrder(sortOrder);

        /**
         * still need to pass here directly due to the async-like nature of set state
         */
        handleGetReports(
            page,
            sizePerPage,
            finalFilterFormat,
            sortField,
            sortOrder
        );
    };

    /**
     * gets the settings, mainly used to know the profile column labels
     */
    const handleGetSettings = async () => {
        setIsBusy(true);
        const response = await getSettings();

        setSettings(response.setting.labels);

        handleGetReports();
    };

    /**
     * modifies some data format coming from API to be used for rendering table data
     * `createdAt` and `capturedBy` needs further modification before rendering
     * @param {object} dataToBeProcessed object containing the original data
     */
    const modifyDataFormat = (dataToBeProcessed) => {
        let nextState = produce(dataToBeProcessed, (draftState) => {
            draftState.leads.forEach((lead) => {
                let capturedBy = '';

                if (lead.capturedBy) {
                    if (lead.capturedBy.firstname) {
                        capturedBy = `${lead.capturedBy.firstname} ${lead.capturedBy.lastname}`;
                    } else {
                        capturedBy =
                            lead.capturedBy.email || lead.capturedBy.phoneNo;
                    }
                }

                lead.createdAt = moment(lead.createdAt).format('DD MMM YYYY');
                lead.capturedBy = capturedBy;
            });
        });

        return nextState;
    };

    /**
     * handles post request for getting reports
     * @param {number} page page number to query
     * @param {number} sizePerPage size per page to use
     * @param {object} filters filters to use
     * @param {string} sortField sorted by which field
     * @param {string} sortOrder sorted by which order (ASC / DESC)
     */
    const handleGetReports = async (
        page,
        sizePerPage,
        filters,
        sortField,
        sortOrder
    ) => {
        let payload = {};

        if (filters || sortField !== '') {
            const sortFieldFinal =
                sortField && textRemover(sortField, 'profile.');

            payload = {
                filter: filters,
                order: { [sortFieldFinal]: sortOrder },
                page,
                sizePerPage,
            };
        }

        const response = await getReports(payload, project);

        const data = modifyDataFormat(response);

        setData(data);
        setIsBusy(false);
    };

    /**
     * handles exporting of report
     */
    const handleExportReport = async () => {
        let payload = {};

        if (filters || sortField !== '') {
            const sortFieldFinal =
                sortField && textRemover(sortField, 'profile.');

            payload = {
                filter: filters,
                order: { [sortFieldFinal]: sortOrder },
            };
        }

        const response = await exportReport(payload);

        FileSaver.saveAs(response, 'leads-report.csv');
    };

    /**
     * renders general stats depending on device type
     */
    const renderGeneralStats = () => {
        if (isMobile) {
            return (
                <div className="reports__stats--mobile">
                    <p>
                        <strong>Leads:</strong>{' '}
                        {!isEmpty(data) ? aggregation.total : '...'}
                    </p>

                    <p>
                        <strong>Unique Leads:</strong>{' '}
                        {!isEmpty(data) ? aggregation.uniqueTotal : '...'}
                    </p>

                    <p>
                        <strong>Leads from Leads Scan:</strong>{' '}
                        {!isEmpty(data) ? aggregation.leadsScanTotal : '...'}
                    </p>

                    <p>
                        <strong>Leads from Showcase:</strong>{' '}
                        {!isEmpty(data) ? aggregation.showcaseTotal : '...'}
                    </p>

                    <p>
                        <strong>Hot Leads:</strong>{' '}
                        {!isEmpty(data) ? aggregation.hotLeadsTotal : '...'}
                    </p>
                </div>
            );
        }

        if (isBrowser) {
            return (
                <div className="reports__stats">
                    <div className="reports__stats-item">
                        <h5>Total Leads</h5>
                        <p>{!isEmpty(data) ? aggregation.total : '...'}</p>
                    </div>
                    <div className="reports__stats-item">
                        <h5>Total Unique Leads</h5>
                        <p>
                            {!isEmpty(data) ? aggregation.uniqueTotal : '...'}
                        </p>
                    </div>
                    <div className="reports__stats-item">
                        <h5>Total Leads from Leads Scan</h5>
                        <p>
                            {!isEmpty(data)
                                ? aggregation.leadsScanTotal
                                : '...'}
                        </p>
                    </div>
                    <div className="reports__stats-item">
                        <h5>Total Leads from Showcase</h5>
                        <p>
                            {!isEmpty(data) ? aggregation.showcaseTotal : '...'}
                        </p>
                    </div>
                    <div className="reports__stats-item">
                        <h5>Total Hot Leads</h5>
                        <p>
                            {!isEmpty(data) ? aggregation.hotLeadsTotal : '...'}
                        </p>
                    </div>
                </div>
            );
        }
    };

    const { aggregation, leads } = data;

    return (
        <Layout>
            <div className="reports">
                {renderGeneralStats()}

                <div className="reports__data-table-wrap">
                    {!isBusy && !isEmpty(data) && data.aggregation.total === 0 && (
                        <div className="panel-empty">
                            <h2>No reports to show</h2>

                            <p>
                                Get started by inviting your leads scan app
                                users.
                            </p>
                        </div>
                    )}

                    {isBusy && <GeneralPreloader />}

                    {!isBusy && !isEmpty(data) && data.aggregation.total > 0 && (
                        <>
                            <div className="text-right mb-3">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleExportReport()}
                                >
                                    <img
                                        src="/assets/icon_download.svg"
                                        alt=""
                                    />{' '}
                                    Download
                                </button>
                            </div>

                            <BootstrapTable
                                keyField="id"
                                data={leads}
                                columns={columns}
                                remote
                                filter={filterFactory()}
                                pagination={paginationFactory({
                                    page: parseInt(data.pagination.currentPage),
                                    sizePerPage: data.pagination.limit,
                                    totalSize: data.pagination.total,
                                })}
                                onTableChange={handleTableChange}
                                striped
                                hover
                                condensed
                                classes="reports__data-table"
                                wrapperClasses="table-responsive"
                            />
                        </>
                    )}
                </div>
            </div>
        </Layout>
    );
}

function mapStateToProps(state) {
    return {
        project: state.dashboardManager.project,
    };
}

export default withRouter(connect(mapStateToProps)(Reports));
