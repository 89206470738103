import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import OTPInput from 'otp-input-react';

import * as ls from 'local-storage';
import TagManager from 'react-gtm-module';

import useAlert from 'components/app/utils/hooks/useAlert';

import { maskEmail } from 'components/app/utils/functions/common';

import { changeProject } from 'redux/actions';
import * as act from 'redux/actions/common/userActions';

import { authenticate, generateOtp } from 'apis/rest/Authentication';
import { getUser } from 'apis/rest/User';

import Alert from 'components/app/common/alert';

import './otp.scss';
import { loginWithAzure } from 'apis/rest/AzureIntegrations';

import styles from '../index.module.scss';
import ArrowBackIcon from './ArrowBackIcon';
import CountDownTimer from '../../../../../../src/ComponentsV2/ProjectDashboard/Components/Common/UpgradeMessage/CountDown/CountDownTimer';
import isEmail from 'validator/lib/isEmail';
import { Tooltip } from 'antd';
const qs = require('qs');
const base64 = require('base-64');

function Otp(props) {
    const [loading, setLoading] = useState(true);
    let {
        location: { state: value, callbackUrl, identifier },
        history,
        changeProject,
    } = props;

    let email = null;
    let mobileNumber = null;
    if (isEmail(value)) {
        email = value;
    } else {
        mobileNumber = value;
    }
    const user = useSelector((state) => state.user);

    const { code, state } =
        props.location.hash && props.location.hash.includes('#code')
            ? props.location.hash
                  .replace('#code', 'code')
                  .split('&')
                  .map((v) => v.split('='))
                  .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {})
            : props.location.hash
                  .split('&')
                  .map((v) => v.split('='))
                  .reduce(
                      (pre, [key, value]) => ({ ...pre, [key]: value }),
                      {}
                  );

    const dispatch = useDispatch();
    let queryString = '';
    let emailToken = '';
    let emailParam = email;
    let emailToUse = emailParam;
    let mobileParam = mobileNumber;
    let mobileToUse = mobileParam;

    if (props.location.search !== '') {
        queryString = qs.parse(props.location.search, {
            ignoreQueryPrefix: true,
        });

        emailToken = base64.decode(queryString.token);

        if (email) {
            emailToUse = emailToken;
        } else {
            mobileToUse = emailToken;
        }
    }
    const auth = ls('auth');
    const require2FASetup = ls('require2FASetup');
    const [otp, setOtp] = useState('');
    const [inputDisabled, setInputDisabled] = useState(false);
    const [isOtpExpired, setIsOtpExpired] = useState(false);
    const [resetCounter, setResetCounter] = useState(false);
    const [identifierState, setIdentifierState] = useState(identifier);
    const { alert, showAlert } = useAlert();

    const loginWithAzureFlow = async () => {
        const { status, token } = await loginWithAzure(code, state);
        if (status) {
            const callback = ls.get('gevme-callback-url');
            ls('auth', token);
            await handleGetUser();
            ls.remove('gevme-callback-url');
            history.push(callback || `/project/${state}`);
        }
    };

    useEffect(() => {
        if (auth) {
            if (require2FASetup) {
                history.push({
                    pathname: '/2fa-setup',
                    email,
                });
            } else {
                checkUserRole();
            }
        } else if (code && state) {
            loginWithAzureFlow();
        } else {
            if (queryString.token && queryString.token !== '') {
                reGenerateOtp();
            }
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        /**
         * handles submission of OTP once all digits have been entered
         */
        const submitOtpAndLogin = async () => {
            const data = {
                code: otp,
                identifier: identifierState,
            };

            const response = await authenticate(data);

            setInputDisabled(false);

            if (response.status) {
                if (response?.['2FAToken']) {
                    ls('2FAToken', response?.['2FAToken']);
                    return history.push({
                        pathname: '/2fa',
                        payload: email ? email : mobileNumber,
                        identifier: identifierState,
                    });
                }

                ls('auth', response.token);

                if (response?.firstTimeVerification) {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'emailVerified',
                            email: emailToUse,
                        },
                    });
                }

                if (response?.require2FASetup) {
                    ls('require2FASetup', true);
                    return history.push({
                        pathname: '/2fa-setup',
                        payload: email ? email : mobileNumber,
                        identifier: identifierState,
                    });
                }

                await handleGetUser();

                await checkUserRole();

                if (callbackUrl) {
                    history.push(callbackUrl);
                }
                //  else {
                //     history.push('/');
                // }
            } else {
                showAlert(response.message, 'error');
            }
            setLoading(false);
        };

        if (otp.length === 6) {
            setInputDisabled(true);
            submitOtpAndLogin();
        }
    }, [otp]);

    /**
     * submits email to generate magic link
     * @param {object} e event object
     */
    const reGenerateOtp = async (e) => {
        const data = {
            payload: email ? { email: emailToUse } : { phoneNo: mobileToUse },
            type: email ? 'email' : 'phone',
        };

        const response = await generateOtp(data.payload, data.type);

        determineFlowUponResponse(response);
    };

    /**
     * determines the flow after receiving "submit" response
     * @param {object} response response from `generateOtp()`
     */

    const determineFlowUponResponse = async (response) => {
        const responseJson = await response.json();

        if (responseJson.status) {
            setIdentifierState(responseJson?.identifier);
            showAlert(responseJson.message, 'success');
            setResetCounter(!resetCounter);
            setInputDisabled(false);
            setIsOtpExpired(false);
        } else {
            if (response.status === 403) {
                history.push('/no-access');
            } else {
                showAlert(responseJson.message, 'error');
            }
        }
    };

    /**
     * gets latest user object
     */
    const handleGetUser = async () => {
        const user = await getUser();
        const project = user.user.projects[0];

        dispatch(act.setUser(user));

        const {
            firstname,
            lastname,
            email,
            company,
            id,
            companyId,
            position,
            role,
            isVerified,
        } = user?.user;

        const userDetails = {
            email,
            firstname,
            lastname,
            company,
            id,
            companyId,
            position,
            isVerified,
            roleName: role?.name,
        };

        ls('userDetails', userDetails);
        ls('project', project);

        changeProject(project);
    };

    /**
     * handles changes to input
     * @param {object} e event object
     */
    const handleInputChange = (e) => {
        try {
            setOtp(e);
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * check user role
     */
    const checkUserRole = () => {
        if (user.roleId === 4) {
            //FIXME: check here
            history.push('/sponsor');
            // if (user.isOnboarded) {
            //     const getProject = ls.get('tempProjId');
            //     history.push(`/project/${getProject}/live/pages`);
            // } else {
            //     history.push('/project/sponsors');
            // }
        } else if (user.roleId === 1) {
            setTimeout(() => {
                history.push('/admin/dashboard');
            }, 500);
        } else {
            const customProjectUrl = ls.get('customProjectUrl');
            if (customProjectUrl) {
                window.location.href = `${customProjectUrl}`;
                ls.remove('customProjectUrl');
            } else {
                window.location.href = '/';
            }
            //  history.push('/',)
        }
    };
    const inputStyles = {
        fontSize: '2.2em',
        fontWeight: 'bold',
        height: 'auto ',
        maxWidth: '60px ',
        maxHeight: '90px ',
        marginRight: '10px',
        borderRadius: '4px',
        border: '1px solid #D0D2DD',
        padding: '14px 7px',
    };

    return (
        <>
            <Alert alert={alert}></Alert>
            {!loading && (
                <div className={`${styles.login} otp`}>
                    <div className={styles.wrapper}>
                        <div className={styles.innerWrapper}>
                            <div className={`${styles.otpBox} otp__box`}>
                                <div className="tw-w-full tw-flex tw-justify-between tw-items-center tw-mb-4 ">
                                    <div
                                        className=" tw-flex tw-gap-1 tw-items-center"
                                        onClick={() => history.push('/login')}
                                    >
                                        <ArrowBackIcon
                                            classData={'tw-cursor-pointer '}
                                        />
                                        <span className="tw-text-black tw-font-open-sans tw-text-2xl tw-font-bold tw-leading-normal tw-tracking-[-0.36px] tw-text-balance ">
                                            Enter 6 digit OTP code
                                        </span>
                                    </div>
                                    <Tooltip title="OTP will expire after 15 minutes.">
                                        <span className="tw-text-gray-500 tw-font-open-sans tw-text-sm tw-font-normal tw-leading-normal">
                                            <CountDownTimer
                                                timeLimit={
                                                    process.env
                                                        .REACT_APP_LOGIN_TIME_LIMIT
                                                }
                                                onEnd={() => {
                                                    setIsOtpExpired(true);
                                                    setInputDisabled(true);
                                                }}
                                                reset={resetCounter}
                                            />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div className="tw-px-1">
                                    <p className="  tw-text-gray-500 tw-font-open-sans tw-text-sm tw-font-normal tw-leading-normal tw-text-[#595959] tw-text-balance tw-m-0  ">
                                        We have just sent an OTP to.
                                    </p>
                                    <p className="  tw-text-gray-500 tw-font-open-sans tw-text-sm tw-font-normal tw-leading-normal tw-text-[#595959] tw-text-balance ">
                                        <strong className="tw-text-[#595959]">
                                            {email
                                                ? emailToUse
                                                : mobileToUse || ''}
                                        </strong>
                                    </p>

                                    <p className="  tw-text-gray-500 tw-font-open-sans tw-text-sm tw-font-normal tw-leading-normal tw-text-[#595959] tw-m-0">
                                        If your {email ? 'email' : 'phone'} is
                                        registered with our platform, you should
                                        receive the OTP shortly. Please check
                                        your {email ? 'email' : 'phone'} inbox
                                        to find the OTP.
                                    </p>
                                </div>

                                <OTPInput
                                    inputStyles={inputStyles}
                                    value={otp}
                                    onChange={setOtp}
                                    autoFocus
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={inputDisabled}
                                    className={'otp-box'}
                                />
                                <p
                                    className={` ${
                                        !isOtpExpired
                                            ? 'tw-text-blue-500'
                                            : 'tw-text-rose-700'
                                    } tw-text-center tw-font-open-sans tw-text-sm tw-font-semibold tw-leading-[21px] tw-cursor-pointer tw-mt-3 tw-mb-2`}
                                    onClick={() => {
                                        reGenerateOtp();
                                    }}
                                >
                                    {isOtpExpired
                                        ? 'Your OTP is expired. Click here to Resend.'
                                        : 'Did not receive an OTP? Resend'}
                                </p>
                                <div className="mt-4">
                                    <p className="foot-note">
                                        By logging in you agree to the{' '}
                                        <a
                                            href="https://www.gevme.com/en/terms-of-use/"
                                            target="_blank"
                                            className=""
                                        >
                                            Terms of Use
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href="https://www.gevme.com/en/privacy-policy/"
                                            target="_blank"
                                        >
                                            Privacy Policy
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <style>{`
            .tw-text-balance{
                text-wrap: balance;
            }
            `}</style>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeProject: (selectedProject) =>
            dispatch(changeProject(selectedProject)),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(Otp));
