import * as ls from "local-storage";
import { store } from "redux/store";

/**
 * exports the report into a CSV file
 */
export async function exportReport(data) {
  const auth = ls.get("auth");
  const user = store?.getState()?.user
  const project = ls.get("project");

  if (auth && user) {
    const authBearer = `Bearer ${auth.access_token}`;
    const projectId = project.projectId;

    const response = await fetch(
      `${process.env.REACT_APP_MYXP_API}/manager/reports/${projectId}/export`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authBearer
        },
        body: JSON.stringify(data)
      }
    );

    return await response.blob();
  }
}
