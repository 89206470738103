import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs } from 'antd';

import Layout from '../../../../common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import Prompt from 'components/app/common/prompt';

import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';

import TimezoneSelector from 'components/app/common/timezoneSelector';
import CreatedTable from './tables/createdTable';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import orderBy from 'lodash/orderBy';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

import { GetProducts } from 'apis/rest/products/GetProducts';
import { DeleteProduct } from 'apis/rest/products/DeleteProduct';

import './products.scss';
import PreviewModal from './previewModal';

const { TabPane } = Tabs;

function Products() {
    const history = useHistory();
    const { projectId, companyId } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [productsData, setProductsData] = useState(null);
    const [tableCurrentPage, setTableCurrentPage] = useState(1);
    const [tableSearchText, setTableSearchText] = useState('');
    const [currentTab, setCurrentTab] = useState('publish');

    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
    const [previewModalData, setPreviewModalData] = useState(null);

    const [deleteItemId, setDeleteItemId] = useState('');

    useEffect(() => {
        handleGetProducts();
    }, [currentTab]);

    const handleGetProducts = async (page = 1, searchTerm = '') => {
        setIsLoading(true);
        try {
            const response = await GetProducts(
                projectId,
                currentTab,
                '',
                page,
                searchTerm
            );
            if (response.status) {
                if (isEmpty(response.products)) {
                    if (response.pagination.currentPage > 1) {
                        setTableCurrentPage(1);
                    }
                }
                setProductsData(response);
            } else {
                openNotification('error', { message: response?.message });
            }
        } catch (error) {
            console.error('ERROR handleGetProducts', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onClickDeleteProduct = async (productId) => {
        try {
            const response = await DeleteProduct(projectId, productId);
            if (response.status) {
                handleGetProducts(tableCurrentPage, tableSearchText);
                openNotification('success', {
                    message: 'product has been deleted.',
                });
            } else {
                openNotification('error', {
                    message: 'An error was encountered please try again',
                });
            }
        } catch (error) {
            openNotification('error', {
                message: 'An error was encountered please try again',
            });
            console.error('ERROR onClickDeleteProduct', error);
        } finally {
            setShowDeletePrompt(false);
            setDeleteItemId('');
        }
    };

    const handlePreDelete = (item) => {
        setShowDeletePrompt(true);
        setDeleteItemId(item.id);
    };

    // Close prompt for delete and duplicate
    const handleClosePrompt = (type) => {
        if (type === 'delete') {
            setShowDeletePrompt(false);
            setDeleteItemId('');
        }
    };

    // let isEmptyData = sum([productsData?.pagination?.totalDraft, productsData?.pagination?.totalPublished])

    return (
        <Layout disableSubMenuWidth={true}>
            <div className="container">
                <div className="row">
                    {/* {!isLoading && isEmptyData === 0 && (
                        <div className="col-12">
                            <div className="bg-white border border-radius d-flex flex-column justify-content-center align-items-center my-3 empty-notification-table ">
                                <div className="text-center">
                                    <h2 className="text-body">
                                        No Product Created
                                    </h2>
                                    <p className="text-body">
                                        Create product here then add them to your livepages through the product blocks.
                                    </p>
                                    <button
                                        className="myxp btn btn-primary mx-auto my-2"
                                        onClick={() => history.push(`/company/${companyId}/project/${projectId}/products/create-product`)}
                                    >
                                        Create Product
                                    </button>
                                </div>
                            </div>
                        </div>
                    )} */}
                    <div className="col-6">
                        <h1 className="my-3">Products</h1>
                    </div>
                    <div className="col-6">
                        <button
                            className="myxp btn btn-primary ml-auto my-3"
                            onClick={() =>
                                history.push(
                                    `/company/${companyId}/project/${projectId}/products/create-product`
                                )
                            }
                        >
                            Create Product
                        </button>
                    </div>
                    <div className="col-12">
                        <TimezoneSelector
                            selectStyle={{ width: 150, zIndex: 10, right: 20 }}
                        />

                        <Tabs
                            className="bg-white py-3 border border-radius sponsors-tabs"
                            defaultActiveKey={currentTab}
                            activeKey={currentTab}
                            onChange={(tabKey) => {
                                setTableSearchText('');
                                setTableCurrentPage(1);
                                setCurrentTab(tabKey);
                            }}
                        >
                            <TabPane
                                tab={`${
                                    isUndefined(
                                        productsData?.pagination?.totalPublished
                                    )
                                        ? 0
                                        : productsData?.pagination
                                              ?.totalPublished
                                } Publish`}
                                key="publish"
                            >
                                {isLoading && <GlobalLoading />}
                                {currentTab === 'publish' && (
                                    <CreatedTable
                                        data={productsData}
                                        loading={isLoading}
                                        pagination={(page) => {
                                            setTableSearchText(tableSearchText);
                                            setTableCurrentPage(page);
                                            handleGetProducts(
                                                page,
                                                tableSearchText
                                            );
                                        }}
                                        search={(searchText) => {
                                            setTableCurrentPage(1);
                                            setTableSearchText(searchText);
                                            handleGetProducts(1, searchText);
                                        }}
                                        onClickDelete={handlePreDelete}
                                        onClickPreview={(item) => {
                                            setPreviewModalData(item);
                                            setIsPreviewModalVisible(true);
                                        }}
                                    />
                                )}
                            </TabPane>
                            <TabPane
                                tab={`${
                                    isUndefined(
                                        productsData?.pagination?.totalDraft
                                    )
                                        ? 0
                                        : productsData?.pagination?.totalDraft
                                } Drafts`}
                                key="draft"
                            >
                                {isLoading && <GlobalLoading />}
                                {currentTab === 'draft' && (
                                    <CreatedTable
                                        data={productsData}
                                        loading={isLoading}
                                        pagination={(page) => {
                                            setTableSearchText(tableSearchText);
                                            setTableCurrentPage(page);
                                            handleGetProducts(
                                                page,
                                                tableSearchText
                                            );
                                        }}
                                        search={(searchText) => {
                                            setTableCurrentPage(1);
                                            setTableSearchText(searchText);
                                            handleGetProducts(1, searchText);
                                        }}
                                        onClickDelete={handlePreDelete}
                                        onClickPreview={(item) => {
                                            setPreviewModalData(item);
                                            setIsPreviewModalVisible(true);
                                        }}
                                    />
                                )}
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>

            <Prompt
                show={showDeletePrompt}
                message={`Are you sure you want to delete of this product?`}
                buttons={['Cancel', 'Proceed to Delete']}
                onHide={() => handleClosePrompt('delete')}
                callback={() => onClickDeleteProduct(deleteItemId)}
            />
            <PreviewModal
                visible={isPreviewModalVisible}
                setVisible={setIsPreviewModalVisible}
                data={{
                    name: previewModalData?.name,
                    description: previewModalData?.description,
                    exhibitorCompanyProfileId:
                        previewModalData?.exhibitorCompanyProfileId,
                    topicTags: !isEmpty(previewModalData?.topicTags)
                        ? previewModalData?.topicTags.map((item) => item?.name)
                        : [],
                    productGalleryItems: !isEmpty(
                        previewModalData?.productGalleryItems
                    )
                        ? previewModalData?.productGalleryItems.map(
                              (item) => item?.url
                          )
                        : [],
                    resources: !isEmpty(previewModalData?.productResources)
                        ? orderBy(
                              previewModalData?.productResources,
                              ['order'],
                              ['asc']
                          )
                        : [],
                    custom1: previewModalData?.custom1,
                    custom2: previewModalData?.custom2,
                    custom3: previewModalData?.custom3,
                    custom4: previewModalData?.custom4,
                    custom5: previewModalData?.custom5,
                    enableCta: previewModalData?.enableCta,
                    ctaLabel: previewModalData?.ctaLabel,
                    ctaLink: previewModalData?.ctaLink,
                    ctaBackgroundColor: previewModalData?.ctaBackgroundColor,
                    ctaColor: previewModalData?.ctaColor,
                }}
            />
            <SidebarContainer>
                <MainMenu priActive="products" all />
            </SidebarContainer>
        </Layout>
    );
}

Products.propTypes = {};

export default Products;
