import { handleLogout } from 'ComponentsV2/ProjectDashboard/utils/handleLogout';
import * as ls from 'local-storage';

export const apiInterceptor = async (url, options) => {
    try {
        const response = await fetch(url, options);
        switch (response.status) {
            case 403:
                if (response.message === 'ACCESS_TOKEN_EXPIRED') {
                    handleLogout();
                } else window.location.href = '/?err=no_access';
            default:
                return response;
        }
    } catch (error) {
        console.error(error);
    }
};
