import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as act from '../../../../../../redux/actions/live/uiActionsLive';
import * as userAct from 'redux/actions/common/userActions';
import Prompt from 'components/app/common/prompt';
import * as ls from 'local-storage';
import Layout from '../../.././../common/layout';
import { getSponsorCompanies } from 'apis/rest/GetSponsorCompanies';
import { sponsorAuthorize, sponsorLogout } from 'apis/rest/Authentication';
import { getSponsor, refreshSponsorTempToken } from 'apis/rest/Sponsor';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import { Table } from 'antd';
import './index.scss';
import 'antd/dist/antd.css';
const PAGE_SIZE = 10;
function SponsorCompanyProfiles() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [companyList, setCompanyList] = useState([]);

    const [paginationData, setPaginationData] = useState({});

    const [currentPage, setCurrentPage] = useState(1);

    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

    const firstRender = useRef(true);

    const columns = [
        { title: 'Company Name', dataIndex: 'companyName', key: '1' },
        { title: 'Project Name', dataIndex: 'projectName', key: '2' },
        {
            title: null,
            key: 'action',
            render: (text, record) => {
                return (
                    <button
                        onClick={() =>
                            handleCompanyClick(record.key, record.project)
                        }
                        className="btn myxp btn-primary ml-auto"
                    >
                        Go to project
                    </button>
                );
            },
        },
    ];

    const handleSponsorCompanyListing = async () => {
        const sponsorTempAuthToken = ls.get('sponsorTempAuth');
        try {
            if (sponsorTempAuthToken) {
                const { status, message, companyProfiles, pagination } =
                    await getSponsorCompanies(currentPage);
                if (status) {
                    setCompanyList(
                        companyProfiles.map((data) => ({
                            key: data.id,
                            projectName: data.project.name,
                            companyName: data.name,
                            project: data.project,
                        }))
                    );
                    setPaginationData(pagination);
                } else throw new Error(message);
            } else history.push('/sponsor/login');
        } catch (error) {
            if (error?.message && error.message.includes('expired')) {
                handleSponsorTempToken(sponsorTempAuthToken);
            } else
                openNotification(
                    'error',
                    {
                        message:
                            error?.message ||
                            'Something went wrong, please try to logout and login again.',
                    },
                    0
                );
        }
    };

    const handleSponsorTempToken = async (sponsorTempAuthToken) => {
        try {
            const { status, token, message } = await refreshSponsorTempToken(
                sponsorTempAuthToken?.access_token
            );

            if (status) {
                ls.set('sponsorTempAuth', token);
                handleSponsorCompanyListing();
            } else throw new Error(message);
        } catch (error) {
            if (error?.message && error.message.includes('invalid auth')) {
                ls.clear();
                history.replace('/sponsor/login');
            } else
                openNotification(
                    'error',
                    {
                        message:
                            error?.message ||
                            'Something went wrong, please try to logout and login again.',
                    },
                    0
                );
        }
    };

    const handleGetSponsor = async (companyId, project, token) => {
        const user = await getSponsor(token);

        dispatch(userAct.setUser(user));
        if (user?.user?.permission)
            dispatch({
                type: 'UPDATE_SPONSOR_MENU_DATA',
                payload: user.user.permission,
            });

        // ls('user', user.user);
        ls('project', project);
        ls.set('tempProjId', project.projectId);

        history.push(
            `/company/${companyId}/project/${project.projectId}/sponsor-home`
        );
    };

    const handleCompanyClick = async (companyId, project) => {
        try {
            const identifier = ls.get('sponsorTempAuth');

            if (!identifier) {
                ls.clear();
                history.push('/sponsor/login');
            } else {
                const token = await sponsorAuthorize({
                    identifier,
                    exhibitorCompanyProfileId: companyId,
                });

                if (token) {
                    const hasSponsorAuth = ls.get('sponsorAuth');
                    const sponsorAuth = !!hasSponsorAuth ? hasSponsorAuth : {};

                    sponsorAuth[`${companyId}-${project.projectId}`] = {
                        ...token,
                        projectId: project.projectId,
                        companyId,
                    };

                    ls.set('sponsorAuth', sponsorAuth);
                    window.SPONSOR_AUTH = sponsorAuth;

                    Object.freeze(window.SPONSOR_AUTH);

                    await handleGetSponsor(companyId, project, token);
                } else throw new Error();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleLogout = async () => {
        

        try {
            const res = await sponsorLogout();
            if (res?.status) {
                ls.clear();
                history.replace('/sponsor/login');
            } else throw res;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        dispatch(act.updateAllowFX(false));
        // Let Layout component know that menus are not visible
        dispatch(act.updateMainMenuVisibility(false));
        dispatch(act.updateSubMenuVisibility(false));
        // Reset defaults
        dispatch(act.updateHeaderTitle(''));
        dispatch(act.updateActiveLivePageName(''));

        handleSponsorCompanyListing();
    }, []);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        handleSponsorCompanyListing();
    }, [currentPage]);

    useEffect(() => {
      if(companyList.length === 1 && paginationData?.total < 2){
          dispatch({
              type:'UPDATE_SINGLECOMPANY_SPONSOR',
              payload:true
          })
        handleCompanyClick(
            companyList[0]?.key,
            companyList[0]?.project
        )
      }
    }, [companyList])
    
    return (
        <>
            <div className="companyProfiles">
                <div className="header header--xpmanager shadow-sm">
                    <div className="header__custom">
                        <div className="header-main">
                            <div className="header-main__title-group">
                                <a href="/sponsor/companyProfiles">
                                    <img
                                        className="solo"
                                        src="/assets/GEVME_LOGO.svg"
                                        alt="Gevme Admin"
                                    />
                                </a>
                            </div>
                            <div className="header-main__title " />
                        </div>
                    </div>
                    <button onClick={() => setShowLogoutConfirm(true)} className="logoutBtn">
                        Logout
                    </button>
                </div>
                <Prompt
                    show={showLogoutConfirm}
                    message={`Are you sure you want to Logout?`}
                    buttons={['Cancel', 'Proceed to Logout']}
                    onHide={() => setShowLogoutConfirm(false)}
                    callback={() => handleLogout()}
                />
                    <Table
                        style={{
                            width: '100%',
                            padding: '0 160px 0 160px',
                            marginTop: '80px',
                            background: '#f5f6fc',
                        }}
                        columns={columns}
                        dataSource={companyList}
                        loading={companyList.length === 0}
                        pagination={{
                            position: ['none', 'bottomCenter'],
                            onChange: setCurrentPage,
                            pageSize: PAGE_SIZE,
                            total: paginationData?.total,
                            showSizeChanger: false,
                            current: currentPage,
                        }}
                    ></Table>
            </div>
        </>
    );
}

export default withRouter(SponsorCompanyProfiles);
