import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as ls from 'local-storage';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import BlockViewConfiguration from '../BlockViewConfiguration';
import EyeIcon from 'Apps/VenueBuilder/Icons/EyeIcon';
import EyeClosedIcon from 'Apps/VenueBuilder/Icons/EyeClosedIcon';
import { decodeHtml } from 'utils/decodeHTML';

import FroalaEditor from 'react-froala-wysiwyg';
// import 'froala-editor/js/plugins/font_family.min.js';
// import 'froala-editor/js/plugins/font_size.min.js';

import styles from './index.module.css';
import sanitizeContent from 'utils/InputSanitization';

/**
 * RichTextContent Component
 *

 * @RichTextContent
 * @category Venue Builder
 * @subcategory Common
 * @description A Rich text editor component
 * @param {string} headingTitle A heading title
 * @param {string} tooltipText A tooltip text
 * @param {string} content Editor text value
 */

const RichTextContent = ({
    headingTitle,
    tooltipText,
    setContent,
    value,
    name,
    isRequired = false,
    error,
    hint,
    maxCount = false,
    isToggleble = false,
    isVisible,
    setVisibility,
    type,
    displayMode,
    blockType = '',
    property = '',
    setValue,
    getValues,
    linkedSettings,
    setLinkedSettings,
}) => {
    let auth = ls.get('auth');
    const displayModeRedux = useSelector((state) => state?.header.display);
    const { projectId, livepageId } = useSelector(
        (state) => state?.header.selection
    );

    const params = useParams();
    const dataProjectId = params.projectId || projectId;

    const [isEyeVisible, setIsEyeVisible] = useState(true);
    // value
    // ? `<p style="line-height: 27.6px; text-align: left;"><strong><span style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(0, 0, 0);">${value}</span></strong></p>`
    // :
    // `<p style="line-height: 27.6px; text-align: left;"><strong><span style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(0, 0, 0);">${value}</span></strong></p>`
    // <p style=\“line-height: 27.6px;\“><strong><span style=\“font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(0, 0, 0);\“>Live Stream</span></strong></p>

    const handleHideContent = (value) => {
        setVisibility(value);
    };

    const handleModelChange = (model) => {
        if (
            blockType &&
            blockType === 'featuredContent' &&
            name === 'description'
        ) {
            setContent(name, sanitizeContent({ str: model, type: 'richtext' }));
        } else setContent(name, model);
    };

    useEffect(() => {
        if (isToggleble) setIsEyeVisible(isVisible);
    }, [isVisible, isToggleble]);

    const headerConfig = {
        pastePlain: true,
        key: process.env.REACT_APP_FROALA_LICENSE_KEY,
        attribution: false,
        placeholderText: 'Block heading title',
        // iframe: true,
        htmlRemoveTags: ['script', 'base'],
        quickInsertTags: [],
        charCounterCount: false,
        imagePaste: false,
        imageUpload: false,
        fontSizeDefaultSelection: '24',
        fontFamilyDefaultSelection: 'Montserrat',
        multiLine: false,
        heightMin: 20,
        width: 225,
        lineHeights: {
            Default: '27.6px',
            '16.1px': '16.1px',
            '18.4px': '18.4px',
            '18.46px': '18.46px',
            '23px': '23px',
            '25.2px': '25.2px',
            '27.6px': '27.6px',
            '36.8px': '36.8px',
            '41.4px': '41.4px',
            Single: '1',
            1.15: '1.15',
            1.5: '1.5',
            Double: '2',
        },
        // toolbarButtons: ['lineHeight'],
        fontSize: [
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '32',
            '36',
            '40',
            '42',
            '48',
            '52',
            '56',
            '60',
            '64',
            '68',
            '72',
            '76',
            '84',
            '88',
            '92',
            '96',
            '100',
        ],
        fontFamily: {
            Arial: 'Arial',
            Georgia: 'Georgia',
            Roboto: 'Roboto',
            Tahoma: 'Tahoma',
            'Times New Roman': 'Times New Roman',
            Oswald: 'Oswald',
            "'Montserrat',sans-serif": 'Montserrat',
            "'Open Sans',sans-serif": 'Open Sans',
        },
        toolbarButtons: {
            moreText: {
                buttons: [
                    'bold',
                    'italic',
                    'underline',
                    'fontFamily',
                    'fontSize',
                    'textColor',
                ],
                buttonsVisible: 0,
            },
            moreParagraph: {
                buttons: [
                    'alignLeft',
                    'alignCenter',
                    'alignRight',
                    'lineHeight',
                ],
                buttonsVisible: 0,
            },
        },
        events: {
            'paste.beforeCleanup': function (clipboard_html) {
                return sanitizeContent({
                    str: clipboard_html,
                    type: 'heading',
                });
            },
        },
    };

    const textAreaConfig = {
        pastePlain: true,
        key: process.env.REACT_APP_FROALA_LICENSE_KEY,
        attribution: false,
        placeholderText: 'Edit your content here!',
        // iframe: true,
        htmlRemoveTags: ['script', 'base'],
        heightMin: 150,
        width: 265,
        quickInsertTags: [''],
        charCounterCount: !maxCount ? false : true,
        charCounterMax: maxCount,
        imagePaste: false,
        imageUpload: false,
        // imageUploadMethod: 'POST',
        // imageUploadParam: 'files',
        // imageUploadParams: { projectId, showcaseId: livepageId },
        // imageUploadURL: `${process.env.REACT_APP_MYXP_API}/manager/livestream/upload`,
        imageInsertButtons: ['imageUpload', 'imageByURL'],
        fontSizeDefaultSelection: '14',

        lineHeights: {
            Default: '27.6px',
            '16.1px': '16.1px',
            '18.4px': '18.4px',
            '18.46px': '18.46px',
            '23px': '23px',
            '25.2px': '25.2px',
            '27.6px': '27.6px',
            '36.8px': '36.8px',
            '41.4px': '41.4px',
            Single: '1',
            1.15: '1.15',
            1.5: '1.5',
            Double: '2',
        },
        fontSize: [
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '32',
            '36',
            '40',
            '42',
            '48',
            '52',
            '56',
            '60',
            '64',
            '68',
            '72',
            '76',
            '84',
            '88',
            '92',
            '96',
            '100',
        ],
        fontFamily: {
            'Arial,sans-serif': 'Arial',
            'Georgia,sans-serif': 'Georgia',
            'Montserrat,sans-serif': 'Montserrat',
            'Roboto,sans-serif': 'Roboto',
            'Tahoma,sans-serif': 'Tahoma',
            'Times New Roman,sans-serif': 'Times New Roman',
            'Oswald,sans-serif': 'Oswald',
            "'Open Sans',sans-serif": 'Open Sans',
            "'Open Sans Condensed',sans-serif": 'Open Sans Condensed',
        },

        fileMaxSize: 1024 * 1024 * 3, // Max size is 3MB
        fileAllowedTypes: [
            'text/plain',
            'image/gif',
            'image/jpeg',
            'image/png',
        ],
        requestHeaders: {
            Authorization: window.SPONSOR_AUTH
                ? `Bearer ${window.SPONSOR_AUTH?.access_token}`
                : auth && `Bearer ${auth?.access_token}`,
        },
        events: {
            'image.uploaded'(response) {
                const data = JSON.parse(response)?.files;

                this.image.insert(data[0], true, null, this.image.get(), null);
                return false;
            },
            'paste.beforeCleanup': function (clipboard_html) {
                return sanitizeContent({
                    str: clipboard_html,
                    type: 'richtext',
                });
            },
            // 'commands.linkInsert'(e) { console.log(e); },
            
        },
        imageEditButtons: [
            'imageReplace',
            'imageAlign',
            'imageCaption',
            'imageRemove',
            '|',
            'imageLink',
            'linkOpen',
            'linkEdit',
            'linkRemove',
            '-',
            'imageDisplay',
            'imageStyle',
            'imageAlt',
            'imageSize',
            'imageTUI',
        ],
        toolbarButtons: {
            moreText: {
                buttons: [
                    'bold',
                    'italic',
                    'underline',
                    'strikeThrough',
                    'subscript',
                    'superscript',
                    'fontFamily',
                    'fontSize',
                    'textColor',
                    'backgroundColor',
                    'inlineClass',
                    'inlineStyle',
                    'clearFormatting',
                ],
                buttonsVisible: 0,
            },
            moreParagraph: {
                buttons: [
                    'alignLeft',
                    'alignCenter',
                    'alignRight',
                    'alignJustify',
                    'formatUL',
                    'formatOLSimple',
                    'paragraphFormat',
                    'paragraphStyle',
                    'quote',
                    'lineHeight',
                    'outdent',
                    'indent',
                ],
                buttonsVisible: 0,
            },
            moreRich: {
                buttons: [
                    'insertLink',
                    // 'insertImage',
                    'insertTable',
                    'specialCharacters',
                    'insertHR',
                ],
                buttonsVisible: 0,
            },
            moreMisc: {
                buttons: [
                    'undo',
                    'redo',
                    'fullscreen',
                    'spellChecker',
                    'selectAll',
                    'html',
                    'help',
                ],
                align: 'right',
                buttonsVisible: 2,
            },
        },
        imageTUIOptions: {
            includeUI: {
                initMenu: 'filter',
                menuBarPosition: 'left',
                theme: {
                    'menu.activeIcon.path':
                        'https://cdn.jsdelivr.net/npm/tui-image-editor@3.2.2/dist/svg/icon-b.svg',
                    'menu.disabledIcon.path':
                        'https://cdn.jsdelivr.net/npm/tui-image-editor@3.2.2/dist/svg/icon-a.svg',
                    'menu.hoverIcon.path':
                        'https://cdn.jsdelivr.net/npm/tui-image-editor@3.2.2/dist/svg/icon-c.svg',
                    'menu.normalIcon.path':
                        'https://cdn.jsdelivr.net/npm/tui-image-editor@3.2.2/dist/svg/icon-d.svg',
                    'submenu.activeIcon.name': 'icon-c',
                    'submenu.activeIcon.path':
                        'https://cdn.jsdelivr.net/npm/tui-image-editor@3.2.2/dist/svg/icon-c.svg',
                    'submenu.normalIcon.name': 'icon-d',
                    'submenu.normalIcon.path':
                        'https://cdn.jsdelivr.net/npm/tui-image-editor@3.2.2/dist/svg/icon-d.svg',
                },
            },
        },
    };

    const displayStyle = displayMode
        ? {
              display:
                  (displayMode === 'desktop' && displayModeRedux.desktop) ||
                  (displayMode === 'tablet' && displayModeRedux.tablet) ||
                  (displayMode === 'mobile' && displayModeRedux.mobile)
                      ? 'block'
                      : 'none',
          }
        : { display: 'block' };

    return (
        <div
            style={{ ...displayStyle }}
            className={`${styles.RichTextContainer} RichTextContainer ${
                type === 'heading' ? 'heading' : ''
            }`}
        >
            <div className={styles.Heading}>
                <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center">
                    <h3 className="title">
                        {decodeHtml(headingTitle)}{' '}
                        {isRequired && (
                            <span className={styles.Required}>*</span>
                        )}
                        {tooltipText && (
                            <Tooltip placement="right" title={tooltipText}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        )}
                    </h3>
                    {displayMode && (
                        <BlockViewConfiguration
                            property={property}
                            setValue={setValue}
                            getValues={getValues}
                            linkedSettings={linkedSettings}
                            setLinkedSettings={setLinkedSettings}
                        />
                    )}
                </div>
            </div>
            <div
                className={`fr ${error ? 'error' : ''} ${
                    isEyeVisible ? '' : 'disabled'
                } ${styles.FroalaEditor}`}
            >
                <FroalaEditor
                    tag="text"
                    config={type === 'heading' ? headerConfig : textAreaConfig}
                    model={value}
                    onModelChange={handleModelChange}
                    className={`rich-text-editor ${
                        type === 'heading' ? 'heading-title' : ''
                    }`}
                    pastePlain={true}
                />
                {isToggleble ? (
                    isEyeVisible ? (
                        <span
                            className="visible"
                            onClick={() => handleHideContent(false)}
                        >
                            <EyeIcon isVisible={isEyeVisible} />
                        </span>
                    ) : (
                        <span
                            className="visible"
                            onClick={() => handleHideContent(true)}
                        >
                            <EyeClosedIcon isVisible={isEyeVisible} />
                        </span>
                    )
                ) : null}
            </div>
            {hint && <span className="hint">{hint}</span>}
            {error || error?.type === 'required' ? (
                <p className="errorText">Required</p>
            ) : null}
            {/* {error && <p className="errorText">Required</p>}
            {error && <p className="errorText">Required</p>} */}
            <style jsx="true">{`
                .fr-toolbar .fr-btn-grp {
                    margin: 0 0px 0 8px !important;
                }
                 {
                    /* .fr-box.fr-basic .fr-element.fr-view {
                    font-family: 'Montserrat',sans-serif;
                    font-size: 24px;
                    color: rgb(0, 0, 0);
                    line-height: 27.6px;
                } */
                }
                .fr {
                    display: flex;
                    flex-direction: row;

                    border-radius: 4px;
                }
                .fr .fr-box {
                    border: 1px solid #dadeeb;
                }
                .fr-second-toolbar {
                    border: 0px !important;
                }
                .fr-box.fr-basic .fr-element {
                    padding: 10px;
                }
                .fr span.visible {
                    cursor: pointer;
                    width: 48px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                }
                .fr.disabled .fr-box {
                    pointer-events: none;
                }
                .fr.disabled .fr-wrapper,
                .fr.disabled .fr-toolbar {
                    background-color: #f5f5f5 !important;
                }
                .fr-toolbar {
                    border: 0px;
                }
                .fr-box.fr-basic.fr-top .fr-wrapper {
                    border-radius: 0px 0px 10px 10px;
                    border: 0px;
                }
                .fr-box.fr-basic .fr-element.fr-view {
                    font-family: 'Roboto', sans-serif;
                }
                .RichTextContainer .hint {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.02em;
                    color: #000000;
                    margin-top: 3px;
                }
                .fr.error {
                    border: 1px solid red;
                    border-radius: 10px;
                }
                .second-toolbar {
                    border: 0;
                }
                .errorText {
                    color: red !important;
                    margin: 0;
                }
                .fr .second-toolbar {
                    display: none;
                }
                .fr-toolbar .fr-command.fr-btn i,
                .fr-toolbar .fr-command.fr-btn svg,
                .fr-popup .fr-command.fr-btn i,
                .fr-popup .fr-command.fr-btn svg,
                .fr-modal .fr-command.fr-btn i,
                .fr-modal .fr-command.fr-btn svg {
                    margin: 0px 4px !important;
                }
                .fr-popup.fr-active {
                    width: 258px;
                    left: 4px !important;
                }
                .fr-popup .fr-layer.fr-active {
                    width: 220px;
                }
                .fr-toolbar .fr-more-toolbar.fr-expanded #insertFile-4,
                .fr-toolbar .fr-more-toolbar.fr-expanded #insertFile-1 {
                    display: none !important;
                }
                .fr-command.fr-btn.fr-active + .fr-dropdown-menu {
                    left: 0px !important;
                    min-width: 80px !important;
                }
                [id*='dropdown-menu-fontSize'] {
                    right: 0px !important;
                }
                .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper {
                    width: 100%;
                }
                .fr-popup .fr-buttons.fr-tabs .fr-special-character-category {
                    padding: 10px 0px;
                }
                .fr-toolbar .fr-tabs .fr-command.fr-btn span,
                .fr-popup .fr-tabs .fr-command.fr-btn span,
                .fr-modal .fr-tabs .fr-command.fr-btn span {
                    text-align: center;
                }
                .fr-popup .fr-color-set > span {
                    width: 31px;
                    height: 31px;
                }
                .fr-toolbar.fr-toolbar-open {
                    padding-bottom: 0px;
                }
            `}</style>
        </div>
    );
};

export default RichTextContent;
