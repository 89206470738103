import * as ls from 'local-storage';
import { store } from 'redux/store';
let authBearer = null;

// multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};
export async function DeletePoll(
    pollId,
    projectId
) {
    setAuthBearer();
    const user = store?.getState()?.user
    if (authBearer && user) {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/polls/${pollId}?projectId=${projectId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    }
}
