import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { List, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';

import { GetChannels } from 'apis/rest/channels/GetChannels';

import GlobalLoading from 'components/app/components/common/globalLoading';
import './livestreamChannel.scss';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import NoChannelView from './NoChannelView';

const { TabPane } = Tabs;
function LivebarChannel() {
    const history = useHistory();
    const { projectId, companyId } = useParams();

    const [channelsData, setChannelsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [currentTab, setCurrentTab] = useState('active-channels');
    const [tableSearchText, setTableSearchText] = useState('');

    useEffect(() => {
        handleGetChannels(1, tableSearchText);
    }, []);

    const handleGetChannels = async (
        currentPage = 1,
        search = '',
        activeTab = currentTab
    ) => {
        try {
            const status = activeTab === 'active-channels' ? 'live' : 'expired';
            const response = await GetChannels(
                projectId,
                currentPage,
                search,
                status
            );
            if (response.status) {
                setChannelsData(response);
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            openNotification('error', {
                message: 'An error was encountered please try again',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const renderPagination = (current, type, originalElement) => {
        return (
            <a
                onClick={() => {
                    if (!current) return null;
                    // setCurrentPage(current)
                    handleGetChannels(current, '');
                }}
            >
                {originalElement}
            </a>
        );
    };

    const Channels = () => (
        <>
            {isLoading && <GlobalLoading />}

            {!isLoading && channelsData?.pagination?.totalChannels === 0 && (
                <NoChannelView companyId={companyId} projectId={projectId} />
            )}

            {!isLoading && channelsData?.pagination?.totalChannels !== 0 && (
                <div className="col-12 p-0">
                    <List
                        className="bg-white pb-3 channels-list-table"
                        itemLayout="horizontal"
                        dataSource={channelsData?.channels}
                        pagination={{
                            itemRender: renderPagination,
                            pageSize: 10,
                            total: channelsData?.pagination?.total,
                            current: channelsData?.pagination?.currentPage,
                        }}
                        renderItem={(item) => (
                            <List.Item
                                onClick={() =>
                                    history.push(
                                        `/company/${companyId}/project/${projectId}/channels/${item.id}`
                                    )
                                }
                                role="button"
                                className="p-3 ant-list-item"
                            >
                                <List.Item.Meta
                                    style={{ border: 'none' }}
                                    title={
                                        <p
                                            className="mb-0 channel-title"
                                            style={{ fontSize: 14 }}
                                        >
                                            {item.name}
                                        </p>
                                    }
                                    description={
                                        <>
                                            <p
                                                className="mb-2 text-dark"
                                                style={{
                                                    fontSize: 12,
                                                }}
                                            >
                                                {item.description}
                                            </p>
                                            <p className="mb-1 font-weight-light channel-expires-text">
                                                This channel expire
                                                {currentTab ===
                                                'active-channels'
                                                    ? 's'
                                                    : 'd'}{' '}
                                                on{' '}
                                                <span className="font-weight-bold text-dark">
                                                    {moment(item.createdAt)
                                                        .add(1, 'M')
                                                        .format('D MMM YYYY')}
                                                </span>
                                            </p>
                                        </>
                                    }
                                />

                                <button className="btn btn-secondary mr-2">
                                    <FontAwesomeIcon icon="chevron-right" />
                                </button>
                            </List.Item>
                        )}
                    />
                </div>
            )}
        </>
    );

    return (
        <>
            <div className="mb-4 d-flex justify-content-end align-items-center">
                <button
                    className="myxp btn btn-primary"
                    onClick={() =>
                        history.push(
                            `/company/${companyId}/project/${projectId}/channels/create`
                        )
                    }
                >
                    Create live stream channel
                </button>
            </div>
            <Tabs
                className="bg-white py-3 border border-radius sponsors-tabs channels-list"
                defaultActiveKey={currentTab}
                activeKey={currentTab}
                onChange={(activeTab) => {
                    setCurrentTab(activeTab);
                    setIsLoading(true);
                    setTableSearchText('');
                    handleGetChannels(1, '', activeTab);
                }}
                tabBarExtraContent={
                    <div className="form-group has-search mr-3">
                        <span className="fa fa-search form-control-feedback"></span>
                        <input
                            value={tableSearchText}
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setTableSearchText(e.target.value)}
                        />
                    </div>
                }
            >
                <TabPane tab="Active Channels" key="active-channels">
                    <Channels />
                </TabPane>
                <TabPane tab="Expired Channels" key="expired-channels">
                    <Channels />
                </TabPane>
            </Tabs>
        </>
    );
}

LivebarChannel.propTypes = {};

export default LivebarChannel;
