import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactPlayer from 'react-player';
import fileDownload from 'js-file-download';
import { debounce } from 'lodash';

import Prompt from 'components/app/common/prompt';
import MediaAssetCardModal from './mediaAssetCardModal';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import {
    DeleteIcon,
    DetailsDotsIcon,
    DownloadIcon,
    LinkIcon,
    MusicIcon,
    PauseIcon,
    PlayIcon,
    SelectedIcon,
    ExpandIcon,
} from './icons';
import './mediaAssetCard.scss';

export default function MediaAssetCard({
    mediaAsset,
    className,
    showOptions,
    isMediaAssetSelected,
    deleteMediaAssetHandler,
    setSelectedMediaAssets,
    handleSelectFile,
    stopOtherMediaPlayerHandler,
    closeOtherMediaAssetDetailsHandler,
    multiple,
    selectedMediaAssets,
    modalType = 'audio',
}) {
    const [isMediaCardHovered, setIsMediaCardHovered] = useState(false);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [showMediaAssetDetailOptions, setShowMediaAssetDetailOptions] =
        useState(null);
    const isMediaCardDetailOptionsOpened =
        showMediaAssetDetailOptions === mediaAsset?.id;
    const [showMediaAssetModal, setShowMediaAssetModal] = useState(false);
    const [mediaPlayerSettings, setMediaPlayerSettings] = useState({
        playing: false,
        seekTo: 0,
    });

    // react player states
    const mediaAssetCardRef = useRef(null);
    const [isMediaAssetCardAtEnd, setIsMediaAssetCardAtEnd] = useState(false);
    const mediaPlayerRef = useRef(null);
    const [mediaPlayerMediaAssetId, setMediaPlayerMediaAssetId] =
        useState(null);
    const [mediaPlayerPlaying, setMediaPlayerPlaying] = useState(false);
    const [mediaPlayerPercentage, setMediaPlayerPercentage] = useState('0');
    const [mediaPlayerDuration, setMediaPlayerDuration] = useState('00:00');
    const [mediaPlayerSeekBarClicked, setMediaPlayerSeekBarClicked] =
        useState(false);

    const handleShowDeletePrompt = () => setShowDeletePrompt(true);
    const handleCloseDeletePrompt = () => setShowDeletePrompt(false);
    const onMediaAssetConfirmDelete = () =>
        deleteMediaAssetHandler(mediaAsset?.id);

    function onMediaAssetSelected(event) {
        event.stopPropagation();
        if (!showOptions) return;

        if (!multiple) {
            if (selectedMediaAssets.includes(mediaAsset?.id)) {
                setSelectedMediaAssets((prevSelectedMediaAssets) =>
                    prevSelectedMediaAssets.filter(
                        (id) => id !== mediaAsset?.id
                    )
                );
                handleSelectFile && handleSelectFile({});
            } else {
                setSelectedMediaAssets([mediaAsset?.id]);
                handleSelectFile && handleSelectFile(mediaAsset);
            }
        } else {
            setSelectedMediaAssets((prevSelectedMediaAssets) =>
                prevSelectedMediaAssets.includes(mediaAsset?.id)
                    ? prevSelectedMediaAssets.filter(
                          (id) => id !== mediaAsset?.id
                      )
                    : [...prevSelectedMediaAssets, mediaAsset?.id]
            );
        }
    }

    async function onMediaAssetDownloadHandler() {
        try {
            const response = await fetch(mediaAsset?.url, {
                credentials: 'include',
            });
            const blob = await response.blob();
            fileDownload(blob, mediaAsset.name);
        } catch (e) {
            openNotification('error', { message: 'Error downloading file' });
            console.error(e);
        }
    }

    function stopMediaPlayerHandler() {
        setMediaPlayerMediaAssetId(null);
        setMediaPlayerPlaying(false);
        setMediaPlayerPercentage('0');
        setMediaPlayerDuration('00:00');
    }

    function onMediaPlayerExpandHandler(event) {
        event.stopPropagation();
        setMediaPlayerSettings({
            playing: mediaPlayerMediaAssetId === mediaAsset?.id,
            seekTo: +mediaPlayerPercentage / 100,
        });
        stopMediaPlayerHandler();
        stopOtherMediaPlayerHandler(null);
        setShowMediaAssetModal(true);
    }

    function mediaPlayerSeekTo(fraction) {
        mediaPlayerRef.current &&
            mediaPlayerRef.current.seekTo(fraction, 'fraction');
    }

    const debouncedMediaPlayerSeekTo = debounce(mediaPlayerSeekTo, 100);
    const debouncedSetMediaPlayerPercentage = debounce(
        setMediaPlayerPercentage,
        10
    );

    function onMediaPlayerClickHandler(e) {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const fraction = x / rect.width;
        debouncedSetMediaPlayerPercentage((fraction * 100).toFixed(2));
        debouncedMediaPlayerSeekTo(fraction);
    }

    function onMediaPlayerSeekHandler(e) {
        e.stopPropagation();
        if (!mediaPlayerSeekBarClicked) return;
        onMediaPlayerClickHandler(e);
    }

    function hideAllMenus() {
        if (showMediaAssetDetailOptions) {
            setShowMediaAssetDetailOptions(null);
            closeOtherMediaAssetDetailsHandler(null);
        }
    }

    function onMediaAssetCardDetailsClicked(event) {
        event.stopPropagation();

        if (showMediaAssetDetailOptions !== mediaAsset?.id) {
            setShowMediaAssetDetailOptions((prevShowMediaAssetDetailOptions) =>
                prevShowMediaAssetDetailOptions === mediaAsset?.id
                    ? null
                    : mediaAsset?.id
            );
            closeOtherMediaAssetDetailsHandler(mediaAsset?.id, () =>
                setShowMediaAssetDetailOptions(null)
            );
        }

        // to check and set the media asset is at end or not
        if (!mediaAssetCardRef.current) {
            return;
        }
        const mediaAssetCardRect =
            mediaAssetCardRef.current.getBoundingClientRect();
        const parentDivRect =
            mediaAssetCardRef.current.parentElement.getBoundingClientRect();
        if (!mediaAssetCardRect || !parentDivRect) {
            return;
        }
        const mediaAssetCardRight = mediaAssetCardRect.right;
        const parentDivRight = parentDivRect.right;
        setIsMediaAssetCardAtEnd(
            parentDivRight - mediaAssetCardRight < mediaAssetCardRect.width
        );
    }

    useEffect(() => {
        window.addEventListener('click', hideAllMenus);
        return () => {
            window.removeEventListener('click', hideAllMenus);
        };
    }, [showMediaAssetDetailOptions]);

    return (
        <>
            <Prompt
                show={showDeletePrompt}
                message={`Are you sure you want to delete "${mediaAsset?.name}" ${mediaAsset?.type} file?`}
                buttons={['Cancel', 'Delete']}
                onHide={handleCloseDeletePrompt}
                callback={onMediaAssetConfirmDelete}
            />
            <MediaAssetCardModal
                mediaAsset={mediaAsset}
                visible={showMediaAssetModal}
                onClose={() => setShowMediaAssetModal(false)}
                onMediaAssetDownloadHandler={onMediaAssetDownloadHandler}
                mediaPlayerSettings={mediaPlayerSettings}
            />
            <div
                ref={mediaAssetCardRef}
                className={`${
                    isMediaAssetSelected
                        ? 'tw-border-2 tw-border-solid tw-border-blue-500'
                        : ''
                } media-asset-card tw-group tw-transition-all tw-duration-200 tw-ease-in-out tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-60 tw-h-40 tw-relative tw-bg-gray-100 tw-rounded-lg tw-mr-5 tw-mb-9 ${className}`}
                onClick={(event) => {
                    event.stopPropagation();
                    setIsMediaCardHovered(true);
                    setShowMediaAssetDetailOptions(null);
                }}
                onMouseOver={() => setIsMediaCardHovered(true)}
                onMouseLeave={() => setIsMediaCardHovered(false)}
            >
                {mediaAsset?.type === 'image' ? (
                    <img
                        className="tw-h-40 tw-w-60 tw-rounded-lg"
                        src={mediaAsset?.url}
                        alt={mediaAsset?.name}
                    />
                ) : (
                    <>
                        <div
                            className={`${
                                mediaAsset?.type === 'video'
                                    ? 'tw-block'
                                    : 'tw-hidden'
                            } tw-absolute ${
                                mediaPlayerMediaAssetId === mediaAsset?.id
                                    ? 'tw-bg-black'
                                    : ''
                            } tw-w-60 tw-h-40 tw-rounded-lg tw-overflow-hidden tw-bg-cover tw-bg-no-repeat tw-bg-center`}
                            style={
                                mediaAsset?.thumbnail &&
                                mediaPlayerMediaAssetId !== mediaAsset?.id
                                    ? {
                                          background: `url(${mediaAsset.thumbnail})`,
                                      }
                                    : {}
                            }
                        >
                            <ReactPlayer
                                ref={mediaPlayerRef}
                                width={'100%'}
                                height={'100%'}
                                url={
                                    mediaPlayerMediaAssetId === mediaAsset?.id
                                        ? mediaAsset?.url
                                        : null
                                }
                                playing={mediaPlayerPlaying}
                                controls={false}
                                onProgress={(data) => {
                                    setMediaPlayerPercentage(
                                        (data.played * 100).toFixed(2)
                                    );
                                    setMediaPlayerDuration(
                                        moment
                                            .utc(data.playedSeconds * 1000)
                                            .format('mm:ss')
                                    );
                                }}
                                onEnded={stopMediaPlayerHandler}
                            />
                        </div>
                        <div
                            className={`${
                                mediaPlayerPlaying
                                    ? 'tw-rounded-full'
                                    : 'tw-rounded-lg'
                            } ${
                                mediaAsset?.type === 'video' &&
                                mediaAsset?.thumbnail
                                    ? 'tw-bg-black/70'
                                    : 'tw-bg-black'
                            } tw-z-10 tw-flex tw-flex-col tw-items-center tw-justify-center tw-cursor-pointer tw-w-16 tw-h-16`}
                            onClick={() => {
                                if (
                                    mediaPlayerMediaAssetId !== mediaAsset?.id
                                ) {
                                    stopOtherMediaPlayerHandler(
                                        mediaAsset?.id,
                                        stopMediaPlayerHandler
                                    );
                                    setMediaPlayerMediaAssetId(mediaAsset?.id);
                                    setMediaPlayerPlaying(true);
                                    setMediaPlayerPercentage('0');
                                    setMediaPlayerDuration('00:00');
                                } else {
                                    setMediaPlayerPlaying(
                                        (playing) => !playing
                                    );
                                }
                            }}
                        >
                            {mediaPlayerMediaAssetId === mediaAsset?.id ? (
                                mediaPlayerPlaying ? (
                                    <PauseIcon />
                                ) : (
                                    <PlayIcon />
                                )
                            ) : isMediaCardHovered ? (
                                <PlayIcon />
                            ) : mediaAsset?.type === 'video' ? (
                                <PlayIcon />
                            ) : (
                                <MusicIcon />
                            )}
                        </div>
                    </>
                )}
                {mediaAsset?.type === 'video' && isMediaCardHovered ? (
                    <div
                        className={`${
                            mediaAsset?.thumbnail
                                ? 'tw-bg-black/70'
                                : 'tw-bg-black'
                        } tw-block tw-absolute tw-w-6 tw-h-6 tw-top-4 tw-right-12 tw-cursor-pointer tw-rounded-lg tw-flex tw-flex-col tw-items-center tw-justify-center`}
                        onClick={onMediaPlayerExpandHandler}
                    >
                        <div className="tw-mx-auto tw-w-fit">
                            <ExpandIcon />
                        </div>
                    </div>
                ) : null}
                {showOptions &&
                    (isMediaAssetSelected ||
                        isMediaCardDetailOptionsOpened ||
                        isMediaCardHovered) && (
                        <div
                            className={`${
                                mediaAsset?.type === 'video' &&
                                mediaAsset?.thumbnail
                                    ? 'tw-bg-black/70'
                                    : 'tw-bg-black'
                            } tw-block tw-absolute tw-w-6 tw-h-6 tw-top-4 tw-right-4 tw-cursor-pointer tw-rounded tw-flex tw-flex-col tw-items-center tw-justify-center tw-shadow-xl tw-shadow-gray-500`}
                            onClick={onMediaAssetCardDetailsClicked}
                        >
                            <div className="tw-mx-auto tw-w-fit">
                                <DetailsDotsIcon />
                            </div>
                        </div>
                    )}
                {showOptions && isMediaCardDetailOptionsOpened && (
                    <div
                        className={`${
                            isMediaAssetCardAtEnd ? 'tw-right-0' : 'tw-left-56'
                        } tw-absolute tw-top-9 tw-w-72 tw-h-auto tw-p-3.5 tw-z-50 tw-bg-white tw-rounded tw-shadow-xl tw-flex tw-flex-col tw-flex tw-mt-1`}
                    >
                        <div className="tw-text-black tw-font-medium tw-text-sm">
                            {mediaAsset?.name}
                        </div>
                        <div className="tw-flex tw-flex-row tw-border-0 tw-border-b tw-border-solid tw-pb-4 tw-border-gray-200 tw-items-center tw-justify-between">
                            <div className="tw-text-gray-700 tw-font-light tw-text-xs">
                                Uploaded by -{' '}
                                {mediaAsset?.createdByUser?.firstname}{' '}
                                {mediaAsset?.createdByUser?.lastname}
                            </div>
                            <div className="tw-text-gray-700 tw-font-light tw-text-xs">
                                {moment
                                    .utc(mediaAsset?.createdAt)
                                    .format('MMM DD, YYYY')}
                            </div>
                        </div>
                        <div
                            className="tw-text-black tw-font-light tw-text-sm tw-py-1 tw-mt-1 tw-cursor-pointer tw-no-underline hover:tw-bg-gray-50"
                            onClick={onMediaAssetDownloadHandler}
                        >
                            <DownloadIcon /> &nbsp;&nbsp;
                            <span>Download</span>
                        </div>
                        <a
                            className="tw-text-black tw-font-light tw-text-sm tw-py-1 tw-cursor-pointer tw-no-underline hover:tw-bg-gray-50"
                            href={mediaAsset?.url}
                            target="_blank"
                        >
                            <LinkIcon /> &nbsp;&nbsp;
                            <span className="tw-pt-0.5">Link</span>
                        </a>
                        <div
                            className="tw-text-black tw-font-light tw-text-sm tw-py-1 tw-cursor-pointer hover:tw-bg-gray-50"
                            onClick={(event) => {
                                event.stopPropagation();
                                handleShowDeletePrompt();
                            }}
                        >
                            <DeleteIcon /> &nbsp;&nbsp;
                            <span className="tw-pt-0.5">Delete</span>
                        </div>
                    </div>
                )}
                {isMediaAssetSelected ||
                isMediaCardDetailOptionsOpened ||
                isMediaCardHovered ? (
                    <div
                        className={`${
                            isMediaAssetSelected
                                ? 'tw-border-white'
                                : 'tw-border-black'
                        } tw-z-40 tw-block tw-absolute tw-w-6 tw-h-6 tw-top-3.5 tw-left-4 tw-cursor-pointer tw-bg-white tw-border-solid tw-border-2 tw-rounded-full tw-shadow-xl tw-shadow-gray-500`}
                        onClick={onMediaAssetSelected}
                    >
                        {isMediaAssetSelected && (
                            <div className="media-asset-selected">
                                <SelectedIcon />
                            </div>
                        )}
                        &nbsp;
                    </div>
                ) : null}
                {mediaPlayerMediaAssetId === mediaAsset?.id && (
                    <div
                        className={`${
                            mediaAsset?.type === 'video'
                                ? 'tw-px-1'
                                : 'tw-px-3.5'
                        } tw-block tw-absolute tw-w-full tw-bottom-2.5 tw-py-0`}
                    >
                        <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center">
                            <div
                                className={`${
                                    mediaAsset?.type === 'video'
                                        ? 'tw-bg-white tw-rounded-lg tw-p-0.5 tw-drop-shadow-xl tw-h-4 tw-flex tw-flex-col tw-justify-center tw-items-start tw-cursor-pointer'
                                        : 'tw-h-2'
                                } tw-w-4/5`}
                                onClick={onMediaPlayerClickHandler}
                                onMouseDown={() =>
                                    setMediaPlayerSeekBarClicked(true)
                                }
                                onMouseMove={onMediaPlayerSeekHandler}
                                onMouseUp={() =>
                                    setMediaPlayerSeekBarClicked(false)
                                }
                            >
                                <div
                                    className={`${
                                        mediaAsset?.type === 'video'
                                            ? 'tw-bg-black tw-rounded-lg tw-h-3.5'
                                            : 'tw-bg-blue-500 tw-h-2 tw-rounded-md'
                                    } `}
                                    style={{
                                        width: `${mediaPlayerPercentage || 0}%`,
                                    }}
                                />
                            </div>
                            <p
                                className={`${
                                    mediaAsset?.type === 'video'
                                        ? 'tw-bg-white tw-text-black tw-rounded-lg tw-font-semibold'
                                        : ''
                                } tw-p-0 tw-m-0 tw-px-1 tw-py-0.5 tw-text-xs tw-font-semibold`}
                            >
                                {mediaPlayerDuration}
                            </p>
                        </div>
                    </div>
                )}
                <div
                    className="tw-block tw-absolute tw-w-full tw-px-2 tw-mx-3.5 tw-my-0 tw-z-10 tw-flex tw-flex-row tw-justify-between tw-items-center"
                    style={{ top: '165px' }}
                >
                    <p className="tw-font-light tw-text-sm tw-text-slate-500">
                        {mediaAsset?.name?.length > 20
                            ? `${mediaAsset?.name?.substring(0, 19)} ...`
                            : mediaAsset?.name}
                    </p>
                    <p className="tw-font-semibold tw-text-sm tw-text-slate-500">
                        {moment.utc(mediaAsset?.createdAt).format('MMM DD')}
                    </p>
                </div>
            </div>
        </>
    );
}

MediaAssetCard.propTypes = {
    mediaAsset: PropTypes.object.isRequired,
    className: PropTypes.string,
    showOptions: PropTypes.bool,
    isMediaAssetSelected: PropTypes.bool,
    deleteMediaAssetHandler: PropTypes.func,
    setSelectedMediaAssets: PropTypes.func,
    handleSelectFile: PropTypes.func,
    stopOtherMediaPlayerHandler: PropTypes.func,
    closeOtherMediaAssetDetailsHandler: PropTypes.func,
};

MediaAssetCard.defaultProps = {
    className: '',
    showOptions: false,
    isMediaAssetSelected: false,
    deleteMediaAssetHandler: () => {},
    setSelectedMediaAssets: () => {},
    handleSelectFile: () => {},
    stopOtherMediaPlayerHandler: () => {},
    closeOtherMediaAssetDetailsHandler: () => {},
};
