import * as ls from "local-storage";

/**
 * Get all projects for Livestream
 */
export async function archiveProject(projectId, status) {
	const auth = ls.get("auth");
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${auth.access_token}`
	};
	let data = JSON.stringify({"archive":status});

	const response = await fetch(
		`${process.env.REACT_APP_MYXP_API}/manager/livestream/projects/${projectId}/archive`,
		{
			method: "POST",
			headers: headers,
			body: data
		},
	);

	return await response.json();
}
