import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import Prompt from 'components/app/common/prompt';
import {
    getAgendaSessions,
    getAgendaSpeakers,
    getAgendaSessionTags,
    deleteSession,
    getAgendaSessionDates,
    duplicateSession,
} from 'apis/rest/AgendaSessions';
import { getLivePages } from 'apis/rest/livestream/GetLivePages';
import ReactPaginate from 'react-paginate';
import { Dropdown } from 'react-bootstrap';
import * as ls from 'local-storage';
import BoxPreloader from 'components/app/common/preloader/boxPreloader';

import useAlertBar from 'components/app/utils/hooks/useAlertBar';
import * as act from 'redux/actions/pages/actionsPages';
import { isString, isArray } from 'lodash';
import { Menu, Dropdown as AntdDropdown, Button } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import './sessions.scss';
import {
    DisplayTimezoneSelect,
    TimezoneLabel,
} from 'components/app/common/displayTimezoneSelect';
import CreateSession from './createSession';
import SpeakerForm from '../speakers/speakerForm';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

function Sessions({ search }) {
    const { projectId, showcaseId } = useParams();
    const dispatch = useDispatch();
    const currDate = new Date();
    const sessionsDates = useSelector(
        (state) => state.pages.agendaSessionDates
    );
    const agendaSessions = useSelector((state) => state?.pages?.agendaSessions);
    const speakersdata = useSelector((state) => state.pages.agendaSpeakers);

    const { showAlertBar } = useAlertBar();
    const [isLoading, setIsLoading] = useState(true);
    const [currentLimit, setCurrentLimit] = useState(10);
    const [showForm, toggleForm] = useState(false);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [editSuccess, setEditSuccess] = useState(false);
    const [addSpeakerSuccess, setAddSpeakerSuccess] = useState(false);
    const [showDuplicatePrompt, setShowDuplicatePrompt] = useState(false);
    const [activeDate, setActiveDate] = useState(
        `${currDate.getFullYear()}-${
            currDate.getMonth() + 1
        }-${currDate.getDate()}`
    );
    const [addedDate, setAddedDate] = useState('');
    const [formTitle, setFormTitle] = useState('Create');
    const [selectedSession, setSelectedSession] = useState({});
    const [showSpeakerForm, toggleSpeakerForm] = useState(false);
    const [speakerId, setSpeakerId] = useState('');
    const [showTimezoneModal, setTimezoneModal] = useState(false);

    const activeTimezone = useSelector(
        (state) => state?.settings?.activeTimezone || moment.tz.guess()
    );

    const user = useSelector((state) => state.user);

    const allLiveShowcases = useRef([]);

    useEffect(() => {
        const handleAgendaSessions = async () => {
            try {
                if (!showcaseId) {
                    const { showcases } = await getLivePages(
                        projectId,
                        'title',
                        'asc',
                        20,
                        0,
                        '',
                        [],
                        user?.roleId === 4 ? ls.get('sponsorAuth') : '',
                        '',
                        1
                    );
                    if (showcases) {
                        allLiveShowcases.current = showcases;
                    } else throw new Error('showcases not found');
                }
                // get sessions
                getSessionDates();
                // get speakers
                getSpeakers();
                // get tags
                getTags();
            } catch (error) {
                console.error(error);
            }
        };
        handleAgendaSessions();
    }, [activeTimezone]);

    const getSession = async (date, page, limit, showcases) => {
        try {
            const currPage = page;
            const currLimit = limit;
            setIsLoading(true);
            const response = await getAgendaSessions(
                projectId,
                user?.roleId === 4
                    ? showcaseId
                        ? showcaseId
                        : allLiveShowcases.current
                              .map((showcase) => showcase.id)
                              .join(',')
                    : null,
                date,
                currLimit,
                currPage,
                search
            );
            if (response.status) {
                response.sessions.map((item) => {
                    if (isString(item.speakers)) {
                        item.speakers = JSON.parse(item.speakers);
                    }
                    if (isString(item.topicTags)) {
                        item.topicTags = JSON.parse(item.topicTags);
                    }
                    if (
                        item.logos &&
                        isString(item.logos) &&
                        item.logos !== 'null'
                    ) {
                        item.logos = JSON.parse(item.logos);
                    }
                });
                dispatch(act.getAgendaSessions(response));
                setIsLoading(false);
            }
        } catch (e) {
            console.error('ERROR getSession', e);
            showAlertBar('Fetching Session Failed', 'error');
        }
    };

    const handleSearchChange = () => {
        if (!search) {
            getSession(activeDate, 1, 10);
        } else {
            getSession(null, 1, 10);
        }
    };

    useEffect(() => {
        handleSearchChange();
    }, [search]);

    const getSessionDates = async (type = 'oninsert') => {
        try {
            const response = await getAgendaSessionDates(
                projectId,
                user?.roleId === 4
                    ? showcaseId
                        ? showcaseId
                        : allLiveShowcases.current
                              .map((showcase) => showcase.id)
                              .join(',')
                    : null
            );
            if (response.status) {
                dispatch(act.getAgendaSessionDates(response.dates));
                if (type !== 'onedit') {
                    setActiveDate(response.dates[0]);
                    getSession(response.dates[0]);
                }
            }
        } catch (e) {
            showAlertBar('Fetching Session Dates Failed', 'error');
        }
    };

    const getSpeakers = async () => {
        try {
            const response = await getAgendaSpeakers(projectId);
            if (response.status) {
                dispatch(act.getAgendaSpeakers(response.speakers));
            }
        } catch (e) {
            showAlertBar('Fetching Speakers Failed', 'error');
        }
    };

    const getTags = async () => {
        try {
            const response = await getAgendaSessionTags(projectId);
            if (response.status) {
                dispatch(act.getAgendaSessionTags(response.topics));
            }
        } catch (e) {
            showAlertBar('Fetching Tags Failed', 'error');
        }
    };

    const handlePageChange = (data) => {
        const page = data.selected + 1;
        getSession(activeDate, page, currentLimit);
    };

    const handleCurrentLimit = (limit) => {
        setCurrentLimit(limit);
        getSession(activeDate, 1, limit);
    };

    const handlePageCount = () => {
        return agendaSessions.pagination
            ? Math.ceil(agendaSessions.pagination.total / currentLimit)
            : 0;
    };

    const handleDeleteSession = async (id) => {
        const response = await deleteSession({
            id,
            projectId,
        });
        if (response.status) {
            setShowDeletePrompt(false);
            dispatch(act.deleteAgendaSession(id));
            showAlertBar(`Session successfully deleted.`, 'success');
        }
    };

    const handleDuplicateSession = async (id) => {
        try {
            const response = await duplicateSession({
                id,
                projectId,
            });
            if (response.status) {
                setShowDuplicatePrompt(false);
                getSession(activeDate);
                openNotification('success', {
                    message: `Session successfully duplicated.`,
                });
            } else throw response;
        } catch (error) {
            console.error(error);
            openNotification('error', {
                message: error?.message || `Failed to duplicate session.`,
            });
        }
    };

    const editSpeaker = (sessionId, id) => {
        setSpeakerId(id);
        setSessionId(sessionId);
        toggleSpeakerForm(true);
    };

    const onSpeakerSaved = async (formDetails, prevImgSrc, id) => {
        getSessionDates();
        getSpeakers();
        getTags();
    };

    // if not call api
    useEffect(() => {
        if (editSuccess) {
            // get session on insert
            // check if date exist
            // if (sessionsDates.includes(addedDate)) {
            // get dates
            getSessionDates('onedit');
            // const getDate = addedDate ? addedDate : activeDate;
            const getDate = selectedSession?.startTime
                ? moment(selectedSession?.startTime).format('YYYY-MM-DD')
                : addedDate;

            getSession(getDate, 1, currentLimit);
            // } else {
            //     // add date and lets
            //     dispatch(act.addSessionDate(addedDate));
            // set active date status
            setActiveDate(getDate);
            //     // get data on that date
            //     getSession(addedDate, 1, currentLimit);
            // }
            setEditSuccess(false);
        }
        if (addSpeakerSuccess) {
            getSpeakers();
        }
    }, [editSuccess, addSpeakerSuccess, addedDate]);

    useEffect(() => {
        if (!showForm) setSelectedSession({});
    }, [showForm]);

    // remove date if there's no record
    useEffect(() => {
        if (
            agendaSessions?.sessions &&
            agendaSessions.sessions.length !==
                agendaSessions.pagination.total &&
            agendaSessions.sessions.length === 0
        ) {
            // remove the date
            dispatch(act.deleteSessionDate(activeDate));
            // set new active date
            setActiveDate(sessionsDates[0]);
            // call session on selected date
            getSession(sessionsDates[0]);
        }
    }, [agendaSessions.sessions]);

    // useEffect(() => {
    //     if (agendaSessions?.sessions !== agendaSessions?.sessions) {
    //         // get sessions
    //         getSessionDates()
    //         // get speakers
    //         getSpeakers()
    //         // get tags
    //         getTags()
    //     }
    // }, [agendaSessions.sessions])

    // handle session search
    // useEffect(() => {
    //     // add interval on search
    //     if(search.length > 0) {
    //         const timer = setTimeout(() => {
    //             getSession(activeDate,1,currentLimit,search)
    //         }, 500);
    //         return () => clearTimeout(timer);
    //     } else {
    //         getSession(activeDate,1,currentLimit)
    //     }
    // }, [search]);

    const handleActivedate = (date) => {
        // convert array to string
        setActiveDate(date);
        getSession(date);
    };

    const handlePreDelete = (id) => {
        setSessionId(id);
        setShowDeletePrompt(true);
    };

    const handlePreDuplicate = (id) => {
        setSessionId(id);
        setShowDuplicatePrompt(true);
    };

    const handleEditSession = (session) => {
        setSelectedSession(session);
        setFormTitle('Edit');
    };

    // Close prompt for delete
    const handleClosePrompt = (type) => {
        if (type === 'delete') {
            setShowDeletePrompt(false);
        }
        if (type === 'duplicate') {
            setShowDuplicatePrompt(false);
        }
    };

    const renderSpeaker = (sessionId, speaker) => {
        return (
            <div
                onClick={() => editSpeaker(sessionId, speaker.id)}
                className="speaker"
                key={speaker.id}
            >
                {speaker.profilePhoto && speaker.profilePhoto !== 'null' ? (
                    <img
                        className="profile"
                        src={speaker.profilePhoto}
                        alt="speaker-profile"
                    />
                ) : (
                    <div className="speaker_profile">
                        <span>
                            {speaker.firstname?.substring(0, 1)}
                            {speaker.lastname?.substring(0, 1)}
                        </span>
                    </div>
                )}
                <div className="speaker-details">
                    <h5>
                        {speaker.firstname} {speaker.lastname}
                    </h5>
                    <span>
                        {`${speaker.jobtitle}${
                            speaker.jobtitle && speaker.company && ', '
                        } ${speaker.company}`}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className="session-container">
            <div
                onClick={() => {
                    toggleForm(true);
                    setFormTitle('Create');
                }}
                className="addSession"
            >
                <img src="/assets/icon_plus.svg" alt="add session" /> {` `}
                Create agenda session
            </div>

            <div className="session-lists">
                <div className="session-dates">
                    {sessionsDates?.length > 0 &&
                        !search &&
                        sessionsDates
                            .slice()
                            .sort((a, b) => b - a)
                            .map((date) => {
                                return (
                                    <span
                                        onClick={() => handleActivedate(date)}
                                        className={`${
                                            date.includes(activeDate)
                                                ? 'active'
                                                : ''
                                        }`}
                                        key={date}
                                    >
                                        {moment(date).format('ddd, DD MMM')}
                                    </span>
                                );
                            })}
                </div>
                {/* {timezone &&
                    !isLoading &&
                    agendaSessions?.sessions?.length > 0 && (
                        <TimezoneLabel
                            tZstyle={{ textAlign: 'right' }}
                            allowChange={false}
                        />
                    )} */}
                <TimezoneLabel
                    tZstyle={{ textAlign: 'right' }}
                    setIsModalVisible={setTimezoneModal}
                />
                <div
                    className={`session ${
                        sessionsDates?.length > 0 ? 'has-item' : ''
                    }`}
                >
                    {isLoading && (
                        <BoxPreloader noOfBoxes={5} hasTitle={false} />
                    )}
                    <DisplayTimezoneSelect
                        isModalVisible={showTimezoneModal}
                        setIsModalVisible={setTimezoneModal}
                    />
                    {!isLoading &&
                        agendaSessions?.sessions?.length > 0 &&
                        [...agendaSessions?.sessions]
                            ?.sort(
                                (a, b) =>
                                    moment(a?.startTime)
                                        ?.tz(activeTimezone)
                                        ?.format('Hmm') -
                                    moment(b?.startTime)
                                        ?.tz(activeTimezone)
                                        ?.format('Hmm')
                            )
                            .map((session) => {
                                const timeStart = moment(session?.startTime)
                                    .tz(activeTimezone)
                                    .format('hh:mma');
                                const timeEnd = moment(session?.endTime)
                                    .tz(activeTimezone)
                                    .format('hh:mma');

                                const diffInDays =
                                    moment(session?.endTime)
                                        .tz(activeTimezone)
                                        .get('days') !==
                                    moment(session?.startTime)
                                        .tz(activeTimezone)
                                        .get('days');

                                const speakers = session?.speakers.filter(
                                    (s) =>
                                        s?.LivestreamSessionSpeaker?.role ===
                                        'speaker'
                                );
                                const moderators = session?.speakers.filter(
                                    (s) =>
                                        s?.LivestreamSessionSpeaker?.role ===
                                        'moderator'
                                );
                                const panelists = session?.speakers.filter(
                                    (s) =>
                                        s?.LivestreamSessionSpeaker?.role ===
                                        'panelist'
                                );

                                return (
                                    <div
                                        className="session-item row"
                                        key={session.id}
                                    >
                                        <div className="left col-sm-3">
                                            <span className="d-block">
                                                {timeStart}
                                                {` - `}
                                                {timeEnd}
                                                <span
                                                    className={`extraday-text ${
                                                        !diffInDays
                                                            ? 'hidden'
                                                            : ''
                                                    }`}
                                                >{` +1 DAY`}</span>
                                            </span>
                                            {/* {!isEmpty(session.logo) && session.logo !== 'null' &&
                                                <img style={{ maxHeight: 75 }} className="img-fluid mt-3" src={session.logo} />
                                            } */}
                                            {isArray(session.logos) &&
                                                session.logos.map((logo) => {
                                                    return (
                                                        <img
                                                            style={{
                                                                maxHeight: 75,
                                                            }}
                                                            className="img-fluid mt-3"
                                                            src={logo}
                                                            key={logo}
                                                            alt="session-logo"
                                                        />
                                                    );
                                                })}
                                        </div>
                                        <div className="right col-sm-9">
                                            <div className="header">
                                                <h2>{session.name}</h2>
                                                <div className="actions">
                                                    <AntdDropdown
                                                        overlay={
                                                            <Menu>
                                                                <Menu.Item
                                                                    key={`${session.id}delete`}
                                                                    onClick={() =>
                                                                        handlePreDelete(
                                                                            session.id
                                                                        )
                                                                    }
                                                                >
                                                                    Delete
                                                                </Menu.Item>
                                                                <Menu.Item
                                                                    key={`${session.id}edit`}
                                                                    onClick={() => {
                                                                        handleEditSession(
                                                                            session
                                                                        );
                                                                        toggleForm(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Menu.Item>
                                                                <Menu.Item
                                                                    key={`${session.id}duplicate`}
                                                                    onClick={() =>
                                                                        handlePreDuplicate(
                                                                            session.id
                                                                        )
                                                                    }
                                                                >
                                                                    Duplicate
                                                                </Menu.Item>
                                                            </Menu>
                                                        }
                                                    >
                                                        <MoreOutlined style={{ fontSize: '17px', color: 'black' }}/>
                                                    </AntdDropdown>
                                                </div>
                                            </div>
                                            <div className="tags">
                                                {session?.topicTags.map(
                                                    (tag) => {
                                                        return (
                                                            <span key={tag.id}>
                                                                {tag.name}
                                                            </span>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            <div className="description">
                                                <p
                                                    className="box-settings__description"
                                                    dangerouslySetInnerHTML={{
                                                        __html: session.description,
                                                    }}
                                                />
                                            </div>
                                            {session?.speakers?.length > 0 && (
                                                <div className="speakers-container">
                                                    {speakers?.length > 0 && (
                                                        <h4>
                                                            Speaker
                                                            {speakers.length > 1
                                                                ? 's'
                                                                : ''}
                                                        </h4>
                                                    )}
                                                    <div className="speakers">
                                                        {speakers.map(
                                                            (speaker) => {
                                                                return renderSpeaker(
                                                                    session.id,
                                                                    speaker
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                    {moderators?.length > 0 && (
                                                        <h4>
                                                            Moderator
                                                            {moderators.length >
                                                            1
                                                                ? 's'
                                                                : ''}
                                                        </h4>
                                                    )}
                                                    <div className="speakers">
                                                        {moderators.map(
                                                            (speaker) => {
                                                                return renderSpeaker(
                                                                    session.id,
                                                                    speaker
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                    {panelists?.length > 0 && (
                                                        <h4>
                                                            Panelist
                                                            {panelists.length >
                                                            1
                                                                ? 's'
                                                                : ''}
                                                        </h4>
                                                    )}
                                                    <div className="speakers">
                                                        {panelists.map(
                                                            (speaker) => {
                                                                return renderSpeaker(
                                                                    session.id,
                                                                    speaker
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}

                    {agendaSessions?.pagination?.totalPage > 1 && (
                        <div className="align-items-center d-flex justify-content-between mt-3">
                            <Dropdown style={{ width: '100px' }}>
                                <Dropdown.Toggle
                                    variant="secondary"
                                    id=""
                                    style={{ padding: '8px 16px' }}
                                    className="m-0 myxp bg-white w-100 border rounded font-weight-bold justify-content-between"
                                >
                                    <span>Limit</span> {currentLimit}
                                </Dropdown.Toggle>

                                <Dropdown.Menu alignRight>
                                    {Array(3)
                                        .fill(5)
                                        .map((el, i) => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    handleCurrentLimit(
                                                        el * (i + 1)
                                                    );
                                                }}
                                                key={i}
                                            >
                                                {el * (i + 1)}
                                            </Dropdown.Item>
                                        ))}
                                </Dropdown.Menu>
                            </Dropdown>

                            <ReactPaginate
                                previousLabel="previous"
                                nextLabel="next"
                                breakLabel="..."
                                breakClassName="break-me"
                                pageCount={handlePageCount()}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={(data) => handlePageChange(data)}
                                containerClassName="pagination"
                                subContainerClassName="pages pagination"
                                activeClassName="active"
                            />
                        </div>
                    )}
                </div>
            </div>

            {!isLoading && search && agendaSessions?.sessions.length === 0 && (
                <div className="no-record">
                    <h2>No result for "{search}"</h2>
                </div>
            )}
            {!isLoading &&
                (!sessionsDates || sessionsDates.length === 0) &&
                agendaSessions?.sessions?.length === 0 &&
                !search && (
                    <div className="no-record">
                        <h2>You haven’t created any session yet</h2>
                        <p>
                            Get started by clicking on the “Create agenda
                            session” button
                        </p>
                    </div>
                )}

            {/* Delete confirmation */}
            <Prompt
                show={showDeletePrompt}
                message="Are you sure you want to delete this session?"
                // description={`Attendees will no longer be able to access this`}
                buttons={['Cancel', 'Proceed to Delete']}
                onHide={() => handleClosePrompt('delete')}
                callback={() => handleDeleteSession(sessionId)}
            />
            <Prompt
                show={showDuplicatePrompt}
                message="Are you sure you want to duplicate this session?"
                buttons={['Cancel', 'Proceed to duplicate']}
                onHide={() => handleClosePrompt('duplicate')}
                callback={() => handleDuplicateSession(sessionId)}
            />

            <CreateSession
                showForm={showForm}
                toggleForm={toggleForm}
                formAction={formTitle}
                selectedSession={selectedSession}
                setEditSuccess={setEditSuccess}
                setAddSpeakerSuccess={setAddSpeakerSuccess}
                setAddedDate={setAddedDate}
            />

            <SpeakerForm
                showForm={showSpeakerForm}
                toggleForm={toggleSpeakerForm}
                speakerId={speakerId}
                setSpeakerId={setSpeakerId}
                showAlertBar={showAlertBar}
                showBlanket={false}
                onSave={onSpeakerSaved}
                zIndex={100}
            />
        </div>
    );
}

export default Sessions;
