import * as ls from 'local-storage';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

export async function UploadFile(projectId, data) {
    setAuthBearer();

    let formData = new FormData();

    for (let property in data) {
        formData.append(property, data[property]);
    }

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/products/resources?projectId=${projectId}`,
        {
            method: 'POST',
            headers: { Authorization: authBearer },
            body: formData,
        }
    );

    return await response.json();
}
