import produce from "immer";
import * as ls from 'local-storage';

import { TOGGLE_MODAL } from "../actions/uiActions";

const enabledVenue = ls.get('enabledVenue');

export const initialState = {
  modal: { visibility: false },
  feature: enabledVenue !== 'null' ? 'venuecollection' : 'null'
};

function uiReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return produce(state, draft => {
        draft.modal = action.payload;
      });
    case 'ENABLE_VENUE_FEATURE':
      return {
        ...state,
        feature: action.payload ? 'venuecollection' : ''
      }
    default:
      return state;
  }
}

export default uiReducer;
