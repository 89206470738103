import produce from "immer";

export const initialState = {
    headerTitle: '',
    projectId: '',
    showcaseId: '',
};

function topNavReducers(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_HEADER_TITLE':
            return produce(state, draft => {
                draft.headerTitle = action.payload;
            });
        case 'UPDATE_PROJECT_ID':
            return produce(state, draft => {
                draft.projectId = action.payload;
            });
        case 'UPDATE_SHOWCASE_ID':
            return produce(state, draft => {
                draft.showcaseId = action.payload;
            });
        default:
            return state;
    }
}

export default topNavReducers;
