import React from 'react';

import './globalSidebar.scss';

/**
* <GlobalSidebar /> Component
* This component must be used alongside <SidebarContainer />
 *
 * <SidebarContainer>
 *     <GlobalSidebar>
 *         <theNavHere />
 *      </GlobalSidebar>
 * </SidebarContainer>
*/

const GlobalSidebar = (props) => {
    // right: boolean => position
    // top: number => distance from top
    // width: number => width of the menus, default is 210px
    // height: number => height
    // distance: number => margin from left or right
    // color: string => background color
    const {
        right,
        top: t,
        width: w,
        height: h,
        distance: d,
        color: c,
        zIndex: z,
        children
    } = props;

    return (
        <div
            id="globalSidebar"
            className={`${right ? 'pRight' : 'pLeft'}`}
            style={{
                zIndex: z ? `${z}` : '50',
                top: t ? `${t}px` : '0px',
                background: c ? `${c}` : '#ffffff',
                height: h ? `${h}vh` : '100vh',
                width: w ? `${w}px` : '210px',
                transition: 'width .6s',
                ... right ? { marginRight: d ? `${d}px` : '0px' } : { marginLeft: d ? `${d}px` : '0px' },
            }}
        >
            {/* Component */}
            {children}
        </div>
    );
}

export default GlobalSidebar;
