import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/react-hooks';

import * as ls from 'local-storage';
import produce from 'immer';

import { updateProfile } from 'apis/rest/UpdateProfile';

import useAlert from 'components/app/utils/hooks/useAlert';
import useFormValidator from 'components/app/utils/hooks/useFormValidator';

import personInfoValidationCriteria from 'components/app/utils/validators/personInfoValidator';

import countryList from 'components/app/utils/dataList/countryList';

import CREATE_PERSON from 'apis/graphql/CreatePerson';
import PEOPLE from 'apis/graphql/People';

import Alert from 'components/app/common/alert';

import './onboarding.scss';
import { useSelector } from 'react-redux';

function Onboarding(props) {
    const { history } = props;

    const user = useSelector(state => state.user)
    const project = ls.get('project');

    const companyId = user.companyId;
    const projectName = project.name;

    const initInput = {
        companyName: user.company.name,
        booth: '',
        website: '',

        linkedin: '',
        facebook: '',
        twitter: '',

        phone: '',
        email: '',

        address: '',
        locationCity: '',
        locationCountry: '',
    };

    const [input, setInput] = useState(initInput);
    const [page, setPage] = useState('welcome');
    const [isBusy, setIsBusy] = useState(false);

    const { alert, showAlert } = useAlert();

    const { validateInput, renderFieldError, hasErrors } = useFormValidator({
        input,
        validationCriteria: personInfoValidationCriteria,
    });

    const [createPerson] = useMutation(CREATE_PERSON, {
        onCompleted: (data) => {
            showAlert('Company Profile created!', 'success');

            setIsBusy(true);

            setTimeout(() => {
                setPage('lastPanel');
            }, 1000);
        },
        onError: (err) => {
            showAlert(err.message, 'error');
        },
    });

    const { data } = useQuery(PEOPLE, {
        variables: { where: { projectId: companyId } },
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        let nextState = produce(input, (draftState) => {
            draftState = initInput;
            draftState.projectId = companyId;
        });
        setInput(nextState);
    }, []);

    useEffect(() => {
        if (data) {
            if (user.isOnboarded && data.people.length > 0) {
                history.push('/');
            }
        }
    }, [data]);

    const onSubmit = (e) => {
        e.preventDefault();

        const formErrors = validateInput();

        if (!hasErrors(formErrors)) {
            createPerson({
                variables: {
                    input: {
                        data: input,
                    },
                },
            });
        } else {
            showAlert(
                'Please check the form for wrong / missing input',
                'error'
            );
        }
    };

    /**
     * handles change input field
     * @param {object} e event object
     */
    const handleInputChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        let type = e.target.type;

        let nextState;

        switch (type) {
            case 'text':
            case 'textarea':
                nextState = produce(input, (draftState) => {
                    draftState[name] = value;
                });
                break;
            case 'select-one':
                nextState = produce(input, (draftState) => {
                    draftState[name] = value;
                });
                break;
            default:
                break;
        }

        setInput(nextState);
    };

    return (
        <>
            <Alert alert={alert}></Alert>
            <div className="onboarding">
                <div className="onboarding__header">
                    <img
                        src="/assets/logo_gevme_myxp.svg"
                        alt="Gevme MYXP Logo"
                        className="mb-2"
                    />
                    <p>
                        <strong>
                            Leads Generation Apps for
                            <br />
                            {projectName}
                        </strong>
                    </p>
                </div>

                {page === 'lastPanel' && (
                    <div className="onboarding__panel onboarding__last-panel">
                        <h1 className="mb-3">What’s next?</h1>
                        <p>
                            You can now start inviting users to the Leads Scan
                            app.
                        </p>{' '}
                        <p>
                            You can read the following FAQ on how to invite
                            Leads Scan users:{' '}
                            <a
                                href="https://www.gevme.com/l/xpleads-faq/#how-do-I-invite"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                As the Leads Scan Manager, how do I invite Leads
                                Scan Users?
                            </a>
                        </p>{' '}
                        <p className="mb-3">
                            If you need help at any time, just tap on the chat
                            icon in the bottom right hand corner to reach us or
                            consult our{' '}
                            <a
                                href="https://www.gevme.com/l/xpleads-faq/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                online FAQ
                            </a>
                            .
                        </p>
                        <p className="onboarding__last-panel-chat">
                            <img src="/assets/icon_chat.svg" alt="Chat Icon" />
                        </p>
                        <div className="onboarding__footer">
                            <div className="grouper grouper--right">
                                <button
                                    className="btn btn-primary"
                                    onClick={async () => {
                                        const response = await updateProfile({
                                            isOnboarded: true,
                                        });

                                        if (response.status) {
                                            history.push('/');
                                        }
                                    }}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {page === 'welcome' && (
                    <div className="onboarding__panel onboarding__welcome">
                        <h1 className="mb-3">Welcome onboard!</h1>

                        <img
                            className="mb-3"
                            src="/assets/img_onboarding_apps.svg"
                            alt="Onboarding Apps"
                        />
                        <p>
                            From here, you will be able to manage the Leads Scan
                            and Showcase apps and the users who have access to
                            the apps.
                        </p>

                        <div className="onboarding__footer">
                            <div className="grouper grouper--right">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        if (data.people.length > 0) {
                                            setPage('lastPanel');
                                        } else {
                                            setPage('createProfile');
                                        }
                                    }}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {page === 'createProfile' && (
                    <div className="onboarding__panel">
                        <h1>Setup your company profile</h1>

                        <p className="mb-5">
                            Your company profile details will be used and
                            displayed across your apps, e.g. it could displayed
                            under the different showcases that you create under
                            the Showcase app.
                        </p>

                        <form onSubmit={onSubmit}>
                            <div className="form-group">
                                <label htmlFor="" className="control-label">
                                    Company Name{' '}
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    value={input.companyName}
                                    name="companyName"
                                    className="form-control"
                                    onChange={(e) => {
                                        handleInputChange(e);
                                    }}
                                    maxLength={200}
                                />
                                {renderFieldError('companyName')}
                            </div>

                            <div className="form-group">
                                <label htmlFor="" className="control-label">
                                    Website{' '}
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    value={input.website}
                                    name="website"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    maxLength={200}
                                />
                                {renderFieldError('website')}
                            </div>

                            <div className="form-group">
                                <label htmlFor="" className="control-label">
                                    Phone <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    value={input.phone}
                                    name="phone"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    maxLength={200}
                                />
                                {renderFieldError('phone')}
                            </div>

                            <div className="form-group">
                                <label htmlFor="" className="control-label">
                                    Email <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    value={input.email}
                                    name="email"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    maxLength={200}
                                />
                                {renderFieldError('email')}
                            </div>

                            <div className="form-group">
                                <label htmlFor="" className="control-label">
                                    Country{' '}
                                    <span className="text-danger">*</span>
                                </label>
                                <select
                                    className="form-control mb-2"
                                    name="locationCountry"
                                    onChange={handleInputChange}
                                    value={input.locationCountry}
                                >
                                    <option value="">Select Country</option>
                                    {countryList.map((country, index) => {
                                        return (
                                            <option value={country} key={index}>
                                                {country}
                                            </option>
                                        );
                                    })}
                                </select>
                                {renderFieldError('locationCountry')}
                            </div>

                            <div className="form-group">
                                <label htmlFor="" className="control-label">
                                    Booth No.
                                </label>
                                <input
                                    type="text"
                                    value={input.booth}
                                    name="booth"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    maxLength={20}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="" className="control-label">
                                    LinkedIn URL
                                </label>
                                <input
                                    type="text"
                                    value={input.linkedin}
                                    name="linkedin"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    maxLength={200}
                                />
                                {renderFieldError('linkedin')}
                            </div>

                            <div className="form-group">
                                <label htmlFor="" className="control-label">
                                    Facebook URL
                                </label>
                                <input
                                    type="text"
                                    value={input.facebook}
                                    name="facebook"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    maxLength={200}
                                />
                                {renderFieldError('facebook')}
                            </div>

                            <div className="form-group">
                                <label htmlFor="" className="control-label">
                                    Twitter URL
                                </label>
                                <input
                                    type="text"
                                    value={input.twitter}
                                    name="twitter"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    maxLength={200}
                                />
                                {renderFieldError('twitter')}
                            </div>

                            <div className="form-group">
                                <label htmlFor="" className="control-label">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    value={input.address}
                                    name="address"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    maxLength={200}
                                />
                            </div>

                            <div className="form-group mb-5">
                                <label htmlFor="" className="control-label">
                                    City
                                </label>
                                <input
                                    type="text"
                                    value={input.locationCity}
                                    name="locationCity"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    maxLength={200}
                                />
                            </div>

                            <div className="onboarding__footer">
                                <div className="grouper grouper--right">
                                    <input
                                        className="btn btn-primary"
                                        type="submit"
                                        value="Next"
                                        disabled={isBusy && 'disabled'}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </>
    );
}

export default withRouter(Onboarding);
