import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { isArray, isEmpty, isObject, set } from 'lodash';
import { useSelector } from 'react-redux';

import produce from 'immer';

import { uploadFile as uploadFileStrapi } from 'apis/rest/strapi/UploadFile';
import { importFile as importFileCrowdflow } from 'apis/rest/crowdflow/ImportFile';
import { deleteFile } from 'apis/rest/strapi/DeleteFile';
import { deleteLivePageImageBackground } from 'apis/rest/livestream/UpdateSettings';

/**
 * @param {object} input input object to be updated with the file id (if needed)
 * @param {func} setInput sets the input state with the file id (if needed)
 */
const useFileUpload = (input, setInput) => {
    const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
    const [filesToBeDeleted, setFilesToBeDeleted] = useState([]);
    const [fileTypes, setFileTypes] = useState([]);
    const [fileToBeDeleted, setFileToBeDeleted] = useState(null);

    const [progress, setProgress] = useState(0);

    const [shouldClearFilesToBeUploaded, setShouldClearFilesToBeUploaded] =
        useState(false);

    const { projectId } = useParams();
    const user = useSelector((state) => state.user);
    const companyId = user.companyId;

    /**
     * clears files to be uploaded
     */
    const clearFilesToBeUploaded = () => {
        setFilesToBeUploaded([]);
        setFileTypes([]);
        setShouldClearFilesToBeUploaded(true);
    };

    /**
     * prepares uploading of file(s) by setting the files to state
     * @param {*} files files to be uploaded
     * @param {*} fileType file types of files to be uploaded
     */
    const prepareFileUpload = (files, fileType) => {
        setShouldClearFilesToBeUploaded(false);

        let newFiles = [];
        let newFileTypes = [];
        if (!isEmpty(files)) {
            newFiles = [...filesToBeUploaded, files];
            newFileTypes = [...fileTypes, fileType];
        }

        setFilesToBeUploaded(newFiles);
        setFileTypes(newFileTypes);
        const nextState = produce(input, (draftState) => {
            if (files && files.length && files[0]) {
                set(draftState, fileType, {
                    url: URL.createObjectURL(files[0]),
                });
            } else {
                set(draftState, fileType, input.image);
            }
        });
        setInput(nextState);
    };

    const prepareFileDelete = (files, fileType) => {
        // Add files and prevent duplicate items
        setFilesToBeDeleted([...new Set([...filesToBeDeleted, ...files])]);
    };

    /**
     * handles file uploads
     * @param {object} data
     * @param {string} uploadType type of upload to know wich file upload handler to use
     */
    const handleFileUpload = async (uploadType, data, type = 'imgID') => {
        switch (uploadType) {
            case 'strapi':
                const res = await handleFileUploadStrapi(data, type);
                setFileToBeDeleted(res);
                return res;
            case 'crowdflowImport':
                return await handleFileImportCrowdflow();
            default:
                break;
        }
    };

    /**
     * Handle file deletion
     * @param {object} files files to be deleted
     * @param {string} fieldName the field name in the input data
     *
     */
    const handleFileDelete = async (files, fieldName) => {
        return await handleFileDeleteStrapi(files, fieldName);
    };

    /**
     * handles file imports to crowdflow
     */
    const handleFileImportCrowdflow = async () => {
        const importResponse = await importFileCrowdflow(
            filesToBeUploaded[0],
            projectId,
            setProgress
        );

        clearFilesToBeUploaded();

        return importResponse;
    };

    /**
     * handles file uploads to strapi
     * @param {object} data
     */
    const handleFileUploadStrapi = async (data, type) => {
        /**
         * attaches file ids to input
         */
        const attachFileIdsToInput = async (data) => {
            if (filesToBeUploaded.length > 0) {
                const uploadIds = await uploadFilesStrapi(type);

                if (uploadIds.length > 0) {
                    let updatedData = produce(data, (draftState) => {
                        uploadIds.forEach((uploadId, index) => {
                            set(draftState, fileTypes[index], uploadId);
                        });
                    });

                    return updatedData;
                } else {
                    console.error('Something went wrong with uploading.');
                }
            }
        };

        /**
         * sets `uploadId(s)` coming from `uploadFile()` to input state
         */
        const uploadFilesStrapi = async (type) => {
            const uploadIds = await Promise.all(
                filesToBeUploaded.map(async (file) => {
                    const uploadId = await uploadFileStrapi(
                        file,
                        companyId,
                        projectId,
                        type
                    );
                    return uploadId;
                })
            );

            if (uploadIds.length > 0) {
                setFilesToBeUploaded([]);
                setFileTypes([]);
                return uploadIds;
            } else {
                console.error('Something went wrong uploading the file.');
                return false;
            }
        };

        return await attachFileIdsToInput(data);
    };

    const handleFileDeleteStrapi = async (file, fieldName) => {
        let promise = null;

        const updateData = (input) => {
            let updatedData = produce(input, (draftState) => {
                if (isObject(draftState[fieldName])) {
                    // draftState[fieldName] = {};
                    set(draftState, fieldName, {});
                } else if (isArray(draftState[fieldName])) {
                    // draftState[fieldName] = [];
                    set(draftState, fieldName, []);
                } else {
                    set(draftState, fieldName, '');
                }
            });

            setInput(updatedData);
        };

        const initializeDeletion = async (file) => {
            if (isArray(file)) {
                promise = await new Promise.all(
                    file.map(async (_file) => {
                        if (_file && _file.hasOwnProperty('id')) {
                            return await deleteLivePageImageBackground(
                                _file.id
                            );
                        }
                    })
                );

                setFilesToBeDeleted([]);
                updateData(input);
            } else if (
                isObject(file) ||
                file.id ||
                fileToBeDeleted?.backgroundImage
            ) {
                promise = await new Promise(async (resolve) => {
                    let deleteresponse = null;

                    if (file.hasOwnProperty('id')) {
                        deleteresponse = await deleteLivePageImageBackground(
                            file.id || fileToBeDeleted?.backgroundImage
                        );
                        if (deleteresponse) {
                            setFilesToBeDeleted([]);
                            updateData(input);
                            resolve(deleteresponse);
                        }
                    } else {
                        deleteresponse = await deleteLivePageImageBackground(
                            file.id || fileToBeDeleted?.backgroundImage
                        );
                        if (deleteresponse) {
                            setFilesToBeDeleted([]);
                            updateData(input);
                            resolve(deleteresponse);
                        }
                    }
                });
            } else {
                setFilesToBeDeleted([]);
                updateData(input);
            }

            // For future reference
            // if (isArray(file)) {
            //     promise = await new Promise.all(
            //         file.map(async (_file) => {
            //             if (_file && _file.hasOwnProperty('id')) {
            //                 return await deleteFile(_file.id);
            //             }
            //         })
            //     );

            //     setFilesToBeDeleted([]);
            //     updateData(input);
            // } else if (isObject(file)) {
            //     promise = await new Promise(async (resolve) => {
            //         if (file.hasOwnProperty('id')) {
            //             const result = await deleteFile(file.id);

            //             setFilesToBeDeleted([]);
            //             updateData(input);
            //             resolve(result);
            //         }
            //     });
            // } else {
            //     setFilesToBeDeleted([]);
            //     updateData(input);
            // }
        };

        if (!isEmpty(file)) {
            initializeDeletion(file);
        } else {
            if (!isEmpty(filesToBeDeleted)) {
                initializeDeletion(filesToBeDeleted, fieldName);
            }
        }

        return await promise;
    };

    return {
        prepareFileUpload,
        prepareFileDelete,
        filesToBeUploaded,
        filesToBeDeleted,
        setFilesToBeUploaded,
        setFilesToBeDeleted,
        fileTypes,

        shouldClearFilesToBeUploaded,
        clearFilesToBeUploaded,

        handleFileUpload,
        handleFileDelete,
        progress,
    };
};

export default useFileUpload;
