import React from 'react';
import ImageBlockIcon from '../Icons/ImageBlockIcon';
import AaBlockIcon from '../Icons/AaBlockIcon';
import HeroContentBlockIcon from '../Icons/HeroContentBlockIcon';
import SponsorsBlockIcon from '../Icons/SponsorsBlockIcon';
import KeyNoteSpeakerBlockIcon from '../Icons/KeyNoteSpeakerBlockIcon';
import FeaturedContentBlockIcon from '../Icons/FeaturedContentBlockIcon';
import MeetingIcon from '../Icons/MeetingIcon';
import PDFIcon from '../Icons/PDFIcon';
import VideoIcon from '../Icons/VideoIcon';
import LiveStreamIcon from '../Icons/LiveStreamIcon';
import SlideShowIcon from '../Icons/SlideShowIcon';
import CTAIcon from '../Icons/CTAIcon';
import AgendaIcon from '../Icons/AgendaIcon';
import CustomHTMLIcon from '../Icons/CustomHTMLIcon';
import LinkIcon from '../Icons/LinkIcon';
import YouTubeIcon from '../Icons/YouTubeIcon';
import VimeoIcon from '../Icons/VimeoIcon';
import FacebookIcon from '../Icons/FacebookIcon';
import MSStreamIcon from '../Icons/MSStreamIcon';
import ZoomIcon from '../Icons/ZoomIcon';
import GEVMELiveIcon from '../Icons/GEVMELiveIcon';
import NotificationsIcon from '../Icons/NotificationsIcon';
import PollsIcon from '../Icons/PollsIcon';
import AttendeeProfile from '../Icons/AttendeeProfileIcon';
import ChatIcon from '../Icons/ChatIcon';
import ParticipantsIcon from '../Icons/ParticipantsIcon';
import DirectMessagesIcon from '../Icons/DirectMessagesIcon';
import MyBookmarksIcon from '../Icons/MyBookmarksIcon';
import Recommendation from '../Icons/RecommendationLogo';
import PeopleDirectory from '../Icons/PeopleDirectory';
import ProductIcon from '../Icons/ProductIcon';
import QnAIcon from '../Icons/QnAIcon';
import SearchIcon from '../Icons/SearchIcon';

const getIconByType = (type, size = 'big') => {
    if (type?.toLowerCase().includes('embediframe')) return <CustomHTMLIcon />;

    switch (type?.toLowerCase()) {
        case 'directmessage':
            return <DirectMessagesIcon />;
        case 'mybookmarks':
            return <MyBookmarksIcon />;
        case 'imageblock':
        case 'image':
        case 'imagelist':
            return <ImageBlockIcon width={34} height={14} />;
        case 'participants':
            return <ParticipantsIcon />;
        case 'chat':
            return <ChatIcon />;
        case 'recommendation':
            return <Recommendation />;
        case 'attendeeprofile':
            return <AttendeeProfile />;
        case 'notification':
        case 'notifications':
            return <NotificationsIcon />;
        case 'polls':
            return <PollsIcon />;
        case 'richtextblock':
        case 'richtext':
            return <AaBlockIcon />;
        case 'herocontentblock':
        case 'herocontent':
            return <HeroContentBlockIcon />;
        case 'featuredcontentblock':
        case 'featuredcontent':
            return <FeaturedContentBlockIcon />;
        case 'speakersblock':
        case 'speakers':
            return <KeyNoteSpeakerBlockIcon />;
        case 'sponsorblock':
        case 'sponsorsblock':
        case 'sponsor':
            return <SponsorsBlockIcon />;
        case 'meeting':
            return <MeetingIcon />;
        case 'pdf':
        case 'resources':
            return <PDFIcon />;
        case 'video':
            return <VideoIcon />;
        case 'livestream':
            return <LiveStreamIcon />;
        case 'imageslideshow':
            return <SlideShowIcon />;
        case 'cta':
        case 'ctacard':
            return <CTAIcon />;
        case 'html':
        case 'htmlblock':
            return <CustomHTMLIcon />;
        case 'link':
        case 'linkblock':
            return <LinkIcon />;
        case 'button':
        case 'buttonBlock':
            return <LinkIcon />;
        case 'gevmelive':
            return <GEVMELiveIcon />;
        case 'agenda':
            return <AgendaIcon />;
        case 'youtube':
            return (
                <YouTubeIcon
                    width={size === 'big' ? 32 : 14}
                    height={size === 'big' ? 32 : 15}
                />
            );
        case 'vimeo':
            return (
                <VimeoIcon
                    width={size === 'big' ? 32 : 18}
                    height={size === 'big' ? 32 : 17}
                />
            );
        case 'facebook':
            return (
                <FacebookIcon
                    width={size === 'big' ? 32 : 14}
                    height={size === 'big' ? 32 : 19}
                />
            );
        case 'msteams':
            return (
                <MSStreamIcon
                    width={size === 'big' ? 32 : 18}
                    height={size === 'big' ? 32 : 19}
                />
            );
        case 'zoom':
            return (
                <ZoomIcon
                    width={size === 'big' ? 32 : 18}
                    height={size === 'big' ? 32 : 19}
                />
            );
        case 'people':
            return <PeopleDirectory />;
        case 'product':
            return <ProductIcon />;
        case 'qna':
            return <QnAIcon />;
        case 'search':
            return (
                <SearchIcon
                    width={size === 'big' ? 32 : 18}
                    height={size === 'big' ? 32 : 19}
                />
            );
    }
};

export default getIconByType;
