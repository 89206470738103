import * as ls from 'local-storage';
import { store } from 'redux/store';

/**
 * create company profile
 * @param {data} object required
 */
export async function createCompanyProfile(data) {
    const auth = ls.get('auth');
    const authBearer = `Bearer ${auth.access_token}`;

    let formData = new FormData();

    for (let property in data) {
        formData.append(property, data[property]);
    }

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/exhibitor-profile`,
        {
            method: 'POST',
            headers: { Authorization: authBearer },
            body: formData,
        }
    );
    return await response.json();
}

/**
 * Update company profile by id
 * @param {companyProfileId} string required
 * @param {data} object required
 */
export async function updateCompanyProfile(exhibitorId, data) {
    const auth = ls.get('auth');
    const authBearer = `Bearer ${auth.access_token}`;

    let formData = new FormData();

    for (const property in data) {
        formData.append(property, data[property]);
    }
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/exhibitor-profile/${exhibitorId}`,
        {
            method: 'POST',
            headers: { Authorization: authBearer },
            body: formData,
        }
    );
    return await response.json();
}

/**
 * Get company profiles by project id
 * @param {projectId} string required
 */
export async function getCompanyProfiles(projectId, exhibitorId) {
    const auth = ls.get('auth');
    const user = store?.getState()?.user

    if (auth && user) {
        const authBearer = `Bearer ${auth.access_token}`;

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/exhibitor-profile/${exhibitorId}`,
            // `${process.env.REACT_APP_MYXP_API}/manager/exhibitor-profile/?projectId=${projectId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    }
}

/**
 * Get company usage by companyId
 * @param {companyId} string required
 */
export async function getCompanyUsage(companyId, type) {
    const auth = ls.get('auth');
    const user = store?.getState()?.user
    let endpoint = `${process.env.REACT_APP_MYXP_API_V2}/manager/companies/${companyId}/usage`;
    if (type) {
        endpoint += `/${type}`;
    }
    if (auth && user) {
        const authBearer = `Bearer ${auth.access_token}`;

        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        });
        return await response.json();
    }
}
