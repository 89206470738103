import produce from "immer";

export const initialState = {
    distance: 0,
    allowFX: false,
    showcaseBlocksGroup: [{id: '0', type: '', weight: '', contenz: '', large: ''}],
    livepageErrorStat: false,
};

function layoutReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_DISTANCE_WIDTH':
            return produce(state, draft => {
                draft.distance = action.payload;
            });
        case 'UPDATE_ALLOW_FX':
            return produce(state, draft => {
                draft.allowFX = action.payload;
            });
        case 'UPDATE_SHOWCASE_BLOCKS_GROUP':
            return produce(state, draft => {
                draft.showcaseBlocksGroup = action.payload;
            });
        case 'UPDATE_LIVEPAGE_ERROR_STAT':
            return produce(state, draft => {
                draft.livepageErrorStat = action.payload;
            });
        default:
            return state;
    }
}

export default layoutReducer;
