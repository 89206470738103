import * as ls from 'local-storage';
import { updatePageCollection } from 'redux/actions/pages/actionsPages';
import { store } from 'redux/store';

let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * gets the list of projects
 */
 export async function getLivePages(
    projectId,
    sortBy = 'title',
    order = 'asc',
    limit = 20,
    start = 0,
    query = '',
    label = [],
    exhibitorCompanyProfileId = '',
    short = '',
    getAll = '',
    type = '',
    status = '',
    startDate = '',
    endDate = '',
    timezone = '',
    updatedIds = [],
    createdIds = [],
    pageIds = [],
    excludePageIds = []
) {
    setAuthBearer();
    const user = store?.getState()?.user;

    // Final endpoint
    let endpoint = `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/livepages?projectId=${projectId}&sortBy=${sortBy}&sortOrder=${order}&limit=${limit}&search=${query}&filter=${label}&start=${start}&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}&short=${short}&getAll=${getAll}`;
    if (type) {
        endpoint += `&type=${type}`;
    }
    if (status) {
        endpoint += `&status=${status}`;
    }
    if (startDate) {
        endpoint += `&startDate=${startDate}`;
    }
    if (endDate) {
        endpoint += `&endDate=${endDate}`;
    }
    if (timezone) {
        endpoint += `&timezone=${timezone}`;
    }
    if (updatedIds?.length) {
        endpoint += `&updatedIds=${updatedIds.join(',')}`;
    }
    if (createdIds?.length) {
        endpoint += `&createdIds=${createdIds.join(',')}`;
    }
    if (pageIds?.length) {
        endpoint += `&pageIds=${pageIds.join(',')}`;
    }
    if (excludePageIds?.length) {
        endpoint += `&excludePageIds=${excludePageIds.join(',')}`;
    }
    if (authBearer && user) {
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        });

        const res = await response.json();

        if (res.status) {
            store.dispatch(updatePageCollection(res.showcases));
        }

        return res;
    }
}

export async function getLivePagesDataByIds(projectId, ids) {
    setAuthBearer();
    const user = store?.getState()?.user;

    // Final endpoint
    let endpoint = `${process.env.REACT_APP_MYXP_API}/manager/livestream/livepages?projectId=${projectId}`;
    if (authBearer && user) {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify({ ids }),
        });

        return await response.json();
    }
}
