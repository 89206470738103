import * as ls from 'local-storage';
import moment from 'moment';

export const getMetabaseToken = async (
    projectId,
    startDate,
    endDate,
    timezone,
    dashboardId
) => {
    try {
        const auth = ls.get('auth');
        const authBearer = `Bearer ${auth.access_token}`;

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/analytics/getLoginMetaBaseToken` +
                `?projectId=${projectId}` +
                `&startDate=${moment(startDate).format('YYYY-MM-DD HH:mm')}` +
                `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm')}` +
                `&timezone=${timezone}` +
                `&dashboard=${dashboardId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
