import * as ls from 'local-storage';
import { store } from 'redux/store';

/**
 * super admin creates new company 
 * @param {object} data data required to create new company
 */
export async function createCompany(data) {
    const auth = ls.get("auth");
    const user = store?.getState()?.user

    if (auth && user) {
        const authBearer = `Bearer ${auth.access_token}`;

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/companies`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authBearer
                },
                body: JSON.stringify(data)
            }
        );
        return await response.json();
    }
}

/**
 * Gets list of companies by superadmin
 */
export async function getCompanies(limit = 10, page = 1, searchQuery = '', archive = false, sortBy = 'updatedAt', sortOrder = true) {
    const auth = ls.get("auth");
    const user = store?.getState()?.user

    if (auth && user) {
        const authBearer = `Bearer ${auth.access_token}`;

        searchQuery = searchQuery.length ? `&search=${searchQuery}` : '';
        let limitQuery = `limit=${limit}`,
            pageQuery = `&page=${page}`,
            archiveQuery = !archive ? `&archive=false` : '', // remove archive query when showing archive, it defaults to true
            sortByQuery = `&sortBy=${sortBy}`,
            sortOrderQuery = `&sortOrder=${sortOrder ? 'desc' : 'asc'}`,
            sortQuery = sortByQuery + sortOrderQuery,
            buildQuery = limitQuery + pageQuery + searchQuery + archiveQuery + sortQuery;

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/companies?${buildQuery}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authBearer
                }
            }
        );
        return await response.json();
    }
}

/**
 * Add a manager to the company
 * @param {object} data data required manager to the company
 */
export async function addManagerToCompany(data) {
    const auth = ls.get("auth");
    const user = store?.getState()?.user

    if (auth && user) {
        const authBearer = `Bearer ${auth.access_token}`;

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/users`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authBearer
                },
                body: JSON.stringify(data)
            }
        );
        return await response.json();
    }
}
