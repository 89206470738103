import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';
import { debounce, filter, isEmpty } from 'lodash';
import { createNameInitials } from 'components/app/utils/functions/common';

import './searchSelectInput.scss';

const { Option } = Select;

const SearchSelectInput = ({
    fetchOptions,
    defaultOptions,
    debounceTimeout,
    withLogoOptions,
    filterId,
    extraData,
    ...props
}) => {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    useEffect(() => {
        debounceFetcher();
    }, []);

    useEffect(() => {
        if (
            !isEmpty(extraData) &&
            !options.find((option) => option.value === extraData.value)
        ) {
            setOptions([...options, extraData]);
        }
    }, [extraData]);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                if (!isEmpty(filterId)) {
                    let filterOptions = filter(newOptions, function (option) {
                        return option.value !== filterId;
                    });
                    setOptions(filterOptions);
                } else {
                    setOptions(newOptions);
                }
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    const optionsData = options?.map(({ label, value, logo }) => (
        <Option value={value} key={value} className="border-bottom py-3">
            {withLogoOptions && (
                <>
                    {!isEmpty(logo) && (
                        <img
                            src={logo}
                            className="img-fluid border rounded"
                            style={{ height: 32 }}
                        />
                    )}
                    {isEmpty(logo) && (
                        <div
                            className="initials-container"
                            style={{
                                width: 32,
                                height: 32,
                                display: 'inline-flex',
                            }}
                        >
                            <span className="initials-text">
                                {createNameInitials([`${label}`])}
                            </span>
                        </div>
                    )}
                </>
            )}
            <span className={withLogoOptions ? 'ml-2' : ''}>{label}</span>
        </Option>
    ));

    return (
        <Select
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            className="w-100"
            {...props}
        >
            {optionsData}
        </Select>
    );
};

SearchSelectInput.propTypes = {
    fetchOptions: PropTypes.func.isRequired,
    debounceTimeout: PropTypes.number,
    withLogoOptions: PropTypes.bool,
    defaultOptions: PropTypes.bool,
    filterId: PropTypes.string,
    extraData: PropTypes.object,
};

SearchSelectInput.defaultProps = {
    fetchOptions: () => {},
    debounceTimeout: 800,
    withLogoOptions: false,
    defaultOptions: false,
    filterId: null,
    extraData: null,
};

export default SearchSelectInput;
