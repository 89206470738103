import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as ls from 'local-storage';

import { getStreamToken } from 'apis/rest/livestream/GetStreamToken';

import { StreamChat } from 'stream-chat';
import { Tabs } from 'antd';

import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';

import * as uiActionsLive from 'redux/actions/live/uiActionsLive.js';
import Dashboard from './Dashboard';

import styles from './index.module.scss';
import { isEmpty } from 'validate.js';
import { store } from 'redux/store';

const { TabPane } = Tabs;

const ChatModeration = ({
    showcaseId = null,
    projectId = null,
    user = null,
}) => {
    const isSponsorDashboard = !isEmpty(window?.SPONSOR_AUTH);
    const params = useParams();
    const userObject = store?.getState()?.user;
    const defaultTabs = ls.get('cdtabs');
    const projectIdFallback = projectId || params?.projectId || '';

    const dispatch = useDispatch;
    dispatch(uiActionsLive.updateSubMenuVisibility(false));

    const [chatClient, setChatClient] = useState(null);
    const [tabs, setTabs] = useState(
        defaultTabs
            ? JSON.parse(defaultTabs)
            : [
                  {
                      name: '1. Firtst 10 Channels',
                      key: 'tab-' + 0,
                      length: 10,
                      active: true,
                  },
              ]
    );
    const [loading, setLoading] = useState(true);
    const [clientUser, setClientUser] = useState({});
    const [channels, setChannels] = useState([]);
    const [error, setError] = useState(null);
    const [loadingAddButton, setLoadingAddButton] = useState(true);
    const [notifyTab, setNotifyTab] = useState('');

    const initChat = async () => {
        try {
            const client = StreamChat.getInstance(
                process.env.REACT_APP_STREAM_CHAT_KEY,
                { timeout: 6000 }
            );

            const res = await getStreamToken(projectId || params.projectId);

            if (res.status) {
                const { me } = await client.connectUser(
                    {
                        id: userObject.id,
                        name: `${userObject.firstname} ${userObject.lastname}`,
                        projectId: projectIdFallback,
                    },
                    res.streamToken
                );

                setClientUser(me);
                setChatClient(client);
            } else throw res;
        } catch (error) {
            console.error(error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeTab = (tab) => {
        try {
            setTabs((prev) =>
                prev.map((p) =>
                    p.key === tab
                        ? { ...p, active: true }
                        : { ...p, active: false }
                )
            );
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddItem = (channelsLength) => {
        try {
            if (channelsLength) {
                setLoadingAddButton(true);
                setTabs((prev) => [
                    ...prev.map((d) => ({ ...d, active: false })),
                    {
                        name: `${
                            channels.length / 10 === 0
                                ? 2
                                : channels.length / 10 + 1
                        }. Next 10 Channels`,
                        length: 10,
                        active: true,
                        key: `tab-${prev.length}`,
                    },
                ]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const textNormalizer = (link) => {
        return link?.toLowerCase().split(' ').join('');
    };

    useEffect(() => {
        initChat();

        ls.set(
            'cdtabs',
            JSON.stringify([
                {
                    name: '1. First 10 Channels',
                    key: 'tab-' + 0,
                    length: 10,
                    active: true,
                },
            ])
        );

        return () => {
            if (chatClient) {
                chatClient.disconnectUser();
            }
        };
    }, []);

    useEffect(() => {
        setLoadingAddButton(true);
        if (channels) {
            setTimeout(() => setLoadingAddButton(false), 500);
        }
    }, [channels]);

    return (
        <Layout allowFixed={false} background="#F7F9FA">
            <div className={styles.Wrap}>
                <h1>Chat Moderation</h1>

                {/* <Dashboard
                    showcaseId={showcaseId || params.showcaseId}
                    projectId={projectId || params.projectId}
                    user={user || userObject}
                    chatClient={chatClient}
                    clientUser={clientUser}
                /> */}

                {loadingAddButton ? (
                    <button
                        className="btn btn-secondary mb-2 Add-Channel"
                        disabled
                    >
                        Channels loading...
                    </button>
                ) : channels.length % 10 === 0 ? (
                    <button
                        className="btn btn-primary mb-2 Add-Channel"
                        onClick={() => handleAddItem(channels.length)}
                    >
                        Load next 10 channels
                    </button>
                ) : null}
                <Tabs
                    onChange={handleChangeTab}
                    tabPosition={'left'}
                    activeKey={tabs && tabs.filter((tab) => tab.active)[0]?.key}
                >
                    {!loading &&
                        chatClient &&
                        tabs.map((tab, index) => (
                            <TabPane
                                tab={tab.name}
                                key={tab.key}
                                forceRender={true}
                            >
                                <Dashboard
                                    showcaseId={showcaseId || params.showcaseId}
                                    projectId={projectId || params.projectId}
                                    user={user || userObject}
                                    chatClient={chatClient}
                                    clientUser={clientUser}
                                    channelCount={tab.length}
                                    setChannels={setChannels}
                                    channels={channels}
                                    tab={tab.key}
                                    setNotifyTab={setNotifyTab}
                                    isSponsorDashboard={isSponsorDashboard}
                                />
                            </TabPane>
                        ))}
                </Tabs>
                <style jsx="true">{`
                    .ant-tabs-content,
                    .ant-tabs {
                        height: 100%;
                    }
                    .Add-Channel {
                        align-self: flex-start;
                    }
                `}</style>
            </div>
            <SidebarContainer>
                <MainMenu priActive={textNormalizer('chat-moderation')} all />
            </SidebarContainer>
        </Layout>
    );
};
export default ChatModeration;
