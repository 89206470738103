export const signup = async (payload, channel = '', recaptchaToken = '', recaptchaAction = '') => {
    let api = `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/accounts/email`;
    if (channel)
        api = `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/accounts/${channel}`;
    const response = await fetch(api, {
        method: 'POST',
        headers: {
            'x-api-key': process.env.REACT_APP_X_API_KEY,
            'Content-Type': 'application/json',
            'recaptcha-token': recaptchaToken,
            'recaptcha-action': recaptchaAction,
        },
        body: JSON.stringify(payload),
    });

    return response.json();
};

export const validateSignupOtp = async (payload) => {
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/accounts/validate`,
        {
            method: 'POST',
            headers: {
                'x-api-key': process.env.REACT_APP_X_API_KEY,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        }
    );

    return response.json();
};

export const updateSignupData = async (id, payload, recaptchaToken = '', recaptchaAction = '') => {
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/accounts/${id}`,
        {
            method: 'PUT',
            headers: {
                'x-api-key': process.env.REACT_APP_X_API_KEY,
                'Content-Type': 'application/json',
                'recaptcha-token': recaptchaToken,
                'recaptcha-action': recaptchaAction,
            },
            body: JSON.stringify(payload),
        }
    );

    return response.json();
};
