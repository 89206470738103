import React from 'react';

function Building() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="46"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
        >
            <path d="M18.463 8.905a1.505 1.505 0 01-2.121 0L15.096 7.66a1.504 1.504 0 010-2.122l2.82-2.82a.048.048 0 00.013-.043.047.047 0 00-.028-.034c-1.82-.78-4.179-.383-5.677 1.105-1.433 1.422-1.515 3.562-.99 5.43a1.497 1.497 0 01-.424 1.503L3 17.811A2.258 2.258 0 106.188 21l7.212-7.83a1.496 1.496 0 011.481-.428c1.854.496 3.963.403 5.378-.994 1.523-1.5 1.851-4.15 1.113-5.668a.046.046 0 00-.035-.025.047.047 0 00-.04.013l-2.834 2.836z"></path>
            <path
                fill="currentColor"
                stroke="none"
                d="M4.5 20.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
            ></path>
        </svg>
    );
}

export default Building;
