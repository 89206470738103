import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import ImageUpload from 'components/app/common/imageUpload';
import * as ls from 'local-storage';

import { useForm } from 'react-hook-form';

import './sponsorEditInputs.scss';
import { isEmpty, isUndefined } from 'lodash';
import GlobalLoading from 'components/app/components/common/globalLoading';
import CountrySelect from 'components/app/common/countrySelect';
import RichTextEditor from 'components/app/common/richTextEditor';
import Tooltip from 'rc-tooltip/es';
import { useSelector } from 'react-redux';

const SponsorEditInputs = forwardRef(
    ({ data, onChangeData, editMode }, ref) => {
        const IMAGE_CROP_ASPECT_RATIO = 7 / 5;
        const { register, errors, handleSubmit, trigger } = useForm();
        const user = useSelector(state => state.user)

        const [code, setCode] = useState('');
        const [name, setName] = useState('');
        const [logo, setLogo] = useState(null);
        const [tagline, setTagline] = useState('');
        const [country, setCountry] = useState('');
        const [description, setDescription] = useState('');
        const [custom1, setCustom1] = useState('');
        const [custom2, setCustom2] = useState('');
        const [custom3, setCustom3] = useState('');
        const [custom4, setCustom4] = useState('');
        const [custom5, setCustom5] = useState('');
        const [visibleImageComponent, setVisibleImageComponent] =
            useState(false);

        useImperativeHandle(ref, () => ({
            handleSubmitForm() {
                return trigger();
            },
        }));

        useEffect(() => {
            setCode(data?.code ? data?.code : '');
            setName(data?.name ? data?.name : '');
            setLogo(data?.logo ? data?.logo : null);
            setTagline(data?.tagline ? data?.tagline : '');
            setCountry(data?.country ? data?.country : '');
            setDescription(data?.description ? data?.description : '');
            setCustom1(data?.custom1 ? data?.custom1 : '');
            setCustom2(data?.custom2 ? data?.custom2 : '');
            setCustom3(data?.custom3 ? data?.custom3 : '');
            setCustom4(data?.custom4 ? data?.custom4 : '');
            setCustom5(data?.custom5 ? data?.custom5 : '');
            if (editMode) {
                if (!isUndefined(data?.logo)) setVisibleImageComponent(true);
            } else {
                setVisibleImageComponent(true);
            }
        }, [data]);

        useEffect(() => {
            let updatedData = { ...data };

            updatedData.name = name;
            updatedData.logo = logo;
            updatedData.tagline = tagline;
            updatedData.country = country;
            updatedData.description = description;
            updatedData.code = code;
            updatedData.custom1 = custom1;
            updatedData.custom2 = custom2;
            updatedData.custom3 = custom3;
            updatedData.custom4 = custom4;
            updatedData.custom5 = custom5;

            onChangeData(updatedData);
        }, [
            name,
            logo,
            tagline,
            country,
            description,
            code,
            custom1,
            custom2,
            custom3,
            custom4,
            custom5,
        ]);

        return (
            <>
                <div className="form-group">
                    <label>
                        Unique ID{' '}
                        <span className="font-weight-bold text-danger">*</span>
                    </label>
                    <input
                        className="form-control"
                        name="code"
                        defaultValue={code}
                        onChange={(e) => setCode(e.target.value)}
                        ref={register({
                            required: true,
                            maxLength: 80,
                            minLength: 3,
                        })}
                        disabled={user.roleId !== 2}
                    />
                    {errors.code?.type === 'required' && (
                        <div className="invalid-feedback d-block">
                            Unique ID is required
                        </div>
                    )}
                    {errors.code?.type === 'maxLength' && (
                        <div className="invalid-feedback d-block">
                            Unique ID exceed max length(80)
                        </div>
                    )}
                    {errors.code?.type === 'minLength' && (
                        <div className="invalid-feedback d-block">
                            Unique ID min length(3)
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label>
                        Name{' '}
                        <span className="font-weight-bold text-danger">*</span>
                    </label>
                    <input
                        className="form-control"
                        name="name"
                        defaultValue={name}
                        onChange={(e) => setName(e.target.value)}
                        ref={register({ required: true, maxLength: 80 })}
                        disabled={user.roleId !== 2}
                    />
                    {errors.name?.type === 'required' && (
                        <div className="invalid-feedback d-block">
                            Name is required
                        </div>
                    )}
                    {errors.name?.type === 'maxLength' && (
                        <div className="invalid-feedback d-block">
                            Name exceed max length(80)
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label>Logo</label>
                    <div style={{ maxWidth: 500 }}>
                        {visibleImageComponent && (
                            <ImageUpload
                                previewImages={logo ? [logo] : []}
                                prepareFilesUpload={(files) => {
                                    setLogo(!isEmpty(files) ? files[0] : null);
                                }}
                                imageCropAspectRatio={IMAGE_CROP_ASPECT_RATIO}
                            />
                        )}
                        {!visibleImageComponent && <GlobalLoading />}
                    </div>
                </div>
                <div className="form-group">
                    <label>Tagline</label>
                    <input
                        className="form-control"
                        name="tagline"
                        value={tagline}
                        onChange={(e) => setTagline(e.target.value)}
                        maxLength={50}
                    />
                    <small class="form-text text-muted">
                        Max character limit (with space) - 50
                    </small>
                </div>

                <div className="form-group">
                    <label>Country</label>
                    <CountrySelect
                        country={country}
                        setCountry={setCountry}
                        disabled={user.roleId !== 2}
                    />
                </div>

                <div className="form-group">
                    <label>Description</label>
                    <RichTextEditor
                        id="description"
                        model={description}
                        onModelChange={setDescription}
                        allowOnlyLineBreaks
                        htmlAllowedTags={['br', 'span', 'p', 'div', 'iframe']}
                        heightMin={150}
                    />
                </div>
                {user.roleId === 2 && (
                    <>
                        <div className="form-group">
                            <label>
                                Custom Field 1
                                <Tooltip
                                    placement="right"
                                    trigger="hover"
                                    key="project-name-question"
                                    overlay={
                                        <div className="project-tooltip">
                                            Custom Field can be pre-defined and
                                            set up with GEVME’s assistance,
                                            please contact our Support at{' '}
                                            <a
                                                href="mailto:cs.virtual@gevme.com"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                cs.virtual@gevme.com
                                            </a>
                                        </div>
                                    }
                                >
                                    <i className="fa fa-question-circle ml-1 pointer" />
                                </Tooltip>
                            </label>
                            <input
                                className="form-control"
                                name="custom1"
                                value={custom1}
                                onChange={(e) => setCustom1(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Custom Field 2</label>
                            <input
                                className="form-control"
                                name="custom2"
                                value={custom2}
                                onChange={(e) => setCustom2(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Custom Field 3</label>
                            <input
                                className="form-control"
                                name="custom3"
                                value={custom3}
                                onChange={(e) => setCustom3(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Custom Field 4</label>
                            <input
                                className="form-control"
                                name="custom4"
                                value={custom4}
                                onChange={(e) => setCustom4(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Custom Field 5</label>
                            <input
                                className="form-control"
                                name="custom5"
                                value={custom5}
                                onChange={(e) => setCustom5(e.target.value)}
                            />
                        </div>
                    </>
                )}
            </>
        );
    }
);

SponsorEditInputs.propTypes = {
    data: PropTypes.object,
    onChangeData: PropTypes.func,
};

SponsorEditInputs.defaultProps = {
    data: null,
    // onSubmitFormValidate: true
};

export default SponsorEditInputs;
