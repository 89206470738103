import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as ls from 'local-storage';
import debounce from 'lodash/debounce';

import PageSpinner from 'components/app/common/pageSpinner';
import {
    getMediaViews,
    exportMediaViews,
    getMediaUsers,
    exportMediaUsers,
} from '../../api';
import FileSaver from 'file-saver';

import moment from 'moment';
import './index.scss';
import { Table, Modal, Menu, Dropdown, Button } from 'antd';
import { LivePageSelector, handleAnalyticsToken } from '../../helpers';
import Search from 'components/app/components/venue/search';
import { DownOutlined } from '@ant-design/icons';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';

const PAGE_SIZE = 10;

const MediaViews = () => {
    const isSponsor = !!ls.get('sponsorIdentifier');
    const projectId = useSelector((state) => state?.header?.projectId);
    const [mediaViews, setMediaViews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationInfo, setPagination] = useState({});
    const [isExporting, setExporting] = useState(false);
    const [hasNoData, setNoData] = useState(false);
    const pageFlag = useRef(false);
    const isMounted = useRef(false);

    const [modalVisibility, setModalVisibility] = useState(false);
    const [peopleData, setPeopleData] = useState([]);
    const [peoplePaginationData, setPeoplePaginationData] = useState([]);
    const peoplePageFlag = useRef(false);
    const selectedResource = useRef(null);
    const [currentPeoplePage, setPeopleCurrentPage] = useState(1);
    const [isMediaDataLoading, setIsMediaDataLoading] = useState(false);
    const [isPeopleDataLoading, setPeopleLoading] = useState(true);
    const [isPeopleExporting, setPeopleExporting] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [searchDebounceText, setSearchDebounceText] = useState(null);

    const handleSearch = (searchTerm) => {
        setSearchValue(searchTerm);
        searchDebounce(searchTerm);
    };

    const searchDebounce = useCallback(
        debounce((searchTerm) => setSearchDebounceText(searchTerm), 500),
        []
    );

    const { startDate, endDate, livePageId } = useSelector(
        (state) => state?.venueAnalytics
    );
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const [isLoading, setLoading] = useState(true);

    const [error, setError] = useState('');

    const [sortDetails, setSortDetails] = useState({
        order: 'DESC',
        by: 'totalPlays',
    });

    const columns = [
        {
            title: <span className="columnHeading">Media Title</span>,
            dataIndex: 'media_title',
            key: 'media_title',
            sorter: true,
            render: (val, rowData) => {
                return rowData.media_title || '-';
            },
        },
        {
            title: <span className="columnHeading">Media Url</span>,
            dataIndex: 'resource',
            key: 'resource',
            sorter: true,
            render: (val, rowData) => {
                return rowData.resource || '-';
            },
        },
        {
            title: <span className="columnHeading">Average Watch Time</span>,
            dataIndex: 'avgWatchedTime',
            sorter: true,
            key: 'avgWatchedTime',
            render: (val, rowData) => {
                return rowData.avgWatchedTime || '-';
            },
        },
        {
            title: <span className="columnHeading">Unique Plays</span>,
            dataIndex: 'uniquePlays',
            key: 'uniquePlays',
            sorter: true,
            render: (val, rowData) => {
                return (
                    <a
                        className="totalPlays"
                        onClick={() =>
                            handleUserModal({ ...rowData, view: 'unique' })
                        }
                    >
                        {val || '-'}
                    </a>
                );
            },
        },
        {
            title: <span className="columnHeading">Total Plays</span>,
            dataIndex: 'totalPlays',
            key: 'totalPlays',
            sorter: true,
            render: (val, rowData) => {
                return (
                    <a
                        className="totalPlays"
                        onClick={() =>
                            handleUserModal({ ...rowData, view: 'total' })
                        }
                    >
                        {val || '-'}
                    </a>
                );
            },
        },
    ];
    const mediaUsersColumns = [
        {
            title: <span className="columnHeading">First Name</span>,
            dataIndex: 'firstName',
            key: 'firstName',
            render: (val, rowData) => {
                return rowData.firstName || '-';
            },
        },
        {
            title: <span className="columnHeading">Last Name</span>,
            dataIndex: 'lastName',
            key: 'lastName',
            render: (val, rowData) => {
                return rowData.lastName || '-';
            },
        },
        {
            title: <span className="columnHeading">Email</span>,
            dataIndex: 'email',
            key: 'email',
            render: (val, rowData) => {
                return rowData.email || '-';
            },
        },
        {
            title: <span className="columnHeading">Company</span>,
            dataIndex: 'company',
            key: 'company',
            render: (val, rowData) => {
                return rowData.company || '-';
            },
        },
        {
            title: <span className="columnHeading">Job Title</span>,
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            render: (val, rowData) => {
                return rowData.jobTitle || '-';
            },
        },
        {
            title: <span className="columnHeading">Start Timestamp</span>,
            dataIndex: 'startTime',
            key: 'startTime',
            render: (val, rowData) => {
                return rowData.startTime || '-';
            },
        },
        {
            title: <span className="columnHeading">End Timestamp</span>,
            dataIndex: 'endTime',
            key: 'endTime',
            render: (val, rowData) => {
                return rowData.endTime || '-';
            },
        },
        {
            title: (
                <span className="columnHeading">
                    {selectedResource.current?.view === 'unique'
                        ? 'Average Watch Duration'
                        : 'Watch Duration'}
                </span>
            ),
            dataIndex:
                selectedResource.current?.view === 'unique'
                    ? 'avgWatchedTime'
                    : 'watched_time',
            key:
                selectedResource.current?.view === 'unique'
                    ? 'avgWatchedTime'
                    : 'watched_time',
            render: (val, rowData) => {
                return selectedResource.current?.view === 'unique'
                    ? rowData.avgWatchedTime || '-'
                    : rowData.watched_time || '-';
            },
        },
    ];

    if (selectedResource.current?.view === 'unique') {
        mediaUsersColumns.splice(5, 2);
    }

    selectedResource.current?.view === 'unique' &&
        mediaUsersColumns.push({
            title: <span className="columnHeading">Total Watch Duration</span>,
            dataIndex: 'totalWatchedTime',
            key: 'totalWatchedTime',
            render: (val, rowData) => {
                return rowData.totalWatchedTime || '-';
            },
        });

    const getUsersData = async () => {
        try {
            setPeopleLoading(true);
            const { status, message, mediaViews, pagination } =
                await getMediaUsers(
                    livePageId,
                    projectId,
                    timezone.toLowerCase(),
                    selectedResource.current.resource,
                    selectedResource.current.view,
                    selectedResource.current.media_title,
                    startDate,
                    endDate,
                    currentPeoplePage
                );
            if (status && mediaViews.length > 0) {
                const parsedViews = mediaViews.map((data, index) => ({
                    key: `${data.userId}-${index}`,
                    firstName: data?.firstname ? data.firstname : '',
                    lastName: data?.lastname ? data.lastname : '',
                    email: data?.email ? data.email : '',
                    company: data?.company ? data.company : '',
                    jobTitle: data?.jobTitle ? data.jobTitle : '',
                    endTime: data?.endTime || '',
                    startTime: data?.startTime || '',
                    activity_time: data?.activity_time
                        ? moment(data.activity_time).format(
                              'MMM D, YYYY hh:mm:ss a'
                          )
                        : data?.server_time
                        ? moment(data.server_time).format(
                              'MMM D, YYYY hh:mm:ss a'
                          )
                        : '',
                    watched_time: data?.watched_time ? data.watched_time : '',
                    avgWatchedTime: data?.avgWatchedTime
                        ? data.avgWatchedTime
                        : '',
                    totalWatchedTime: data?.totalWatchedTime || '',
                }));
                setPeopleData(parsedViews);
                setPeoplePaginationData(pagination);
                setPeopleLoading(false);
            } else throw new Error(message);
        } catch (error) {
            console.error(error);
            setPeopleData([]);
            setPeopleLoading(false);
        }
    };
    const handleUserModal = ({ resource, view, media_title }) => {
        setModalVisibility(true);
        selectedResource.current = { resource, view, media_title };
        getUsersData();
    };
    const exportUsersData = async () => {
        try {
            setPeopleExporting(true);
            const response = await exportMediaUsers(
                livePageId,
                projectId,
                timezone.toLowerCase(),
                selectedResource.current.resource,
                selectedResource.current.view,
                selectedResource.current.media_title,
                startDate,
                endDate
            );

            FileSaver.saveAs(
                response,
                `Media-Users-Data-${moment().unix()}.csv`
            );

            setPeopleExporting(false);
        } catch (error) {
            console.error(error);
            setPeopleExporting(false);
        }
    };
    const handleMediaViews = async (search = null, page = null) => {
        try {
            setIsMediaDataLoading(true);
            const { status, message, mediaViews, pagination } =
                await getMediaViews(
                    livePageId,
                    projectId,
                    timezone.toLowerCase(),
                    startDate,
                    endDate,
                    page || currentPage,
                    PAGE_SIZE,
                    search || searchValue,
                    sortDetails?.by,
                    sortDetails?.order
                );
            if (status) {
                setIsMediaDataLoading(false);
                if (mediaViews.length === 0) {
                    setNoData(true);
                } else {
                    setNoData(false);
                }

                const parsedMediaViews = mediaViews.map((data, index) => ({
                    key: `mediaView-${index}-${currentPage}`,
                    ...data,
                }));

                setMediaViews(parsedMediaViews);
                setPagination(pagination);
            } else {
                setIsMediaDataLoading(false);
                throw new Error(message);
            }
        } catch (error) {
            if (error && error.message.includes('jwt expired')) {
                handleAnalyticsToken();
            }
            console.error(error);
        }
    };
    const handleExport = async (viewType = null) => {
        try {
            setExporting(true);
            const response = await exportMediaViews(
                livePageId,
                projectId,
                timezone.toLowerCase(),
                startDate,
                endDate,
                viewType
            );

            FileSaver.saveAs(
                response,
                `Media-${viewType ? 'Viewers' : 'Views'}${
                    viewType ? `-${viewType}` : ''
                }-${moment().unix()}.csv`
            );

            setExporting(false);
        } catch (error) {
            console.error(error);
            setExporting(false);
        }
    };
    const exportMenu = (
        <Menu
            onClick={({ key }) => {
                if (key === '1') handleExport('unique');
                else if (key === '2') handleExport('all');
                else handleExport();
            }}
        >
            <Menu.Item key="1">All unique media viewers</Menu.Item>
            <Menu.Item key="2">All media viewers</Menu.Item>
            <Menu.Item key="3">All media viewership</Menu.Item>
        </Menu>
    );

    const handleChange = (pagination, filters, sorter) => {
        const { field = null, order = null } = sorter;
        if (field && order)
            setSortDetails({
                order: order.includes('asc') ? 'ASC' : 'DESC',
                by: field,
            });
    };

    useEffect(() => {
        if (livePageId && startDate && endDate) {
            if (currentPage !== 1) {
                setCurrentPage(1);
                pageFlag.current = false;
            }
            handleMediaViews(null, 1);
        }
    }, [livePageId, startDate, endDate, timezone]);

    useEffect(() => {
        if (pageFlag.current) {
            handleMediaViews();
        }
        pageFlag.current = true;
    }, [currentPage]);

    useEffect(() => {
        if (peoplePageFlag.current) {
            getUsersData();
        }
        peoplePageFlag.current = true;
    }, [currentPeoplePage]);

    useEffect(() => {
        if (!modalVisibility) {
            setPeopleData([]);
            setPeoplePaginationData([]);
            setPeopleCurrentPage(1);
            peoplePageFlag.current = false;
            selectedResource.current = null;
        } else {
            peoplePageFlag.current = true;
        }
    }, [modalVisibility]);

    useEffect(() => {
        if (searchDebounceText !== null) {
            setCurrentPage(1);
            handleMediaViews(searchDebounceText, 1);
        }
    }, [searchDebounceText]);

    useEffect(() => {
        if (sortDetails?.order && sortDetails?.by && isMounted.current) {
            handleMediaViews();
        }
        isMounted.current = true;
    }, [sortDetails]);

    return (
        <div className="interactionsAnalysisContainer">
            {!error && (
                <>
                    <LivePageSelector
                        {...{
                            projectId,
                            setLoading,
                            setError,
                            isLoading,
                        }}
                    />
                </>
            )}
            {error && <div className="errorContainer">{error}</div>}

            <div className="d-flex align-items-center justify-content-end mt-2">
                <div className="d-flex align-items-center">
                    <Search
                        handleSearch={handleSearch}
                        searchValue={searchValue}
                        placeholderText="Search"
                    />
                </div>
            </div>

            {isMediaDataLoading && (!mediaViews || mediaViews.length === 0) && (
                <PageSpinner
                    type="Oval"
                    color="#ACBDC9"
                    height={45}
                    width={45}
                    msg="Fetching data..."
                />
            )}

            {!error && mediaViews.length > 0 && (
                <div className="visitsContainer">
                    <div className="titleContainer">
                        <h2 className="pageHeading">
                            What media did visitors watch?
                        </h2>
                        <span>
                            {`${(currentPage - 1) * PAGE_SIZE + 1}-${
                                (currentPage - 1) * PAGE_SIZE +
                                mediaViews.length
                            } of ${paginationInfo.total} records`}
                        </span>
                    </div>
                    <Table
                        onChange={handleChange}
                        dataSource={mediaViews}
                        columns={columns}
                        loading={isMediaDataLoading}
                        pagination={{
                            position: ['none', 'bottomCenter'],
                            onChange: setCurrentPage,
                            pageSize: PAGE_SIZE,
                            total: paginationInfo?.total,
                            showSizeChanger: false,
                            current: currentPage,
                        }}
                    />
                    <div className="exportContainer">
                        <Dropdown overlay={exportMenu}>
                            <Button loading={isExporting}>
                                Export <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
            )}

            {hasNoData && !isMediaDataLoading && (
                <div className="visitsContainer">
                    <div className="noUserContainer">No media views found</div>
                </div>
            )}
            <Modal
                width={1200}
                visible={modalVisibility}
                footer={null}
                onCancel={() => setModalVisibility(false)}
            >
                <div className="peopleModal">
                    {isPeopleDataLoading && (
                        <PageSpinner
                            type="Oval"
                            color="#ACBDC9"
                            height={48}
                            width={48}
                        />
                    )}
                    {peopleData.length > 0 && !isPeopleDataLoading && (
                        <>
                            <Table
                                style={{ width: '1200px' }}
                                dataSource={peopleData}
                                columns={mediaUsersColumns}
                                bordered
                                pagination={{
                                    position: ['none', 'bottomCenter'],
                                    onChange: setPeopleCurrentPage,
                                    pageSize: PAGE_SIZE,
                                    total: peoplePaginationData?.total,
                                    showSizeChanger: false,
                                    current: currentPeoplePage,
                                }}
                            />
                            <div className="exportContainer">
                                <div
                                    onClick={() => {
                                        if (!isPeopleExporting)
                                            exportUsersData();
                                    }}
                                >
                                    {isPeopleExporting
                                        ? 'Exporting...'
                                        : 'Export'}
                                </div>
                            </div>
                        </>
                    )}
                    {peopleData.length === 0 && !isPeopleDataLoading && (
                        <div className="noRecords">No records found</div>
                    )}
                </div>
            </Modal>

            <style>{`
            ant-select-selection-search
            {
                height: 100%;
                line-height: 46px;

            }
            `}</style>
        </div>
    );
};
export default MediaViews;
