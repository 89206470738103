import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IFrameResizer from 'iframe-resizer-react';
import { withRouter, useParams } from 'react-router-dom';
import PageSpinner from 'components/app/common/pageSpinner';
import TimezoneSelector from 'components/app/common/timezoneSelector';
import { getMetabaseToken } from './api';

import './index.scss';
import DateTimeRangePicker from 'components/app/common/dateTimeRangePicker';
import { Select, Tooltip, Tabs, Switch, Button } from 'antd';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import moment from 'moment';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import toggleFullscreen from '../utils/fullscreen';
const { TabPane } = Tabs;
const { Option } = Select;

const autoRefreshOptions = [
    { value: '1', label: '1 minute' },
    { value: '2', label: '2 minutes' },
    { value: '5', label: '5 minutes' },
    { value: '10', label: '10 minutes' },
    { value: '15', label: '15 minutes' },
    { value: '30', label: '30 minutes' },
    { value: '60', label: '60 minutes' },
];

const NIGHT_MODE_TEXT = '#dedfe0';
const NIGHT_MODE_BG = '#2e353b';
const Logins = () => {
    const { projectId } = useParams();
    const { startDate, endDate, activeMenu } = useSelector(
        (state) => state?.venueAnalytics
    );
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const dispatch = useDispatch();
    const [metabaseToken, setMetabaseToken] = useState(null);
    const [autoRefresh, setAutoRefresh] = useState(1);
    const [isNightMode, setIsNightMode] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(false);

    const [dashboardId, setDashboardID] = useState(
        process.env.REACT_APP_VIRTUAL_LOGINS_METABASE_DASHBOARD_ID
    );

    useEffect(() => {
        getMetaToken();
    }, [startDate, endDate, timezone, autoRefresh, dashboardId, isNightMode]);

    async function getMetaToken() {
        const json = await getMetabaseToken(
            projectId,
            startDate,
            endDate,
            timezone,
            dashboardId
        );
        setMetabaseToken(json.token);
    }

    const handleChange = (val) => {
        switch (val) {
            case 'virtualLogins': {
                setDashboardID(
                    process.env.REACT_APP_VIRTUAL_LOGINS_METABASE_DASHBOARD_ID
                );
                break;
            }
            case 'physicalCheckIns': {
                setDashboardID(
                    process.env
                        .REACT_APP_PHYSICAL_CHECK_IN_METABASE_DASHBOARD_ID
                );
                break;
            }
            case 'hybrid': {
                setDashboardID(
                    process.env.REACT_APP_HYBRID_METABASE_DASHBOARD_ID
                );
                break;
            }
        }
    };

    const updateAutoRefreshStartEndDate = () => {
        const currentDate = moment().format('YYYY-MM-DDTHH:mm:ss');
        if (moment(currentDate).isAfter(endDate)) {
            let start = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
            let end = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
            dispatch({
                type: 'SET_DATE',
                payload: { startDate: start, endDate: end },
            });
        }
    };

    const handleNightMode = () => {
        setIsNightMode(!isNightMode);
    };

    const handleFullscreen = () => {
        toggleFullscreen('_dashboard', setShowFullScreen);
    };

    return (
        <div className="interactionsAnalysisContainer">
            <div style={{ position: 'relative', marginBottom: '10px' }}>
                <div className="visitLiveBox controls">
                    <DateTimeRangePicker showTime showRelativePicker showLive />
                </div>
                <TimezoneSelector />
                {/* auto refresh dropdown  */}
                {activeMenu === 'live' && (
                    <Tooltip title="Set the refresh frequency of the dashboard">
                        <div className="autoRefresh d-flex align-items-center">
                            {autoRefresh && autoRefresh !== 'off' && (
                                <CountdownCircleTimer
                                    key={autoRefresh}
                                    style={{ marginLeft: 10 }}
                                    strokeLinecap="butt"
                                    size={25}
                                    isPlaying
                                    duration={autoRefresh * 60}
                                    colors={[
                                        '#004777',
                                        '#F7B801',
                                        '#A30000',
                                        '#A30000',
                                    ]}
                                    colorsTime={[10, 6, 3, 0]}
                                    onComplete={() => {
                                        updateAutoRefreshStartEndDate();
                                        return {
                                            shouldRepeat: true,
                                            delay: 0,
                                        };
                                    }}
                                ></CountdownCircleTimer>
                            )}
                            <Select
                                placeholder="Auto-Refresh"
                                defaultValue="1 minute"
                                style={{ width: '150px', marginLeft: '5px' }}
                                onChange={(value) => setAutoRefresh(value)}
                            >
                                {autoRefreshOptions.map((option) => (
                                    <Option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </Tooltip>
                )}
            </div>

            {/* new tab for virtual,physical & hybrid check-in */}
            <div id="_dashboard">
                <Tabs
                    tabBarStyle={{
                        color: '#0000',
                        background: `${
                            isNightMode ? NIGHT_MODE_BG : '#FFFFFF'
                        }`,
                        margin: '0px',
                        padding: '15px 15px 0px',
                        height: '56px',
                    }}
                    defaultActiveKey="1"
                    onChange={handleChange}
                >
                    <TabPane tab="Virtual Logins" key="virtualLogins">
                        {metabaseToken && (
                            <div className="visitLiveBox">
                                <IFrameResizer
                                    src={
                                        'https://bi.gevme.com' +
                                        '/embed/dashboard/' +
                                        metabaseToken +
                                        `#bordered=false&titled=false&refresh=${
                                            autoRefresh * 60
                                        }&${isNightMode && 'night'}`
                                    }
                                    style={{
                                        width: '1px',
                                        minWidth: '100%',
                                        border: 'none',
                                    }}
                                />
                            </div>
                        )}
                        {!metabaseToken && (
                            <PageSpinner
                                type="Oval"
                                color="#ACBDC9"
                                height={45}
                                width={45}
                                msg="Fetching data..."
                            />
                        )}
                    </TabPane>
                    <TabPane tab="Physical Check-Ins" key="physicalCheckIns">
                        {metabaseToken && (
                            <div className="visitLiveBox">
                                <IFrameResizer
                                    src={
                                        'https://bi.gevme.com' +
                                        '/embed/dashboard/' +
                                        metabaseToken +
                                        `#bordered=false&titled=false&refresh=${
                                            autoRefresh * 60
                                        }&${isNightMode && 'night'}`
                                    }
                                    style={{
                                        width: '1px',
                                        minWidth: '100%',
                                        border: 'none',
                                    }}
                                />
                            </div>
                        )}
                        {!metabaseToken && (
                            <PageSpinner
                                type="Oval"
                                color="#ACBDC9"
                                height={45}
                                width={45}
                                msg="Fetching data..."
                            />
                        )}
                    </TabPane>
                    <TabPane tab="Hybrid" key="hybrid">
                        {metabaseToken && (
                            <div className="visitLiveBox">
                                <IFrameResizer
                                    src={
                                        'https://bi.gevme.com' +
                                        '/embed/dashboard/' +
                                        metabaseToken +
                                        `#bordered=false&titled=false&refresh=${
                                            autoRefresh * 60
                                        }&${isNightMode && 'night'}`
                                    }
                                    style={{
                                        width: '1px',
                                        minWidth: '100%',
                                        border: 'none',
                                    }}
                                />
                            </div>
                        )}
                        {!metabaseToken && (
                            <PageSpinner
                                type="Oval"
                                color="#ACBDC9"
                                height={45}
                                width={45}
                                msg="Fetching data..."
                            />
                        )}
                    </TabPane>
                </Tabs>

                {/* nightmode and fullscreen  */}
                <div className="night-mode-and-fullscreen">
                    <Tooltip title="Night Mode/Day Mode">
                        <Switch size="small" onChange={handleNightMode} />
                    </Tooltip>
                    <Tooltip title="Switch to fullscreen mode">
                        <button
                            style={{
                                background: `${
                                    isNightMode ? NIGHT_MODE_BG : 'white'
                                }`,
                                fontSize: '18px',
                            }}
                            className="border-0 mx-2 text-primary"
                            onClick={handleFullscreen}
                        >
                            {showFullScreen ? (
                                <FullscreenExitOutlined />
                            ) : (
                                <FullscreenOutlined />
                            )}
                        </button>
                    </Tooltip>
                </div>
            </div>

            <style>
                {`
                .ant-tabs-tab
                {  
                    color: ${isNightMode ? NIGHT_MODE_TEXT : 'black'};
                    font-family: 'Open Sans';
                    letter-spacing: -0.2px;
                    font-weight: 400;
                    
                }
                .ant-tabs-tab>div
                {
                    color:${isNightMode ? NIGHT_MODE_TEXT : 'black'};
                }
                .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                    color: #2190ff !important;
                }
                #_dashboard{
                    overflow-y:scroll;
                    position:relative;
                }
                .night-mode-and-fullscreen{
                    position:absolute;
                    top:10px;
                    right:10px;
                    z-index:99
                }
                `}
            </style>
        </div>
    );
};

export default withRouter(Logins);
