import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { DownOutlined, MoreOutlined, LeftOutlined } from '@ant-design/icons';
import {
    Layout,
    Collapse,
    Tooltip,
    Input,
    Switch,
    Select,
    Spin,
    AutoComplete,
} from 'antd';
import moment from 'moment';

import { isEmpty, debounce } from 'lodash';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';
import uuid from 'react-uuid';
import Countdown from 'react-countdown';

import PreviewWindow from './previewWindow';
import ImageUpload from 'components/app/common/imageUpload/imageUpload';
import RichTextEditor from 'components/app/common/richTextEditor';

import SettingsText from 'Apps/VenueBuilder/Components/Common/SettingsText';
import NotificationBox from './notificationBox';
import { POSITIONS } from './notificationBox/notificationBox';
import ScheduleModal from './scheduleModal';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

import { getLivePages } from 'apis/rest/livestream/GetLivePages';
import { CreateNotification } from 'apis/rest/notifications/CreateNotification';
import { UpdateNotification } from 'apis/rest/notifications/UpdateNotification';
import { GetNotification } from 'apis/rest/notifications/GetNotification';
import { CancelNotification } from 'apis/rest/notifications/CancelNotification';

import './createAnnouncement.scss';
import SwitchComponent from 'Apps/VenueBuilder/Components/Common/SwitchComponent';
import SendSettings from '../../helpers/SendSettings';
import LivePagesList from 'Apps/VenueBuilder/Components/Common/LivePagesList';
import * as ls from 'local-storage';
import { useSelector } from 'react-redux';
import { decodeHtml } from 'utils/decodeHTML';
import sanitizeContent from 'utils/InputSanitization';

const { Header, Sider, Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

function CreateAnnouncements() {
    const { control, errors, setValue, trigger,setError,clearErrors } = useForm();
    const { projectId, notificationId, companyId } = useParams();
    const location = useLocation();
    const history = useHistory();
    const isSponsor = !!ls.get('sponsorAuth');
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const previewMode = location?.state?.previewMode || false;
    const sentTableType = location?.state?.sentTableType || false;

    const [title, setTitle] = useState('Welcome to the conference!');
    const [subTitle, setSubTitle] = useState('');
    const [description, setDescription] = useState('');
    const [buttonLabel, setButtonLabel] = useState('');
    const [ctaId, setCtaId] = useState(uuid());

    const [buttonURL, setButtonURL] = useState('');
    const [buttonColor, setButtonColor] = useState('#e2e9f3');
    const [buttonTextColor, setButtonTextColor] = useState('#000000');
    const [openInNewWindow, setOpenInNewWindow] = useState(true);
    const [image, setImage] = useState('');
    const [autoCloseTime, setAutoCloseTime] = useState(1);
    const [position, setPosition] = useState(POSITIONS.BOTTOM_LEFT);
    const [jobRunTime, setJobRunTime] = useState('');

    const [ctaVisible, setCtaVisible] = useState(false);
    const [senderVisible, setSenderVisible] = useState(false);
    const [autoCloseVisible, setAutoCloseVisible] = useState(false);
    const [livePagesList, setLivePagesList] = useState([]);
    const [livePagesSource, setLivePagesSource] = useState(
        isSponsor ? 'livepage' : 'project'
    );
    const [selectedLivePages, setSelectedLivePages] = useState([]);
    const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
    const [notificationSendNow, setNotificationSendNow] = useState(false);
    const [isLivePageSelectRequired, setLivePageSelectRequired] =
        useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [sendMode, updateSendMode] = useState('sendNow');
    const [scheduleDate, setScheduleDate] = useState(
        moment().tz(timezone).add(10, 'minutes')
    );
    const [timeUnit, setTimeUnit] = useState('mins');

    const [triggerTime, setTriggerTime] = useState(2);
    const { triggers: pageTriggers } = useSelector((state) => state?.settings);

    const [selectedTrigger, updateTrigger] = useState(pageTriggers[0]);

    useEffect(() => {
        setScheduleDate(moment().tz(timezone).add(10, 'minutes'));
    }, [timezone, sendMode]);

    useEffect(() => {
        handleGetLivePages();
        if (notificationId) {
            handleGetNotification();
        } else {
            setIsLoading(false);
        }
    }, []);

    const validateForXSS = (value) => {
        if (
            value.indexOf('onerror(') > -1 ||
            value.indexOf('debugger(') > -1 ||
            value.indexOf('<script') > -1 ||
            value.indexOf('.onload') > -1 ||
            value.indexOf('alert(') > -1 ||
            value.indexOf('console.') > -1
        )
            return false;
        else return true;
    };

    const handleGetNotification = async () => {
        try {
            const response = await GetNotification(projectId, notificationId);
            if (response.status) {
                const {
                    title,
                    subTitle,
                    description,
                    cta,
                    image,
                    position,
                    autoCloseTime,
                    targetType,
                    targetId,
                    ...restData
                } = response;
                setTitle(title ? title : '');
                setSubTitle(subTitle ? subTitle : '');
                setDescription(description ? description : '');
                setButtonLabel(cta?.length ? cta[0].label : '');
                setCtaId(cta?.length ? cta[0]?.id : uuid());
                setButtonURL(cta?.length ? cta[0].url : '');
                setButtonColor(cta?.length ? cta[0].color : '#e2e9f3');
                setButtonTextColor(cta?.length ? cta[0].textColor : '#000000');
                setOpenInNewWindow(cta?.length ? cta[0].openInNewWindow : true);
                setImage(image ? image : '');
                setAutoCloseTime(autoCloseTime ? autoCloseTime : 1);
                setPosition(position ? position : POSITIONS.BOTTOM_LEFT);
                setCtaVisible(cta?.length ? true : false);
                setSenderVisible(subTitle || image ? true : false);
                setAutoCloseVisible(autoCloseTime ? true : false);
                setLivePagesSource(targetType ? targetType : 'project');
                setSelectedLivePages(targetType === 'livepage' ? targetId : []);
                if (restData?.job?.runTime) {
                    updateSendMode('schedule');
                    setJobRunTime(response?.job?.runTime);
                }
                if (restData?.trigger?.id) {
                    updateSendMode('trigger');
                    if (restData?.triggerConfig?.timeUnit) {
                        let triggerTime = restData?.triggerConfig?.timeInSecs;
                        setTimeUnit(restData?.triggerConfig?.timeUnit);

                        if (restData?.triggerConfig?.timeUnit === 'mins')
                            triggerTime = triggerTime / 60;
                        setTriggerTime(triggerTime);
                    }

                    const triggerEle = pageTriggers.find(
                        ({ code }) => code === restData?.trigger?.code
                    );
                    if (triggerEle) updateTrigger(triggerEle);
                }
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            console.error('ERROR handleGetNotification', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetLivePages = async () => {
        try {
            const { showcases } = await getLivePages(
                projectId,
                'title',
                'asc',
                20,
                0,
                '',
                [],
                '',
                1
            );
            if (!showcases || showcases.length === 0) throw 'failed';
            let showcaseList = [];
            showcases.map((item) => {
                showcaseList.push({
                    value: item.title,
                    title: item.title,
                    key: item.id,
                    slug: item.slug,
                });
            });
            setLivePagesList(showcaseList);
        } catch (error) {
            setLivePagesList([]);
        }
    };

    const handleCreateOrEditNotification = (notificationStatus) => {
        trigger().then((inputValidation) => {
            if (inputValidation) {
                if (
                    livePagesSource === 'livepage' &&
                    !selectedLivePages?.length
                ) {
                    setLivePageSelectRequired(true);
                    return;
                }
                if (notificationStatus === 'draft') {
                    //Edit Draft Notification
                    if (!isEmpty(notificationId)) {
                        handleUpdateNotification();
                    }
                    //Create Draft Notification
                    if (isEmpty(notificationId)) {
                        handleCreateNotification();
                    }
                }
            }
        });
    };

    const handleCreateNotification = async () => {
        let createInputs = {
            ...(livePagesSource === 'project' && {
                targetType: 'project',
            }),
            ...(livePagesSource === 'livepage' && {
                targetType: 'livepage',
                targetId: selectedLivePages.map((value) => value.key),
            }),
            data: {
                title,
                description,
                position,
                type: 'recommendation-session',
                ...(ctaVisible && {
                    cta: [
                        {
                            id: ctaId,
                            label: buttonLabel,
                            url: buttonURL,
                            color: buttonColor,
                            textColor: buttonTextColor,
                            openInNewWindow,
                        },
                    ],
                }),
                ...(senderVisible && {
                    subTitle,
                    image,
                }),
                ...(autoCloseVisible && {
                    autoCloseTime: Number(autoCloseTime),
                }),
            },
        };
        if (sendMode === 'trigger') {
            let timeInSecs = null;

            createInputs = {
                ...createInputs,
                data: {
                    ...createInputs.data,
                    triggerCode: selectedTrigger.code,
                    oncePerParticipant: true,
                },
            };
            if (
                [
                    'LIVEPAGE_SPECIFIC_TIME',
                    'LIVEPAGE_USER_INACTIVE_TIME',
                ].includes(selectedTrigger.code)
            ) {
                timeInSecs =
                    timeUnit === 'secs' ? triggerTime : triggerTime * 60;
                createInputs = {
                    ...createInputs,
                    data: {
                        ...createInputs.data,
                        triggerConfig: { timeInSecs, timeUnit },
                    },
                };
            }
        }
        try {
            setIsLoading(true);
            const response = await CreateNotification(createInputs, projectId);
            if (response.status) {
                openNotification('success', {
                    message: 'Notification created!',
                });
                history.push(
                    `/company/${companyId}/project/${projectId}/notification/edit-announcement/${response.id}`
                );
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            console.error('ERROR CreateNotification', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateNotification = async () => {
        let updateInputs = {
            data: {
                title,
                description,
                position,
                ...(ctaVisible && {
                    cta: [
                        {
                            id:ctaId,
                            label: buttonLabel,
                            url: buttonURL,
                            color: buttonColor,
                            textColor: buttonTextColor,
                            openInNewWindow,
                        },
                    ],
                }),
                ...(!ctaVisible && {
                    cta: [],
                }),
                ...(senderVisible && {
                    subTitle,
                    image,
                }),
                ...(!senderVisible && {
                    subTitle: null,
                    image: null,
                }),
                ...(autoCloseVisible && {
                    autoCloseTime: Number(autoCloseTime),
                }),
                ...(!autoCloseVisible && {
                    autoCloseTime: null,
                }),

                ...(livePagesSource === 'project' && {
                    targetType: 'project',
                }),
                ...(livePagesSource === 'livepage' && {
                    targetType: 'livepage',
                    targetId: selectedLivePages.map((value) => value.key),
                }),
            },
        };
        if (sendMode === 'trigger') {
            let timeInSecs = null;

            updateInputs = {
                ...updateInputs,
                data: {
                    ...updateInputs.data,
                    triggerCode: selectedTrigger.code,
                    oncePerParticipant: true,
                },
            };
            if (
                [
                    'LIVEPAGE_SPECIFIC_TIME',
                    'LIVEPAGE_USER_INACTIVE_TIME',
                ].includes(selectedTrigger.code)
            ) {
                timeInSecs =
                    timeUnit === 'secs' ? triggerTime : triggerTime * 60;
                updateInputs = {
                    ...updateInputs,
                    data: {
                        ...updateInputs.data,
                        triggerConfig: { timeInSecs, timeUnit },
                    },
                };
            }
        }

        try {
            const response = await UpdateNotification(
                updateInputs,
                projectId,
                notificationId
            );
            if (response.status) {
                openNotification('success', {
                    message: 'Notification updated!',
                });
                setIsLoading(true);
                handleGetNotification();
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            console.error('ERROR UpdateNotification', error);
        }
    };

    const handleNotificationStatus = (notificationStatus) => {
        trigger().then((inputValidation) => {
            if (inputValidation) {
                if (
                    livePagesSource === 'livepage' &&
                    !selectedLivePages?.length
                ) {
                    setLivePageSelectRequired(true);
                    return;
                }
                setIsScheduleModalVisible(true);
                if (notificationStatus === 'schedule')
                    setNotificationSendNow(false);
                else setNotificationSendNow(true);
            }
        });
    };
    const onClickCancelNotification = async () => {
        try {
            const response = await CancelNotification(
                projectId,
                notificationId
            );
            if (response.status) {
                history.push(
                    `/company/${companyId}/project/${projectId}/notification/announcements`
                );
                openNotification('success', {
                    message: 'Notification canceled!',
                });
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            console.error('ERROR onClickCancelNotification', error);
            openNotification('error', {
                message: 'An error was encountered please try again',
            });
        }
    };

    return (
        <Layout className={'VenueBuilder'}>
            <Header className="AppHeader p-2">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <button
                            className="btn"
                            onClick={() =>
                                history.push(
                                    `/company/${companyId}/project/${projectId}/notification/announcements`
                                )
                            }
                        >
                            <LeftOutlined />
                        </button>
                        <h1 className="mb-0">
                            {notificationId
                                ? previewMode
                                    ? 'View'
                                    : 'Edit'
                                : 'Create'}{' '}
                            announcement
                        </h1>
                    </div>
                    <div
                        className={`d-flex align-items-center ${
                            previewMode ? 'flex-fill' : ''
                        }`}
                    >
                        {/* <button className="myxp btn px-1 d-flex">  <MoreOutlined style={{ fontSize: '20px' }} /></button> */}
                        {previewMode && jobRunTime && !sentTableType && (
                            <div
                                style={{ backgroundColor: '#F5F5F5' }}
                                className="d-flex justify-content-between align-items-center h-100 flex-fill px-3 mr-1 ml-3"
                            >
                                <Countdown
                                    date={jobRunTime}
                                    renderer={({
                                        days,
                                        hours,
                                        minutes,
                                        seconds,
                                    }) => {
                                        return (
                                            <span>{`Scheduled for ${days} Days ${hours} Hrs ${minutes} Mins ${seconds} Secs`}</span>
                                        );
                                    }}
                                />
                                {/* <span role="button" className="text-danger notification-input-label" onClick={() => onClickCancelNotification()}>Cancel Schedule</span> */}
                            </div>
                        )}
                        {!previewMode && (
                            <>
                                <button
                                    className="myxp btn btn-secondary"
                                    style={{ width: 'auto' }}
                                    onClick={() =>
                                        history.push(
                                            `/company/${companyId}/project/${projectId}/notification/announcements`
                                        )
                                    }
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() =>
                                        handleCreateOrEditNotification('draft')
                                    }
                                    className="myxp btn btn-primary"
                                >
                                    Save Draft
                                </button>
                                <button
                                    onClick={() =>
                                        handleNotificationStatus(sendMode)
                                    }
                                    className="myxp btn btn-sm btn-dark mx-2"
                                >
                                    {sendMode === 'schedule'
                                        ? 'Schedule'
                                        : sendMode === 'trigger'
                                        ? 'Set trigger'
                                        : 'Set live'}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </Header>

            <Layout>
                {isLoading && <GlobalLoading />}
                {!isLoading && (
                    <>
                        <Sider width={300} className={'AppSideBar'}>
                            <Collapse
                                expandIconPosition="right"
                                defaultActiveKey={['1', '2']}
                            >
                                <Panel
                                    header={<h2 className="mb-0">Content</h2>}
                                    key="1"
                                >
                                    <Controller
                                        as={
                                            <SettingsText
                                                headingTitle="Title"
                                                tooltipText="Announcement title"
                                                isVisible={!previewMode}
                                                error={errors?.title}
                                                setValue={(name, value) => {
                                                    if (validateForXSS(value)) {
                                                        setValue(name, value);
                                                        setTitle(value);
                                                        clearErrors('name');
                                                    } else {
                                                        setError('name', {
                                                            message:
                                                                'Invalid name.',
                                                        });
                                                        setValue(name, '');
                                                        setTitle('');
                                                    }
                                                }}
                                                isRequired={true}
                                            />
                                        }
                                        name="title"
                                        control={control}
                                        defaultValue={title}
                                        rules={{ required: true }}
                                    />

                                    {errors?.name && (
                                        <p
                                            style={{
                                                marginTop: '-10px',
                                            }}
                                            className="invalid-feedback d-block"
                                        >
                                            {errors?.name?.message}
                                        </p>
                                    )}

                                    <Controller
                                        as={
                                            <>
                                                {' '}
                                                <SettingsText
                                                    headingTitle="Description"
                                                    tooltipText="Send notification to the following livepages"
                                                    isVisible={!previewMode}
                                                    value={description}
                                                    allowOnlyLineBreaks
                                                    iframe
                                                    setValue={(value) => {
                                                        setDescription(
                                                            sanitizeContent({
                                                                str: value,
                                                                type: 'description',
                                                            })
                                                        );
                                                        clearErrors(
                                                            'description'
                                                        );
                                                    }}
                                                    type={'richtext'}
                                                    error={errors?.description}
                                                />
                                                {errors?.description && (
                                                    <p
                                                        style={{
                                                            marginTop: '-10px',
                                                        }}
                                                        className="invalid-feedback d-block"
                                                    >
                                                        {
                                                            errors?.description
                                                                ?.message
                                                        }
                                                    </p>
                                                )}
                                            </>
                                        }
                                        name="description"
                                        control={control}
                                        defaultValue={description}
                                    />
                                    <Collapse
                                        className="mt-3 bg-white"
                                        defaultActiveKey={
                                            ctaVisible ? ['1'] : []
                                        }
                                        onChange={(e) =>
                                            setCtaVisible(!isEmpty(e))
                                        }
                                    >
                                        <Panel
                                            showArrow={false}
                                            className={'panel-header-collapse'}
                                            header={
                                                <SwitchComponent
                                                    label="CTA"
                                                    disabled={previewMode}
                                                    // tooltipText={'tooltipText'}
                                                    switchStatus={ctaVisible}
                                                    onChange={(e) =>
                                                        setCtaVisible(e)
                                                    }
                                                    padding={true}
                                                />
                                            }
                                            key="1"
                                        >
                                            <div className="form-input-container">
                                                <Controller
                                                    as={
                                                        <SettingsText
                                                            headingTitle="Text"
                                                            isVisible={
                                                                !previewMode
                                                            }
                                                            error={
                                                                errors?.buttonLabel
                                                            }
                                                            setValue={(
                                                                name,
                                                                value
                                                            ) => {
                                                                setValue(
                                                                    name,
                                                                    value
                                                                );
                                                                setButtonLabel(
                                                                    value
                                                                );
                                                            }}
                                                            isRequired={true}
                                                        />
                                                    }
                                                    name="buttonLabel"
                                                    control={control}
                                                    defaultValue={buttonLabel}
                                                    rules={{
                                                        required: ctaVisible
                                                            ? true
                                                            : false,
                                                    }}
                                                />
                                            </div>
                                            <div className="form-input-container mb-2 mt-3">
                                                {!isEmpty(livePagesList) && (
                                                    <Controller
                                                        render={({
                                                            value,
                                                            onChange,
                                                        }) => (
                                                            <>
                                                                <LivePagesList
                                                                    title="Link"
                                                                    placeholder="Paste link or search for a page"
                                                                    link={
                                                                        buttonURL
                                                                    }
                                                                    setLink={(
                                                                        value
                                                                    ) => {
                                                                        setButtonURL(
                                                                            value
                                                                        );
                                                                        setValue(
                                                                            'buttonURL',
                                                                            value
                                                                        );
                                                                    }}
                                                                    isRequired={
                                                                        true
                                                                    }
                                                                    isSubmitted={
                                                                        false
                                                                    }
                                                                    paddingReset={
                                                                        true
                                                                    }
                                                                    livePagesList={
                                                                        livePagesList
                                                                    }
                                                                    {...{
                                                                        openInNewWindow,
                                                                        setOpenInNewWindow,
                                                                    }}
                                                                />
                                                                {errors?.buttonURL && (
                                                                    <div className="invalid-feedback d-block mt-1">
                                                                        {
                                                                            errors
                                                                                ?.buttonURL
                                                                                ?.message
                                                                        }
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        onChange={(val) => val}
                                                        name="buttonURL"
                                                        defaultValue={buttonURL}
                                                        control={control}
                                                        rules={{
                                                            required: ctaVisible
                                                                ? 'Required'
                                                                : false,
                                                            pattern: {
                                                                value: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm,
                                                                message:
                                                                    'URL incorrect',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            </div>

                                            <div className="form-input-container">
                                                <span className="notification-input-label">
                                                    Button Background Color
                                                </span>
                                                <div className="input-group mt-2">
                                                    <input
                                                        type="color"
                                                        className="input--color"
                                                        value={buttonColor}
                                                        name="buttonColor"
                                                        onChange={(e) =>
                                                            setButtonColor(
                                                                e.target.value
                                                            )
                                                        }
                                                        disabled={previewMode}
                                                    />
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control input--color_content cta-color-input"
                                                        value={buttonColor}
                                                        name="buttonColor"
                                                        onChange={(e) =>
                                                            setButtonColor(
                                                                e.target.value
                                                            )
                                                        }
                                                        disabled={previewMode}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-input-container mt-2">
                                                <span className="notification-input-label">
                                                    Button Text Color
                                                </span>
                                                <div className="input-group mt-2">
                                                    <input
                                                        type="color"
                                                        className="input--color"
                                                        value={buttonTextColor}
                                                        name="buttonTextColor"
                                                        onChange={(e) =>
                                                            setButtonTextColor(
                                                                e.target.value
                                                            )
                                                        }
                                                        disabled={previewMode}
                                                    />
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control input--color_content cta-color-input"
                                                        value={buttonTextColor}
                                                        name="buttonTextColor"
                                                        onChange={(e) =>
                                                            setButtonTextColor(
                                                                e.target.value
                                                            )
                                                        }
                                                        disabled={previewMode}
                                                    />
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>

                                    <Collapse
                                        className="mt-3 bg-white"
                                        defaultActiveKey={
                                            senderVisible ? ['1'] : []
                                        }
                                        onChange={(e) =>
                                            setSenderVisible(!isEmpty(e))
                                        }
                                    >
                                        <Panel
                                            showArrow={false}
                                            className={'panel-header-collapse'}
                                            header={
                                                <SwitchComponent
                                                    label="Sender"
                                                    disabled={previewMode}
                                                    // tooltipText={'tooltipText'}
                                                    switchStatus={senderVisible}
                                                    onChange={(e) =>
                                                        setSenderVisible(e)
                                                    }
                                                    padding={true}
                                                />
                                            }
                                            key="1"
                                        >
                                            <div className="form-input-container">
                                                <span className="notification-input-label">
                                                    Name
                                                </span>
                                                <Input
                                                    className="rounded mt-2"
                                                    name="subTitle"
                                                    onChange={(e) =>
                                                        setSubTitle(
                                                            e.target.value
                                                        )
                                                    }
                                                    value={subTitle}
                                                    disabled={previewMode}
                                                />
                                            </div>
                                            <div className="form-input-container">
                                                <span className="notification-input-label">
                                                    Image
                                                </span>
                                                <div className="my-2">
                                                    <ImageUpload
                                                        disabled={previewMode}
                                                        previewImages={
                                                            image ? [image] : []
                                                        }
                                                        prepareFilesUpload={(
                                                            files
                                                        ) => {
                                                            setImage(files[0]);
                                                        }}
                                                        handleFileDelete={(
                                                            file
                                                        ) => {
                                                            setImage('');
                                                        }}
                                                        imageCropAspectRatio={1}
                                                        autoUpload={true}
                                                        withoutRedux={true}
                                                        projectId={projectId}
                                                    />
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>

                                    <div className="form-input-container mt-3">
                                        <div className="d-flex align-items-center">
                                            <span className="notification-input-label mr-1">
                                                Position
                                            </span>
                                            <Tooltip
                                                placement="right"
                                                title={
                                                    'Determines where the announcement will appear on the page'
                                                }
                                            >
                                                <InfoCircleOutlined />
                                            </Tooltip>
                                        </div>
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            className="rounded mt-2"
                                            optionFilterProp="children"
                                            defaultValue={position}
                                            onChange={(e) => setPosition(e)}
                                            disabled={previewMode}
                                        >
                                            <Option value={POSITIONS.TOP_LEFT}>
                                                Top Left
                                            </Option>
                                            <Option
                                                value={POSITIONS.TOP_CENTERED}
                                            >
                                                Top Centred
                                            </Option>
                                            <Option value={POSITIONS.TOP_RIGHT}>
                                                Top Right
                                            </Option>
                                            <Option value={POSITIONS.CENTERED}>
                                                Centred
                                            </Option>
                                            <Option
                                                value={POSITIONS.BOTTOM_LEFT}
                                            >
                                                Bottom Left
                                            </Option>
                                            <Option
                                                value={
                                                    POSITIONS.BOTTOM_CENTERED
                                                }
                                            >
                                                Bottom Centred
                                            </Option>
                                            <Option
                                                value={POSITIONS.BOTTOM_RIGHT}
                                            >
                                                Bottom Right
                                            </Option>
                                        </Select>
                                    </div>
                                    <Collapse
                                        className="mt-3 bg-white"
                                        defaultActiveKey={
                                            autoCloseVisible ? ['1'] : []
                                        }
                                        onChange={(e) =>
                                            setAutoCloseVisible(!isEmpty(e))
                                        }
                                    >
                                        <Panel
                                            showArrow={false}
                                            className={'panel-header-collapse'}
                                            header={
                                                <SwitchComponent
                                                    label="Auto-close"
                                                    disabled={previewMode}
                                                    // tooltipText={'tooltipText'}
                                                    switchStatus={
                                                        autoCloseVisible
                                                    }
                                                    onChange={(e) =>
                                                        setAutoCloseVisible(e)
                                                    }
                                                    padding={true}
                                                />
                                            }
                                            key="1"
                                        >
                                            <div className="form-input-container">
                                                <span className="notification-input-label">
                                                    Close notification
                                                    automatically after
                                                </span>
                                                <Input
                                                    suffix={'secs'}
                                                    className="rounded mt-2"
                                                    name="autoCloseTime"
                                                    type="number"
                                                    min={1}
                                                    onChange={(e) =>
                                                        setAutoCloseTime(
                                                            e.target.value
                                                        )
                                                    }
                                                    value={autoCloseTime}
                                                    disabled={previewMode}
                                                />
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </Panel>
                            </Collapse>
                        </Sider>
                        <Content className="AppContent m-4">
                            <PreviewWindow>
                                <NotificationBox
                                    title={title}
                                    subTitle={subTitle}
                                    image={image}
                                    description={decodeHtml(
                                        sanitizeContent({
                                            str: description,
                                            type: 'description',
                                        })
                                    )}
                                    position={position}
                                    ctaVisible={ctaVisible}
                                    openInNewWindow={openInNewWindow}
                                    buttonLabel={buttonLabel}
                                    buttonURL={buttonURL}
                                    buttonColor={buttonColor}
                                    buttonTextColor={buttonTextColor}
                                    senderVisible={senderVisible}
                                />
                            </PreviewWindow>
                        </Content>
                        <SendSettings
                            {...{
                                selectedLivePages,
                                setSelectedLivePages,
                                livePagesSource,
                                setLivePagesSource,
                                livePagesList,
                                isSponsor,
                                previewMode,
                                sendMode,
                                updateSendMode,
                                scheduleDate,
                                setScheduleDate,
                                timezone,
                                selectedTrigger,
                                updateTrigger,
                                triggerTime,
                                setTriggerTime,
                                timeUnit,
                                setTimeUnit,
                                isLivePageSelectRequired,
                                setLivePageSelectRequired,
                            }}
                        />
                    </>
                )}
            </Layout>
            <ScheduleModal
                {...{
                    scheduleDate,
                    setScheduleDate,
                    sendMode,
                    selectedTrigger,
                    triggerTime,
                    timeUnit,
                    oncePerParticipant: true,
                }}
                notificationSendNow={notificationSendNow}
                visible={isScheduleModalVisible}
                setVisible={setIsScheduleModalVisible}
                onRefreshData={() =>
                    history.push(
                        `/company/${companyId}/project/${projectId}/notification/announcements`
                    )
                }
                notificationEditMode={notificationId ? true : false}
                notificatonData={{
                    title,
                    description,
                    subTitle,
                    image,
                    position,
                    autoCloseTime,
                    senderVisible,
                    autoCloseVisible,
                    jobRunTime,
                    ctaVisible,
                    buttonLabel,
                    buttonURL,
                    buttonColor,
                    ctaId,
                    buttonTextColor,
                    openInNewWindow,
                    targetType: livePagesSource,
                    targetId: selectedLivePages,
                    id: notificationId,
                    ...(ctaVisible && {
                        cta: [
                            {
                                label: buttonLabel,
                                url: buttonURL,
                                color: buttonColor,
                                textColor: buttonTextColor,
                                openInNewWindow,
                            },
                        ],
                    }),
                }}
            />
            <style global="false" jsx="true">{`
                .settingstext,
                .RichTextContainer {
                    padding: 10px 0px 16px;
                }
            `}</style>
        </Layout>
    );
}

CreateAnnouncements.propTypes = {};

export default CreateAnnouncements;
