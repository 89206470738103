import React from 'react';
import { useHistory } from 'react-router-dom';

export default function NoChannelView({ companyId, projectId }) {
    const history = useHistory();
    return (
        <div className="col-12">
            <div className="bg-white border border-radius d-flex flex-column justify-content-center align-items-center my-3 empty-notification-table ">
                <div className="text-center">
                    <div className="empty-ellips mb-3 mx-auto"></div>
                    <h2 className="text-body">
                        Create and manage your Live Stream channels
                    </h2>
                    <p className="text-body">
                        Channels are the conduit for your live streams and help
                        you manage them more easily. Create your channels here
                        then add them to your livepages through the live stream
                        blocks.
                    </p>
                    <button
                        className="myxp btn btn-primary mx-auto my-2"
                        onClick={() =>
                            history.push(
                                `/company/${companyId}/project/${projectId}/dashboard/livestreaming/create`
                            )
                        }
                    >
                        Create Live Stream channel
                    </button>
                </div>
            </div>
        </div>
    );
}
