import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Space, Input, Button } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import sum from 'lodash/sum';
import moment from 'moment';
import SideModal from 'components/app/common/sideModal/';
import PollResult from '../../pollResult';
import { getFullname } from '../../polls';

import './liveTable.scss';
import { useSelector } from 'react-redux';
import GlobalLoading from 'components/app/components/common/globalLoading';

const LiveTable = ({
    data,
    onRefreshData,
    pagination,
    loading,
    search,
    onClickEndPoll,
    setSorter,
}) => {
    const [searchText, setSearchText] = useState('');
    const [sideModalVisibility, setSideModalVisibility] = useState(false);
    const [selectedPoll, setSelectedNotification] = useState(null);
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );
    const sortDirections = ['descend', 'ascend', 'descend'];

    useEffect(() => {
        let trimSearchText = searchText?.trim();
        search(trimSearchText);
    }, [searchText]);

    //Search part
    let searchInput;
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder={`Search`}
                    value={selectedKeys[0]}
                    defaultValue={searchText}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        let searchTextSelect = selectedKeys[0] ? selectedKeys[0] : searchText;
        if (!/\s/.test(searchTextSelect)) {
            confirm();
            setSearchText(searchTextSelect);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (a, b, sorter) => {
        const { field, order } = sorter;

        setSorter({
            sortOrder: order === 'ascend' ? 'ASC' : 'DESC',
            sortBy: field,
        });
    };

    const columns = [
        {
            title: 'Question',
            dataIndex: 'question',
            sorter: true,
            sortDirections,
            ...getColumnSearchProps('question'),
        },
        {
            title: 'Sent On',
            dataIndex: 'publishedTime',
            sorter: true,
            sortDirections,
            render: (publishedTime, item) => {
                if (!publishedTime) return null;
                return (
                    <span>
                        {moment(publishedTime)
                            .tz(timezone)
                            .format('DD MMMM YYYY [at] HH:mm')}
                    </span>
                );
            },
        },
        {
            title: 'Trigger',
            dataIndex: 'action',
            render: (action, item) => {
                let triggerTime = action?.triggerConfig?.timeInSecs;
                if (action?.triggerConfig?.timeUnit === 'mins')
                    //LIVEPAGE_SPECIFIC_TIME
                    triggerTime = triggerTime / 60;

                return (
                    <>
                        {triggerTime && (
                            <span>
                                {triggerTime} {action.triggerConfig.timeUnit}{' '}
                                after livepage loads
                            </span>
                        )}
                        {action && !triggerTime && (
                            <span>when livepage loads</span>
                        )}
                        {!action && <span>-</span>}
                    </>
                );
            },
        },
        {
            title: 'Target',
            dataIndex: 'target',
            sorter: true,
            sortDirections,
            render: (target, item) => {
                return (
                    <span>
                        {item?.targetType === 'project'
                            ? 'All Livepages'
                            : target?.length}
                    </span>
                );
            },
        },
        {
            title: 'Sent by',
            dataIndex: 'updatedBySponsor',
            render: (updatedBySponsor, item) => {
                return (
                    <span>
                        {getFullname(updatedBySponsor || item?.updatedByUser)}
                    </span>
                );
            },
        },
        {
            title: 'Responses',
            dataIndex: 'reponses',
            render: (reponses, item) => {
                return <div>{item?.voters.length}</div>;
            },
        },
        {
            render: (item) => {
                return (
                    <div className="d-flex justify-content-end">
                        <button
                            className="myxp btn btn-sm btn-link text-danger"
                            onClick={() => onClickEndPoll(item.id)}
                        >
                            End Poll
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link"
                            onClick={() => {
                                setSideModalVisibility(true);
                                setSelectedNotification(item);
                            }}
                        >
                            Live Report
                        </button>
                    </div>
                );
            },
        },
    ];

    const globalLoadingComponent = {
        spinning: loading,
        indicator: (
            <GlobalLoading
                loadingText="Fetching data..."
                height={45}
                width={45}
            />
        ),
    };

    const renderPagination = (current, type, originalElement) => {
        return <a onClick={() => pagination(current)}>{originalElement}</a>;
    };

    return (
        <>
            <Table
                onChange={handleChange}
                className="sponsors-company-table"
                rowKey="id"
                columns={columns}
                dataSource={data?.polls}
                loading={globalLoadingComponent}
                pagination={{
                    itemRender: renderPagination,
                    pageSize: 10,
                    total: data?.pagination?.total,
                    current: data?.pagination?.currentPage,
                }}
            />

            <SideModal
                visibility={sideModalVisibility}
                setVisibility={(status) => {
                    setSideModalVisibility(status);
                    setSelectedNotification(null);
                }}
                title={`"${selectedPoll?.question}"`}
                subTitle={
                    <span
                        class="badge badge-pill badge-danger d-flex align-items-center"
                        style={{ width: 'fit-content' }}
                    >
                        {' '}
                        <span className="white-dot" /> LIVE POLL
                    </span>
                }
            >
                <h3 className="poll-modal-title mt-2">
                    {sum(selectedPoll?.options?.map((option) => option?.vote))}{' '}
                    Responses
                </h3>

                <PollResult data={selectedPoll} type={'live'} />
                <button
                    className="myxp btn btn-sm btn-danger btn-block mt-3"
                    onClick={() => {
                        onClickEndPoll(selectedPoll.id);
                        setSideModalVisibility(false);
                        setSelectedNotification(null);
                    }}
                >
                    End Poll
                </button>
            </SideModal>
            <style jsx="true">{`
                .modal .myxp-edit-prompt .modal-content {
                    overflow-y: scroll;
                }
            `}</style>
        </>
    );
};

LiveTable.propTypes = {
    data: PropTypes.object,
    onRefreshData: PropTypes.func,
    pagination: PropTypes.func,
    search: PropTypes.func,
    onClickEndPoll: PropTypes.func,
    loading: PropTypes.bool,
    setSorter: PropTypes.func,
};

LiveTable.defaultProps = {
    data: null,
    loading: false,
};

export default LiveTable;
