import * as ls from 'local-storage';

let access_token = null;

//sponsor multi-login handling
const setAuthToken = () => {
    try {
        if (access_token) return;

        if (window.SPONSOR_AUTH) {
            access_token = window.SPONSOR_AUTH.access_token;
        } else {
            let auth = ls.get('auth');
            access_token = auth && auth.access_token;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * generates livepage preview link
 */
export const generateLivePagePreviewLink = (slug) => {
    setAuthToken();
    const previewLink = `${process.env.REACT_APP_GEVME_LIVE_URL}/${slug}?preview=${access_token}`;
    return previewLink;
};
