import * as ls from 'local-storage';
import { store } from 'redux/store';

/**
 * gets the list of leads users
 */
export async function getSettings() {
    const auth = ls.get('auth');
    const project = ls.get('project');
    const user = store?.getState()?.user

    if (auth && user) {
        const authBearer = `Bearer ${auth.access_token}`;
        const projectId = project.projectId;

        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/settings/${projectId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    }
}
