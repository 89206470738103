import * as ls from 'local-storage';
import { store } from 'redux/store';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * Update and create block visibility criteria
 * @param {showcaseBlockId} string required
 * @param {data} object required
 */
export async function updateBlockCriteria(projectId, showcaseBlockId, data) {
    setAuthBearer();
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/block-criteria/${projectId}/showcase-blocks/${showcaseBlockId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}

/**
 * Get block visibility criteria
 * @param {showcaseBlockId} string required
 */
export async function getBlockCriteria(projectId, showcaseBlockId) {
    setAuthBearer();

    const user = store?.getState()?.user;

    if (authBearer && user) {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/block-criteria/${projectId}/showcase-blocks/${showcaseBlockId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    }
}
/**
 * delete block visibility criteria by id
 * @param {showcaseBlockId} string required
 */
export async function deleteBlockCriteria(projectId, showcaseBlockId) {
    setAuthBearer();

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/block-criteria/${projectId}/showcase-blocks/${showcaseBlockId}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

/**
 * Update and create pages visibility criteria
 * @param {showcaseBlockId} string required
 * @param {data} object required
 */
export async function updatePageCriteria(projectId, showcaseBlockId, data) {
    setAuthBearer();

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/showcase-criteria/${projectId}/showcases/${showcaseBlockId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}

/**
 * Get pages visibility criteria
 * @param {showcaseBlockId} string required
 */
export async function getPageCriteria(projectId, showcaseBlockId) {
    setAuthBearer();

    const user = store?.getState()?.user;

    if (authBearer && user) {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/showcase-criteria/${projectId}/showcases/${showcaseBlockId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    }
}
/**
 * delete pages visibility criteria by id
 * @param {showcaseBlockId} string required
 */
export async function deletePageCriteria(projectId, showcaseBlockId) {
    setAuthBearer();

    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/showcase-criteria/${projectId}/showcases/${showcaseBlockId}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}
