import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import useAlertBar from 'components/app/utils/hooks/useAlertBar';
import * as ls from 'local-storage';

import Layout from 'components/app/common/layout';
import SponsorEditInputs from 'components/app/views/apps/sponsorship/sponsors/sponsors/sponsorEdit/inputs';
import SocialMediaLinks from 'components/app/views/apps/sponsorship/sponsors/sponsors/sponsorEdit/socialMediaLinks';

import { isString } from 'lodash';
import { UpdateCompany } from 'apis/rest/sponsorship/UpdateCompany';

import { GetCompany } from 'apis/rest/sponsorship/GetCompany';

import './sponsorDashboardEdit.scss';

function SponsorDashboardEdit(props) {
    const sponsorEditInputsRef = useRef();

    const { companyId, projectId } = useParams();

    const { showAlertBar } = useAlertBar();

    const [companyData, setCompanyData] = useState(null);
    const [onClickFormValidation, setOnClickFormValidation] = useState(false);
    const [isRenderSocialMedia, setIsRenderSocialMedia] = useState(true);

    useEffect(() => {
        handleGetCompany();
    }, []);

    const handleGetCompany = async () => {
        const response = await GetCompany(companyId);
        if (response.status) {
            const resultData = response.sponsor;

            if (isString(resultData.socialMediaLinks)) {
                resultData.socialMediaLinks = JSON.parse(
                    resultData.socialMediaLinks
                );
            }
            setCompanyData(resultData);
            if (resultData.projectId === '6124c0c3ce2e89003ed19806') {
                setIsRenderSocialMedia(false);
            }
        }
    };

    const handleUpdateCompany = async () => {
        sponsorEditInputsRef.current
            .handleSubmitForm()
            .then((validationResult) => {
                if (validationResult) {
                    if (projectId === 'undefined') {
                        showAlertBar(
                            'please re-login with your project id',
                            'error'
                        );
                        return;
                    }
                    onUpdateCompany();
                }
            })
            .catch((error) => {
                console.error('ERROR sponsorEditInputsRef', error);
            });
    };

    const onUpdateCompany = async () => {
        const inputs = {
            projectId,
            company: companyData.name,
            code: companyData.code,
            tagline: companyData.tagline,
            description: companyData.description,
            logo: companyData.logo,
            custom1: companyData.custom1,
            custom2: companyData.custom2,
            custom3: companyData.custom3,
            custom4: companyData.custom4,
            custom5: companyData.custom5,
            socialMediaLinks: JSON.stringify(companyData.socialMediaLinks),
        };
        const response = await UpdateCompany(companyId, inputs);

        if (response.status) {
            handleGetCompany();
            showAlertBar('Company updated!', 'success');
        } else {
            showAlertBar(response.message, 'error');
        }
    };

    return (
        <Layout background={'rgb(243, 245, 250)'}>
            <div className="container" style={{ maxWidth: '650px' }}>
                <div className="row mt-3 mb-5">
                    <div className="col-sm-8 d-flex align-items-center">
                        <h1 className="mr-5 mb-0">
                            {companyData?.name
                                ? companyData?.name
                                : 'Company Name'}
                        </h1>
                    </div>
                    <div className="col-sm-4 d-flex justify-content-end align-items-center">
                        <button
                            className="myxp btn btn-primary"
                            onClick={() => handleUpdateCompany()}
                        >
                            Save
                        </button>
                    </div>
                </div>
                {companyData && (
                    <>
                        <SponsorEditInputs
                            data={companyData}
                            onChangeData={(data) => setCompanyData(data)}
                            ref={sponsorEditInputsRef}
                            onClickFormValidation={setOnClickFormValidation}
                        />
                        {isRenderSocialMedia && (
                            <SocialMediaLinks
                                data={companyData}
                                onChangeData={(data) => setCompanyData(data)}
                            />
                        )}
                    </>
                )}
            </div>
            <SidebarContainer>
                <MainMenu />
            </SidebarContainer>
        </Layout>
    );
}

SponsorDashboardEdit.propTypes = {};

export default SponsorDashboardEdit;
