import * as ls from 'local-storage';
import { logout, sponsorLogout } from 'apis/rest/Authentication';

export const handleLogout = async () => {
    let userType = null;
    if (ls.get('sponsorAuth')) userType = 'sponsor';
    try {
        if (userType === 'sponsor') await sponsorLogout();
        else await logout({});
    } catch (error) {
        console.error(error);
    } finally {
        ls.clear();
        try {
            const cookies = document.cookie.split('; ');
            for (let c = 0; c < cookies.length; c++) {
                const d = window.location.hostname.split('.');
                while (d.length > 0) {
                    const cookieBase =
                        encodeURIComponent(
                            cookies[c].split(';')[0].split('=')[0]
                        ) +
                        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
                        d.join('.') +
                        ' ;path=';
                    const p = window.location.pathname.split('/');
                    document.cookie = cookieBase + '/';
                    while (p.length > 0) {
                        document.cookie = cookieBase + p.join('/');
                        p.pop();
                    }
                    d.shift();
                }
            }
        } catch (error) {
            console.error(error);
        }
        try {
            if (window.fcWidget) {
                window.fcWidget.user.clear();
                window.fcWidget.destroy();
            }
        } catch (error) {
            console.error(error);
        }
        if (userType === 'sponsor') window.location.href = '/sponsor/login';
        else window.location.href = '/login';
    }
};
