import * as ls from 'local-storage';
import { store } from 'redux/store';

export async function createCompanyUser(data) {
    const auth = ls.get('auth');
    const authBearer = `Bearer ${auth.access_token}`;
    const response = await fetch(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/users`,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );

    return await response.json();
}
