import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from 'antd';

import Layout from '../../../../common/layout';
import SidebarContainer from '../../../../common/globalBar/sidebarContainer';
import MainMenu from '../../../../components/admin/menus/mainMenu';
import Visits from './visits';
import ContentTracking from './content';
import MediaTracking from './media';
import PageViewsTracking from './pageViews';
import Chat from './chat';
import Logins from './logins';
// import Polls from './polls';
import Bookmarks from './bookmarks';
import * as ls from 'local-storage';
import './analytics.scss';
import { getAnalyticsToken } from './api';
import Overview from 'ComponentsV2/ProjectDashboard/Components/analytics/overview';
import BoothOverview from './overview/boothOverview';
const analyticsAuth = 'analyticsAuth';
function Analytics(props) {
    const { match } = props;
    const projectId = match.params.projectId;
    const enabledVenue = useSelector(
        (state) => state?.venuesettings?.enableVenue
    );
    const isSponsor = !!ls.get('sponsorIdentifier');

    // -- start tabs --
    const tabs = [];
    if (isSponsor && enabledVenue)
        tabs.push({
            type: 'Booth Overview',
            tooltip: 'Booth traffic dashboard',
        });

    enabledVenue &&
        !isSponsor &&
        tabs.push({ type: 'Overview', tooltip: 'Event traffic dashboard' });

    if (!isSponsor) {
        tabs.push({
            type: 'Attendance',
            tooltip: 'Event Attendance Dashboard',
        });
    }

    tabs.push({
        type: 'Media',
        tooltip: 'The number of times a visitor played a media.',
    });

    enabledVenue &&
        !isSponsor &&
        tabs.push({
            type: 'Visits',
            tooltip:
                'A visit is a browsing session by a visitor where they view pages, watch media and interact with content.',
        });

    if (enabledVenue) {
        tabs.push({
            type: 'Content',
            tooltip:
                'The number of times a content block was interacted with (eg, a ‘click’ on a banner or PDF).',
        });
        tabs.push({
            type: 'Page Views',
            tooltip: 'The number of times visitors land on a page.',
        });
    }

    tabs.push({ type: 'Chat', tooltip: null });
    tabs.push({ type: 'Bookmarks', tooltip: null });

    const [selTabVal, goToTab] = useState(tabs[0].type);
    // -- end tabs --

    const [isAnalyticsAuthSet, setAnalyticsAuth] = useState(
        isSponsor
            ? !!ls.get(analyticsAuth)?.[window?.SPONSOR_AUTH?.companyId]
            : !!ls.get(analyticsAuth)
    );

    const dispatch = useDispatch();

    const fetchAnalyticsToken = async () => {
        try {
            const { status, accessToken, message } = await getAnalyticsToken();
            if (status) {
                if (window?.SPONSOR_AUTH?.companyId) {
                    const analyticsAuthValue = ls.get(analyticsAuth) || {};

                    ls.set(analyticsAuth, {
                        ...analyticsAuthValue,
                        [window?.SPONSOR_AUTH?.companyId]: { accessToken },
                    });
                } else {
                    ls.set(analyticsAuth, { accessToken });
                }
                setAnalyticsAuth(true);
            } else throw new Error(message);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (enabledVenue && !isAnalyticsAuthSet) {
            fetchAnalyticsToken();
        }
    }, [enabledVenue]);

    useEffect(() => {
        return () => dispatch({ type: 'RESET_DEFAULT' });
    }, []);

    return (
        <Layout style={{ background: '#ccc' }}>
            <div className="analyticsHead">
                <span className="title">Analytics</span>
                {tabs.map(({ type, tooltip }, index) => (
                    <span
                        key={index}
                        onClick={() => {
                            goToTab(type);
                            if (type !== 'Attendance')
                                dispatch({
                                    type: 'SET_ACTIVE_MENU',
                                    payload: { activeMenu: 'absolute' },
                                });
                        }}
                        className={type == selTabVal ? 'activeTab' : 'subTitle'}
                    >
                        {tooltip ? (
                            <Tooltip placement="top" title={tooltip}>
                                {type}
                            </Tooltip>
                        ) : (
                            type
                        )}
                    </span>
                ))}
            </div>
            {selTabVal === 'Booth Overview' && <BoothOverview />}
            {selTabVal === 'Overview' && <Overview />}
            {selTabVal === 'Visits' && (
                <Visits
                    isAnalyticsAuthSet={isAnalyticsAuthSet}
                    projectId={projectId}
                    enabledVenue={enabledVenue}
                />
            )}
            {selTabVal === 'Content' && (
                <ContentTracking
                    projectId={projectId}
                    enabledVenue={enabledVenue}
                />
            )}
            {selTabVal === 'Media' && (
                <MediaTracking
                    projectId={projectId}
                    enabledVenue={enabledVenue}
                />
            )}
            {selTabVal === 'Attendance' && <Logins projectId={projectId} />}
            {selTabVal === 'Page Views' && (
                <PageViewsTracking
                    projectId={projectId}
                    enabledVenue={enabledVenue}
                />
            )}
            {selTabVal === 'Logins' && <Logins projectId={projectId} />}
            {selTabVal === 'Chat' && (
                <Chat projectId={projectId} enabledVenue={enabledVenue} />
            )}
            {/* {selTabVal === 'Polls' && (
                <Polls projectId={projectId} enabledVenue={enabledVenue} />
            )} */}
            {selTabVal === 'Bookmarks' && (	
                <Bookmarks projectId={projectId} enabledVenue={enabledVenue} />	
            )}
            <SidebarContainer>
                <MainMenu priActive="analytics" all />
            </SidebarContainer>
        </Layout>
    );
}

export default withRouter(Analytics);
