import * as React from 'react';

const MyBookmarksIcon = (props) => {
    return (
        <svg
            width={18}
            height={22}
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.444 2H3.556c-.413 0-.808.152-1.1.422A1.39 1.39 0 002 3.44v15.84a.68.68 0 00.108.365c.07.111.171.202.293.265a.83.83 0 00.789-.02L9 16.53l5.81 3.362a.824.824 0 00.79.019.754.754 0 00.292-.265.68.68 0 00.108-.365V3.44a1.39 1.39 0 00-.456-1.018 1.623 1.623 0 00-1.1-.422z"
                stroke="#7A829D"
                strokeWidth={2.5}
            />
        </svg>
    );
};

export default MyBookmarksIcon;