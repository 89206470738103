import * as ls from 'local-storage';
import { store } from 'redux/store';

let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * gets the list of chats
 */
export async function getChatCount(projectId, start, search) {
    setAuthBearer();
    const user = store?.getState()?.user

    if (authBearer && user) {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/analytics/chats?projectId=${projectId}&sortBy=title&sortOrder=asc&limit=10&search=${search}&filter=&start=${start}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    }
}

export async function exportChatData(projectId, showcaseId) {
    setAuthBearer();

    const user = store?.getState()?.user

    if (authBearer && user) {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/analytics/chats/${showcaseId}/export?projectId=${projectId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );

        return await response.blob();
    }
}

export async function exportAllChatData(projectId) {
    setAuthBearer();

    const user = store?.getState()?.user

    if (authBearer && user) {
        const response = await fetch(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/analytics/chats/export?projectId=${projectId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );

        return await response.blob();
    }
}

export const getIframePolicies = () => {
    return {
        sandbox: "allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation allow-downloads allow-modals allow-presentation allow-same-origin",
        allow: "camera *; microphone *; accelerometer; magnetometer; autoplay; encrypted-media; gyroscope; geolocation; picture-in-picture; xr-spatial-tracking; webvr; vr; webxr; xr",
        allowVr: "yes",
        allowFullScreen: true,
        seamless: true,
        loading: 'lazy',
        frameBorder: '0'
    }
}