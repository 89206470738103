import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from "lodash";
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import produce from 'immer';
import arrayMove from 'array-move';
import useInputChange from "components/app/utils/hooks/useInputChange";

import './socialMediaLinks.scss';

const SortableContainer = sortableContainer(({ children }) => {
    return <tbody className="SortableList">{children}</tbody>;
});

const SortableItem = sortableElement(
    ({
        value,
        sortIndex,
        handleInputChange,
        renderFieldError,
        deleteSocialMediaLink,
    }) => {
        return (
            <tr>
                <td >
                    <select
                        name={`socialMediaLinks[${sortIndex}].type`}
                        className="form-control form-control-sm social-media-select"
                        onChange={handleInputChange}
                        value={value.type}
                    >
                        <option value="facebook">&#xf082; Facebook</option>
                        <option value="linkedin">&#xf08c; LinkedIn</option>
                        <option value="instagram">&#xf16d; Instagram</option>
                        <option value="wechat">&#xf1d7; WeChat</option>
                        <option value="youtube">&#xf167; YouTube</option>
                        <option value="website">&#xe078; WebSite</option>
                    </select>
                </td>
                <td >
                    <input className="form-control form-control-sm mb-0"
                        onChange={handleInputChange}
                        type="text"
                        value={value.url}
                        name={`socialMediaLinks[${sortIndex}].url`}
                        placeholder="Link" />
                </td>
                <td >
                    <input className="form-control form-control-sm mb-0"
                        onChange={handleInputChange}
                        type="text"
                        value={value.label}
                        name={`socialMediaLinks[${sortIndex}].label`}
                        placeholder="label" />
                </td>
                <td >
                    <img
                        src="/assets/icon_trash.svg"
                        alt=""
                        onClick={() => deleteSocialMediaLink(sortIndex)}
                    />
                    <img src="/assets/icon_draggable.svg" alt="" />
                </td>
            </tr>
        );
    }
);
const SocialMediaLinks = ({ data, onChangeData }) => {
    const [input, setInput] = useState({ socialMediaLinks: data?.socialMediaLinks ? data?.socialMediaLinks : [] });

    const { handleInputChange } = useInputChange(input, setInput);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const rearrangedCustomMenu = arrayMove(
            input.socialMediaLinks,
            oldIndex,
            newIndex
        );
        const nextState = produce(input, (draftState) => {
            draftState.socialMediaLinks = rearrangedCustomMenu;
        });
        setInput(nextState);
    };

    const addSocialMediaLink = () => {
        const nextState = produce(input, (draftState) => {
            if (!input.socialMediaLinks) {
                draftState.socialMediaLinks = [];
            }

            draftState.socialMediaLinks.push({
                type: 'facebook',
                url: '',
                label: '',
            });
        });
        setInput(nextState);
    };

    const deleteSocialMediaLink = (selectedMenuIndex) => {
        const isDeleteConfirmed = window.confirm('Are you sure you want to delete this?');

        if (isDeleteConfirmed) {
            const nextState = produce(input, (draftState) => {
                draftState.socialMediaLinks.splice(selectedMenuIndex, 1);
            });
            setInput(nextState);
        }
    };

    useEffect(() => {
        let updatedData = { ...data }
        updatedData.socialMediaLinks = input['socialMediaLinks']

        onChangeData(updatedData)
    }, [input])

    return (
        <div className="w-100 widget-menu ">
            <table className="table table-general">
                <thead>
                    <tr>
                        <th>Links</th>
                        <th>URL</th>
                        <th>Label</th>
                        <th></th>
                    </tr>
                </thead>
                <SortableContainer
                    onSortEnd={onSortEnd}
                    pressDelay={200}
                >
                    {!isEmpty(input.socialMediaLinks) && input.socialMediaLinks.map((socialMediaItem, index) => {
                        return (
                            <SortableItem
                                key={index}
                                index={index}
                                value={socialMediaItem}
                                sortIndex={index}
                                handleInputChange={handleInputChange}
                                renderFieldError={null}
                                deleteSocialMediaLink={deleteSocialMediaLink}
                            />
                        );
                    })}
                </SortableContainer>
            </table>
            <button
                className="btn btn-primary btn-block btn-xs"
                onClick={addSocialMediaLink}
            >+ Add Links</button>
        </div>
    )
}

SocialMediaLinks.propTypes = {
    data: PropTypes.object,
    onChangeData: PropTypes.func,
}

SocialMediaLinks.defaultProps = {
    data: null,
};

export default SocialMediaLinks
