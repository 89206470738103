import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Link, useParams, useHistory, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Tooltip from 'rc-tooltip';
import Fade from 'react-reveal/Fade';
import { useSelector, useDispatch } from 'react-redux';

import * as ls from 'local-storage';

import GlobalSidebar from '../../../../common/globalBar/globalSidebar';

import { getSettings } from '../../../../../../apis/rest/livestream/GetSettings';

// Redux - actions
import * as act from '../../../../../../redux/actions/live/uiActionsLive';
import * as actUI from '../../../../../../redux/actions/uiActions';
import { setVenueSettings } from '../../../../../../redux/actions/venues/actionsVenues';

import './mainMenu.scss';
import 'rc-tooltip/assets/bootstrap.css';
import { getTriggers } from 'apis/rest/GetTriggers';

// <GlobalSidebar /> Component
const MainMenu = (props) => {
    const {
        projectId: directProjectId,
        showcaseId: directShowcaseId,
        companyId,
    } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const superAdminCompany = ls.get('superAdminCompany');
    const superAdminProjectId = ls.get('superAdminProjectId');
    const isSponsor = !!ls.get('sponsorIdentifier');
    let superAdminCompanyId = null;
    if (superAdminCompany) {
        const parseCompany = JSON.parse(superAdminCompany);
        superAdminCompanyId = parseCompany.id;
    }

    // venue feature
    const enabledVenue = useSelector(
        (state) => state?.venuesettings?.enableVenue
    );
    // ls.get('enabledVenue');

    // if enabledVenue on this project, lets call for redux menu
    useEffect(() => {
        const isSponsor = !!ls.get('sponsorIdentifier');
        if (enabledVenue) {
            dispatch(actUI.enableVenueCollectionFeature(true));
            if (isSponsor) dispatch(act.enableSponsorVenue());
            else dispatch(act.enableVenue(true));
        } else {
            dispatch(actUI.enableVenueCollectionFeature(false));
            dispatch(act.disbleVenue());
            if (isSponsor) dispatch(act.enableSponsor());
        }
    }, [enabledVenue]);

    // Redux - states
    const mainMenuData = useSelector((state) => {
        return state.menus.mainMenuData;
    });

    const isMainMenuOpen = useSelector((state) => state.menus.mainMenuOpen);
    const userRoleId = useSelector((state) => state.user.roleId) || user.roleId;
    // const hasAlreadyVenue = useSelector((state) => state.venues.hasVenue)

    // placement: boolean => if true then set element to right, else left
    // priActive: string => the main link, this case, People and Live
    // secActive: string => the sub link of main
    // all: boolean => determine if all menu will be returned
    const { placement, priActive, secActive, all, match } = props;
    // Component states
    const [directProjectTitle, setDirectProjectTitle] = useState('');
    const [menuClicked, setMenuClicked] = useState(!isMainMenuOpen);
    const [mainLinks, setMainLinks] = useState([]);
    const [allPeopleSub, setAllPeopleSub] = useState([]);
    const [allProjectSub, setAllProjectSub] = useState([]);
    const [allSponsorshipSub, setAllSponsorshipSub] = useState([]);
    const [allNotificationSub, setAllNotificationSub] = useState([]);
    const [allLiveSub, setAllLiveSub] = useState([]);
    const [allChannelsSub, setAllChannelsSub] = useState([]);
    const [primaryActive, setPrimaryActive] = useState(null);
    const [secondaryActive, setSecondaryActive] = useState(null);

    const { triggers } = useSelector((state) => state?.settings);
    const fetchTriggers = async () => {
        try {
            const {
                triggers: resTriggers,
                status,
                message = null,
            } = await getTriggers();
            if (status) {
                dispatch({
                    type: 'SET_TRIGGERS',
                    payload: resTriggers,
                });
            } else throw new Error(message);
        } catch (error) {
            console.error(error);
        }
    };

    useLayoutEffect(() => {
        if (directProjectId !== undefined) {
            let newProjectId;
            if (user?.roleId === 4) {
                const tempProjId = ls.get('tempProjId');
                const getUserProject = ls.get('project');
                newProjectId = getUserProject.projectId
                    ? getUserProject.projectId
                    : tempProjId;
            } else {
                newProjectId = directProjectId;
            }

            fetchProject(newProjectId);
            dispatch(act.updateProjectId(newProjectId));
        }

        if (mainMenuData) {
            const {
                mainLinks,
                allPeopleSub,
                allProjectSub,
                allSponsorshipSub,
                allNotificationSub,
                allLiveSub,
                allChannelsSub,
            } = mainMenuData;

            // Temporary
            const allPeopleSubClone = [...allPeopleSub];
            allPeopleSubClone.shift();

            // Set
            setMainLinks(mainLinks);
            setAllPeopleSub(allPeopleSubClone);
            setAllProjectSub(allProjectSub);
            setAllSponsorshipSub(
                allSponsorshipSub ? allSponsorshipSub : ['Packages', 'Sponsors']
            );
            setAllNotificationSub(allNotificationSub);
            setAllLiveSub(allLiveSub);
            setAllChannelsSub(allChannelsSub);
        }
        if (priActive) setPrimaryActive(textNormalizer(priActive));
        if (secActive) setSecondaryActive(textNormalizer(secActive));
    }, []);

    useEffect(() => {
        dispatch(act.updateHeaderTitle(directProjectTitle));
    }, [directProjectTitle]);

    useEffect(() => {
        if (triggers?.length === 0) fetchTriggers();
    }, []);

    const fetchProject = async (projectId) => {
        //If super admin no need fetch
        if (userRoleId === 1) return null;
        const response = await getSettings(projectId);
        if (response.message) {
            history.push('/?err=snf');
        } else {
            await setDirectProjectTitle(response.setting.title);
            // save user timezone
            ls('timezone', response.setting.timezone);

            // save venueId and if enableVenue
            dispatch(
                setVenueSettings({
                    venueId: response.setting.venueId,
                    enableVenue: response.setting.enableVenue,
                    analyticsSiteId: response?.setting?.analyticsSiteId,
                    experience: response?.setting?.experience?.name,
                })
            );
            dispatch({
                type: 'SET_CUSTOM_DOMAIN',
                payload: response?.setting?.customDomain,
            });
            dispatch({
                type: 'SET_PROJECT_TIMEZONE',
                payload: response?.setting?.timezone,
            });
            // if project has venue
            // setHasAlreadyVenue(response?.setting?.venueId ? true : false)
        }
    };

    // Handler for menu click
    const handleMenuClick = () => {
        // Update component state
        setMenuClicked(!menuClicked);
        // Update Redux states
        dispatch(act.updateAllowFX(true));
        dispatch(act.toggleMainMenu(!isMainMenuOpen));
    };

    // Handler for when main menu is visible
    const handleOnReveal = () => {
        // Let layout know that this menu is visible
        dispatch(act.updateMainMenuVisibility(true));
    };

    const textNormalizer = (link) => {
        return link?.toLowerCase().split(' ').join('');
    };

    return (
        <GlobalSidebar
            right={placement && true}
            width={menuClicked ? 80 : 176}
            zIndex={50}
            top={50}
        >
            {/* This is used to detect if menus are visible */}
            <Fade delay={0} onReveal={handleOnReveal}>
                <span style={{ opacity: 0 }}> </span>
            </Fade>

            {/* Hamburger Menu lines */}
            <div
                id="mainMenu"
                className={`${menuClicked && 'menuclicked'}`}
                onClick={handleMenuClick}
            >
                <div className="span1"></div>
            </div>

            <ul
                className={`app-mainmenu nav flex-column ${
                    enabledVenue ? 'venue' : ''
                }`}
            >
                {/* Main Nav Links*/}
                {mainMenuData?.mainLinks.map((mainlink, index) => {
                    const ml = textNormalizer(mainlink.name);
                    if (
                        !mainlink.name &&
                        process.env.REACT_APP_ENVIRONMENT !== 'production'
                    )
                        return null;
                    if (userRoleId === 4 && ml === 'sponsorship') return null;
                    if (userRoleId === 4 && ml === 'people') return null;
                    if (userRoleId === 4 && ml === 'virtual') return null;
                    if (
                        userRoleId !== 4 &&
                        ml === 'companyprofile' &&
                        !isSponsor
                    )
                        return null;
                    if (
                        (userRoleId === 2 || userRoleId === 4) &&
                        ml === 'livemanager'
                    )
                        return null;
                    if (userRoleId !== 4 && ml === 'livepages' && enabledVenue)
                        return null;
                    if (userRoleId === 1) {
                        if (ml !== 'projects') return null;
                    } else {
                        if (ml === 'projects') return null;
                    }
                    return (
                        <React.Fragment key={index}>
                            <li
                                data-tip={ml}
                                className={
                                    'nav-item ' +
                                    (ml === primaryActive ||
                                    match.path.includes(ml) ||
                                    (match.path ===
                                        '/company/:companyId/project/:projectId' &&
                                        ml === 'dashboard') ||
                                    (match.path ===
                                        '/company/:companyId/project/:projectId/live/pages/:showcaseId/agenda' &&
                                        ml === 'agenda') ||
                                    (match.path.includes('live/pages') &&
                                        ml === 'livepages') ||
                                    (match.path.includes('company-profile') &&
                                        ml === 'companyprofile') ||
                                    (ml === 'chatmoderation' &&
                                        match.path ===
                                            '/company/:companyId/project/:projectId/chat-moderation') ||
                                    (ml === 'videos' &&
                                        match.path ===
                                            '/company/:companyId/project/:projectId/videos') ||
                                    (ml === 'recommendation' &&
                                        match.path ===
                                            '/company/:companyId/project/:projectId/dashboard/business-matching/recommendation-engine')
                                        ? 'active'
                                        : '')
                                }
                            >
                                <Link
                                    id="main"
                                    className={
                                        (ml === primaryActive ||
                                        match.path.includes(ml) ||
                                        (match.path.includes('live/pages') &&
                                            ml === 'livepages') ||
                                        (match.path ===
                                            '/company/:companyId/project/:projectId/live/pages/:showcaseId/agenda' &&
                                            ml === 'agenda') ||
                                        (match.path.includes(
                                            'company-profile'
                                        ) &&
                                            ml === 'companyprofile') ||
                                        ((match.path.includes('live-manager') ||
                                            match.path.includes(
                                                'live/chatdb'
                                            )) &&
                                            (ml === 'livemanager' ||
                                                ml === 'chatmoderator')) ||
                                        (ml === 'chatmoderation' &&
                                            match.path ===
                                                '/company/:companyId/project/:projectId/chat-moderation') ||
                                        (ml === 'recommendation' &&
                                            match.path ===
                                                '/company/:companyId/project/:projectId/dashboard/business-matching/recommendation-engine')
                                            ? 'active '
                                            : '') +
                                        (menuClicked ? 'menuclicked' : '')
                                    }
                                    to={
                                        userRoleId === 1
                                            ? `/admin/dashboard/${superAdminCompanyId}/`
                                            : `/company/${companyId}/project/${
                                                  directProjectId !==
                                                      undefined &&
                                                  directProjectId
                                              }/${
                                                  mainlink.link === 'dashboard'
                                                      ? ''
                                                      : mainlink.link
                                              }`
                                    }
                                >
                                    {userRoleId === 4 ? (
                                        <p
                                            className={`sidebar-${ml} main ${ml}`}
                                        >
                                            {mainlink.name}
                                        </p>
                                    ) : (
                                        <p className={`sidebar-${ml} main`}>
                                            {mainlink.name.toLowerCase() ===
                                            'recommendation'
                                                ? 'Recommend-ation'
                                                : mainlink.name}
                                        </p>
                                    )}
                                </Link>
                            </li>
                            {all && ml === primaryActive && ml === 'people' && (
                                <>
                                    {mainMenuData.allPeopleSub.map(
                                        (sublink) => {
                                            const sl = textNormalizer(sublink);
                                            return (
                                                <Tooltip
                                                    key={sl}
                                                    placement="right"
                                                    trigger={
                                                        menuClicked
                                                            ? 'hover'
                                                            : ''
                                                    }
                                                    overlayClassName="tooltipZ"
                                                    overlay={
                                                        <span>{sublink}</span>
                                                    }
                                                >
                                                    <li
                                                        key={sl}
                                                        className="nav-item nav-item__sub"
                                                    >
                                                        <Link
                                                            id="sub"
                                                            className={`nav-link ${
                                                                sl ===
                                                                    secondaryActive &&
                                                                'active'
                                                            } ${
                                                                menuClicked &&
                                                                'menuclicked'
                                                            }`}
                                                            to={
                                                                sl === 'fields'
                                                                    ? `/company/${companyId}/project/${
                                                                          directProjectId !==
                                                                              undefined &&
                                                                          directProjectId
                                                                      }/people/fields`
                                                                    : sl ===
                                                                          'dataintegration' &&
                                                                      `/company/${companyId}/project/${
                                                                          directProjectId !==
                                                                              undefined &&
                                                                          directProjectId
                                                                      }/people/data-integration`
                                                            }
                                                        >
                                                            <p
                                                                className={`sidebar-${sl} sub`}
                                                            >
                                                                {sublink}
                                                            </p>
                                                        </Link>
                                                    </li>
                                                </Tooltip>
                                            );
                                        }
                                    )}
                                </>
                            )}
                            {all && ml === primaryActive && ml === 'livepages' && (
                                <>
                                    {allLiveSub.map((sublink) => {
                                        let sl = textNormalizer(sublink);
                                        if (sl === 'livepages') {
                                            sl = 'livepage';
                                        }
                                        return (
                                            <Tooltip
                                                key={sl}
                                                placement="right"
                                                trigger={
                                                    menuClicked ? 'hover' : ''
                                                }
                                                overlayClassName="tooltipZ"
                                                overlay={<span>{sublink}</span>}
                                            >
                                                <li
                                                    key={sl}
                                                    className="nav-item nav-item__sub"
                                                >
                                                    <Link
                                                        id="sub"
                                                        className={`nav-link  ${
                                                            sl ===
                                                                secondaryActive &&
                                                            'active'
                                                        } ${
                                                            menuClicked &&
                                                            'menuclicked'
                                                        }`}
                                                        to={
                                                            sl === 'livepage'
                                                                ? `/company/${companyId}/project/${
                                                                      directProjectId !==
                                                                          undefined &&
                                                                      directProjectId
                                                                  }/live/pages`
                                                                : sl ===
                                                                  'livesite'
                                                                ? `/company/${companyId}/project/${
                                                                      directProjectId !==
                                                                          undefined &&
                                                                      directProjectId
                                                                  }/live/site/settings`
                                                                : sl ===
                                                                  'livemanager'
                                                                ? '/livemanagertest'
                                                                : sl ===
                                                                      'chatmoderation' ||
                                                                  sl ===
                                                                      'livemanager'
                                                                ? `/company/${companyId}/project/${
                                                                      directProjectId !==
                                                                          undefined &&
                                                                      directProjectId
                                                                  }/live/chatdb`
                                                                : (sl ===
                                                                      'templates') &
                                                                      (directProjectId !==
                                                                          undefined) &&
                                                                  directProjectId
                                                                ? `/company/${companyId}/project/${directProjectId}/live/templates`
                                                                : (sl ===
                                                                      'analytics') &
                                                                      (directProjectId !==
                                                                          undefined) &&
                                                                  directProjectId
                                                                ? `/company/${companyId}/project/${directProjectId}/live/analytics`
                                                                : ''
                                                        }
                                                    >
                                                        <p
                                                            className={`sub-sidebar-${sl} sub`}
                                                        >
                                                            {sublink}
                                                        </p>
                                                    </Link>
                                                </li>
                                            </Tooltip>
                                        );
                                    })}
                                </>
                            )}
                            {all &&
                                ml === primaryActive &&
                                ml === 'sponsorship' && (
                                    <>
                                        {allSponsorshipSub &&
                                            allSponsorshipSub.map((sublink) => {
                                                const sl =
                                                    textNormalizer(sublink);
                                                return (
                                                    <Tooltip
                                                        key={sl}
                                                        placement="right"
                                                        trigger={
                                                            menuClicked
                                                                ? 'hover'
                                                                : ''
                                                        }
                                                        overlayClassName="tooltipZ"
                                                        overlay={
                                                            <span>
                                                                {sublink}
                                                            </span>
                                                        }
                                                    >
                                                        <li
                                                            key={sl}
                                                            className="nav-item nav-item__sub"
                                                        >
                                                            <Link
                                                                id="sub"
                                                                className={`nav-link ${
                                                                    sl ===
                                                                        secondaryActive &&
                                                                    'active'
                                                                } ${
                                                                    menuClicked &&
                                                                    'menuclicked'
                                                                }`}
                                                                to={
                                                                    sl ===
                                                                    'packages'
                                                                        ? `/company/${companyId}/project/${
                                                                              directProjectId !==
                                                                                  undefined &&
                                                                              directProjectId
                                                                          }/sponsorship/packages`
                                                                        : sl ===
                                                                              'sponsors' &&
                                                                          `/company/${companyId}/project/${
                                                                              directProjectId !==
                                                                                  undefined &&
                                                                              directProjectId
                                                                          }/sponsorship/sponsors`
                                                                }
                                                            >
                                                                <p
                                                                    className={`sub-sidebar-${sl} sub`}
                                                                >
                                                                    {sublink}
                                                                </p>
                                                            </Link>
                                                        </li>
                                                    </Tooltip>
                                                );
                                            })}
                                    </>
                                )}
                            {all &&
                                ml === primaryActive &&
                                ml === 'pushengagement' && (
                                    <>
                                        {allNotificationSub &&
                                            allNotificationSub.map(
                                                (sublink) => {
                                                    const sl =
                                                        textNormalizer(sublink);
                                                    return (
                                                        <Tooltip
                                                            key={sl}
                                                            placement="right"
                                                            trigger={
                                                                menuClicked
                                                                    ? 'hover'
                                                                    : ''
                                                            }
                                                            overlayClassName="tooltipZ"
                                                            overlay={
                                                                <span>
                                                                    {sublink}
                                                                </span>
                                                            }
                                                        >
                                                            <li
                                                                key={sl}
                                                                className="nav-item nav-item__sub"
                                                            >
                                                                <Link
                                                                    id="sub"
                                                                    className={`nav-link ${
                                                                        sl ===
                                                                            secondaryActive &&
                                                                        'active'
                                                                    } ${
                                                                        menuClicked &&
                                                                        'menuclicked'
                                                                    }`}
                                                                    to={
                                                                        sl ===
                                                                        'announcements'
                                                                            ? `/company/${companyId}/project/${
                                                                                  directProjectId !==
                                                                                      undefined &&
                                                                                  directProjectId
                                                                              }/notification/announcements`
                                                                            : sl ===
                                                                                  'polls' &&
                                                                              `/company/${companyId}/project/${
                                                                                  directProjectId !==
                                                                                      undefined &&
                                                                                  directProjectId
                                                                              }/notification/polls`
                                                                    }
                                                                >
                                                                    <p
                                                                        className={`sub-sidebar-${sl} sub`}
                                                                    >
                                                                        {
                                                                            sublink
                                                                        }
                                                                    </p>
                                                                </Link>
                                                            </li>
                                                        </Tooltip>
                                                    );
                                                }
                                            )}
                                    </>
                                )}
                            {all &&
                                ml === primaryActive &&
                                ml === 'projects' &&
                                userRoleId === 1 && (
                                    <>
                                        {allProjectSub &&
                                            allProjectSub.map((sublink) => {
                                                const sl =
                                                    textNormalizer(sublink);

                                                return (
                                                    <Tooltip
                                                        key={sl}
                                                        placement="right"
                                                        trigger={
                                                            menuClicked
                                                                ? 'hover'
                                                                : ''
                                                        }
                                                        overlayClassName="tooltipZ"
                                                        overlay={
                                                            <span>
                                                                {sublink}
                                                            </span>
                                                        }
                                                    >
                                                        <li
                                                            key={sl}
                                                            className="nav-item nav-item__sub"
                                                        >
                                                            <Link
                                                                id="sub"
                                                                className={`nav-link ${
                                                                    sl ===
                                                                        secondaryActive &&
                                                                    'active'
                                                                } ${
                                                                    menuClicked &&
                                                                    'menuclicked'
                                                                }`}
                                                                to={
                                                                    sl ===
                                                                    'contentsecurity'
                                                                        ? `/admin/dashboard/${superAdminCompanyId}/${superAdminProjectId}`
                                                                        : ''
                                                                }
                                                            >
                                                                <p
                                                                    className={`sub-sidebar-${sl} sub`}
                                                                >
                                                                    {sublink}
                                                                </p>
                                                            </Link>
                                                        </li>
                                                    </Tooltip>
                                                );
                                            })}
                                    </>
                                )}
                            {all && ml === primaryActive && ml === 'channels' && (
                                <>
                                    {allChannelsSub?.map((sublink) => {
                                        return (
                                            <Tooltip
                                                key={sublink.key}
                                                placement="right"
                                                trigger={
                                                    menuClicked ? 'hover' : ''
                                                }
                                                overlayClassName="tooltipZ"
                                                overlay={
                                                    <span>{sublink.name}</span>
                                                }
                                            >
                                                <li
                                                    key={sublink.key}
                                                    className="nav-item nav-item__sub"
                                                >
                                                    <Link
                                                        id="sub"
                                                        className={`nav-link 
                                                        ${
                                                            sublink.key ===
                                                                secondaryActive &&
                                                            'active'
                                                        }
                                                        ${
                                                            menuClicked &&
                                                            'menuclicked'
                                                        }`}
                                                        to={`/company/${companyId}/project/${directProjectId}/channels/${sublink.link}`}
                                                    >
                                                        <p
                                                            className={`sidebar-${sublink.key} venue sub`}
                                                        >
                                                            {sublink.name}
                                                        </p>
                                                    </Link>
                                                </li>
                                            </Tooltip>
                                        );
                                    })}
                                </>
                            )}
                            {/* Virtual sub pages */}
                            {all &&
                                ml === primaryActive &&
                                (ml === 'virtual' ||
                                    ml === 'livepages' ||
                                    ml === 'menus' ||
                                    ml === 'authentication') &&
                                mainMenuData?.allVirtualSub?.map((sub) => {
                                    const sl = textNormalizer(sub.name);
                                    return (
                                        <li
                                            key={sub.key}
                                            className={`nav-item venue nav-item__sub`}
                                        >
                                            <Link
                                                className={`nav-link ${
                                                    sl === secondaryActive &&
                                                    'active'
                                                }
                                                ${
                                                    secondaryActive ===
                                                        'livepage' &&
                                                    sl === 'livepages' &&
                                                    'active'
                                                }
                                                ${
                                                    menuClicked && 'menuclicked'
                                                }`}
                                                to={`/company/${companyId}/project/${
                                                    directProjectId !==
                                                        undefined &&
                                                    directProjectId
                                                }/${sub.link}`}
                                            >
                                                <p
                                                    className={`sidebar-${sl} venue sub`}
                                                >
                                                    {' '}
                                                    {sub.name}{' '}
                                                </p>
                                            </Link>
                                        </li>
                                    );
                                })}
                            {menuClicked && (
                                <ReactTooltip
                                    className={`tooltipx`}
                                    effect="solid"
                                    place="right"
                                />
                            )}
                        </React.Fragment>
                    );
                })}
            </ul>
        </GlobalSidebar>
    );
};

export default withRouter(MainMenu);
