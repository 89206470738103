import * as ls from "local-storage";

/**
 * Get all projects for Livestream
 */
export async function createProject(data) {
	const auth = ls.get("auth");
	const authBearer = `Bearer ${auth.access_token}`;

	const response = await fetch(
		`${process.env.REACT_APP_MYXP_API}/manager/livestream/projects`,
		{
			method: "POST",
			headers: {
				Authorization: authBearer
			},
			body: data
		},
	);

	return await response.json();
}
